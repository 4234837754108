import { useEffect, useState } from "react";
import {
  Card,
  Common,
  Icon,
  RedirectToOUI,
  CustomScrollbar,
} from "@/components";
import { CustommerRequestItem } from "./CustommerRequestItem";
import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector, userViewSelector } from "@/providers/auth/selector";

import { useGetRelationshipConnects } from "../services/dashboard";
import { USER_VIEW } from "@/configuration/globalVariable";

export const CustommerRequest = () => {
  const [listCustomer, setListCustomer] = useState<any>([]);
  const user = useAppSelector(userSelector);
  const getRelationshipConnects = useGetRelationshipConnects();
  const userView = useAppSelector(userViewSelector);
  const { isSuccess, data, isLoading } = getRelationshipConnects;

  useEffect(() => {
    getRelationshipConnects.mutate({
      accountId: user.accountId,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setListCustomer(data);
    }
    // eslint-disable-next-line
  }, [isSuccess, data]);

  const handleFilter = (removeItem: any) => {
    setListCustomer(
      listCustomer.filter(
        (item: any) => removeItem.relationshipId !== item.relationshipId
      )
    );
  };

  return (
    <Card
      title={`${
        userView === USER_VIEW.SUPPLIER ? `Customer` : `Supplier`
      } Request`}
      className="mb-4 xl:mb-0 lg:h-72 xl:h-1/2 flex-1 xl:flex-auto"
      pathViewAll={"/relationship/accounts/ReceivedConnectionRequests"}
      isLoading={isLoading}
    >
      {Boolean(listCustomer.length) && (
        <div className="h-full w-full py-2 lg:absolute">
          <CustomScrollbar
            renderView={(props) => (
              <div {...props} className="!static lg:!absolute" />
            )}
          >
            <div className="px-5">
              {listCustomer.map((item: any, index: any) => (
                <CustommerRequestItem
                  key={item.relationshipId}
                  isFirstItem={!index}
                  data={item}
                  onActionComplete={() => handleFilter(item)}
                />
              ))}
            </div>
          </CustomScrollbar>
        </div>
      )}
      {!isLoading && !listCustomer.length && (
        <Common.NoData
          iconRender={Icon.TeamRounded}
          title="You don't have any connection request"
          bottomElement={
            userView === USER_VIEW.CUSTOMER ? (
              <RedirectToOUI to={"/relationship/accounts/browseSuppliers"}>
                <Common.Button className="mt-5">
                  Browse TOM&apos;s supplier list
                </Common.Button>
              </RedirectToOUI>
            ) : null
          }
        />
      )}
      {isLoading && (
        <Common.NoData
          iconRender={Icon.TeamRounded}
          title="Loading your connection request ..."
        />
      )}
    </Card>
  );
};

import headerAvatar from "./header-avatar.png";
import logo from "./Logo.svg";
import noAvatar from "./no-avatar.jpg";
import authLogoBg from "./auth-logo-bg.jpg";
import noImage from "./no-image-available.jpeg";
import noImg from "./logo-placeholder.png";
import cusTopLeftBg from "./bg-cus-Left-pattern.png";
import cusTopRightBg from "./bg-cus-right-pattern.png";

import appStore from "./app-store.svg";
import googlePlay from "./google-play.svg";
import CustomerApp from "./CustomerApp.png";
import DriverApp from "./DriverApp.png";
import shop from "./shop.svg";
import shopBlue from "./shopBlue.svg";
import checked from "./checked.svg";
import flag from "./flag.svg";

import clock from "./clock.svg";
import deliveryTime from "./delivery-time.svg";
import distant from "./distant.svg";
import driver from "./driver.svg";
import location from "./location.svg";
import order from "./order.svg";
import stock from "./stock.svg";
import warningCircle from "./warning-circle.svg";

import marker from "./marker.svg";
import markerFlagEnd from "./markerFlagEnd.svg";
import markerStore from "./markerStore.svg";

import IconStatus from "./IconStatus.png";

export const Images = {
  logo,
  headerAvatar,
  noAvatar,
  noImage,
  authLogoBg,
  cusTopLeftBg,
  cusTopRightBg,
  noImg,
  appStore,
  googlePlay,
  CustomerApp,
  DriverApp,
  shop,
  shopBlue,
  checked,
  flag,
  clock,
  deliveryTime,
  distant,
  driver,
  location,
  order,
  stock,
  warningCircle,
  marker,
  markerFlagEnd,
  markerStore,
  IconStatus,
};

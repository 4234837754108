import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { userData, LoginData } from "@/models";
import { AUTH, USER_VIEW } from "@/configuration/globalVariable";
const API_PATH = "account/";

// Disabled feature
export const fetchLoginAsync = createAsyncThunk(
  "fetchLoginUser",
  async (data: LoginData) => {
    return (
      axios
        // .post(`latest/authentication/loginnew`, {
        .post(`authentication/login`, {
          ...data,
          // accountId: AUTH.ACCOUNT_ID,
          accountId: "",
          apiKey: AUTH.API_KEY,
          clientInfo: AUTH.CLIENT_INFO,
        })
        .then((res) => {
          if (res.data.errorMessage) {
            return res.data;
          }
          return res.data.data;
        })
    );
  }
);

export const fetchUserProfileAsync = createAsyncThunk(
  "fetchUserProfile",
  async () => {
    const res = await axios.get(`${API_PATH}getprofile`).then((res) => {
      if (res.data.errorMessage) {
        return res.data;
      }
      return res.data.data;
    });
    return res;
  }
);

export const fetchSwitchAccountAsync = createAsyncThunk(
  "fetchSwitchAccountAsync",
  async (data: { accountId: string; defaultView?: number }) => {
    const res = await axios
      .post(`${API_PATH}switchaccount`, data)
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      });
    return res;
  }
);

const initialState = {
  user: {} as userData,
  loading: false,
  loaded: false,
  errorMessage: "",
};

const checkUserView = (userProfile: userData) => {
  return {
    ...userProfile,
    userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER].includes(
      userProfile.userView
    )
      ? userProfile.userView
      : USER_VIEW.CUSTOMER,
  };
};

const authSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<userData>) => {
      state.user = checkUserView(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLoginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.user = checkUserView(action.payload);
      })
      .addCase(fetchUserProfileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserProfileAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.user = checkUserView(action.payload);
      })
      .addCase(fetchSwitchAccountAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSwitchAccountAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.user = checkUserView(action.payload);
      });
  },
});
export const { setUser } = authSlice.actions;
export default authSlice;

import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath={`url(#clip${uid})`}>
        <path
          d="M0.666748 7.99996C0.666748 7.99996 3.33341 2.66663 8.00008 2.66663C12.6667 2.66663 15.3334 7.99996 15.3334 7.99996C15.3334 7.99996 12.6667 13.3333 8.00008 13.3333C3.33341 13.3333 0.666748 7.99996 0.666748 7.99996Z"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.00008 9.99996C9.10465 9.99996 10.0001 9.10453 10.0001 7.99996C10.0001 6.89539 9.10465 5.99996 8.00008 5.99996C6.89551 5.99996 6.00008 6.89539 6.00008 7.99996C6.00008 9.10453 6.89551 9.99996 8.00008 9.99996Z"
          fill="none"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

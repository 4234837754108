import { useState } from "react";
import { useOutletContext } from "react-router-dom";

import { LayoutPartials, Common } from "@/components";
import { TabAllocation } from "../components";

const tabs = [
  {
    name: "Allocation",
    current: true,
  },
  {
    name: "Route",
    current: false,
    linkNavigate: "/delivery/routes",
  },
];

interface seDateContextType {
  date: any;
  setDate: (date: any) => void;
}

const Index = () => {
  const [activeTab, setActiveTab] = useState<any>(tabs[0].name);
  const [date, setDate] = useOutletContext<seDateContextType | any>();

  return (
    <LayoutPartials.BodyContent
      pageTitle="Delivery Management"
      rightContent={
        <Common.TabUnderline
          data={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          wrapClassName="mt-1"
        />
      }
    >
      {activeTab === tabs[0].name && (
        <TabAllocation date={date} setDate={setDate} />
      )}
    </LayoutPartials.BodyContent>
  );
};

export default Index;

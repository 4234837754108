import { FC, SelectHTMLAttributes } from "react";
import clsx from "clsx";
import { WrapInput } from "./WrapInput";

interface selectItem {
  id: number;
  text: string;
  value: string | number;
}

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  wrapClassName?: string;
  id?: string;
  ipSize?: "lg" | "md";
  isError?: boolean;
  errorMessage?: string;
  className?: string;
  options: selectItem[];
}

export const Selectbox: FC<Props> = ({
  label,
  wrapClassName,
  id,
  ipSize = "lg",
  isError,
  errorMessage,
  className,
  disabled,
  options,
  ...rest
}) => {
  return (
    <WrapInput
      label={label}
      errorMessage={errorMessage}
      wrapClassName={wrapClassName}
    >
      <select
        {...rest}
        id={id}
        className={clsx(
          "block w-full border text-base rounded",
          ipSize === "md" ? "py-1 pl-2" : "py-[0.375rem] pl-3",
          isError
            ? "border-red focus:outline-none focus:ring-red focus:border-red"
            : "border-neutral-20 focus:ring-blue focus:border-blue",
          disabled ? "bg-neutral-20 text-neutral-40" : "text-neutral",
          className
        )}
        disabled={disabled}
      >
        {options.map((item, index) => (
          <option key={index + item.id} value={item.value}>
            {item.text}
          </option>
        ))}
      </select>
    </WrapInput>
  );
};

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3303_807234)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2H13V0H11V2H9V0H7V2H5V0H3V2H1C0.447 2 0 2.447 0 3V4H16V3C16 2.447 15.553 2 15 2ZM16 5H0V15C0 15.553 0.447 16 1 16H15C15.553 16 16 15.553 16 15V5ZM11.7134 7.91162L10.8384 7.03662C10.8149 7.01319 10.7831 7.00002 10.75 7.00002C10.7169 7.00002 10.6851 7.01319 10.6616 7.03662L6.875 10.8232L5.33838 9.28662C5.31494 9.26319 5.28315 9.25002 5.25 9.25002C5.21686 9.25002 5.18507 9.26319 5.16163 9.28662L4.28663 10.1616C4.2632 10.1851 4.25003 10.2169 4.25003 10.25C4.25003 10.2831 4.2632 10.3149 4.28663 10.3384L6.78663 12.8384C6.81007 12.8618 6.84186 12.875 6.875 12.875C6.90815 12.875 6.93994 12.8618 6.96338 12.8384L11.7134 8.08837C11.7368 8.06493 11.75 8.03314 11.75 8C11.75 7.96685 11.7368 7.93506 11.7134 7.91162Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3303_807234">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

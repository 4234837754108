import {
  DashboardTop,
  PendingOrder,
  CustommerRequest,
  TodayRoutes,
} from "../components";

export const SupplierView = () => {
  return (
    <div className="h-full flex flex-col">
      <DashboardTop />
      <div className="flex-1 xl:flex">
        <PendingOrder />
        <div className="lg:flex xl:flex-col xl:ml-3 xl:w-1/3 mt-3 xl:mt-0 lg:space-x-4 xl:space-x-0">
          <TodayRoutes />
          <CustommerRequest />
        </div>
      </div>
    </div>
  );
};

export default SupplierView;

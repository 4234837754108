import { memo } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Common, DeliveryStatusLabel, Icon } from "@/components";
import { distanceFormat, timeWorkingFormat } from "@/utils";
import {
  DeliveryLineStatusValue,
  DeliveryStatusValue,
  PATH,
} from "@/configuration/globalVariable";
import { manifest, manifestLine } from "../models/DeliveryManifest";
import { driverName } from "../utils";

interface Props {
  optimized?: boolean;
  isFirst?: boolean;
  isChecked?: boolean;
  data: manifest;
  canCheck: boolean;
  isETAsLoading?: boolean;
  onCheck: (id: string, isStopped: boolean) => void;
  onChangeDriver: () => void;
}

export const DeliveryItem = memo(
  ({
    optimized,
    isFirst,
    data,
    isChecked,
    canCheck,
    onCheck,
    isETAsLoading,
    onChangeDriver,
  }: Props) => {
    const workingTime = timeWorkingFormat(data?.workingTime || 0);
    const distance = distanceFormat(data?.distance || 0);

    const isETAMapLoading =
      isETAsLoading && Boolean(data.manifestLines.length && !data.polylines);
    const isCanChangeDriver = ![
      DeliveryStatusValue.COMPLETED,
      DeliveryStatusValue.DELIVERING,
    ].includes(data.status);

    return (
      <div
        data-manifest-id={data.id}
        className={clsx(
          "flex px-4 py-2.5 border border-neutral-10 rounded group hover:bg-neutral-5",
          !isFirst && "mt-2",
          isChecked ? "bg-blue-5" : "border-neutral-10"
        )}
      >
        <div
          data-tip={
            !canCheck
              ? "Can’t select the Delivering route to<br />Optimize or Edit. You can force stop in<br />route details to perform actions."
              : null
          }
        >
          <Common.Checkbox
            disabled={!canCheck || isETAMapLoading}
            checked={isChecked}
            onChange={() =>
              onCheck(data.id, data.status === DeliveryStatusValue.STOPPED)
            }
            ipSize="md"
          />
        </div>
        <div className="ml-4 text-sm text-neutral-70 flex-1">
          <div className="flex items-center mb-2">
            <Common.DotColor style={{ backgroundColor: data.route.colour }} />
            <div className="flex-1 flex items-center">
              <div
                className="font-semibold text-neutral-80 mx-2 line-clamp-1"
                data-tip={data.route.name}
              >
                {data.route.name}
              </div>
              {data.status !== DeliveryStatusValue.PENDING && (
                <DeliveryStatusLabel status={data.status} />
              )}
              {optimized && (
                <div className="ml-3" data-tip="Optimized">
                  <Icon.CheckedCircle size="16" />
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center mb-2">
            <div data-tip="Distance">
              <Icon.Distant size="16" className="fill-neutral-40" />
            </div>
            <div className="ml-1.5 h-4 flex items-center">
              {isETAMapLoading ? <Icon.Loading size="12" /> : distance}
            </div>
            <div data-tip="Working time">
              <Icon.Clock size="12" className="fill-neutral-40 ml-4 mr-1.5" />
            </div>
            <div className="h-4 flex items-center">
              {isETAMapLoading ? <Icon.Loading size="12" /> : workingTime}
            </div>
          </div>
          <div className="flex">
            <div
              data-tip="Driver"
              className={clsx(isCanChangeDriver && "cursor-pointer")}
              onClick={() => (isCanChangeDriver ? onChangeDriver() : {})}
            >
              <Icon.Driver size="16" className="fill-neutral-40" />
            </div>
            <div className="min-w-[3.438rem] ml-1.5">
              {driverName(data.driver) || "No driver"}
            </div>
            <div className="mt-0.5" data-tip="Order completed">
              <Icon.DeliveryTime
                size="12"
                className="fill-neutral-40 ml-4 mr-1.5"
              />
            </div>
            <div className="whitespace-nowrap">
              {
                data.manifestLines.filter(
                  (mnf: manifestLine) =>
                    mnf.deliveryStatus === DeliveryLineStatusValue.COMPLETED
                ).length
              }{" "}
              / {data.manifestLines.length} order
              {data.manifestLines.length > 1 && "s"}
            </div>
          </div>
        </div>
        {!(isETAMapLoading || isETAsLoading) && (
          <div className="flex items-center ml-auto opacity-0 group-hover:opacity-100">
            <Link
              to={`/${PATH.DELIVERY._}/${PATH.DELIVERY.DELIVERY_MANIFESTS}/${data.id}`}
              state={{ data }}
            >
              <Common.ViewDetailBtn />
            </Link>
          </div>
        )}
      </div>
    );
  }
);

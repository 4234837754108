import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M31.8451 30.9067L28.5118 26.9067C28.4492 26.8317 28.3709 26.7712 28.2825 26.7298C28.194 26.6883 28.0975 26.6668 27.9998 26.6667H3.99975C3.90203 26.6668 3.80552 26.6883 3.71704 26.7298C3.62857 26.7712 3.55029 26.8317 3.48775 26.9067L0.154417 30.9067C0.0733444 31.0041 0.0216812 31.1225 0.00547714 31.2481C-0.0107269 31.3738 0.00919907 31.5014 0.0629217 31.6161C0.116644 31.7309 0.20194 31.8279 0.30882 31.8959C0.4157 31.9639 0.539741 32 0.666417 32.0001H31.3331C31.4598 32 31.5838 31.9639 31.6907 31.8959C31.7976 31.8279 31.8829 31.7309 31.9366 31.6161C31.9903 31.5014 32.0102 31.3738 31.994 31.2481C31.9778 31.1225 31.9262 31.0041 31.8451 30.9067Z" />
        <path d="M8.66667 14H4.66667C4.29848 14 4 14.2985 4 14.6667V24.6667C4 25.0349 4.29848 25.3333 4.66667 25.3333H8.66667C9.03486 25.3333 9.33333 25.0349 9.33333 24.6667V14.6667C9.33333 14.2985 9.03486 14 8.66667 14Z" />
        <path d="M17.9997 14H13.9997C13.6315 14 13.333 14.2985 13.333 14.6667V24.6667C13.333 25.0349 13.6315 25.3333 13.9997 25.3333H17.9997C18.3679 25.3333 18.6663 25.0349 18.6663 24.6667V14.6667C18.6663 14.2985 18.3679 14 17.9997 14Z" />
        <path d="M27.3337 14H23.3337C22.9655 14 22.667 14.2985 22.667 14.6667V24.6667C22.667 25.0349 22.9655 25.3333 23.3337 25.3333H27.3337C27.7018 25.3333 28.0003 25.0349 28.0003 24.6667V14.6667C28.0003 14.2985 27.7018 14 27.3337 14Z" />
        <path d="M2.77897 12.3693C2.8398 12.4607 2.92225 12.5356 3.01901 12.5875C3.11578 12.6394 3.22385 12.6665 3.33364 12.6666H28.667C28.7768 12.6665 28.8848 12.6394 28.9816 12.5875C29.0784 12.5356 29.1608 12.4607 29.2216 12.3693L30.555 10.3693C30.6218 10.2689 30.6601 10.1522 30.6659 10.0318C30.6716 9.91132 30.6446 9.79156 30.5877 9.68525C30.5308 9.57894 30.4461 9.49006 30.3426 9.42807C30.2392 9.36608 30.1209 9.33331 30.0003 9.33325H2.00031C1.87972 9.33331 1.7614 9.36608 1.65796 9.42807C1.55453 9.49006 1.46984 9.57894 1.41292 9.68525C1.356 9.79156 1.32898 9.91132 1.33475 10.0318C1.34051 10.1522 1.37883 10.2689 1.44564 10.3693L2.77897 12.3693Z" />
        <path d="M2.00035 7.99993H30.0003C30.1519 8.00031 30.2991 7.94902 30.4176 7.85453C30.5361 7.76003 30.6189 7.62798 30.6523 7.48013C30.6857 7.33228 30.6677 7.17747 30.6013 7.04121C30.5349 6.90496 30.424 6.7954 30.287 6.7306L16.287 0.0639316C16.1973 0.0218277 16.0994 0 16.0003 0C15.9013 0 15.8034 0.0218277 15.7137 0.0639316L1.71368 6.7306C1.57666 6.7954 1.46581 6.90496 1.39941 7.04121C1.33301 7.17747 1.31502 7.33228 1.34841 7.48013C1.3818 7.62798 1.46457 7.76003 1.58309 7.85453C1.7016 7.94902 1.84878 8.00031 2.00035 7.99993Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { useState } from "react";
import { LayoutPartials, Common } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../../components";

export const TimePicker = () => {
  const [time, setTime] = useState<Date | null>(null);
  const handleChange = (date: Date) => {
    setTime(date);
  };

  return (
    <LayoutPartials.BodyContent className="mb-4" pageTitle="Timepicker Input">
      <ComponentInfo
        title="TimePicker input component"
        code={`import { Common } from "@/components";

const [time, setTime] = useState<Date | null>(null);
const handleChange = (date: Date) => {
  setTime(date);
};

<Common.TimePicker selected={time} onChange={handleChange} />`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  ipSize?: "lg" | "md";
  className?: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  disabled?: boolean;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  ipSize = "lg",
}`}
      />

      <DisplayComponent
        componentName="Timepicker input"
        className="mb-3"
        RenderComp={() => (
          <Common.TimePicker selected={time} onChange={handleChange} />
        )}
        code={`const [time, setTime] = useState<Date | null>(null);
const handleChange = (date: Date) => {
  setTime(date);
};

<Common.TimePicker selected={time} onChange={handleChange} />`}
      />
    </LayoutPartials.BodyContent>
  );
};

import { EPickingStatus } from "@/configuration/globalVariable";
import { addSpaceBeforeUppercaseChars } from "@/utils";

export interface DetailRowProps {
  index: number;
  productId: string;
  productSKU: string;
  productName: string;
  unit: string;
  pickingStatus: EPickingStatus;
  quantity: number | null;
  comment: string | null;
}
export const DetailRow = (props: DetailRowProps) => (
  <>
    <tr>
      <td className="border border-line pl-8 pr-2 w-5/12">
        {`${props.productName} (${props.productSKU})`}
      </td>
      <td className="border border-line px-2 text-right w-1/12">
        {props.unit}
      </td>
      <td className="border border-line px-2 text-right w-2/12">
        {addSpaceBeforeUppercaseChars(EPickingStatus[props.pickingStatus])}
      </td>
      <td className="border border-line px-2 text-right w-1/12">
        {props.quantity}
      </td>
      <td className="border border-line px-2 w-3/12">{props.comment}</td>
    </tr>
  </>
);

import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import { Images } from "@/assets/images";
import { Common, Icon, RedirectToOUI } from "@/components";
import { useLogout } from "@/services/authentication";
import { useAppSelector } from "@/sharedStore/hooks";
import {
  userSelector,
  accountsSelector,
  userViewSelector,
} from "@/providers/auth/selector";
// import {
//   fetchUserProfileAsync,
//   // fetchSwitchAccountAsync,
// } from "@/providers/auth/authSlice";
import { activeAccounts } from "@/models";
import { USER_VIEW } from "@/configuration/globalVariable";
import { imgUrl } from "@/utils";
// import { SnackbarUtils } from "@/utils";

const menuItem = [
  {
    id: 1,
    text: "My User Profile",
    link: `/global/profile/view`,
  },
  {
    id: 2,
    text: "My Company Account",
    link: `/global/account/view`,
  },
  {
    id: 3,
    text: "My Subscription",
    link: `/subscriptions/billingplans`,
  },
  // {
  //   id: 4,
  //   text: "Switch Business",
  //   link: `/global/account/selectaccount`,
  // },
];

const DropdownList = () => {
  const userView = useAppSelector(userViewSelector);
  const user = useAppSelector(userSelector);
  const accounts = useAppSelector(accountsSelector);
  // const dispatch = useAppDispatch();
  const logout = useLogout();

  const handleSignOut = () => {
    logout();
  };
  const switchBusinessPath = `/webapi/page/process/SwitchAccount?accountId=${
    user.accountId
  }&view=${
    user.userView === USER_VIEW.SUPPLIER
      ? USER_VIEW.CUSTOMER
      : USER_VIEW.SUPPLIER
  }`;

  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return (
    <div className="py-1 relative z-50">
      {accounts && accounts.length && (
        <div>
          <div className="font-semibold text-neutral-50 mb-2 mt-4 px-4 text-sm">
            COMPANY ACCOUNTS
          </div>
          <div className="max-h-[12.5rem] overflow-y-auto">
            {accounts.map((account: activeAccounts, index) => (
              <RedirectToOUI
                to={`/webapi/page/process/SwitchAccount?accountId=${account.accountId}`}
                key={index}
              >
                <Common.DropdownItem
                  className={clsx(
                    user?.accountId === account.accountId && "bg-neutral-10"
                  )}
                >
                  <div
                    className="inline-block h-6 w-6 rounded-full bg-cover bg-center mr-2"
                    style={{
                      backgroundImage: `url('${imgUrl(
                        account.logoImageUrl,
                        "avatar"
                      )}')`,
                    }}
                  />
                  <span
                    data-tip={account.tradingName}
                    className="mr-auto truncate w-[12rem] text-left font-semibold text-neutral"
                  >
                    {account.tradingName}
                  </span>
                  {user?.accountId === account.accountId && (
                    <Icon.CheckedFill size="16" className="fill-blue ml-3" />
                  )}
                </Common.DropdownItem>
              </RedirectToOUI>
            ))}
          </div>
          <RedirectToOUI to={`/Global/Account/EmptyBusinessAccount`}>
            <Common.DropdownItem>
              <Icon.AddCircle size="12" className="mr-2 fill-neutral-50" />
              Add an account
            </Common.DropdownItem>
          </RedirectToOUI>
          <div className="border-b border-neutral-20 my-2" />
        </div>
      )}
      {menuItem.map((item) => (
        <RedirectToOUI key={item.id} to={item.link}>
          <Common.DropdownItem>{item.text}</Common.DropdownItem>
        </RedirectToOUI>
      ))}
      <RedirectToOUI to={switchBusinessPath}>
        <Common.DropdownItem className="flex justify-between">
          Switch to {userView === USER_VIEW.CUSTOMER ? "Supplier" : "Customer"}{" "}
          view
          <Icon.Switch className="fill-blue ml-4" />
        </Common.DropdownItem>
      </RedirectToOUI>
      <div className="border-t border-neutral-10 pb-1 mt-1" />
      <Common.DropdownItem onClick={handleSignOut} className="text-red">
        Sign out
      </Common.DropdownItem>
    </div>
  );
};

function Avatar() {
  const user = useAppSelector(userSelector);

  // const handleSwitchAccount = (account: activeAccounts) => {
  //   dispatch(fetchSwitchAccountAsync({ accountId: account.accountId })).then(
  //     () => {
  //       SnackbarUtils.success(
  //         `Switched to <b>${account.tradingName}</b> successfully.`
  //       );
  //     }
  //   );
  // };

  // useEffect(() => {
  //   if (!user?.token) {
  //     dispatch(fetchUserProfileAsync());
  //   }
  // }, [dispatch, user]);

  // const handleSwitchBusiness = () => {
  //   const defaultView =
  //     user.userView === USER_VIEW.SUPPLIER
  //       ? USER_VIEW.CUSTOMER
  //       : USER_VIEW.SUPPLIER;
  //   dispatch(
  //     fetchSwitchAccountAsync({
  //       accountId: user.accountId,
  //       defaultView,
  //     })
  //   ).then(() => {
  //     SnackbarUtils.success(
  //       `Switched to ${
  //         defaultView === USER_VIEW.SUPPLIER ? "supplier" : "customer"
  //       } view successfully.`
  //     );
  //   });
  // };

  return (
    <Common.DropDowns
      buttonRender={
        <div className="flex items-center ml-3 text-left">
          <div
            className="inline-block h-8 w-8 rounded-full bg-cover bg-center"
            style={{ backgroundImage: `url('${Images.noAvatar}')` }}
          />
          <div className="ml-2.5 hidden md:block">
            <p className="font-semibold group-hover:text-neutral-70 truncate w-[8.125rem]">
              {user?.displayName}
            </p>
            <p className="text-xs text-neutral-60 text-left group-hover:text-neutral-70 truncate w-[8.125rem]">
              {user?.companyName}
            </p>
          </div>
        </div>
      }
      listRender={<DropdownList />}
    />
  );
}

export { Avatar };

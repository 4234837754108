import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.66675C0 3.11446 0.447715 2.66675 1 2.66675H20.3333C20.8856 2.66675 21.3333 3.11446 21.3333 3.66675V8.00004H27.3228C27.6993 8.00004 28.0438 8.2115 28.2143 8.54717L31.8915 15.7866C31.9628 15.9269 31.9999 16.0821 31.9999 16.2395V23C31.9999 23.5523 31.5522 24 30.9999 24H29.3333V24.0001C29.3333 26.9456 26.9454 29.3334 23.9999 29.3334C21.0544 29.3334 18.6666 26.9456 18.6666 24.0001H13.3333C13.3333 26.9456 10.9454 29.3334 7.99992 29.3334C5.0544 29.3334 2.66659 26.9456 2.66659 24.0001L1 24.0001C0.447717 24.0001 0 23.5524 0 23.0001V3.66675ZM21.5834 10.6667C21.4453 10.6667 21.3334 10.7787 21.3334 10.9167V15.7501C21.3334 15.8882 21.4453 16.0001 21.5834 16.0001H27.5956C27.7814 16.0001 27.9023 15.8045 27.8192 15.6383L25.4025 10.8049C25.3602 10.7202 25.2736 10.6667 25.1789 10.6667H21.5834ZM23.9999 26.6667C25.4727 26.6667 26.6666 25.4728 26.6666 24.0001C26.6666 22.5273 25.4727 21.3334 23.9999 21.3334C22.5272 21.3334 21.3333 22.5273 21.3333 24.0001C21.3333 25.4728 22.5272 26.6667 23.9999 26.6667ZM10.6666 24.0001C10.6666 25.4728 9.47268 26.6667 7.99992 26.6667C6.52716 26.6667 5.33325 25.4728 5.33325 24.0001C5.33325 22.5273 6.52716 21.3334 7.99992 21.3334C9.47268 21.3334 10.6666 22.5273 10.6666 24.0001Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { ModalContent, Card, Common } from "@/components";
import { driverOption, manifest } from "../models";
import { useEffect, useState } from "react";
import { useChangeDriver, useFetchDrivers } from "../services/deliveryManifest";
import { DriverCombobox } from "./DriverCombobox";
import { driverName } from "../utils";
import { SnackbarUtils } from "@/utils";
import { changeDriverIntoManifest } from "../providers/deliveryManifest/slice";
import { useAppDispatch } from "@/sharedStore/hooks";

interface DriverRes {
  text: string;
  value: string;
}
interface Props {
  open: boolean;
  onClose: () => void;
  currentData: manifest;
  manifests: manifest[];
  onSubmit: (position: number) => void;
}

export const ModalChangeDriver = ({
  open,
  onSubmit,
  onClose,
  currentData,
  manifests,
}: Props) => {
  const { data } = useFetchDrivers();
  const changeDriver = useChangeDriver();
  const [drivers, setDrivers] = useState<any>([]);
  const [defaultDriver, setDefaultDriver] = useState<boolean>(false);
  const [isAllocated, setAllocated] = useState<string[]>([]);
  const [driverSelected, setDriverSelected] = useState<driverOption>({
    id: currentData?.driver?.id,
    text: driverName(currentData?.driver),
    value: currentData?.driver?.id,
  });
  const { isLoading } = changeDriver;
  const dispatch = useAppDispatch();
  const handleClose = () => {
    onClose();
    onSubmit(1);
  };

  useEffect(() => {
    data &&
      setDrivers(
        data
          .sort((a: DriverRes, b: DriverRes) => {
            if (a.text < b.text) {
              return -1;
            }
            if (a.text > b.text) {
              return 1;
            }
            return 0;
          })
          .map((driver: DriverRes) => {
            return {
              id: driver.value,
              text: driver.text,
              value: driver.value,
            };
          })
      );
  }, [data]);
  useEffect(() => {
    setDriverSelected({
      id: currentData?.driver?.id,
      text: driverName(currentData?.driver),
      value: currentData?.driver?.id,
    });
  }, [currentData]);

  const handleChange = (drive: driverOption) => {
    const allAllocated = [] as string[];
    manifests.map((manifest: manifest) => {
      if (manifest.driver.id === drive.id) {
        allAllocated.push(drive.id);
      }
      return manifest;
    });
    setAllocated(allAllocated);
    setDriverSelected(drive);
  };

  const handleSave = () => {
    changeDriver.mutate(
      {
        manifestId: currentData.id,
        driverId: driverSelected.id,
        isDefault: defaultDriver,
      },
      {
        onSuccess(data) {
          SnackbarUtils.success(
            `Driver <b>${driverSelected.text}</b> has been changed to delivery for route <b>${currentData.route.name}</b> successfully.`
          );
          dispatch(
            changeDriverIntoManifest({
              manifestChangeDriver: {
                ...currentData,
                driver: {
                  ...currentData.driver,
                  ...data.driver,
                },
              },
            })
          );
          handleClose();
        },
      }
    );
  };

  return (
    <ModalContent
      open={open}
      onClose={handleClose}
      panelClassWidth="max-w-[30rem] w-full"
    >
      <Card title={"Change driver"}>
        <div className="p-4">
          <div className="font-bold mb-4">
            Select driver for <b>{currentData?.route?.name}</b>:
          </div>
          <DriverCombobox
            drivers={drivers}
            value={driverSelected}
            onChange={handleChange}
          />
          <Common.Checkbox
            onChange={(e) => setDefaultDriver(e.target.checked)}
            checked={defaultDriver}
            label="Set as default driver"
            wrapClassName="mt-4"
          />
        </div>
        {isAllocated.length >= 1 && (
          <div className="text-yellow-dark text-center px-4 pb-4">
            Warning! The selected driver is currently allocated into more than 1
            route on the same date. Make sure this is intended.
          </div>
        )}
        <div className="shadow-overline px-4 py-3 flex space-x-2 justify-end">
          <Common.Button
            disabled={isLoading}
            onClick={handleClose}
            color="transparent"
          >
            Cancel
          </Common.Button>
          <Common.Button
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => handleSave()}
          >
            Save
          </Common.Button>
        </div>
      </Card>
    </ModalContent>
  );
};

import axios from "axios";
import { useMutation } from "react-query";
import { optimizeRequest } from "../models";
// import { manifest } from "../models";
// import { sortManifestLine } from "../utils";

const API_PATH = "Delivery/";

// export const useGetOptimize = () => {
//   return useQuery(["customers"], async () => {
//     return axios.get(`${API_PATH}Optimize`).then((value) => value.data);
//   });
// };

// {
//   "timBalance": true,
//   "visitBalanceCoefficient": 2.0,
//   "routes": [
//     {
//       "id": "fb2eb1b0-cf96-4216-adec-7bd700542ffd",
//       "manifestId": "dad0f1e7-6c67-4f18-ab6d-fd86863d43e2",
//       "workStartTime": "sample string 3",
//       "workEndTime": "sample string 4",
//       "breakStartTime": "sample string 5",
//       "breakEndTime": "sample string 6",
//       "returnToDepot": true,
//       "excludeOptimize": true,
//       "totalTimeBefore": 9,
//       "totalDistanceBefore": 10
//     },
//     {
//       "id": "fb2eb1b0-cf96-4216-adec-7bd700542ffd",
//       "manifestId": "dad0f1e7-6c67-4f18-ab6d-fd86863d43e2",
//       "workStartTime": "sample string 3",
//       "workEndTime": "sample string 4",
//       "breakStartTime": "sample string 5",
//       "breakEndTime": "sample string 6",
//       "returnToDepot": true,
//       "excludeOptimize": true,
//       "totalTimeBefore": 9,
//       "totalDistanceBefore": 10
//     }
//   ]
// }

export const useOptimizeRequest = () =>
  useMutation((data: optimizeRequest) =>
    axios
      .post(`${API_PATH}Optimize`, {
        ...data,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res?.data?.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useFetchOptimizeByJobId = () =>
  useMutation((jobId: string) =>
    axios
      .get(`${API_PATH}Optimize`, {
        params: { jobId },
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res?.data?.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useSubmitOptimizeRoutes = (completedAction: any) =>
  useMutation((data: any) =>
    axios
      .post(`${API_PATH}ApplyOptimize`, data)
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }

        if (completedAction) {
          completedAction();
        }

        return res?.data?.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

// {
//   "unserved": [
//     {
//       "manifestLine": {
//         "id": "00000000-0000-0000-0000-000000000000",
//         "manifestId": "00000000-0000-0000-0000-000000000000",
//         "documentId": "00000000-0000-0000-0000-000000000000",
//         "invoiceId": "00000000-0000-0000-0000-000000000000",
//         "orderId": "00000000-0000-0000-0000-000000000000",
//         "documentGroupId": "00000000-0000-0000-0000-000000000000",
//         "documentAccountId": "00000000-0000-0000-0000-000000000000",
//         "document": {
//           "id": "00000000-0000-0000-0000-000000000000",
//           "tenantAccountId": "00000000-0000-0000-0000-000000000000",
//           "documentTypeId": 0,
//           "note": "string",
//           "deliveryAddressId": "00000000-0000-0000-0000-000000000000",
//           "deliveryAddressLine1": "string",
//           "deliveryAddressLine2": "string",
//           "deliverySuburb": "string",
//           "deliveryState": "string",
//           "deliveryPostCode": "string",
//           "deliveryCountry": "string",
//           "deliveryLatitude": 0,
//           "deliveryLongitude": 0,
//           "saleDocumentNumber": "string",
//           "purchaseDocumentNumber": "string",
//           "isDeliverable": true,
//           "documentTypeName": "string",
//           "documentTypeKey": "string",
//           "customerAccountId": "00000000-0000-0000-0000-000000000000",
//           "supplierAccountId": "00000000-0000-0000-0000-000000000000",
//           "customerTradingName": "string",
//           "documentRefs": [
//             {
//               "id": "00000000-0000-0000-0000-000000000000",
//               "saleDocumentNumber": "string",
//               "purchaseDocumentNumber": "string",
//               "documentTypeId": 0,
//               "workflowStateId": "00000000-0000-0000-0000-000000000000",
//               "workflowState": {
//                 "id": "00000000-0000-0000-0000-000000000000",
//                 "workflowId": "00000000-0000-0000-0000-000000000000",
//                 "name": "string",
//                 "isEditable": true,
//                 "isEditableByNonOwner": true,
//                 "isDeletable": true,
//                 "isDeletableByNonOwner": true,
//                 "shouldCreateDocumentCopy": true,
//                 "type": 0,
//                 "transitions": [
//                   {
//                     "id": "00000000-0000-0000-0000-000000000000",
//                     "name": "string",
//                     "description": "string",
//                     "isAffirmative": true,
//                     "isExecutableByNonOwner": true,
//                     "isAcceptToDelegate": true,
//                     "isExecutable": true,
//                     "isVisible": true,
//                     "confirmMessage": "string"
//                   }
//                 ],
//                 "bgColorClass": "string",
//                 "isAcceptToDelegate": true,
//                 "color": "string",
//                 "stateColor": {
//                   "textColor": "string",
//                   "bgColor": "string"
//                 },
//                 "externalLink": "string",
//                 "externalLinkDesc": "string"
//               },
//               "type": "string",
//               "supplierOrderEmail": "string",
//               "totalProduct": 0
//             }
//           ],
//           "documentGroupId": "00000000-0000-0000-0000-000000000000"
//         },
//         "invoice": {
//           "id": "00000000-0000-0000-0000-000000000000",
//           "tenantAccountId": "00000000-0000-0000-0000-000000000000",
//           "documentTypeId": 0,
//           "note": "string",
//           "deliveryAddressId": "00000000-0000-0000-0000-000000000000",
//           "deliveryAddressLine1": "string",
//           "deliveryAddressLine2": "string",
//           "deliverySuburb": "string",
//           "deliveryState": "string",
//           "deliveryPostCode": "string",
//           "deliveryCountry": "string",
//           "deliveryLatitude": 0,
//           "deliveryLongitude": 0,
//           "saleDocumentNumber": "string",
//           "purchaseDocumentNumber": "string",
//           "isDeliverable": true,
//           "documentTypeName": "string",
//           "documentTypeKey": "string",
//           "customerAccountId": "00000000-0000-0000-0000-000000000000",
//           "supplierAccountId": "00000000-0000-0000-0000-000000000000",
//           "customerTradingName": "string",
//           "documentRefs": [
//             {
//               "id": "00000000-0000-0000-0000-000000000000",
//               "saleDocumentNumber": "string",
//               "purchaseDocumentNumber": "string",
//               "documentTypeId": 0,
//               "workflowStateId": "00000000-0000-0000-0000-000000000000",
//               "workflowState": {
//                 "id": "00000000-0000-0000-0000-000000000000",
//                 "workflowId": "00000000-0000-0000-0000-000000000000",
//                 "name": "string",
//                 "isEditable": true,
//                 "isEditableByNonOwner": true,
//                 "isDeletable": true,
//                 "isDeletableByNonOwner": true,
//                 "shouldCreateDocumentCopy": true,
//                 "type": 0,
//                 "transitions": [
//                   {
//                     "id": "00000000-0000-0000-0000-000000000000",
//                     "name": "string",
//                     "description": "string",
//                     "isAffirmative": true,
//                     "isExecutableByNonOwner": true,
//                     "isAcceptToDelegate": true,
//                     "isExecutable": true,
//                     "isVisible": true,
//                     "confirmMessage": "string"
//                   }
//                 ],
//                 "bgColorClass": "string",
//                 "isAcceptToDelegate": true,
//                 "color": "string",
//                 "stateColor": {
//                   "textColor": "string",
//                   "bgColor": "string"
//                 },
//                 "externalLink": "string",
//                 "externalLinkDesc": "string"
//               },
//               "type": "string",
//               "supplierOrderEmail": "string",
//               "totalProduct": 0
//             }
//           ],
//           "documentGroupId": "00000000-0000-0000-0000-000000000000"
//         },
//         "order": {
//           "id": "00000000-0000-0000-0000-000000000000",
//           "tenantAccountId": "00000000-0000-0000-0000-000000000000",
//           "documentTypeId": 0,
//           "note": "string",
//           "deliveryAddressId": "00000000-0000-0000-0000-000000000000",
//           "deliveryAddressLine1": "string",
//           "deliveryAddressLine2": "string",
//           "deliverySuburb": "string",
//           "deliveryState": "string",
//           "deliveryPostCode": "string",
//           "deliveryCountry": "string",
//           "deliveryLatitude": 0,
//           "deliveryLongitude": 0,
//           "saleDocumentNumber": "string",
//           "purchaseDocumentNumber": "string",
//           "isDeliverable": true,
//           "documentTypeName": "string",
//           "documentTypeKey": "string",
//           "customerAccountId": "00000000-0000-0000-0000-000000000000",
//           "supplierAccountId": "00000000-0000-0000-0000-000000000000",
//           "customerTradingName": "string",
//           "documentRefs": [
//             {
//               "id": "00000000-0000-0000-0000-000000000000",
//               "saleDocumentNumber": "string",
//               "purchaseDocumentNumber": "string",
//               "documentTypeId": 0,
//               "workflowStateId": "00000000-0000-0000-0000-000000000000",
//               "workflowState": {
//                 "id": "00000000-0000-0000-0000-000000000000",
//                 "workflowId": "00000000-0000-0000-0000-000000000000",
//                 "name": "string",
//                 "isEditable": true,
//                 "isEditableByNonOwner": true,
//                 "isDeletable": true,
//                 "isDeletableByNonOwner": true,
//                 "shouldCreateDocumentCopy": true,
//                 "type": 0,
//                 "transitions": [
//                   {
//                     "id": "00000000-0000-0000-0000-000000000000",
//                     "name": "string",
//                     "description": "string",
//                     "isAffirmative": true,
//                     "isExecutableByNonOwner": true,
//                     "isAcceptToDelegate": true,
//                     "isExecutable": true,
//                     "isVisible": true,
//                     "confirmMessage": "string"
//                   }
//                 ],
//                 "bgColorClass": "string",
//                 "isAcceptToDelegate": true,
//                 "color": "string",
//                 "stateColor": {
//                   "textColor": "string",
//                   "bgColor": "string"
//                 },
//                 "externalLink": "string",
//                 "externalLinkDesc": "string"
//               },
//               "type": "string",
//               "supplierOrderEmail": "string",
//               "totalProduct": 0
//             }
//           ],
//           "documentGroupId": "00000000-0000-0000-0000-000000000000"
//         },
//         "position": 0,
//         "earliest": "string",
//         "latest": "string",
//         "stopTime": 0,
//         "isApplyFuture": true,
//         "calculatedEta": "2023-02-08T03:15:18.694Z",
//         "pendingETA": "2023-02-08T03:15:18.694Z",
//         "manualETA": "2023-02-08T03:15:18.694Z",
//         "eta": "2023-02-08T03:15:18.694Z",
//         "deliveredOn": "2023-02-08T03:15:18.694Z",
//         "deliveryStatus": 0,
//         "signature": "string",
//         "latitude": 0,
//         "longitude": 0
//       },
//       "error": "string"
//     }
//   ],
//   "solution": [
//     {
//       "manifestId": "00000000-0000-0000-0000-000000000000",
//       "route": {
//         "id": "00000000-0000-0000-0000-000000000000",
//         "name": "string",
//         "defaultDriverId": "00000000-0000-0000-0000-000000000000",
//         "colour": "string",
//         "isValidInvoice": true,
//         "isContainOrder": true,
//         "totalOrders": 0
//       },
//       "visits": [
//         {
//           "manifestLine": {
//             "id": "00000000-0000-0000-0000-000000000000",
//             "manifestId": "00000000-0000-0000-0000-000000000000",
//             "documentId": "00000000-0000-0000-0000-000000000000",
//             "invoiceId": "00000000-0000-0000-0000-000000000000",
//             "orderId": "00000000-0000-0000-0000-000000000000",
//             "documentGroupId": "00000000-0000-0000-0000-000000000000",
//             "documentAccountId": "00000000-0000-0000-0000-000000000000",
//             "document": {
//               "id": "00000000-0000-0000-0000-000000000000",
//               "tenantAccountId": "00000000-0000-0000-0000-000000000000",
//               "documentTypeId": 0,
//               "note": "string",
//               "deliveryAddressId": "00000000-0000-0000-0000-000000000000",
//               "deliveryAddressLine1": "string",
//               "deliveryAddressLine2": "string",
//               "deliverySuburb": "string",
//               "deliveryState": "string",
//               "deliveryPostCode": "string",
//               "deliveryCountry": "string",
//               "deliveryLatitude": 0,
//               "deliveryLongitude": 0,
//               "saleDocumentNumber": "string",
//               "purchaseDocumentNumber": "string",
//               "isDeliverable": true,
//               "documentTypeName": "string",
//               "documentTypeKey": "string",
//               "customerAccountId": "00000000-0000-0000-0000-000000000000",
//               "supplierAccountId": "00000000-0000-0000-0000-000000000000",
//               "customerTradingName": "string",
//               "documentRefs": [
//                 {
//                   "id": "00000000-0000-0000-0000-000000000000",
//                   "saleDocumentNumber": "string",
//                   "purchaseDocumentNumber": "string",
//                   "documentTypeId": 0,
//                   "workflowStateId": "00000000-0000-0000-0000-000000000000",
//                   "workflowState": {
//                     "id": "00000000-0000-0000-0000-000000000000",
//                     "workflowId": "00000000-0000-0000-0000-000000000000",
//                     "name": "string",
//                     "isEditable": true,
//                     "isEditableByNonOwner": true,
//                     "isDeletable": true,
//                     "isDeletableByNonOwner": true,
//                     "shouldCreateDocumentCopy": true,
//                     "type": 0,
//                     "transitions": [
//                       {
//                         "id": "00000000-0000-0000-0000-000000000000",
//                         "name": "string",
//                         "description": "string",
//                         "isAffirmative": true,
//                         "isExecutableByNonOwner": true,
//                         "isAcceptToDelegate": true,
//                         "isExecutable": true,
//                         "isVisible": true,
//                         "confirmMessage": "string"
//                       }
//                     ],
//                     "bgColorClass": "string",
//                     "isAcceptToDelegate": true,
//                     "color": "string",
//                     "stateColor": {
//                       "textColor": "string",
//                       "bgColor": "string"
//                     },
//                     "externalLink": "string",
//                     "externalLinkDesc": "string"
//                   },
//                   "type": "string",
//                   "supplierOrderEmail": "string",
//                   "totalProduct": 0
//                 }
//               ],
//               "documentGroupId": "00000000-0000-0000-0000-000000000000"
//             },
//             "invoice": {
//               "id": "00000000-0000-0000-0000-000000000000",
//               "tenantAccountId": "00000000-0000-0000-0000-000000000000",
//               "documentTypeId": 0,
//               "note": "string",
//               "deliveryAddressId": "00000000-0000-0000-0000-000000000000",
//               "deliveryAddressLine1": "string",
//               "deliveryAddressLine2": "string",
//               "deliverySuburb": "string",
//               "deliveryState": "string",
//               "deliveryPostCode": "string",
//               "deliveryCountry": "string",
//               "deliveryLatitude": 0,
//               "deliveryLongitude": 0,
//               "saleDocumentNumber": "string",
//               "purchaseDocumentNumber": "string",
//               "isDeliverable": true,
//               "documentTypeName": "string",
//               "documentTypeKey": "string",
//               "customerAccountId": "00000000-0000-0000-0000-000000000000",
//               "supplierAccountId": "00000000-0000-0000-0000-000000000000",
//               "customerTradingName": "string",
//               "documentRefs": [
//                 {
//                   "id": "00000000-0000-0000-0000-000000000000",
//                   "saleDocumentNumber": "string",
//                   "purchaseDocumentNumber": "string",
//                   "documentTypeId": 0,
//                   "workflowStateId": "00000000-0000-0000-0000-000000000000",
//                   "workflowState": {
//                     "id": "00000000-0000-0000-0000-000000000000",
//                     "workflowId": "00000000-0000-0000-0000-000000000000",
//                     "name": "string",
//                     "isEditable": true,
//                     "isEditableByNonOwner": true,
//                     "isDeletable": true,
//                     "isDeletableByNonOwner": true,
//                     "shouldCreateDocumentCopy": true,
//                     "type": 0,
//                     "transitions": [
//                       {
//                         "id": "00000000-0000-0000-0000-000000000000",
//                         "name": "string",
//                         "description": "string",
//                         "isAffirmative": true,
//                         "isExecutableByNonOwner": true,
//                         "isAcceptToDelegate": true,
//                         "isExecutable": true,
//                         "isVisible": true,
//                         "confirmMessage": "string"
//                       }
//                     ],
//                     "bgColorClass": "string",
//                     "isAcceptToDelegate": true,
//                     "color": "string",
//                     "stateColor": {
//                       "textColor": "string",
//                       "bgColor": "string"
//                     },
//                     "externalLink": "string",
//                     "externalLinkDesc": "string"
//                   },
//                   "type": "string",
//                   "supplierOrderEmail": "string",
//                   "totalProduct": 0
//                 }
//               ],
//               "documentGroupId": "00000000-0000-0000-0000-000000000000"
//             },
//             "order": {
//               "id": "00000000-0000-0000-0000-000000000000",
//               "tenantAccountId": "00000000-0000-0000-0000-000000000000",
//               "documentTypeId": 0,
//               "note": "string",
//               "deliveryAddressId": "00000000-0000-0000-0000-000000000000",
//               "deliveryAddressLine1": "string",
//               "deliveryAddressLine2": "string",
//               "deliverySuburb": "string",
//               "deliveryState": "string",
//               "deliveryPostCode": "string",
//               "deliveryCountry": "string",
//               "deliveryLatitude": 0,
//               "deliveryLongitude": 0,
//               "saleDocumentNumber": "string",
//               "purchaseDocumentNumber": "string",
//               "isDeliverable": true,
//               "documentTypeName": "string",
//               "documentTypeKey": "string",
//               "customerAccountId": "00000000-0000-0000-0000-000000000000",
//               "supplierAccountId": "00000000-0000-0000-0000-000000000000",
//               "customerTradingName": "string",
//               "documentRefs": [
//                 {
//                   "id": "00000000-0000-0000-0000-000000000000",
//                   "saleDocumentNumber": "string",
//                   "purchaseDocumentNumber": "string",
//                   "documentTypeId": 0,
//                   "workflowStateId": "00000000-0000-0000-0000-000000000000",
//                   "workflowState": {
//                     "id": "00000000-0000-0000-0000-000000000000",
//                     "workflowId": "00000000-0000-0000-0000-000000000000",
//                     "name": "string",
//                     "isEditable": true,
//                     "isEditableByNonOwner": true,
//                     "isDeletable": true,
//                     "isDeletableByNonOwner": true,
//                     "shouldCreateDocumentCopy": true,
//                     "type": 0,
//                     "transitions": [
//                       {
//                         "id": "00000000-0000-0000-0000-000000000000",
//                         "name": "string",
//                         "description": "string",
//                         "isAffirmative": true,
//                         "isExecutableByNonOwner": true,
//                         "isAcceptToDelegate": true,
//                         "isExecutable": true,
//                         "isVisible": true,
//                         "confirmMessage": "string"
//                       }
//                     ],
//                     "bgColorClass": "string",
//                     "isAcceptToDelegate": true,
//                     "color": "string",
//                     "stateColor": {
//                       "textColor": "string",
//                       "bgColor": "string"
//                     },
//                     "externalLink": "string",
//                     "externalLinkDesc": "string"
//                   },
//                   "type": "string",
//                   "supplierOrderEmail": "string",
//                   "totalProduct": 0
//                 }
//               ],
//               "documentGroupId": "00000000-0000-0000-0000-000000000000"
//             },
//             "position": 0,
//             "earliest": "string",
//             "latest": "string",
//             "stopTime": 0,
//             "isApplyFuture": true,
//             "calculatedEta": "2023-02-08T03:15:18.694Z",
//             "pendingETA": "2023-02-08T03:15:18.694Z",
//             "manualETA": "2023-02-08T03:15:18.694Z",
//             "eta": "2023-02-08T03:15:18.694Z",
//             "deliveredOn": "2023-02-08T03:15:18.694Z",
//             "deliveryStatus": 0,
//             "signature": "string",
//             "latitude": 0,
//             "longitude": 0
//           },
//           "position": 0,
//           "arrivalTime": "string",
//           "finishTime": "string",
//           "longitude": 0,
//           "latitude": 0
//         }
//       ],

//       "applyForFeature": true
//     }
//   ],
//   "deliveryDate": "string"
// }

export const useApplyOptimize = () =>
  useMutation((data: { date: string; routeId: string }) =>
    axios
      .post(`${API_PATH}ApplyOptimize`, {
        ...data,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res?.data?.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

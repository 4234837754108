import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M13.2475 1.05414e-06H0.766137C0.54586 -0.00041535 0.344044 0.122562 0.243413 0.31841C0.141394 0.516895 0.1593 0.75591 0.289634 0.937184L4.86229 7.37879C4.86381 7.38101 4.86548 7.38309 4.86701 7.38531C5.03315 7.60961 5.12309 7.88139 5.12351 8.16051V13.4123C5.12254 13.5678 5.18361 13.7173 5.29312 13.8276C5.40278 13.9378 5.55185 14 5.70731 14C5.78628 13.9999 5.86457 13.9842 5.93758 13.9538L8.50706 12.9741C8.73719 12.9038 8.89001 12.6865 8.89001 12.425V8.16051C8.89042 7.88139 8.98037 7.60961 9.14637 7.38531C9.1479 7.38309 9.14956 7.38101 9.15109 7.37879L13.7239 0.937045C13.8542 0.75591 13.8721 0.517034 13.7701 0.318549C13.6696 0.122562 13.4677 -0.00041535 13.2475 1.05414e-06V1.05414e-06Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { useState, useEffect, memo, useMemo } from "react";
import clsx from "clsx";
import { Common, Icon } from "@/components";
import {
  addressGenerate,
  checkTimeOfWindow,
  timeFormat,
  timeStopFormat,
} from "@/utils";
import { manifest, manifestLine } from "../models/DeliveryManifest";
import { destination, dropMenuEditItem } from "../models";
import { dropdownIds } from "../configVariable";
import { ETATimeFormat } from "../utils";

const DropdownMenu = [
  {
    id: dropdownIds.MOVETR,
    name: "Move to other route",
    tooltipContent: "",
  },
  {
    id: dropdownIds.CHANGE_POSITION,
    name: "Change position",
    tooltipContent: "",
  },
  {
    id: dropdownIds.UNALLOCATE,
    name: "Unallocated",
    tooltipContent: "",
    color: "text-red",
  },
];

interface Props {
  data: manifestLine;
  route: manifest;
  index: number;
  className?: string;
  isUnllocatedItem?: boolean;
  isCompleted?: boolean;
  // ETALineData: manifestLineETA;
  positionChangedToHighlight?: destination;
  col: number;
  isRefreshETATemp?: boolean;
  routeStatus: number;
  onUnallocateClick: (data: manifestLine) => void;
  onMoveToAnotherRouteClick: (data: manifestLine, route: manifest) => void;
  onChangePositionClick: (data: manifestLine) => void;
}

export const EditRouteItem = memo(
  ({
    data,
    route,
    isCompleted,
    index,
    className,
    isUnllocatedItem,
    // ETALineData,
    positionChangedToHighlight,
    col,
    isRefreshETATemp,
    routeStatus,
    onUnallocateClick,
    onChangePositionClick,
    onMoveToAnotherRouteClick,
  }: Props) => {
    const [isExpand, setExpand] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [isChangePosirionHighlight, setChangePosirionHighlight] =
      useState<boolean>(false);
    const ETATime = useMemo(() => ETATimeFormat(data, routeStatus), [data]);

    const stopTime = useMemo(
      () =>
        data.stopTime != null &&
        data.stopTime >= 0 &&
        timeStopFormat(data.stopTime),
      [data]
    );

    const earliest = timeFormat(data?.earliest, true);
    const latest = timeFormat(
      data.latest === "00:00:00" ? "23:59:00" : data.latest,
      true
    );

    const isOutOfTimeWindow = useMemo(() => {
      return checkTimeOfWindow(earliest, latest, ETATime);
    }, [data]);

    const handleDropdownItemClick = (item: dropMenuEditItem) => {
      if (item.id === dropdownIds.UNALLOCATE) {
        onUnallocateClick(data);
      }
      if (item.id === dropdownIds.CHANGE_POSITION) {
        onChangePositionClick(data);
      }
      if (item.id === dropdownIds.MOVETR) {
        onMoveToAnotherRouteClick(data, route);
      }
    };

    useEffect(() => {
      let timer: any = null;
      if (
        positionChangedToHighlight?.index == data.position &&
        col == parseInt(positionChangedToHighlight?.droppableId || "")
      ) {
        setChangePosirionHighlight(true);
        timer = setTimeout(() => {
          setChangePosirionHighlight(false);
        }, 5000);
      }

      return () => {
        timer && clearTimeout(timer);
        setChangePosirionHighlight(false);
      };
      // eslint-disable-next-line
    }, [data.position, col]);

    return (
      <div
        className={clsx(
          "border rounded shadow-sm group select-none",
          isCompleted ? "bg-neutral-30" : "hover:border-blue hover:bg-blue-5",
          isChangePosirionHighlight
            ? "bg-blue-5 border-blue-80"
            : "border-neutral-10 bg-white"
        )}
      >
        {isOutOfTimeWindow && isExpand && (
          <div
            onClick={() => setExpand(!isExpand)}
            className="flex items-center rounded-t bg-yellow-dark-light text-yellow-dark font-medium px-3 py-1 cursor-pointer"
          >
            <Icon.WarningCircle size="12" className="fill-yellow-dark mr-3" />
            Out of time window
          </div>
        )}
        <div className={clsx("p-3 text-sm", className)}>
          <div className="flex items-start text-neutral-80 font-semibold mb-1.5">
            <button
              className="p-2 -mt-1 -ml-2"
              onClick={() => setExpand(!isExpand)}
            >
              {isExpand ? (
                <Icon.ArrowDown size="10" className="fill-neutral-40" />
              ) : (
                <Icon.ArrowRight size="10" className="fill-neutral-40" />
              )}
            </button>
            <div
              className="mr-2 cursor-pointer"
              onClick={() => setExpand(!isExpand)}
            >
              {isCompleted ? (
                <div
                  className={clsx(
                    "w-5 h-5 rounded-full font-semibold flex items-center justify-center text-neutral-50 bg-green"
                  )}
                >
                  <Icon.Checked size="12" className="fill-white" />
                </div>
              ) : isOutOfTimeWindow && !isExpand ? (
                <Icon.WarningCircle size="20" className="fill-yellow-dark" />
              ) : (
                <div
                  className={clsx(
                    "w-5 h-5 rounded-full font-semibold flex items-center justify-center text-neutral-50 bg-neutral-10"
                  )}
                >
                  {index}
                </div>
              )}
            </div>
            <div>
              <div className="flex justify-between">
                <span className="text-blue whitespace-nowrap">
                  #{data.document.saleDocumentNumber}
                </span>{" "}
                {!isExpand && !isUnllocatedItem && (
                  <div className="flex items-center px-2 font-normal">
                    <div data-tip="Estimated time of arrival ">
                      <Icon.Clock size="12" className="fill-blue-80" />
                    </div>
                    <span className="ml-1.5 text-blue whitespace-nowrap">
                      {isRefreshETATemp && !isCompleted ? (
                        <Icon.Loading />
                      ) : (
                        ETATime
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div>
                {data.document.customerTradingName
                  ? ` - ${data.document.customerTradingName}`
                  : ""}
              </div>
            </div>
            {isCompleted ? (
              <div>
                <div className="w-6 h-6" />
              </div>
            ) : (
              <Common.DropDowns
                onOpen={setDropdownOpen}
                className={clsx(
                  "ml-auto",
                  !isDropdownOpen && "opacity-0 group-hover:opacity-100"
                )}
                buttonRender={
                  <div className="w-6 h-6 inline-flex items-center justify-center border font-medium group bg-white text-blue border-neutral-20 hover:border-blue-dark rounded">
                    <Icon.SeeMore size="14" className="fill-blue" />
                  </div>
                }
                listRender={
                  <div>
                    {DropdownMenu.map((item) =>
                      !(isUnllocatedItem && item.id !== dropdownIds.MOVETR) ? (
                        <span data-tip={item.tooltipContent} key={item.id}>
                          <Common.DropdownItem
                            className={clsx(
                              "border-t border-t-neutral-10 w-36 font-normal text-sm",
                              item.color
                            )}
                            onClick={() => handleDropdownItemClick(item)}
                          >
                            {item.name}
                          </Common.DropdownItem>
                        </span>
                      ) : null
                    )}
                  </div>
                }
              />
            )}
          </div>
          {/* Toggle */}
          <div className={clsx(isExpand ? "block" : "hidden")}>
            {true && (
              <div className="flex items-center text-neutral-70 mb-2">
                {/* <Icon.Clock size="12" className="fill-neutral-40 mr-1.5" />
            7:15AM - 7:45AM
            <Icon.Stock size="12" className="fill-neutral-40 mr-1.5 ml-auto" />
            10 mins */}
                <div className="flex items-center mr-5">
                  <div data-tip="Earliest time - Latest time">
                    <Icon.DeliveryTime size="12" className="fill-neutral-40" />
                  </div>
                  <span className={"ml-1.5 text-neutral-70"}>
                    {earliest} - {latest}
                  </span>
                </div>
                <div className="flex items-center">
                  <div data-tip="Estimated time of arrival ">
                    <Icon.Clock
                      size="12"
                      className={clsx(
                        isOutOfTimeWindow ? "fill-yellow-dark" : "fill-blue-80"
                      )}
                    />
                  </div>
                  <span
                    className={clsx(
                      "ml-1.5",
                      isOutOfTimeWindow ? "text-yellow-dark" : "text-blue"
                    )}
                  >
                    {isRefreshETATemp ? <Icon.Loading /> : ETATime}
                  </span>
                </div>
              </div>
            )}
            {true && (
              <div className="flex items-center text-neutral-70 mb-2">
                <div className="flex items-center mr-5">
                  <div data-tip="Stop time">
                    <Icon.Stock size="12" className="fill-neutral-40" />
                  </div>
                  <span className="ml-1.5 text-neutral-70">{stopTime}</span>
                </div>
              </div>
            )}

            <div className="flex items-start">
              <div data-tip="Delivery address">
                <Icon.Location size="12" className="fill-neutral-40 mr-1.5" />
              </div>
              <div className="text-neutral-50 line-clamp-2">
                {addressGenerate(
                  data.document.deliveryAddressLine1,
                  data.document.deliveryAddressLine2,
                  data.document.deliverySuburb,
                  data.document.deliveryState,
                  data.document.deliveryPostCode
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Scrollbars from "react-custom-scrollbars";
import clsx from "clsx";
import { Common, Icon, Table, Td, Th, CustomScrollbar } from "@/components";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import {
  pantryListSelector,
  isPantryListLoadingSelector,
  isScrollToBottomSelector,
  searchKeywordsSelector,
} from "../providers/selector";
import {
  deleteLines,
  editLine,
  fetchPantryListAsync,
  selectPantryLines,
  setFavourite,
  setIsScrollToBottom,
  sortPantryLines,
} from "../providers/slice";
import { SearchProductsSelectbox } from "./SearchProductsSelectBox";
import { EPantryMode } from "../models/EPantryMode";
import { useGetFilterPantryLines } from "../providers/hooks";
import { currencyFormatter, nameof } from "@/utils";
import { SortPantryLinesActionProps } from "../providers/actionProps";
import { PantryLine } from "../models/PantryLine";

const CommentInput = React.memo(
  (props: {
    id: string;
    value: string;
    onUpdate: (id: string, value: string) => void;
  }) => {
    const [value, setState] = useState<string>(props.value || "");
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setState(e.target.value);
      props.onUpdate(props.id, e.target.value);
    };
    return (
      <Common.Input
        maxLength={200}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    );
  }
);

const Index = () => {
  // Table styles
  const tableHeaderClasses = "bg-neutral-20 font-bold";
  const tableRowClasses = "hover:bg-neutral-5";
  const tableCellClasses =
    "!whitespace-normal border-b border-neutral-20 px-2 py-1";
  const stickyBottomCellClasses = `${tableCellClasses} bg-neutral-5 sticky bottom-0`;

  // Get pantry list from redux
  const dispatch = useAppDispatch();
  const pantry = useAppSelector(pantryListSelector);
  const pantryLines = pantry.pantryLines;
  const isLoadingList = useAppSelector(isPantryListLoadingSelector);
  const isTbScrollToBottom = useAppSelector(isScrollToBottomSelector);
  const searchKeywords = useAppSelector(searchKeywordsSelector);
  const tableScrollRef = React.useRef<Scrollbars | null>(null);

  // Get query strings
  const [searchParams] = useSearchParams();

  const selectedLineIds = useMemo(() => {
    return pantryLines?.filter((l) => l.isSelected).map((l) => l.id);
  }, [pantryLines]);

  const filteredLines = useGetFilterPantryLines();

  // Infinite scroll
  const [page, setPage] = useState<number>(1);
  const pageSize = 20;

  const [showingLines, setShowingLines] = useState<PantryLine[]>([]);

  // Only reset pagination on filtering or editing
  useEffect(() => {
    setPage(1);
    tableScrollRef.current?.scrollToTop();
  }, [pantry.isShowFavouriteOnly, searchKeywords, pantry.isEditing]);

  // Calculate data for pagination
  useEffect(() => {
    setShowingLines(
      !filteredLines
        ? []
        : Array.from(Array(pageSize * page).keys())
            .map((i) => filteredLines[i])
            .filter((item) => item)
    );
  }, [page, filteredLines]);

  const selectableLineIds = useMemo(() => {
    return filteredLines?.filter((l) => !l.isEditing).map((d) => d.id);
  }, [filteredLines]);

  // Fetch data first time
  const params = Object.fromEntries(searchParams?.entries()) as any;
  useEffect(() => {
    if (searchParams && params) {
      dispatch(
        fetchPantryListAsync({
          id: params.Id,
          customerAccountId: params.CustomerAccountId,
          supplierAccountId: params.SupplierAccountId,
        })
      );
    }
    // eslint-disable-next-line
  }, [searchParams]);

  // Rebuild tooltip every time data has changed
  useEffect(() => {
    if (filteredLines?.length > 0) {
      ReactTooltip.rebuild();
    }
  }, [filteredLines]);

  // Scroll to bottom of table
  useEffect(() => {
    if (isTbScrollToBottom) {
      tableScrollRef.current?.scrollToBottom();
      dispatch(setIsScrollToBottom(false));
    }
  }, [isTbScrollToBottom]);

  // Checkbox all
  const [isCheckAll, setIsCheckAll] = useState(false);

  // Sorting state
  const [sortColumns, setSortColumns] = useState<SortPantryLinesActionProps[]>(
    [
      nameof<PantryLine>("productSKU"),
      nameof<PantryLine>("productName"),
      nameof<PantryLine>("unit"),
      nameof<PantryLine>("price"),
      nameof<PantryLine>("comment"),
    ].map((item) => ({
      sortBy: item,
      sortDirection: undefined,
    }))
  );

  useEffect(() => {
    setIsCheckAll(
      !selectableLineIds?.some((r) => !selectedLineIds.includes(r))
    );
  }, [selectedLineIds, selectableLineIds]);

  const onChangeCheckBox = (id: string) => {
    const changedselectedIds = [...selectedLineIds];
    const index = selectedLineIds.indexOf(id);
    if (index === -1) {
      changedselectedIds.push(id);
    } else {
      changedselectedIds.splice(index, 1);
    }
    dispatch(selectPantryLines(changedselectedIds));
  };

  const onChangeCheckBoxAll = () => {
    if (isCheckAll) {
      // Uncheck
      dispatch(
        selectPantryLines(
          selectedLineIds.filter((i) => !selectableLineIds.includes(i))
        )
      );
    } else {
      // Check
      dispatch(selectPantryLines([...selectedLineIds, ...selectableLineIds]));
    }
  };

  const onClickFavourite = (id: string) => {
    if (pantry.isEditing) {
      dispatch(setFavourite(id));
    }
  };

  const onCommentChange = (id: string, value: string) => {
    dispatch(editLine({ id, changeProps: { comment: value || "" } }));
  };

  // const onEditClick = (id: string) => {
  //   const editLineActionProps: EditLineActionProps = {
  //     id,
  //     isEditing: true,
  //   };
  //   dispatch(editLine(editLineActionProps));
  // };

  const onSortClick = (sort: SortPantryLinesActionProps) => {
    const sortDirection: "ASC" | "DESC" | undefined =
      sort.sortDirection === "ASC" ? "DESC" : "ASC";

    setSortColumns(
      sortColumns.map((item) =>
        item.sortBy === sort.sortBy
          ? { ...item, sortDirection }
          : { ...item, sortDirection: undefined }
      )
    );
    dispatch(sortPantryLines(sort));
  };

  const onScrollPagination = () => {
    // Use react-custome-scrollbar API to get current scroll position
    const scrollValues = tableScrollRef.current?.getValues();

    // Trigger scroll pagination if current position reach 90% of height
    if ((scrollValues?.top || 0) > 0.9) {
      const lastPage = Math.ceil(filteredLines.length / pageSize);
      const newPage = page + 1;
      if (newPage <= lastPage) {
        setPage(newPage);
      }
    }
  };

  const getSortDirection = (sortBy: string) => {
    return sortColumns.find((c) => c.sortBy === sortBy)?.sortDirection;
  };

  const _nameofPantryLineProductSKU = nameof<PantryLine>("productSKU");
  const _nameofPantryLineProductName = nameof<PantryLine>("productName");
  const _nameofPantryLineUnit = nameof<PantryLine>("unit");
  const _nameofPantryLineComment = nameof<PantryLine>("comment");
  const _nameofPantryLinePrice = nameof<PantryLine>("price");

  const ThSort = (props: {
    className: string;
    sortBy: keyof PantryLine;
    label: string;
  }) => {
    return (
      <Th
        className={props.className}
        onSortClick={() =>
          onSortClick({
            sortBy: props.sortBy,
            sortDirection: getSortDirection(props.sortBy),
          })
        }
        sort={getSortDirection(props.sortBy)}
      >
        {props.label}
      </Th>
    );
  };

  return (
    <div className="flex flex-col flex-1">
      <CustomScrollbar ref={tableScrollRef} onScrollStop={onScrollPagination}>
        <Table
          id="pantry-lines-table"
          className="relative w-full border border-line border-separate border-spacing-0"
        >
          {/* Checkbox Column */}
          {/* Index Column */}
          {/* SKU Column */}
          {/* Product Name Column */}
          {/* Unit Column */}
          {/* Comment Column */}
          {/* Price Column */}
          {/* Favourite & Delete Column */}
          <thead>
            <tr>
              {pantry.isEditing && pantry.mode === EPantryMode.Manual && (
                <Th className={`${tableHeaderClasses} w-[2%]`}>
                  {filteredLines.length > 0 && (
                    <Common.Checkbox
                      id="checkbox_all"
                      ipSize="md"
                      checked={isCheckAll}
                      onChange={() => onChangeCheckBoxAll()}
                    />
                  )}
                </Th>
              )}

              <Th className={`${tableHeaderClasses} w-[5%]`}>#</Th>

              <ThSort
                className={`${tableHeaderClasses} w-[15%]`}
                sortBy={_nameofPantryLineProductSKU as keyof PantryLine}
                label="SKU"
              />
              <ThSort
                className={tableHeaderClasses}
                sortBy={_nameofPantryLineProductName as keyof PantryLine}
                label="PRODUCT NAME"
              />
              <ThSort
                className={tableHeaderClasses}
                sortBy={_nameofPantryLineUnit as keyof PantryLine}
                label="UNIT"
              />
              <ThSort
                className={`${tableHeaderClasses} w-[20%]`}
                sortBy={_nameofPantryLineComment as keyof PantryLine}
                label="COMMENT"
              />
              <ThSort
                className={clsx(tableHeaderClasses, "text-right")}
                sortBy={_nameofPantryLinePrice as keyof PantryLine}
                label="PRICE"
              />
              <Th className={`${tableHeaderClasses}`}></Th>
            </tr>
          </thead>
          <tbody>
            {/* Normal lines */}
            {!isLoadingList &&
              filteredLines?.length > 0 &&
              filteredLines !== undefined &&
              showingLines?.map((item, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {!item.isEditing && (
                    <tr
                      id={item.id}
                      className={clsx(
                        tableRowClasses,
                        item.isSelected ? "bg-[#F3F8FF]" : ""
                      )}
                    >
                      {/* Checkbox Column */}
                      {pantry.isEditing &&
                        pantry.mode === EPantryMode.Manual && (
                          <Td className="!whitespace-normal border-b border-neutral-20 px-3 py-2">
                            <Common.Checkbox
                              id={`pantry-item-${item.id}`}
                              ipSize="md"
                              checked={selectedLineIds.includes(item.id)}
                              onChange={() => onChangeCheckBox(item.id)}
                            />
                          </Td>
                        )}

                      {/* Index Column */}
                      <Td className={tableCellClasses}>{lineIndex + 1}</Td>

                      {/* SKU Column */}
                      <Td className={`${tableCellClasses} group`}>
                        <div className="flex items-center">
                          <p>{item.productSKU}</p>
                          {/* <Common.Button
                            color={"transparent"}
                            btSize={"sm"}
                            iconType="fill"
                            iconLeft={Icon.Edit}
                            rounded
                            className="mx-2 hidden group-hover:block"
                            onClick={() => onEditClick(item.id)}
                          ></Common.Button> */}
                        </div>
                      </Td>

                      {/* Product Name Column */}
                      <Td className={`${tableCellClasses} `}>
                        <div className="flex items-center">
                          <img
                            alt=""
                            loading="lazy"
                            className="mr-2 w-8 h-8 object-cover d-inline-block"
                            src={item.productImageUrl}
                          ></img>
                          <div className="flex flex-col">
                            <div className="flex items-center">
                              <p>{item.productName}</p>
                              {!item.isFromProductAgreement && (
                                <span
                                  data-tip={`This product is not in ${pantry.customerTradingName}'s agreed list. <br />Adding it into pantry list will also add to the agreed list for this customer.`}
                                >
                                  <Icon.WarningCircle
                                    iconType="fill"
                                    className="ml-2 fill-yellow-dark"
                                    size={12}
                                  />
                                </span>
                              )}
                            </div>

                            <div className="flex items-center">
                              <Icon.Tag
                                size={12}
                                iconType="fill"
                                className="fill-neutral-40"
                              />
                              <p className="text-neutral-60">
                                {item.productCategoryName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Td>

                      {/* Unit Column */}
                      <Td className={tableCellClasses}>{item.unit}</Td>

                      {/* Comment Column */}
                      <Td className={tableCellClasses}>
                        {pantry.isEditing ? (
                          // <Common.Input
                          //   maxLength={200}
                          //   value={item.comment || ""}
                          //   onChange={(e) =>
                          //     onCommentChange(item.id, e.target.value)
                          //   }
                          // />
                          <CommentInput
                            id={item.id}
                            value={item.comment}
                            onUpdate={onCommentChange}
                          />
                        ) : (
                          item.comment
                        )}
                      </Td>

                      {/* Price Column */}
                      <Td className={clsx(tableCellClasses, "text-right")}>
                        {item.isPriceTBC
                          ? "TBC"
                          : `${currencyFormatter.format(item.price)}`}
                      </Td>

                      {/* Favourite & Delete Column */}
                      <Td className={tableCellClasses}>
                        <div className="flex items-center">
                          {!item.isFavourite && (
                            <Common.Button
                              color="transparent"
                              btSize={"md"}
                              iconType="stroke"
                              iconLeft={Icon.Star}
                              outline
                              onClick={() => onClickFavourite(item.id)}
                            ></Common.Button>
                          )}
                          {item.isFavourite && (
                            <button
                              type="button"
                              className="inline-flex items-center justify-center font-medium group w-8 h-8 bg-transparent text-yellow"
                              onClick={() => onClickFavourite(item.id)}
                            >
                              <Icon.Star className="fill-blue hover:fill-blue-dark" />
                            </button>
                          )}
                          {pantry.isEditing &&
                            pantry.mode === EPantryMode.Manual && (
                              <span data-tip="Remove">
                                <Common.Button
                                  color="red"
                                  btSize={"md"}
                                  iconType="fill"
                                  iconLeft={Icon.Trash}
                                  outline
                                  className="bg-transparent border-transparent hover:!border-transparent"
                                  onClick={() => {
                                    dispatch(deleteLines([item.id]));
                                  }}
                                ></Common.Button>
                              </span>
                            )}
                        </div>

                        {/* <Common.Button
                        color="transparent"
                        btSize={"md"}
                        iconType="fill"
                        iconLeft={Icon.Trash}
                        outline
                      ></Common.Button> */}
                      </Td>
                    </tr>
                  )}
                </React.Fragment>
              ))}

            {/* This is sticky bottom line use for adding new product */}
            {/* Usually only have 1 line at the time */}
            {!isLoadingList &&
              pantry?.isEditing &&
              filteredLines?.length > 0 &&
              filteredLines !== undefined &&
              pantryLines
                ?.filter((l) => !l.isDeleted && l.isEditing)
                .map((item, lineIndex) => (
                  <React.Fragment key={lineIndex}>
                    <tr id={item.id} className={clsx(tableRowClasses)}>
                      {/* Checkbox Column */}
                      <Td className={stickyBottomCellClasses}></Td>

                      {/* Index Column */}
                      <Td className={stickyBottomCellClasses}>
                        {/* {pantryLines.filter((p) => !p.isDeleted).length} */}
                      </Td>

                      {/* SKU Column */}
                      <Td className={stickyBottomCellClasses}></Td>

                      {/* Product Name Column */}
                      <Td className={stickyBottomCellClasses}>
                        <SearchProductsSelectbox
                          lineRendering={filteredLines}
                          pantryLineId={item.id}
                          placehoder="To add a product, seach for SKU or product name..."
                        />
                      </Td>

                      {/* Unit Column */}
                      <Td className={stickyBottomCellClasses}></Td>

                      {/* Comment Column */}
                      <Td className={stickyBottomCellClasses}>
                        <Common.Input
                          maxLength={200}
                          value={item.comment}
                          onChange={(e) =>
                            onCommentChange(item.id, e.target.value)
                          }
                        />
                      </Td>

                      {/* Price Column */}
                      <Td className={stickyBottomCellClasses}></Td>

                      {/* Favourite & Delete Column */}
                      <Td className={stickyBottomCellClasses}></Td>
                    </tr>
                  </React.Fragment>
                ))}

            {!isLoadingList &&
              !filteredLines?.length &&
              filteredLines !== undefined && (
                <tr>
                  <Td colSpan={100}>
                    <div className="h-[calc(100%-39px)]">
                      <Common.NoData
                        title="No product found"
                        description={
                          pantry?.pantryLines?.length === 0
                            ? "Go to edit to add products into this pantry list manually or set it to auto to update automatically as prrders placed"
                            : ""
                        }
                      />
                    </div>
                  </Td>
                </tr>
              )}

            {isLoadingList && (
              <tr>
                <Td colSpan={100}>
                  <div className="h-[calc(100%-39px)]">
                    <Icon.Loading size={60} className="mx-auto my-36" />
                  </div>
                </Td>
              </tr>
            )}
          </tbody>
        </Table>
      </CustomScrollbar>
    </div>
  );
};

export default Index;

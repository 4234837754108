import { LayoutPartials, Common } from "@/components";
import { SnackbarUtils } from "@/utils";
import { ComponentInfo } from "../../components";

const NotistackScreen = () => {
  return (
    <LayoutPartials.BodyContent pageTitle="Notistack screen">
      <ComponentInfo
        title="Import SnackBar Utils"
        code={`import { SnackbarUtils } from "@/utils";`}
      />

      <ComponentInfo
        title="Call Snackbar with each kind of messages"
        code={`Success message: SnackbarUtils.info("Text message");

Error message: SnackbarUtils.error("Text message");

Infomation message: SnackbarUtils.info("Text message");

Warning message: SnackbarUtils.warning("Text message");

Default message: SnackbarUtils.toast("Text message");`}
      />

      <div className="space-x-2">
        <Common.Button
          color="green"
          onClick={() =>
            SnackbarUtils.success(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elitipsum."
            )
          }
        >
          Success message
        </Common.Button>
        <Common.Button
          color="red"
          onClick={() => SnackbarUtils.error("Lorem ipsum dolor sit")}
        >
          Error message
        </Common.Button>
        <Common.Button
          color="blue"
          onClick={() =>
            SnackbarUtils.info(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elitipsum. Lorem ipsum dolor sit amet, consectetur adipiscing elitipsum. Lorem ipsum dolor sit amet, consectetur adipiscing elitipsum."
            )
          }
        >
          Info message
        </Common.Button>
        <Common.Button
          color="yellow"
          onClick={() =>
            SnackbarUtils.warning(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elitipsum."
            )
          }
        >
          Warning message
        </Common.Button>
        <Common.Button
          color="gray"
          onClick={() => SnackbarUtils.toast("Action message")}
        >
          Action message
        </Common.Button>
        <Common.Button
          color="transparent"
          onClick={() => SnackbarUtils.clearAllNoti()}
        >
          Close All message
        </Common.Button>
      </div>
    </LayoutPartials.BodyContent>
  );
};

export default NotistackScreen;

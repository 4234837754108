import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { demoData } from "@/models/ex";

export const fetchDemoAsync = createAsyncThunk("fetchDemoList", async () => {
  return axios.get<demoData[]>(`/posts`).then((res) => {
    return typeof res.data === "object" ? res.data : [];
  });
});

const initialState = {
  data: [] as demoData[],
  loading: false,
  loaded: false,
};
const productListSlice = createSlice({
  name: "productList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDemoAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDemoAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.data = action.payload;
      });
  },
});

export default productListSlice;

import { Fragment, forwardRef, useImperativeHandle } from "react";
import clsx from "clsx";
import { Listbox, Transition } from "@headlessui/react";

interface Props {
  className?: string;
  buttonClassName?: string;
  listClassName?: string;
  buttonRender: JSX.Element;
  listRender: JSX.Element;
  onOpen?: (open: boolean) => void;
  selected: any;
  onChange: (value: any) => void;
  disabled?: boolean;
  showAbove?: boolean;
}

const Index = forwardRef(
  (
    {
      className,
      buttonClassName,
      listClassName,
      buttonRender,
      listRender,
      onOpen,
      selected,
      onChange,
      disabled,
      showAbove = false,
    }: Props,
    // eslint-disable-next-line
    ref
  ) => {
    let op: boolean = false;
    useImperativeHandle(ref, () => ({
      isOpen: {
        op,
      },
    }));

    return (
      <Listbox
        as="div"
        className={clsx("relative flex-shrink-0 group block", className)}
        value={selected}
        onChange={onChange}
        disabled={disabled}
      >
        {(open) => {
          op = open?.open;
          onOpen && onOpen(Boolean(open?.open));
          return (
            <>
              <Listbox.Button
                className={clsx(
                  "relative w-full h-9 inline-flex items-center justify-center rounded bg-white py-2 pl-3 pr-10 text-left border border-neutral-20 focus:outline-none",
                  disabled
                    ? "bg-neutral-20 text-neutral-40 cursor-default"
                    : "text-neutral cursor-pointer",
                  buttonClassName
                )}
              >
                {buttonRender}
              </Listbox.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Listbox.Options
                  className={clsx(
                    "origin-top-right absolute right-0 max-h-60 w-full overflow-auto rounded-md shadow-modal bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 transform opacity-100 scale-100",
                    showAbove ? "mb-2 bottom-full" : "mt-2 top-full",
                    listClassName
                  )}
                >
                  <div>{listRender}</div>
                </Listbox.Options>
              </Transition>
            </>
          );
        }}
      </Listbox>
    );
  }
);

export default Index;

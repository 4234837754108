import { Icon } from "@/components";
import { useEffect, useState } from "react";
import { DashboardTopItem } from "./DashboardTopItem";

import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector } from "@/providers/auth/selector";

import { useGetOverduePaymentsSummary } from "../services/dashboard";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import moment from "moment";

export const DashboardTopOverduePayments = () => {
  const [dataRender, setDatarender] = useState({
    title: "overdue payment",
    count: 0,
    amount: 0,
    countSuffix: "Invoices",
    isLoading: true,
  });
  const user = useAppSelector(userSelector);
  const fromDate = moment().subtract(30, "days").format(TIME_FORMAT.REQUEST);
  const toDate = moment(new Date()).format(TIME_FORMAT.REQUEST);

  const path = `/sales/documents/invoices?customerId=&number=&status=00000000-0000-0006-0007-000000000000,00000000-0000-0006-0021-000000000000&fromdate=${fromDate}&todate=${toDate}&paymethod=&routename=&routeid=&rangefor=2&doctype=3`;

  const pendingCreditsSummary = useGetOverduePaymentsSummary();
  const { isLoading, isSuccess, data } = pendingCreditsSummary;

  useEffect(() => {
    pendingCreditsSummary.mutate({
      accountId: user.accountId,
      fromDate,
      toDate,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setDatarender({
        ...dataRender,
        isLoading: false,
        amount: data ? data[0]?.totalAmount : 0,
        count: data ? data[0]?.totalCount : 0,
      });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <DashboardTopItem
      title={dataRender.title}
      note="(Last 30 days)"
      isLoading={isLoading}
      icon={
        <div>
          <Icon.CreditCard
            size="24"
            className="fill-status-warning hidden lg:block"
          />
          <Icon.CreditCard
            size="16"
            className="fill-status-warning lg:hidden"
          />
        </div>
      }
      count={dataRender.count}
      amount={dataRender.amount}
      countSuffix={dataRender.countSuffix}
      pathViewMore={path}
    />
  );
};

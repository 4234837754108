import { Fragment, useEffect, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import isEmpty from "lodash/isEmpty";
import { Icon, Common } from "@/components";
import clsx from "clsx";

interface Props {
  data: object[];
  ipSize?: "lg" | "md";
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  keySearch: string;
  renderItem: React.ElementType;
  onSelect: (item: any) => void;
  displayValue: () => React.ReactNode | string;
}

export const InputSearchContent = ({
  data,
  ipSize = "lg",
  className,
  disabled,
  keySearch,
  placeholder,
  onSelect,
  displayValue,
  renderItem: RenderItem,
}: Props) => {
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState("");
  const inputRef = useRef<null | HTMLInputElement>(null);
  let timerBlur: any = null;

  const filteredPeople =
    query === ""
      ? data
      : data.filter((item: any) =>
          item[keySearch]
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleSelect = (item: any) => {
    setSelected(item);
    onSelect(item);
    timerBlur = setTimeout(() => {
      inputRef?.current?.blur();
    }, 500);
  };

  useEffect(() => {
    return () => timerBlur && clearTimeout(timerBlur);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Combobox value={selected} onChange={handleSelect}>
      {({ open }) => {
        const isDisplayInput = open || isEmpty(displayValue());
        return (
          <div className="relative">
            <div className="relative z-0 w-full cursor-default">
              <div
                className={clsx(
                  "block border text-base rounded pr-10 border-neutral-20 focus:ring-blue focus:border-blue w-full h-full absolute top-0 left-0 z-0",
                  ipSize === "md" ? "py-1 pl-2" : "py-[0.375rem] pl-3",
                  disabled ? "bg-neutral-20 text-neutral-40" : "text-neutral",
                  isDisplayInput ? "opacity-0" : "opacity-100",
                  className
                )}
              >
                {displayValue()}
              </div>
              <Combobox.Input
                ref={inputRef}
                className={clsx(
                  "block w-full border text-base rounded pr-10 border-neutral-20 focus:ring-blue focus:border-blue relative z-10",
                  !isDisplayInput ? "opacity-0" : "opacity-100",
                  ipSize === "md" ? "py-1 pl-2" : "py-[0.375rem] pl-3",
                  disabled ? "bg-neutral-20 text-neutral-40" : "text-neutral",
                  className
                )}
                placeholder={placeholder}
                onChange={(event) => setQuery(event.target.value)}
                onFocus={(e: any) => {
                  if (
                    e.relatedTarget?.id?.includes("headlessui-combobox-button")
                  )
                    return;
                  !open && e?.target?.nextSibling?.click();
                }}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 z-20">
                <Icon.AngleDown size="12" className="stroke-neutral-50" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredPeople.length === 0 && query !== "" ? (
                  <Common.NoData
                    iconRender={Icon.Split}
                    title="No Route founded"
                    description="Try browse from your route list or try another keyword"
                  />
                ) : (
                  filteredPeople.map((item: any, index) => (
                    <Combobox.Option key={item.id} value={item}>
                      {({ selected, active }) => (
                        <RenderItem
                          isFirst={!index}
                          item={item}
                          selected={selected}
                          active={active}
                        />
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        );
      }}
    </Combobox>
  );
};

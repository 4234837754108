import clsx from "clsx";
import { EPickingStatus } from "@/configuration/globalVariable";
import { ProductGroup, ProductGroupProps } from "./ProductGroup";

export interface PickingStatusGroupProps {
  isFirst: boolean;
  groupName: string;
  pickingStatus: EPickingStatus;
  totalProducts: number;
  products: ProductGroupProps[];
}

export const PickingStatusGroup = (props: PickingStatusGroupProps) => {
  const backgroundClass = (pickingStatus: EPickingStatus) => {
    switch (pickingStatus) {
      case EPickingStatus.ToPick:
        return "bg-blue-light";
      case EPickingStatus.Picked:
        return "bg-green-light";
      case EPickingStatus.Substitute:
        return "bg-yellow-light";
      case EPickingStatus.NotAvailable:
        return "bg-red-light";
      case EPickingStatus.BackOrder:
        return "bg-red-light";
      default:
        return "bg-blue-light";
    }
  };
  const textClass = (pickingStatus: EPickingStatus) => {
    switch (pickingStatus) {
      case EPickingStatus.ToPick:
        return "text-blue";
      case EPickingStatus.Picked:
        return "text-green";
      case EPickingStatus.Substitute:
        return "text-yellow-dark";
      case EPickingStatus.NotAvailable:
        return "text-red";
      case EPickingStatus.BackOrder:
        return "text-red";
      default:
        return "text-blue";
    }
  };
  return (
    <>
      <table className="border-collapse border border-line w-full">
        <thead>
          <tr className={props.isFirst ? "" : "show-only-print"}>
            <th
              id="header-picking-stt"
              scope="col"
              className="border border-line bg-neutral-60 text-white font-bold px-2 w-6/12"
            >
              PICKING STATUS
            </th>
            <th
              id="header-qty"
              scope="col"
              className="border border-line bg-neutral-60 text-white font-bold px-2 w-1/12"
            >
              QTY
            </th>
            <th
              id="header-notes"
              scope="col"
              className="border border-line bg-neutral-60 text-white font-bold px-2 w-5/12"
            >
              NOTES
            </th>
          </tr>
          <tr>
            <th
              scope="colgroup"
              colSpan={3}
              className={clsx(
                "span px-2 font-bold",
                backgroundClass(props.pickingStatus),
                textClass(props.pickingStatus)
              )}
            >
              <div className="flex items-center">
                <p>{props.groupName}</p>
                <p className="ml-auto">
                  Total Products: {props.products.length}
                </p>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.products.map((p, index) => (
            <ProductGroup
              key={`${p.productId}-${index}`}
              groupCount={index + 1}
              productId={p.productId}
              productSKU={p.productSKU}
              productName={p.productName}
              unit={p.unit}
              details={p.details}
            />
          ))}
        </tbody>
      </table>
      <footer></footer>
    </>
  );
};

import { SvgIcon } from "./SvgIcon";
// Fill
function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M8.00078 4.49996C8.20452 4.49996 8.39576 4.59933 8.51264 4.76683L12.8875 11.0167C13.0213 11.2073 13.0363 11.4566 12.93 11.6635C12.8219 11.8704 12.6088 11.9998 12.3757 11.9998H3.62464C3.39152 11.9998 3.17841 11.8704 3.07028 11.6635C2.96404 11.4566 2.97904 11.2073 3.11278 11.0167L7.48766 4.76683C7.60454 4.59933 7.79578 4.49996 7.99953 4.49996H8.00078Z" />
    </SvgIcon>
  );
}

export default IconComponent;

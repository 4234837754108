import {
  FC,
  TableHTMLAttributes,
  TdHTMLAttributes,
  ThHTMLAttributes,
} from "react";
import clsx from "clsx";
import { Icon } from "../Icons";

export const Table: FC<TableHTMLAttributes<HTMLTableElement>> = ({
  children,
  className,
  id,
}) => {
  return (
    <table
      id={id}
      className={clsx("min-w-full border-separate", className)}
      style={{ borderSpacing: 0 }}
    >
      {children}
    </table>
  );
};

interface ThProps extends ThHTMLAttributes<HTMLTableColElement> {
  sort?: "ASC" | "DESC";
  onSortClick?: () => void;
}

export const Th: FC<ThProps> = ({ onSortClick, children, className, sort }) => {
  return (
    <th
      scope="col"
      className={clsx(
        "sticky top-0 z-[5] bg-white border-b border-neutral-20 text-left px-3 py-2.5 align-middle uppercase text-sm font-semibold text-neutral-40",
        className,
        onSortClick ? "cursor-pointer select-none" : ""
      )}
      onClick={onSortClick}
    >
      <div className="inline-flex items-center">
        {children}
        {onSortClick && (
          <button className="ml-1">
            {sort === "ASC" && (
              <Icon.SortByDown size="6" className="fill-neutral-40" />
            )}
            {sort === "DESC" && (
              <Icon.SortByUp size="6" className="fill-neutral-40" />
            )}
            {!sort && <Icon.SortBy size="12" className="fill-neutral-40" />}
          </button>
        )}
      </div>
    </th>
  );
};

export const Td: FC<TdHTMLAttributes<HTMLTableCellElement>> = ({
  children,
  className,
  ...options
}) => {
  return (
    <td className={clsx("whitespace-nowrap p-3", className)} {...options}>
      {children}
    </td>
  );
};

import TableWithPagination from "./TableWithPagination";

export interface Product {
  id: number;
  name: string;
  price: number;
}

export interface TableProps {
  page: number;
  sizePerPage: number;
  data: Product[];
  loading: boolean;
}

const products: Product[] = Array.from(Array(500).keys()).map((i) => ({
  id: i,
  name: `item name ${i}`,
  price: 2000 + i,
}));

const headerClasses =
  "sticky top-0 p-2 z-[1] border border-line bg-neutral-20 text-left text-neutral-40 font-bold";

const cellClasses = "border-b border-neutral-20 px-2 py-1";

const Index = () => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-auto">
        <div className="text-hd5 font-medium pb-2">Table with pagination</div>
        <TableWithPagination
          headerClasses={headerClasses}
          cellClasses={cellClasses}
          products={products}
          defaultPageSize={10}
        />
      </div>
    </div>
  );
};
export default Index;

import { useEffect } from "react";
import { Common, Icon } from "@/components";
import { userSelector } from "@/providers/auth/selector";
import { useAppSelector } from "@/sharedStore/hooks";
import { DocumentGroup, DocumentGroupProps } from "./components/ProductGroup";
import { useGetPickingProductsReportGroupByDocuments } from "./services/groupByDocuments";
import { getQueryString } from "@/utils";

const Index = () => {
  const getPickingProductsReport =
    useGetPickingProductsReportGroupByDocuments();
  const { data, isLoading, isSuccess } = getPickingProductsReport;

  const user = useAppSelector(userSelector);

  const fetchData = () => {
    getPickingProductsReport.mutate(getQueryString());
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      window.print();
    }
  }, [isSuccess]);

  const documentGroups: DocumentGroupProps[] = data?.documents;
  const totalDocuments: number = data?.totalDocuments;
  return (
    <>
      {documentGroups && Boolean(documentGroups?.length) && (
        <div className="relative min-h-screen overflow-hidden bg-gray-50">
          <div className="container relative bg-white px-6 pt-10 pb-8 sm:mx-auto sm:rounded-lg sm:px-10">
            <div className="text-base leading-7 text-gray-600">
              <ul className="space-y-4">
                <li className="flex items-center">
                  <p className="mr-2 text-hd4 font-semibold">
                    Product Totals by Customer
                  </p>
                  <p className="p-1 shadow-sm bg-neutral-30 rounded-full"></p>
                  <p className="ml-2 text-hd4 font-semibold">
                    {totalDocuments} Orders
                  </p>
                </li>
                <li className="flex items-center">
                  <p className="font-semibold">Supplier: {user.companyName}</p>
                </li>
              </ul>

              <table className="border-collapse border border-line w-full">
                <thead>
                  <tr className="">
                    <th
                      id="header-sale-doc-num"
                      scope="col"
                      className="border border-line bg-neutral-60 text-white font-bold px-2 w-5/12"
                    >
                      ORDER #
                    </th>
                    <th
                      id="header-unit"
                      scope="col"
                      className="border border-line bg-neutral-60 text-white font-bold px-2 w-1/12"
                    >
                      UNIT
                    </th>
                    <th
                      id="header-picking-stt"
                      scope="col"
                      className="border border-line bg-neutral-60 text-white font-bold px-2 w-2/12"
                    >
                      PICKING STATUS
                    </th>
                    <th
                      id="header-qty"
                      scope="col"
                      className="border border-line bg-neutral-60 text-white font-bold px-2 w-1/12"
                    >
                      QTY
                    </th>
                    <th
                      id="header-notes"
                      scope="col"
                      className="border border-line bg-neutral-60 text-white font-bold px-2 w-3/12"
                    >
                      NOTES
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {documentGroups &&
                    documentGroups.map((p, index) => (
                      <DocumentGroup
                        key={`${p.documentId}-${index}`}
                        documentId={p.documentId}
                        saleDocumentNumber={p.saleDocumentNumber}
                        customerName={p.customerName}
                        details={p.details}
                      />
                    ))}
                </tbody>
              </table>
              <footer></footer>
            </div>
          </div>
        </div>
      )}
      {!isLoading &&
        !data?.documents?.length &&
        data?.documents !== undefined && (
          <div className="h-[calc(100%-39px)]">
            <Common.NoData title="No data." />
          </div>
        )}
      {isLoading && (
        <div className="w-full h-[calc(100vh-6.25rem)] flex items-center justify-center">
          Loading...
          <Icon.Loading className="ml-3" />
        </div>
      )}
    </>
  );
};

export default Index;

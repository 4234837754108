import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C1.44772 0 1 0.447715 1 1V15C1 15.5523 1.44772 16 2 16H14C14.5523 16 15 15.5523 15 15V1C15 0.447715 14.5523 0 14 0H2ZM4 4.75H12V3.25H4V4.75ZM4 7.75H12V6.25H4V7.75ZM8 10.75H4V9.25H8V10.75Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

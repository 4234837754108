import { Icon } from "@/components";
import { PATH, ENV, USER_VIEW } from "@/configuration/globalVariable";

// Screen
// import GetStartedScreen from "@/pages/GetStarted";
import DashboardScreen from "@/pages/Dashboard";
// import EnterOrderScreen from "@/pages/EnterOrder";
import SalesOrdersScreen from "@/pages/SalesOrders";
import CustomerManagementScreen from "@/pages/CustomerManagement";
import MyCustomerScreen from "@/pages/CustomerManagement/Screens/MyCustomer";
import MyCustomerEditScreen from "@/pages/CustomerManagement/Screens/MyCustomerEdit";
import BrowseCustomerScreen from "@/pages/CustomerManagement/Screens/BrowseCustomer";

import DeliveryScreen from "@/pages/Delivery";
import DeliveryManifestsScreen from "@/pages/Delivery/Screens/DeliveryManifests";
import EditRoutesScreen from "@/pages/Delivery/Screens/EditRoutes";
import DeliveryRouteDetailScreen from "@/pages/Delivery/Screens/DeliveryRouteDetail";
import DeliveryRouteOptimizingScreen from "@/pages/Delivery/Screens/Optimizing";
import DeliveryRouteOptimizeGroupPreviewScreen from "@/pages/Delivery/Screens/OptimizedGroupPreview";

import SchedulePlanner from "@/pages/SchedulePlanner";
import DeliveryScheduleDetailScreen from "@/pages/SchedulePlanner/Screens/DeliveryScheduleDetail";
import DeliveryScheduleEditScreen from "@/pages/SchedulePlanner/Screens/DeliveryScheduleEdit";
import SchedulePlannerList from "@/pages/SchedulePlanner/Screens/SchedulePlanner";
import SchedulePlannerAssignedList from "@/pages/SchedulePlanner/Screens/SchedulePlannerAssignedList";

import CommonAppComponentWrap from "@/pages/CommonAppComponent";
import ButtonScreen from "@/pages/CommonAppComponent/Screens/Buttons";
import IconScreen from "@/pages/CommonAppComponent/Screens/Icons";
import FromScreen from "@/pages/CommonAppComponent/Screens/Form";
import TooltipScreen from "@/pages/CommonAppComponent/Screens/Tooltip";
import LabelScreen from "@/pages/CommonAppComponent/Screens/Label";
import NotistackScreen from "@/pages/CommonAppComponent/Screens/Notistack";
import AlertScreen from "@/pages/CommonAppComponent/Screens/Alerts";
import ListBoxScreen from "@/pages/CommonAppComponent/Screens/ListBox";
import Timeline from "@/pages/CommonAppComponent/Screens/Timeline";
import Table from "@/pages/CommonAppComponent/Screens/Table";

import PantryList from "@/pages/PantryList";

export const sidebarNavigation = [
  {
    name: "Get Started",
    icon: Icon.Started,
    isDiffDomain: true,
    path: `/global/account/gettingstarted`,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER],
  },
  {
    name: "Dashboard",
    icon: Icon.Dashboard,
    path: "/",
    element: DashboardScreen,
    index: true,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
  },
  {
    name: "Enter Order",
    icon: Icon.Cart,
    isDiffDomain: true,
    path: `/sales/placeorder/documents/placeorder`,
    isNavRender: true,
    userView: [USER_VIEW.SUPPLIER],
  },
  {
    name: "Place Order",
    icon: Icon.Cart,
    isDiffDomain: true,
    path: `/purchases/placeorder/documents/placeorder`,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER],
  },
  {
    name: "Sales Orders",
    icon: Icon.Bill,
    path: PATH.SALES_ORDERS,
    element: SalesOrdersScreen,
    noPage: true,
    isNavRender: true,
    userView: [USER_VIEW.SUPPLIER],
    subItems: [
      {
        name: "Orders",
        isDiffDomain: true,
        path: `/sales/documents/orders`,
        isNavRender: true,
      },
      {
        name: "Invoices",
        isDiffDomain: true,
        path: `/sales/documents/invoices`,
        isNavRender: true,
      },
      {
        name: "Quotes",
        isDiffDomain: true,
        path: `/sales/documents/quotes`,
        isNavRender: true,
      },
    ],
  },
  {
    name: "Purchase Orders",
    icon: Icon.Bill,
    path: PATH.SALES_ORDERS,
    element: SalesOrdersScreen,
    noPage: true,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER],
    subItems: [
      {
        name: "Orders",
        isDiffDomain: true,
        path: `/purchases/documents/orders`,
        isNavRender: true,
      },
      {
        name: "Invoices",
        isDiffDomain: true,
        path: `/purchases/documents/invoices`,
        isNavRender: true,
      },
      {
        name: "Quotes",
        isDiffDomain: true,
        path: `/purchases/documents/quotes`,
        isNavRender: true,
      },
    ],
  },
  {
    name: "Customer management",
    icon: Icon.Team,
    path: PATH.SALES_ORDERS,
    element: SalesOrdersScreen,
    noPage: true,
    isNavRender: true,
    userView: [USER_VIEW.SUPPLIER],
    subItems: [
      {
        name: "Add customers",
        isDiffDomain: true,
        path: `/relationship/accounts/createcustomer`,
        isNavRender: true,
      },
      {
        name: "My customers",
        isDiffDomain: true,
        path: `/relationship/accounts/mycustomers`,
        isNavRender: true,
      },
      {
        name: "Browse customers",
        isDiffDomain: true,
        path: `/relationship/accounts/browseCustomers`,
        isNavRender: true,
      },
    ],
  },
  {
    name: "Supplier management",
    icon: Icon.Vehical,
    path: PATH.SALES_ORDERS,
    element: SalesOrdersScreen,
    noPage: true,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER],
    subItems: [
      {
        name: "Add suppliers",
        isDiffDomain: true,
        path: `/relationship/accounts/createsupplier`,
        isNavRender: true,
      },
      {
        name: "My suppliers",
        isDiffDomain: true,
        path: `/relationship/accounts/mysuppliers`,
        isNavRender: true,
      },
      {
        name: "Browse suppliers",
        isDiffDomain: true,
        path: `/relationship/accounts/browseSuppliers`,
        isNavRender: true,
      },
    ],
  },
  // New UI
  {
    name: "Customer Management*",
    icon: Icon.Team,
    path: PATH.CUSTOMER_MANAGEMENT,
    element: CustomerManagementScreen,
    noPage: true,
    isNavRender: true,
    envDisplay: [ENV.DEV],
    userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
    subItems: [
      {
        name: "My customers",
        index: true,
        path: PATH.MY_CUSTOMER,
        element: MyCustomerScreen,
        isNavRender: true,
      },
      {
        name: "My customers editing",
        index: true,
        path: PATH.EDIT_MY_CUSTOMER + "/:id",
        element: MyCustomerEditScreen,
        isNavRender: false,
      },
      {
        name: "Browse customers",
        path: PATH.BROWSE_CUSTOMER,
        element: BrowseCustomerScreen,
        isNavRender: true,
      },
    ],
  },
  // {
  //   name: "Customer Management",
  //   icon: Icon.Team,
  //   isDiffDomain: true,
  //   path: OUTSITE_LINK,
  //   isNavRender: true,
  // },

  // Delivery group
  // {
  //   name: "Delivery",
  //   icon: Icon.Vehical,
  //   path: PATH.DELIVERY,
  //   element: DeliveryScreen,
  //   isNavRender: true,
  //   userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
  //   envDisplay: [ENV.DEV],
  // },
  // {
  //   name: "Delivery edit routes",
  //   path: `${PATH.DELIVERY}/${PATH.EDIT_ROUTES}`,
  //   element: EditRoutesScreen,
  //   userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
  //   envDisplay: [ENV.DEV],
  // },
  // {
  //   name: "Delivery route detail",
  //   path: `${PATH.DELIVERY}/${PATH.ROUTE_DETAILS}`,
  //   element: DeliveryRouteDetailScreen,
  //   userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
  //   envDisplay: [ENV.DEV],
  // },
  // {
  //   name: "Delivery allocate",
  //   path: `${PATH.DELIVERY}/${PATH.ALLOCATE}`,
  //   element: AllocateScreen,
  //   userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
  //   envDisplay: [ENV.DEV],
  // },
  // End Delivery group
  {
    name: "Scheduling",
    icon: Icon.Calendar,
    path: PATH.SCHEDULE_PLANNER._,
    element: SchedulePlanner,
    isNavRender: true,
    userView: [USER_VIEW.SUPPLIER],
    settingDisplay: "isSupportDeliverySchedule",
    subItems: [
      {
        name: "Schedule Planner list",
        index: true,
        element: SchedulePlannerList,
        path: PATH.SCHEDULE_PLANNER.SCHEDULE_LIST,
      },
      {
        name: "Schedule Planner detail",
        element: DeliveryScheduleDetailScreen,
        path: PATH.SCHEDULE_PLANNER.SCHEDULE_DETAIL,
      },
      {
        name: "Schedule Planner edit",
        element: DeliveryScheduleEditScreen,
        path: PATH.SCHEDULE_PLANNER.SCHEDULE_EDIT,
      },
      {
        name: "Schedule Planner assigned list",
        element: SchedulePlannerAssignedList,
        path: PATH.SCHEDULE_PLANNER.SCHEDULE_ASSIGNED_LIST,
      },
    ],
  },
  {
    name: "Delivery",
    icon: Icon.Vehical,
    path: PATH.DELIVERY._,
    element: DeliveryScreen,
    noPage: true,
    isNavRender: true,
    userView: [USER_VIEW.SUPPLIER],
    subItems: [
      {
        name: "Routes",
        isDiffDomain: true,
        path: `/delivery/routes`,
        isNavRender: true,
      },
      {
        name: "Route allocation",
        isDiffDomain: true,
        path: `/delivery/routeallocation`,
        isNavRender: true,
      },
      // {
      //   name: "Buttons",
      //   path: PATH.COMMON_BUTTON,
      //   element: ButtonScreen,
      //   index: true,
      //   isNavRender: true,
      // },

      // Feature is developing=================
      // {
      //   name: "Delivery allocation (dev)",
      //   path: `${PATH.ALLOCATE}`,
      //   element: AllocateScreen,
      //   isNavRender: true,
      //   envDisplay: [ENV.DEV],
      // },
      {
        name: "Delivery manifest",
        path: PATH.DELIVERY.DELIVERY_MANIFESTS,
        element: DeliveryManifestsScreen,
        isNavRender: true,
        isBeta: true,
      },
      {
        name: "Delivery route detail",
        path: `${PATH.DELIVERY.DELIVERY_MANIFESTS}/:routeDetailId`,
        element: DeliveryRouteDetailScreen,
      },
      {
        name: "Delivery edit routes",
        path: `${PATH.DELIVERY.EDIT_ROUTES}`,
        element: EditRoutesScreen,
      },

      // Optimize routes
      {
        name: "Delivery routes optimizing",
        path: `${PATH.DELIVERY.OPTIMIZING}`,
        element: DeliveryRouteOptimizingScreen,
      },
      {
        name: "Delivery routes optimized preview",
        path: `${PATH.DELIVERY.OPTIMIZED_PREVIEW}`,
        element: DeliveryRouteOptimizeGroupPreviewScreen,
      },
      // End optimize routes
      // End Feature is developing=============

      {
        name: "Delivery manifest",
        isDiffDomain: true,
        path: `/delivery/deliverymanifests`,
        isNavRender: true,
      },
      {
        name: "Driver setting",
        isDiffDomain: true,
        path: `/delivery/drivers`,
        isNavRender: true,
      },
    ],
  },
  {
    name: "Price List",
    icon: Icon.Price,
    isDiffDomain: true,
    path: `/pricing/pricelist`,
    isNavRender: true,
    userView: [USER_VIEW.SUPPLIER],
  },
  {
    name: "Reports",
    icon: Icon.Report,
    path: PATH.SALES_ORDERS,
    element: SalesOrdersScreen,
    noPage: true,
    isNavRender: true,
    userView: [USER_VIEW.SUPPLIER],
    subItems: [
      {
        name: "Sales",
        isDiffDomain: true,
        path: `/Reports/GenerateReports/SalesReports`,
        isNavRender: true,
      },
      {
        name: "Picking",
        isDiffDomain: true,
        path: `/Reports/GenerateReports/PickingReports`,
        isNavRender: true,
      },
    ],
  },
  {
    name: "Payment",
    icon: Icon.Money,
    isDiffDomain: true,
    path: `/payment/zai/index`,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
  },
  {
    name: "Pantry List",
    icon: Icon.List,
    path: PATH.PANTRY_LIST,
    element: PantryList,
    isNavRender: false,
    isBeta: true,
    userView: [USER_VIEW.SUPPLIER],
  },
  {
    name: "Pantry List",
    icon: Icon.List,
    isDiffDomain: true,
    path: `/orderforms/forms/pantry`,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER],
  },
  {
    name: "Inventory",
    icon: Icon.Box,
    path: PATH.SALES_ORDERS,
    element: SalesOrdersScreen,
    noPage: true,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
    subItems: [
      {
        name: "Products",
        isDiffDomain: true,
        path: `/inventory/products`,
        isNavRender: true,
      },
      {
        name: "Product upload",
        isDiffDomain: true,
        path: `/inventory/productupload`,
        isNavRender: true,
      },
    ],
  },
  {
    name: "Apps & Integrations",
    icon: Icon.Dashboard,
    isDiffDomain: true,
    path: `/businessIntegration/apps/`,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
  },
  {
    name: "Settings",
    icon: Icon.Settings,
    isDiffDomain: true,
    path: `/Settings/ManageSettings/Options`,
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
  },
  {
    name: "Storybook (Dev)",
    icon: Icon.Despatch,
    path: `${PATH.APP_STORYBOOK}`,
    element: CommonAppComponentWrap,
    envDisplay: [ENV.DEV],
    isNavRender: true,
    userView: [USER_VIEW.CUSTOMER, USER_VIEW.SUPPLIER],
    subItems: [
      {
        name: "Buttons",
        path: PATH.COMMON_BUTTON,
        element: ButtonScreen,
        index: true,
        isNavRender: true,
      },
      {
        name: "Icons",
        path: PATH.COMMON_ICON,
        element: IconScreen,
        isNavRender: true,
      },
      {
        name: "Form",
        path: PATH.COMMON_FORM,
        element: FromScreen,
        isNavRender: true,
      },
      {
        name: "Tooltip",
        path: PATH.COMMON_TOOLTIP,
        element: TooltipScreen,
        isNavRender: true,
      },
      {
        name: "Label",
        path: PATH.COMMON_LABEL,
        element: LabelScreen,
        isNavRender: true,
      },
      {
        name: "Notistack",
        path: PATH.NOTISTACK,
        element: NotistackScreen,
        isNavRender: true,
      },
      {
        name: "Alert",
        path: PATH.ALERTS,
        element: AlertScreen,
        isNavRender: true,
      },
      {
        name: "ListBox",
        path: PATH.LISTBOX,
        element: ListBoxScreen,
        isNavRender: true,
      },
      {
        name: "Timeline",
        path: PATH.TIMELINE,
        element: Timeline,
        isNavRender: true,
      },
      {
        name: "Table",
        path: PATH.TABLE,
        element: Table,
        isNavRender: true,
      },
    ],
  },
] as any;

import clsx from "clsx";
import { DeliveryStatusLabel, Icon } from "@/components";
interface Props {
  data: any;
  isFirstItem?: Boolean;
}

export const TodayRouteItem = ({ data, isFirstItem }: Props) => {
  return (
    <div className={clsx("py-3", !isFirstItem && "shadow-overline")}>
      <div className="flex items-center">
        <span className="mr-3 font-semibold line-clamp-1">
          {data.routeName}
        </span>
        {![0, 4].includes(data.status) && (
          <DeliveryStatusLabel status={data.status} />
        )}
        <Icon.AngleRight size="12" classname="fill-blue ml-auto" />
      </div>
      <div className="mt-3">
        <span className="text-sm font-semibold text-neutral-40 uppercase">
          Order number:{" "}
        </span>
        <span className="text-neutral-80">{data.totalOrders} orders</span>
      </div>
    </div>
  );
};

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M8 1L10.2748 5.60856L15.3604 6.34754L11.6802 9.93449L12.5487 15.0004L8 12.6088L3.4513 15.0004L4.31983 9.93449L0.639648 6.34754L5.72516 5.60856L8 1Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default IconComponent;

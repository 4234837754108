import { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";

import { Table, Th, Td, Icon } from "@/components";
import { formatAMPM } from "../utils";
import { holidaySchedulesType } from "../models";

const HEADER_TD_CLASSNAME = "bg-neutral-10 border-t text-neutral-50";
const BODY_TD_CLASSNAME = "border-b border-neutral-20";
const FORMAT_DATE = "DD/MM/YYYY";

const HolidayScheduleDetail = ({
  holidayDetail,
  status,
}: {
  holidayDetail: holidaySchedulesType;
  status: string | null;
}) => {
  const [isShow, setShow] = useState<boolean>(true);
  const [listDelivery, setListDelivery] = useState(holidayDetail.data.items);

  useEffect(() => {
    setShow(status !== "Collapse");
  }, [status]);

  const [sort, setSort] = useState<{
    [Key: string]: "ASC" | "DESC" | undefined;
  }>({
    date: undefined,
    despatchDate: undefined,
    orderDate: undefined,
    cutOffTime: undefined,
  });

  const icon = !isShow ? (
    <Icon.AngleDown iconType="stroke" className="stroke-neutral-50" />
  ) : (
    <Icon.AngleUp iconType="stroke" className="stroke-neutral-50" />
  );

  const toggleCollapse = () => {
    setShow(!isShow);
  };

  const handleSortListDeliveryDate = (
    type: "ASC" | "DESC" | undefined,
    column: string
  ) => {
    const listDeliveryDate = [...listDelivery];
    listDeliveryDate.sort((a, b) => {
      const valueA = a[column].toString().toLowerCase();
      const valueB = b[column].toString().toLowerCase();
      if (type === "ASC") {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
      } else {
        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
      }
      return 0;
    });
    setListDelivery(listDeliveryDate);
  };

  const handleUpdateIconSort = (column: string) => {
    const updateSort = { ...sort };

    Object.keys(updateSort).forEach((col) => {
      if (col === column) {
        updateSort[col] = ["DESC", undefined].includes(updateSort[col])
          ? "ASC"
          : "DESC";
        handleSortListDeliveryDate(updateSort[col], column);
      } else {
        updateSort[col] = undefined;
      }
    });
    setSort(updateSort);
  };

  return (
    <div className="mt-4 border-b border-neutral-20">
      <div className="flex justify-between items-center mb-3">
        <div>
          <div className="text-neutral-90 font-bold">{holidayDetail.name}</div>
          {!isShow && (
            <div className="text-neutral-60 font-semibold text-xs mt-0.5">
              Delivery date(s): {listDelivery.length}
            </div>
          )}
        </div>

        <div className="flex justify-end items-center">
          <div className="inline-flex items-center justify-center">
            <Icon.Calendar className="fill-neutral-50" />
            <span className="ml-2 font-semibold text-neutral-60">
              Date range
            </span>
          </div>
          <div className="ml-2 text-neutral">
            <div>
              {moment(holidayDetail.data.dateFrom).format(FORMAT_DATE)} -{" "}
              {moment(holidayDetail.data.dateTo).format(FORMAT_DATE)}
            </div>
          </div>
          <div
            className="select-none relative inline-flex items-center justify-center bg-white font-medium text-neutral-60 hover:text-blue group w-9 h-9 rounded cursor-pointer ml-4"
            onClick={toggleCollapse}
          >
            {icon}
          </div>
        </div>
      </div>
      {isShow && (
        <Table className="relative w-full border-l border-r border-neutral-20 mb-5">
          <thead className="text-neutral-50">
            <tr>
              <Th
                className={clsx(`${HEADER_TD_CLASSNAME} text-right`)}
                sort={sort.date}
                onSortClick={() => {
                  handleUpdateIconSort("date");
                }}
              >
                <span>DELIVERY DATE</span>
              </Th>
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)}></Th>
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)}></Th>
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)}></Th>
              <Th
                className={clsx(`${HEADER_TD_CLASSNAME} text-right`)}
                sort={sort.despatchDate}
                onSortClick={() => {
                  handleUpdateIconSort("despatchDate");
                }}
              >
                <span>DESPATCH DATE</span>
              </Th>
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)}></Th>
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)}></Th>
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)}></Th>
              <Th
                className={clsx(`${HEADER_TD_CLASSNAME} text-right`)}
                sort={sort.orderDate}
                onSortClick={() => {
                  handleUpdateIconSort("orderDate");
                }}
              >
                <span>ORDER DATE</span>
              </Th>
              <Th
                className={clsx(`${HEADER_TD_CLASSNAME} text-right`)}
                sort={sort.cutOffTime}
                onSortClick={() => {
                  handleUpdateIconSort("cutOffTime");
                }}
              >
                <span>CUT-OFF TIME</span>
              </Th>
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)}></Th>
            </tr>
          </thead>

          <tbody className="bg-white ">
            {listDelivery.map((schedule: any) => {
              return (
                <tr
                  className="text-sm font-medium text-neutral-90 group hover:bg-neutral-5"
                  key={schedule.date}
                >
                  <Td className={clsx(`${BODY_TD_CLASSNAME} text-right`)}>
                    {moment(schedule.date).format(FORMAT_DATE)}
                    <span className="w-[16px] inline-block"></span>
                  </Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME}`)}></Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME}`)}></Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME}`)}></Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME} text-right`)}>
                    {moment(schedule.despatchDate).format(FORMAT_DATE)}
                    <span className="w-[16px] inline-block"></span>
                  </Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME}`)}></Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME}`)}></Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME}`)}></Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME} text-right`)}>
                    {moment(schedule.orderDate).format(FORMAT_DATE)}
                    <span className="w-[16px] inline-block"></span>
                  </Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME} text-right`)}>
                    {(schedule.cutOffTime &&
                      formatAMPM(schedule.cutOffTime)) || (
                      <span className="text-neutral-40">--</span>
                    )}
                    <span className="w-[16px] inline-block"></span>
                  </Td>
                  <Td className={clsx(`${BODY_TD_CLASSNAME}`)}></Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default HolidayScheduleDetail;

import { HTMLAttributes } from "react";
import clsx from "clsx";

interface Props extends HTMLAttributes<HTMLElement> {
  bgColor?: string;
  className?: string;
}
export const DotColor = ({
  bgColor = "bg-blue-dark-50",
  className,
  ...rest
}: Props) => (
  <span
    className={clsx(
      "inline-block w-2.5 h-2.5 rounded-full",
      bgColor,
      className
    )}
    {...rest}
  />
);

import AppLayout from "./AppLayout";
import AuthLayout from "./AuthLayout";
import EmptyLayout from "./EmptyLayout";
import TransferingLayout from "./TransferingLayout";

export const MasterLayout = {
  AppLayout,
  AuthLayout,
  TransferingLayout,
  EmptyLayout,
};

import Header from "./Header";
import Sidebar from "./Sidebar";
import BodyContent from "./BodyContent";
import TomBackdrop from "./TomBackdrop";

export const LayoutPartials = {
  Header,
  Sidebar,
  BodyContent,
  TomBackdrop,
};

import { useEffect, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import { Common, Icon } from "@/components";
import ListBoxOption from "@/components/common/ListBox/ListBoxOption";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { EPantryMode } from "../models/EPantryMode";
import { pantryListSelector } from "../providers/selector";
import { setPantryMode } from "../providers/slice";
interface Props {
  onChange?: (value: any) => void;
}

interface ListRenderProps {
  modes: {
    key: EPantryMode;
    val: string;
  }[];
}

const ListRender = ({ modes }: ListRenderProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  return (
    <>
      {modes.map((mode) => (
        <ListBoxOption
          key={mode.key}
          value={mode}
          content={(selected) => {
            return (
              <div
                data-tip={
                  mode.key === EPantryMode.Auto
                    ? "The pantry list will be updated automatically based on the customer placing an order."
                    : ""
                }
              >
                <span
                  className={clsx(
                    "block truncate",
                    selected ? "font-medium" : "font-normal"
                  )}
                >
                  {mode.val}
                </span>
                {selected ? (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                    <Icon.Checked
                      iconType="fill"
                      className="h-5 w-5 fill-neutral-90"
                    />
                  </span>
                ) : null}
              </div>
            );
          }}
        />
      ))}
    </>
  );
};
const Index = ({ onChange }: Props) => {
  const dispatch = useAppDispatch();
  const pantry = useAppSelector(pantryListSelector);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [pantry.mode]);

  const onChangePantryMode = (value: EPantryMode) => {
    if (onChange) {
      onChange(value);
    } else {
      dispatch(setPantryMode(value));
    }
  };

  const ext = useMemo(() => {
    const modes = Object.entries(EPantryMode)
      .map(([key, val]) => ({ key: Number(key), val: val.toString() }))
      .filter((v) => !isNaN(Number(v.key)));

    const selectedMode = modes.find((m) => m.key === pantry?.mode);
    return { modes, selectedMode };
  }, [pantry.mode]);

  const buttonRender = (
    <>
      <span className="block truncate w-14">{EPantryMode[pantry.mode]}</span>
      <span
        data-tip={
          pantry.mode === EPantryMode.Auto
            ? "The pantry list will be updated automatically based on the customer placing an order."
            : "User can add and remove products in the pantry list manually"
        }
      >
        <Icon.InfoFill iconType="fill" className="fill-neutral-60" size={12} />
      </span>

      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <Icon.AngleDown
          className="stroke-neutral-60"
          aria-hidden="true"
          iconType="stroke"
          size={12}
        />
      </span>
    </>
  );

  return (
    <Common.ListBox
      buttonRender={buttonRender}
      listRender={<ListRender modes={ext.modes} />}
      selected={ext.selectedMode}
      onChange={(mode) => onChangePantryMode(Number(mode.key))}
      disabled={!pantry.isEditing}
    />
  );
};

export default Index;

import { Outlet } from "react-router-dom";

interface Props {
  pageName?: string;
}

const Index = ({ pageName }: Props) => {
  return (
    <>
      <div className="text-9xl">
        {pageName || "Sales orders page"}
        <Outlet />
      </div>
    </>
  );
};

export default Index;

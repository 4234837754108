import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 60 60">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0123 29.948H19.8948H19.7759C9.74197 30.0133 1.5176 38.3217 1.0014 48.795C0.982553 49.1909 1.12609 49.578 1.40014 49.8666C1.67274 50.1537 2.05264 50.3161 2.44994 50.3161H37.3383H37.3571C37.7472 50.3117 38.1198 50.1493 38.3895 49.8666C38.6621 49.578 38.8057 49.1909 38.7868 48.795C38.2706 38.3217 30.0462 30.0133 20.0123 29.948ZM30.4261 29.8813C35.8216 32.9973 39.8381 38.4319 41.204 44.9264H57.5497C57.947 44.9264 58.3269 44.764 58.5995 44.4769C58.8736 44.1883 59.0171 43.8012 58.9983 43.4053C58.5154 33.6121 50.7942 25.8518 41.3968 25.8518C37.2542 25.8518 33.4407 27.3568 30.4261 29.8813ZM19.8948 11.0299C15.0779 11.0299 11.1688 14.9405 11.1688 19.7559C11.1688 24.5728 15.0779 28.482 19.8948 28.482C24.7103 28.482 28.6209 24.5728 28.6209 19.7559C28.6209 14.9405 24.7103 11.0299 19.8948 11.0299ZM41.3968 9.68286C37.6225 9.68286 34.5572 12.7481 34.5572 16.5225C34.5572 20.2982 37.6225 23.3621 41.3968 23.3621C45.1726 23.3621 48.2364 20.2982 48.2364 16.5225C48.2364 12.7481 45.1726 9.68286 41.3968 9.68286Z"
        fill="url(#paint0_linear_923_116295)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_923_116295"
          x1="5.34994"
          y1="57.5501"
          x2="45.9498"
          y2="18.4003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#258CCB" />
          <stop offset="1" stopColor="#45B9FF" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

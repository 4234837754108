import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M10.8929 9.71526C10.4791 7.27031 7.54517 6.46786 7.15649 6.3801C6.34156 7.01952 5.18796 7.01952 4.37302 6.3801C3.98436 6.46791 1.1131 7.25775 0.69934 9.71526C0.486202 10.7434 0.548909 11.6211 1.07551 11.7966C1.38896 11.8969 1.90305 11.7715 2.45475 11.596C2.46726 11.596 2.46726 11.596 2.47985 11.5835H2.49236C2.83091 11.4707 3.06916 11.1572 3.06916 10.7936V10.756C3.06916 10.4425 2.89363 10.1667 2.64288 10.0287C3.08158 8.72481 4.32288 7.79693 5.78974 7.79693C7.24419 7.79693 8.48544 8.7373 8.94935 10.0413C8.69861 10.1917 8.52308 10.455 8.52308 10.7685V10.8061C8.52308 11.1823 8.77382 11.4957 9.11237 11.6085C9.67657 11.7716 10.1782 11.9095 10.479 11.8092C11.0181 11.6462 11.1059 10.7435 10.8928 9.71529L10.8929 9.71526Z" />
      <path d="M8.52311 3.4086C8.52311 4.98742 7.30498 6.2673 5.80233 6.2673C4.29969 6.2673 3.08154 4.98742 3.08154 3.4086C3.08154 1.82977 4.29967 0.549896 5.80233 0.549896C7.30496 0.549896 8.52311 1.82977 8.52311 3.4086Z" />
      <path d="M6.65499 10.6431C6.7428 10.7935 6.79291 10.9565 6.79291 11.1446C6.79291 11.3702 6.7177 11.5708 6.59229 11.7339C7.28189 11.6963 7.85859 11.6085 8.34755 11.4956C8.36005 11.3828 8.37265 11.2574 8.37265 11.1321C8.37265 10.5177 8.15951 9.96605 7.80846 9.51468L6.65499 10.6431Z" />
      <path d="M4.92456 10.6431L3.78358 9.52722C3.42002 9.9661 3.20679 10.5303 3.20679 11.1446C3.20679 11.27 3.21929 11.3953 3.23189 11.5082C3.72087 11.621 4.29767 11.6962 4.98717 11.7338C4.86175 11.5708 4.78654 11.3576 4.78654 11.1445C4.78663 10.9566 4.83683 10.781 4.92455 10.6431L4.92456 10.6431Z" />
      <path d="M5.78981 10.6431C5.51397 10.6431 5.28833 10.8688 5.28833 11.1446C5.28833 11.4204 5.51397 11.6461 5.78981 11.6461C6.06565 11.6461 6.29129 11.4204 6.29129 11.1446C6.29129 10.8688 6.06565 10.6431 5.78981 10.6431V10.6431Z" />
      <path d="M5.78965 8.56178C5.15022 8.56178 4.57344 8.78742 4.12207 9.17617L5.27558 10.2921C5.42601 10.2043 5.60155 10.1541 5.78967 10.1541C5.97771 10.1541 6.16584 10.2043 6.31627 10.3046L7.46978 9.18868C7.00589 8.80002 6.42912 8.56177 5.78969 8.56177L5.78965 8.56178Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M2.58047 13.7864C2.96124 12.3109 4.84947 11.725 6.15717 12.5073C7.72396 13.4447 9.81129 14.3571 12 14.3571C14.1887 14.3571 16.276 13.4447 17.8428 12.5074C19.1505 11.725 21.0388 12.3109 21.4195 13.7864L23.6775 22.5358C23.8408 23.1688 23.3629 23.7857 22.7092 23.7857H1.29083C0.637126 23.7857 0.159204 23.1688 0.322549 22.5358L2.58047 13.7864Z" />
      <circle cx="12" cy="6" r="6" />
    </SvgIcon>
  );
}

export default IconComponent;

import { useEffect, useState } from "react";
import { batch } from "react-redux";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import debounce from "lodash/debounce";
import { Common, Icon } from "@/components";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { SelectProductActionProps } from "../providers/actionProps";
import { pantryListSelector } from "../providers/selector";
import { selectProduct, setIsScrollToBottom } from "../providers/slice";
import { useSupplierSearchProducts } from "../services/pantryList";
import { PantryLine } from "../models/PantryLine";
import { useGetPantryLineProductIds } from "../providers/hooks";

interface SearchProductsSelectboxProps {
  pantryLineId: string;
  placehoder?: string;
  lineRendering: PantryLine[];
}
export const SearchProductsSelectbox = (
  props: SearchProductsSelectboxProps
) => {
  const dispatch = useAppDispatch();
  const pantry = useAppSelector(pantryListSelector);
  // const [selected, setSelected] = useState<any>(null);
  const [searchKeywords, setSearchKeywords] = useState<string>("");
  const [controller, setController] = useState(() => new AbortController());

  const pantryLineProductIds = useGetPantryLineProductIds();

  const supplierSearchProducts = useSupplierSearchProducts({
    controller,
    setController,
  });

  const { data, isLoading } = supplierSearchProducts;

  const delaySearchKeywords = debounce((newKeywords: string) => {
    if (newKeywords.length > 1) {
      supplierSearchProducts.mutate({
        keywords: searchKeywords,
        customerAccountId: pantry.customerAccountId,
        productCategoryId: undefined,
      });
    }
  }, 700);

  useEffect(() => {
    if (!searchKeywords && data) supplierSearchProducts.reset();
  }, [searchKeywords]);

  useEffect(() => {
    delaySearchKeywords(searchKeywords);
    // eslint-disable-next-line
  }, [searchKeywords]);

  useEffect(() => {
    if (data?.length > 0) {
      ReactTooltip.rebuild();
    }
    // eslint-disable-next-line
  }, [data]);

  const Item = ({ item }: any) => {
    return (
      <>
        <div
          className={clsx(
            "w-full flex flex-row items-center cursor-pointer px-3 py-4 select-none shadow-overline",
            "hover:bg-neutral-5",
            pantryLineProductIds.includes(item.id) ? "bg-blue-10" : ""
          )}
        >
          <div className="basis-1/4">{item.productSKU}</div>
          <div className="basis-1/2">
            <div className="flex items-center">
              <img
                alt=""
                loading="lazy"
                className="mr-2 w-8 h-8 object-cover d-inline-block"
                src={item.productImageUrl}
              ></img>
              <p>{item.productName}</p>
              {!item.isFromProductAgreement && (
                <span
                  className="mr-2"
                  data-tip={`This product is not in ${pantry.customerTradingName}'s agreed list. <br />Adding it into pantry list will also add to the agreed list for this customer.`}
                >
                  <Icon.WarningCircle
                    iconType="fill"
                    className="ml-2 fill-yellow-dark"
                    size={12}
                  />
                </span>
              )}
            </div>
          </div>
          <div className="basis-1/4">{`$${item.price}/${item.unit}`}</div>
        </div>
      </>
    );
  };

  const header = () => (
    <div className="w-full flex flex-row px-3 py-2 select-none shadow-overline">
      <div className="basis-1/4 font-bold">SKU</div>
      <div className="basis-1/2 font-bold">PRODUCT NAME</div>
      <div className="basis-1/4 font-bold">UNIT PRICE</div>
    </div>
  );
  // const handleDisplayValue = () => {
  //   return selected ? (
  //     <div className="flex items-center pl-0.5">
  //       <div>
  //         <Common.DotColor />
  //       </div>
  //       <div className="flex-1 min-w-0 font-semibold ml-2">
  //         <div className="w-full truncate">{selected?.productName}</div>
  //       </div>
  //     </div>
  //   ) : (
  //     ""
  //   );
  // };

  const onSelect = (selected: any) => {
    const comment =
      pantry.pantryLines[
        pantry.pantryLines.findIndex((l) => l.id === props.pantryLineId)
      ].comment;
    const selectProductActionProps: SelectProductActionProps = {
      id: props.pantryLineId,
      isNew: true, // Only new lines is editing
      comment,
      product: {
        id: selected?.id,
        productSKU: selected?.productSKU,
        productName: selected?.productName,
        unit: selected?.unit,
        price: selected?.price,
        isPriceTBC: selected?.isPriceTBC,
        productCategoryId: selected?.productCategoryId,
        productCategoryName: selected?.productCategoryName,
        productImageUrl: selected?.productImageUrl,
        universalProductId: selected?.universalProductId || null,
        isFromProductAgreement: selected?.isFromProductAgreement,
        isFavourite: selected?.isFavourite,
        comment,
      },
    };
    batch(() => {
      dispatch(selectProduct(selectProductActionProps));
      dispatch(setIsScrollToBottom(true));
    });

    // reset search data after select
    setSearchKeywords("");
    supplierSearchProducts.reset();
    // setSelected(selected);
  };

  return (
    <Common.CustomCombobox
      data={data as any}
      isLoading={isLoading}
      value={searchKeywords}
      optionsHeader={header}
      renderItem={Item}
      placeholder={props.placehoder}
      keySearch="productName"
      // displayValue={handleDisplayValue}
      onSelect={onSelect}
      onChangeInput={(input: string | undefined) =>
        setSearchKeywords(input ? input.trim() : "")
      }
      optionsClassName={clsx(
        props.lineRendering.length > 4
          ? "-top-2 transform -translate-y-full" // dropdown show on top
          : "", // dropdown show on down
        "min-w-[600px]"
      )}
      useSection
      sectionProp="productCategoryName"
      sectionClassName="px-3 py-2 shadow-overline bg-neutral-10"
      noDataDesc={
        searchKeywords.length > 1
          ? ""
          : "Type 2 more characters for searching products"
      }
    />
  );
};

import clsx from "clsx";
import { Fragment, useEffect } from "react";
import parse from "html-react-parser";
import { Icon } from "@/components";
import { Menu, Transition } from "@headlessui/react";
import {
  useGetCountUnreadMessages,
  useGetAccountMessages,
} from "@/services/notification";
import { useTransferLogin } from "@/services/authentication";
import { NotiItem } from "../models";
import { Common } from "@/components/common";

const PAGE_SIZE = 5;
const FETCH_UNREADMESSAGE_INTERVAL = 60000;

const Notification = () => {
  const transferLogin = useTransferLogin();

  // Fetch unread message each 1minute
  const getCountUnreadMessages = useGetCountUnreadMessages();
  const { data: unreadMessageCount } = getCountUnreadMessages;
  useEffect(() => {
    getCountUnreadMessages.mutate();

    const timer = setInterval(() => {
      getCountUnreadMessages.mutate();
    }, FETCH_UNREADMESSAGE_INTERVAL);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, []);

  // Get notification list
  const getAccountMessages = useGetAccountMessages();
  const {
    isLoading: notiIsLoading,
    isSuccess: fetchListSuccess,
    data: messageList,
  } = getAccountMessages;

  const handleFetchDataAfterOpenList = () => {
    if (!messageList && !fetchListSuccess) {
      getAccountMessages.mutate({
        pageSize: PAGE_SIZE,
        pageNumber: 1,
        shouldIncludeArchivedMessages: true,
      });
    }
  };
  // End Get notification list

  const handleTransferLogin = (path: string) => {
    transferLogin.mutate({ path });
  };

  const handleItemClick = (itemData: NotiItem) => {
    const actionData = JSON.parse(itemData.actionData.actionData);
    handleTransferLogin(actionData?.UrlRedirect);
  };

  return (
    <>
      <Menu
        as="div"
        className={clsx("relative flex-shrink-0 group block")}
        onClick={handleFetchDataAfterOpenList}
      >
        {() => {
          return (
            <>
              <Menu.Button onClick={handleFetchDataAfterOpenList}>
                <div className="ml-5 mr-3.5">
                  <Icon.Noti
                    size={20}
                    badge={unreadMessageCount}
                    className="fill-neutral-50"
                  />
                </div>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={clsx(
                    "origin-top-right absolute right-3 mt-2 min-w-56 rounded-md shadow-modal bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10",
                    "-right-4 md:-right-24"
                  )}
                >
                  <div className="w-[19.5rem] md:w-[21.25rem]">
                    <div className="text-neutral-70 bg-wh rounded-b">
                      {messageList && messageList.items ? (
                        <>
                          <div className="bg-blue text-white font-medium px-4 py-3 rounded-t relative">
                            <span className="w-4 h-4 bg-blue rotate-45 block absolute -top-2 right-8 md:right-28" />
                            {unreadMessageCount || 0} new notification
                            {unreadMessageCount > 1 ? "s" : ""}
                          </div>
                          {messageList.items.map((notiItem: NotiItem) => (
                            <div
                              key={notiItem.id}
                              className={clsx(
                                "px-4 py-2 cursor-pointer shadow-overline hover:bg-blue-10",
                                !notiItem.isRead && "bg-blue-5"
                              )}
                              onClick={() => handleItemClick(notiItem)}
                            >
                              <div className="text-neutral-80">
                                {parse(notiItem.messageContentHtml)}
                              </div>
                              <div className="text-neutral-80 font-light text-xs mt-1">
                                {notiItem.createdDate}
                              </div>
                            </div>
                          ))}
                          <div
                            className="text-center text-neutral-60 cursor-pointer py-3 px-4 hover:bg-blue-10"
                            onClick={() =>
                              handleTransferLogin(
                                "/Notification/AccountNotification"
                              )
                            }
                          >
                            See all notifications
                          </div>
                        </>
                      ) : (
                        <>
                          {notiIsLoading ? (
                            <Common.NoData
                              iconRender={Icon.Loading}
                              title={"Notification is loading..."}
                            />
                          ) : (
                            <Common.NoData />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    </>
  );
};

export { Notification };

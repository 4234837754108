import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Combobox, Transition } from "@headlessui/react";
// import isEmpty from "lodash/isEmpty";
import groupBy from "lodash/groupBy";
import { Icon, CustomScrollbar, Common } from "@/components";
interface Props {
  data: object[];
  value?: string;
  ipSize?: "lg" | "md";
  className?: string;
  optionsClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  keySearch: string;
  optionsHeader?: React.ElementType;
  renderItem: React.ElementType;
  onSelect: (item: any) => void;
  onChangeInput?: (item: any) => void;
  // displayValue: () => React.ReactNode | string;
  useSection?: boolean;
  sectionProp?: string;
  sectionClassName?: string;
  noDataTitle?: string;
  noDataDesc?: string;
  isLoading?: boolean;
}

export const CustomCombobox = ({
  data,
  value,
  ipSize = "lg",
  className,
  optionsClassName,
  disabled,
  keySearch,
  placeholder,
  onSelect,
  onChangeInput,
  // displayValue,
  optionsHeader: OptionsHeader,
  renderItem: RenderItem,
  useSection,
  sectionProp,
  sectionClassName,
  noDataTitle = "No Data",
  noDataDesc = "",
  isLoading = false,
}: Props) => {
  // const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState("");
  const inputRef = useRef<null | HTMLInputElement>(null);
  let timerBlur: any = null;

  const filteredData =
    query === ""
      ? data
      : data.filter((item: any) =>
          item[keySearch]
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleSelect = (item: any) => {
    // setSelected(item);
    onSelect(item);
    timerBlur = setTimeout(() => {
      inputRef?.current?.blur();
    }, 500);
  };

  useEffect(() => {
    return () => timerBlur && clearTimeout(timerBlur);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupFilteredDataBySection = useMemo(() => {
    if (useSection && !!sectionProp) {
      return groupBy(filteredData, sectionProp);
    }
    return undefined;
  }, [filteredData, useSection, sectionProp]);

  return (
    <Combobox value={value} onChange={handleSelect}>
      {({ open }) => {
        // const isDisplayInput = open || isEmpty(displayValue());
        return (
          <div className="relative">
            <div className="relative z-0 w-full cursor-default">
              {/* <div
                className={clsx(
                  "block border text-base rounded pr-10 border-neutral-20 focus:ring-blue focus:border-blue w-full h-full absolute top-0 left-0 z-0",
                  ipSize === "md" ? "py-1 pl-2" : "py-[0.375rem] pl-3",
                  disabled ? "bg-neutral-20 text-neutral-40" : "text-neutral",
                  isDisplayInput ? "opacity-0" : "opacity-100",
                  className
                )}
              >
                {displayValue()}
              </div> */}
              <Combobox.Input
                ref={inputRef}
                className={clsx(
                  "block w-full border text-base rounded pr-10 border-neutral-20 focus:ring-blue focus:border-blue relative z-10",
                  // !isDisplayInput ? "opacity-0" : "opacity-100",
                  ipSize === "md" ? "py-1 pl-2" : "py-[0.375rem] pl-3",
                  disabled ? "bg-neutral-20 text-neutral-40" : "text-neutral",
                  className
                )}
                placeholder={placeholder}
                onChange={(event) =>
                  onChangeInput
                    ? onChangeInput(event.target.value)
                    : setQuery(value ?? event.target.value)
                }
                onFocus={(e: any) => {
                  if (
                    e.relatedTarget?.id?.includes("headlessui-combobox-button")
                  )
                    return;
                  !open && e?.target?.nextSibling?.click();
                }}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 z-20">
                <Icon.AngleDown size="12" className="stroke-neutral-50" />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options
                className={clsx(
                  "absolute z-10 mt-1 h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                  optionsClassName
                )}
              >
                <CustomScrollbar>
                  <>
                    {!!OptionsHeader && <OptionsHeader />}

                    {!!groupFilteredDataBySection &&
                      Object.getOwnPropertyNames(
                        groupFilteredDataBySection
                      ).map((section, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div
                              className={clsx(
                                "w-full select-none sticky top-0",
                                sectionClassName
                              )}
                            >
                              {section}
                            </div>
                            {groupFilteredDataBySection[section].map(
                              (item: any, index) => (
                                <Combobox.Option key={item.id} value={item}>
                                  {({ selected, active }) => (
                                    <RenderItem
                                      isFirst={!index}
                                      item={item}
                                      selected={selected}
                                      active={active}
                                    />
                                  )}
                                </Combobox.Option>
                              )
                            )}
                          </React.Fragment>
                        );
                      })}

                    {!isLoading &&
                      (!filteredData || filteredData?.length === 0) && (
                        <Common.NoData
                          title={noDataTitle}
                          description={noDataDesc}
                        />
                      )}
                    {isLoading && (
                      <Icon.Loading size={60} className="mx-auto my-24" />
                    )}
                    {!groupFilteredDataBySection &&
                      !(filteredData.length === 0 && query !== "") &&
                      filteredData.map((item: any, index) => (
                        <Combobox.Option key={item.id} value={item}>
                          {({ selected, active }) => (
                            <RenderItem
                              isFirst={!index}
                              item={item}
                              selected={selected}
                              active={active}
                            />
                          )}
                        </Combobox.Option>
                      ))}
                  </>
                </CustomScrollbar>
              </Combobox.Options>
            </Transition>
          </div>
        );
      }}
    </Combobox>
  );
};

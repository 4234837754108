import {
  ModalContent,
  Card,
  Icon,
  CustomScrollbar,
  Common,
} from "@/components";
import { DotColor } from "@/components/common/DotColor";
import { addressGenerate, timeStopFormat } from "@/utils";
import clsx from "clsx";
import { manifest, manifestLine } from "../models";

const Item = ({ data }: { data: manifestLine }) => (
  <div
    className={clsx(
      "px-3 py-2 flex border rounded mx-4",
      "mt-2",
      "border-neutral-10 bg-white"
    )}
  >
    <div>
      <div
        className={clsx(
          "w-5 h-5 rounded-full font-semibold flex items-center justify-center",
          "bg-neutral-10 text-neutral-50"
        )}
      >
        {data.position + 1}
      </div>
    </div>
    <div className="flex-1 ml-4">
      <div className="font-semibold">
        <span className="text-blue">#{data.document.saleDocumentNumber}</span>
        {data.document.customerTradingName
          ? ` - ${data.document.customerTradingName}`
          : ""}
      </div>
      <div className="flex mt-2">
        <div className="flex items-center mr-5">
          <div data-tip="Stop time">
            <Icon.Stock size="12" className="fill-neutral-40" />
          </div>
          <span className="ml-1.5 text-neutral-70">
            {data.stopTime && timeStopFormat(data.stopTime)}
          </span>
        </div>
      </div>
      <div className="flex mt-2">
        <div className="mt-1" data-tip="Delivery address">
          <Icon.Location size="12" className="fill-neutral-40" />
        </div>
        <div className="ml-1.5 text-neutral-70">
          {addressGenerate(
            data.document.deliveryAddressLine1,
            data.document.deliveryAddressLine2,
            data.document.deliverySuburb,
            data.document.deliveryState,
            data.document.deliveryPostCode
          )}
        </div>
      </div>
    </div>
  </div>
);

interface Props {
  open: boolean;
  onClose: () => void;
  data?: manifest;
}

export const ModalOptimizeDetail = ({ open, onClose, data }: Props) => (
  <ModalContent open={open} onClose={onClose} panelClassWidth="w-[40rem]">
    <Card title="Order details">
      <div className="px-6 pt-5 pb-1 flex items-center">
        <DotColor />
        <div className="font-semibold ml-2">
          {data?.route.name} - {data?.manifestLines?.length} order
          {data?.manifestLines && data?.manifestLines?.length > 1 ? "s" : ""}
        </div>
      </div>
      <div className="px-2 mb-6 h-[50vh]">
        {data?.manifestLines && data?.manifestLines.length ? (
          <CustomScrollbar>
            {data?.manifestLines.map((manifestLine) => (
              <Item key={manifestLine.id} data={manifestLine} />
            ))}
          </CustomScrollbar>
        ) : (
          <Common.NoData title="This route don't have orders yet" />
        )}
      </div>
    </Card>
  </ModalContent>
);

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M6 0.666626H0V1.99996H6V0.666626Z" />
      <path d="M5.99992 3.33325H1.33325V4.66659H5.99992V3.33325Z" />
      <path d="M6.00008 6H2.66675V7.33333H6.00008V6Z" />
      <path d="M6 8.66663H4V9.99996H6V8.66663Z" />
      <path d="M15.6313 7.40325L13.2386 6.20725L12.6466 3.83859C12.5726 3.54125 12.3059 3.33325 11.9999 3.33325H10.9166H7.33325C7.33325 3.33325 7.33325 11.6339 7.33325 11.9999C7.33325 12.3659 7.36725 12.6666 7.36725 12.6666C7.53525 14.1626 8.79325 15.3333 10.3333 15.3333C11.8733 15.3333 13.1313 14.1626 13.2993 12.6666H15.3333C15.7013 12.6666 15.9999 12.3679 15.9999 11.9999V7.99992C15.9999 7.74725 15.8573 7.51659 15.6313 7.40325ZM10.3333 13.9999C9.41459 13.9999 8.66659 13.2526 8.66659 12.3333C8.66659 11.4139 9.41459 10.6666 10.3333 10.6666C11.2519 10.6666 11.9999 11.4139 11.9999 12.3333C11.9999 13.2526 11.2519 13.9999 10.3333 13.9999ZM8.66659 6.66659V4.66659H11.5626L11.9999 6.66659H8.66659Z" />
    </SvgIcon>
  );
}

export default IconComponent;

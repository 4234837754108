import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M11 2L5 8L11 14"
        fill="none"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import clsx from "clsx";
import { Icon } from "@/components/Icons";

interface Props {
  size?: "lg" | "md" | "sm";
  children: React.ReactNode;
  onRemove?: () => void;
  color?: "neutral" | "red" | "blue" | "yellow" | "green" | string;
  isStatus?: boolean;
  className?: string;
  style?: any;
}

export const Label = ({
  size = "lg",
  color = "neutral",
  children,
  onRemove,
  isStatus = false,
  className,
  style,
}: Props) => {
  const handleSize = () => {
    if (size === "lg") {
      return "h-6";
    }
    if (size === "md") {
      return "h-5";
    }
    if (size === "sm") {
      return "h-4";
    }
  };
  const handleColor = () => {
    if (color === "red") {
      return isStatus
        ? "bg-red-light text-red"
        : "bg-red-light text-neutral-80";
    }
    if (color === "blue") {
      return isStatus
        ? "bg-blue-light text-blue"
        : "bg-blue-light text-neutral-80";
    }
    if (color === "yellow") {
      return isStatus
        ? "bg-yellow-light text-yellow"
        : "bg-yellow-light text-neutral-80";
    }
    if (color === "green") {
      return isStatus
        ? "bg-green-light text-green"
        : "bg-green-light text-neutral-80";
    }

    return "bg-neutral-20 text-neutral-80";
  };
  return (
    <div
      style={style}
      className={clsx(
        "rounded-full inline-flex items-center px-2.5 text-sm",
        handleSize(),
        handleColor(),
        className
      )}
    >
      {children}
      {onRemove && (
        <span onClick={onRemove}>
          <Icon.Remove
            size="12"
            className="ml-1.5 cursor-pointer fill-neutral-40 hover:fill-neutral-80"
          />
        </span>
      )}
    </div>
  );
};

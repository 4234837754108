import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M7 8.5C9.2895 8.5 11 6.1245 11 4C11 2.93913 10.5786 1.92172 9.82843 1.17157C9.07828 0.421427 8.06087 0 7 0C5.93913 0 4.92172 0.421427 4.17157 1.17157C3.42143 1.92172 3 2.93913 3 4C3 6.1245 4.7105 8.5 7 8.5Z" />
      <path d="M8 12.4998C7.9984 11.4435 8.37031 10.4205 9.05 9.61184C8.36926 9.53661 7.68488 9.49922 7 9.49984C5.00838 9.4461 3.02747 9.80867 1.184 10.5643C0.831266 10.722 0.531739 10.9784 0.321624 11.3026C0.111509 11.6268 -0.000199868 12.005 2.6845e-07 12.3913V14.9998C2.6845e-07 15.1324 0.0526787 15.2596 0.146447 15.3534C0.240215 15.4472 0.367392 15.4998 0.5 15.4998H9.1525C8.41033 14.6769 7.99969 13.608 8 12.4998Z" />
      <path d="M12.5 9C11.8078 9 11.1311 9.20527 10.5555 9.58986C9.97993 9.97444 9.53133 10.5211 9.26642 11.1606C9.00152 11.8001 8.9322 12.5039 9.06725 13.1828C9.2023 13.8618 9.53564 14.4854 10.0251 14.9749C10.5146 15.4644 11.1383 15.7977 11.8172 15.9327C12.4961 16.0678 13.1999 15.9985 13.8394 15.7336C14.4789 15.4687 15.0256 15.0201 15.4101 14.4445C15.7947 13.8689 16 13.1922 16 12.5C16 11.5717 15.6313 10.6815 14.9749 10.0251C14.3185 9.36875 13.4283 9 12.5 9ZM12.5 14.5V13H10.5V12H12.5V10.5L15 12.5L12.5 14.5Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M4.6584 11.9551C4.83556 11.8741 4.94838 11.6961 4.94838 11.5001L4.94838 9.50008H11.3814C11.6545 9.50008 11.8762 9.27608 11.8762 9.00008L11.8762 7.00008C11.8762 6.72408 11.6545 6.50008 11.3814 6.50008L4.94838 6.50008L4.94838 4.50008C4.94838 4.30408 4.83457 4.12608 4.6584 4.04508C4.48125 3.96308 4.2744 3.99508 4.12793 4.12408L0.16916 7.62408C0.0612836 7.71908 -7.70688e-05 7.85608 -7.70688e-05 8.00008C-7.70688e-05 8.14408 0.0622733 8.28108 0.16916 8.37608L4.12793 11.8761C4.27341 12.0061 4.48125 12.0361 4.6584 11.9551Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 0C15.7761 0 16 0.223858 16 0.5V15.5C16 15.7761 15.7761 16 15.5 16H14.5C14.2239 16 14 15.7761 14 15.5L14 0.5C14 0.223858 14.2239 0 14.5 0H15.5Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 15 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 7.4375C14.5 7.91089 14.1082 8.29464 13.625 8.29464H3.48744L7.24372 11.9743C7.58543 12.309 7.58543 12.8517 7.24372 13.1864C6.90201 13.5212 6.34799 13.5212 6.00628 13.1864L0.756281 8.04359C0.414572 7.70886 0.414572 7.16614 0.756281 6.83141L6.00628 1.68855C6.34799 1.35382 6.90201 1.35382 7.24372 1.68855C7.58543 2.02329 7.58543 2.566 7.24372 2.90074L3.48744 6.58036L13.625 6.58036C14.1082 6.58036 14.5 6.96411 14.5 7.4375Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

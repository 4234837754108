/* eslint-disable */
import axios from "axios";
import { getToken } from "@/utils/authUtils";
import { API_ENDPOINT, PATH } from "./globalVariable";
import { SnackbarUtils } from "@/utils";

const TIMEOUT = 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = API_ENDPOINT;

// const isTokenValid = (token: string) => {
//   try {
//     const { exp } = jwtDecode(token) as any;
//     return exp > Date.now() / 1000;
//   } catch (e) {
//     return false;
//   }
// };

// const setupAxiosInterceptors = () => {
//   const onRequestSuccess = (config: any) => {
//     const token = getUser().token;
//     // if (token && isTokenValid(token)) {
//     //   config.headers.common.Authorization = `Bearer ${token}`;
//     // }
//     // return config;
//     // const { accessToken } = Helpers.getToken() || {};
//     if (token) {
//       config.headers.common.Authorization = `Jwt ${token}`;
//     }
//     return config;
//   };
//   const onResponseSuccess = (response: any) => response;
//   const onResponseError = (err: any) => {
//     const status = err.status || (err.response ? err.response.status : 0);
//     console.log("status: ", status, location.pathname !== PATH.SIGN_IN);
//     // Unauthentication or Token expired
//     if (
//       (status === 401 || status === 403) &&
//       location.pathname !== PATH.SIGN_IN
//     ) {
//       useLogout();
//       // location.href = "/logout";
//     }

//     return Promise.reject(err);
//   };
//   axios.interceptors.request.use(onRequestSuccess);
//   axios.interceptors.response.use(onResponseSuccess, onResponseError);
// };

// HOOK
// const AxiosInterceptor = ({ children }: any) => {
//   // const navigate = useNavigate();
//   const logout = useLogout();
//   useEffect(() => {
//     const onRequestSuccess = (config: any) => {
//       const token = getToken();
//       if (token) {
//         config.headers.common.Authorization = `Jwt ${token}`;
//       }
//       return config;
//     };

//     axios.interceptors.request.use(onRequestSuccess);

//     const resInterceptor = (response: any) => {
//       return response;
//     };

//     const errInterceptor = (error: any) => {
//       if ([401, 403].includes(error.response.status)) {
//         logout();
//       }

//       return Promise.reject(error);
//     };

//     const interceptor = axios.interceptors.response.use(
//       resInterceptor,
//       errInterceptor
//     );

//     return () => axios.interceptors.response.eject(interceptor);
//   }, []);

//   return children;
// };
// export { AxiosInterceptor };

const AxiosInterceptor = () => {
  const onRequestSuccess = (config: any) => {
    const token = getToken();
    if (token) {
      config.headers.common.Authorization = `Jwt ${token}`;
    }
    return config;
  };

  axios.interceptors.request.use(onRequestSuccess);

  const resInterceptor = (response: any) => {
    if (response?.data?.errorMessage) {
      SnackbarUtils.error(response?.data?.errorMessage);
      return Promise.reject(response);
    }
    // if (response?.data?.data?.error) {
    //   SnackbarUtils.error(response?.data?.data?.error);
    //   return Promise.reject(response);
    // }
    return response;
  };

  const errInterceptor = (error: any) => {
    // console.log("error errInterceptor: ", error);
    if (error.ERR_CANCELED) {
      return;
    }
    if ([401, 403].includes(error?.response?.status)) {
      const win: Window = window;
      win.location = `/#/${PATH.AUTH}/${PATH.SIGN_OUT}`;
    }

    return Promise.reject(error);
  };

  const interceptor = axios.interceptors.response.use(
    resInterceptor,
    errInterceptor
  );

  return () => axios.interceptors.response.eject(interceptor);
};
export { AxiosInterceptor };

import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import moment from "moment";
import { Icon, Common } from "@/components";
import { manifest, optimizeInfo } from "../models";
import { driverName } from "../utils";
import { timeFormat } from "@/utils";

interface Props {
  openDetail: () => void;
  data: optimizeInfo;
  manifest: manifest;
  onItemChange: (item: optimizeInfo) => void;
}

export const RouteOptimizeItem = ({
  openDetail,
  data,
  manifest,
  onItemChange,
}: Props) => {
  const [isExpand, setIsExpand] = useState(true);
  const handleChange = (target: string, date: Date | null) => {
    onItemChange({
      ...data,
      [target]: date ? moment(date).format("HH:mm") : null,
    });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  return data && manifest ? (
    <div
      className={clsx(
        "p-4 border bg-neutral-5 rounded mb-3",
        !isExpand && (!data.workEndTime || !data.workStartTime)
          ? "border-yellow"
          : "border-neutral-20"
      )}
    >
      <div
        className={clsx(
          "flex space-x-3",
          isExpand && "pb-4 border-b border-neutral-10"
        )}
      >
        <div className="flex-1 flex items-center">
          <Common.DotColor style={{ backgroundColor: manifest.route.colour }} />
          <span className="font-semibold ml-2 mr-1.5">
            {manifest.route.name}
          </span>
          <span
            className="text-blue cursor-pointer hover:underline"
            onClick={openDetail}
          >
            ( {manifest.manifestLines.length} order
            {manifest.manifestLines.length > 1 ? "s" : ""} )
          </span>
          <div>
            <div className="w-1.5 h-1.5 rounded-full bg-neutral-30 mx-3" />
          </div>
          <Icon.Driver size="12" className="fill-neutral-40" />
          <span className="text-neutral-70 ml-2 mr-auto">
            {driverName(manifest.driver)}
          </span>
          <div className={clsx("flex items-center", isExpand && "hidden")}>
            <span className="font-semibold mr-2">Working time:</span>
            {timeFormat(data.workStartTime)}{" "}
            <Icon.TimeTo className="fill-neutral-40 mx-2" />{" "}
            {timeFormat(data.workEndTime)}
            <div>
              <div className="w-1.5 h-1.5 rounded-full bg-neutral-30 mx-3" />
            </div>
            <span className="font-semibold mr-2">Driver break time:</span>
            {timeFormat(data.breakStartTime)}{" "}
            <Icon.TimeTo className="fill-neutral-40 mx-2" />{" "}
            {timeFormat(data.breakEndTime)}
          </div>
          <button className="p-2 ml-7" onClick={() => setIsExpand(!isExpand)}>
            {isExpand ? (
              <Icon.AngleUp size="12" className="stroke-blue" />
            ) : (
              <Icon.AngleDown size="12" className="stroke-blue" />
            )}
          </button>
        </div>
      </div>
      <div className={clsx(!isExpand && "hidden")}>
        <div className="pt-4 space-x-3">
          <div className="flex items-center">
            <Common.TimeRangePicker
              value={{
                startTime: data.workStartTime
                  ? moment(data.workStartTime, "HH:mm").toDate()
                  : null,
                endTime: data.workEndTime
                  ? moment(data.workEndTime, "HH:mm").toDate()
                  : null,
              }}
              onChange={(target, date) =>
                handleChange(
                  target === "startTime" ? "workStartTime" : "workEndTime",
                  date
                )
              }
              label="Working time"
            />
            <div className="ml-4 mt-6">
              <Common.Checkbox
                id={`returnToDepot-${data.id}`}
                label="Return to depot after delivery"
                checked={data.returnToDepot}
                onChange={() =>
                  onItemChange({
                    ...data,
                    returnToDepot: !data.returnToDepot,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="flex items-center mt-4 ">
          <Common.TimeRangePicker
            value={{
              startTime: data.breakStartTime
                ? moment(data.breakStartTime, "HH:mm").toDate()
                : null,
              endTime: data.breakEndTime
                ? moment(data.breakEndTime, "HH:mm").toDate()
                : null,
            }}
            onChange={(target, date) =>
              handleChange(
                target === "startTime" ? "breakStartTime" : "breakEndTime",
                date
              )
            }
            label="Driver break time"
          />
          <div className="flex items-center mt-6 ml-4">
            <Common.Checkbox
              id={`excludeOptimize-${data.id}`}
              label="Combine this route"
              checked={data.excludeOptimize}
              onChange={() =>
                onItemChange({
                  ...data,
                  excludeOptimize: !data.excludeOptimize,
                })
              }
            />
            <span
              className="ml-1.5"
              data-tip="This will only combine this route for today"
            >
              <Icon.InfoFill size="12" className="fill-neutral-40" />
            </span>
          </div>
          <div className="ml-4 mt-6">
            <Common.Checkbox
              id={`strictStart-${data.id}`}
              label="Strict start"
              checked={
                data.strictStart ||
                !(!data.breakStartTime && !data.breakEndTime)
              }
              disabled={!(!data.breakStartTime && !data.breakEndTime)}
              onChange={() =>
                onItemChange({
                  ...data,
                  strictStart: !data.strictStart,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

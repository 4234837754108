import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";
import { Common, Icon, ModalInform } from "@/components";
import {
  PATH,
  TIME_FORMAT,
  CanModifyRouteByStatus,
  DeliveryStatusValue,
} from "@/configuration/globalVariable";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { GoogleMap } from "./Map";
import { ModalOptimize } from "./ModalOptimize";
import { DeliveryList } from "./DeliveryList";
import { StorageAddress } from "./StorageAddress";

import {
  clearManifest,
  moveAndApplyManifestLine,
  replaceManifestsUpdatedETAs,
} from "../providers/deliveryManifest/slice";
import { fetchAllManifestByDateAsync } from "../providers/deliveryManifest/sliceServices";
import {
  manifestsSelector,
  unallocatedDocumentCountSelector,
  deliveryManifestLoading,
  storageAddressSelector,
  deliveryManifestLoaded,
  optimizingGroupsSelector,
} from "../providers/deliveryManifest/selector";
import {
  manifest,
  manifestLine,
  // solution,
  storageAddress,
  // visits,
} from "../models/DeliveryManifest";
import {
  finishPendingRequests,
  getRouteETAWithMapIds,
  useSaveEditingRoutes,
} from "../services/deliveryManifest";
import {
  checkCompletedDocumentPosition,
  // hoursToISO,
  mergeETAsIntoRoutes,
  refillManifestLinePosition,
} from "../utils";
import {
  useGetETAByManifestId,
  useGetRouteById,
} from "../providers/deliveryManifest/hooks";
import { ModalMoveRouteEditScreen } from "./ModalMoveRouteEditScreen";
import { SnackbarUtils } from "@/utils";
import { ModalChangeDriver } from "./ModalChangeDriver";
import { userSelector } from "@/providers/auth/selector";

export const TabAllocation = ({ date, setDate }: any) => {
  const [modalConfirmCancel, setModalConfirmCancel] = useState<
    "refreshList" | "changeDate" | "edit" | string
  >("");
  const [startDate, setStartDate] = useState(date);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isOpenOptimizeModal, setOpenOptimizeModal] = useState(false);
  const [isChangeDriverModal, setChangeDriverModal] = useState<manifest>(
    {} as manifest
  );
  const [isHideEmptyRoute, setHideEmptyRoute] = useState(true);
  const [isETALoading, setETALoading] = useState(false);

  const [routesSelected, setRoutesSelected] = useState<string[]>([]);
  const [manifestsOptimizing, setManifestsOptimizing] = useState<manifest[]>(
    []
  );
  const [routesStoppedSelected, setRoutesStoppedSelected] = useState<string[]>(
    []
  );
  const [unallocateChecked, setUnallocateChecked] = useState(false);
  const user = useAppSelector(userSelector);
  const manifests = useAppSelector(manifestsSelector) || [];
  const isDeliveryManifestLoaded = useAppSelector(deliveryManifestLoaded);
  const loading = useAppSelector(deliveryManifestLoading);
  const unallocatedDocumentCount = useAppSelector(
    unallocatedDocumentCountSelector
  );
  const getRouteById = useGetRouteById();
  const getETAByManifestId = useGetETAByManifestId();

  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;

  const optimizingGroups = useAppSelector(optimizingGroupsSelector);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleChangeDateToFetch = useCallback((date: Date) => {
    setStartDate(date);
  }, []);

  // if (isHideEmptyRoute) {
  //   return item.manifestLines.length;
  // }
  const results = useMemo(
    () =>
      manifests
        ? manifests
            .filter((item: manifest) =>
              isHideEmptyRoute ? item?.manifestLines?.length : true
            )
            .filter((item: manifest) => {
              if (
                item.route?.name &&
                item.route?.name
                  .toUpperCase()
                  .indexOf(searchKeyword.toUpperCase()) > -1
              )
                return true;
              if (
                item.driver?.givenName &&
                item.driver?.givenName
                  .toUpperCase()
                  .indexOf(searchKeyword.toUpperCase()) > -1
              )
                return true;
              if (
                item.driver?.lastName &&
                item.driver?.lastName
                  .toUpperCase()
                  .indexOf(searchKeyword.toUpperCase()) > -1
              )
                return true;
              if (item.manifestLines.length) {
                return item.manifestLines.filter(
                  (manifestLine: manifestLine) =>
                    manifestLine.document.customerTradingName &&
                    manifestLine.document.customerTradingName
                      .toUpperCase()
                      .indexOf(searchKeyword.toUpperCase()) > -1
                ).length;
              }
              return false;
            })
            .sort((a: manifest, b: manifest) => {
              if (a.route.name < b.route.name) {
                return -1;
              }
              if (a.route.name > b.route.name) {
                return 1;
              }
              return 0;
            })
        : [],
    [searchKeyword, manifests, isHideEmptyRoute]
  );

  const handleEditRoutes = () => {
    const routesActive = [] as manifest[];
    const routesUnActive = [] as manifest[];
    manifests.map((manifest: manifest) => {
      if (routesSelected.includes(manifest.id)) {
        routesActive.push(manifest);
      }
      if (
        !(
          !routesSelected.includes(manifest.id) &&
          CanModifyRouteByStatus.includes(manifest.status)
        )
      ) {
        routesUnActive.push(manifest);
      }
      return manifest;
    });

    navigate(`/${PATH.DELIVERY._}/${PATH.DELIVERY.EDIT_ROUTES}`, {
      state: {
        unallocate: unallocateChecked,
        deliveryDate: date,
        routesActive: unallocateChecked
          ? [{}].concat(routesActive)
          : routesActive,

        routesUnActive,
      },
    });
  };

  const handleSubmitOptimize = () => {
    setOpenOptimizeModal(false);
  };

  const fetchManifestList = (date: any) => {
    finishPendingRequests("RefreshList");
    setManifestsOptimizing([] as manifest[]);
    setUnallocateChecked(false);
    dispatch(clearManifest());
    dispatch(
      fetchAllManifestByDateAsync({
        date: moment(date).format(TIME_FORMAT.REQUEST),
        isFetchETA: true,
      })
    ).then((mnf) => {
      const manifestsPayload = mnf.payload.manifests;
      if (manifestsPayload && manifestsPayload.length) {
        handleFetchandMergeETAIntoRoutes(manifestsPayload);
      }
    });
  };

  const handleChangeDate = (date: any) => {
    finishPendingRequests("changeDate");
    setDate(date);
    setManifestsOptimizing([]);
    setRoutesSelected([]);
    setRoutesStoppedSelected([]);
    fetchManifestList(date);
    setUnallocateChecked(false);
  };

  useEffect(() => {
    if (!isDeliveryManifestLoaded) {
      fetchManifestList(location.state?.deliveryDate || date);
      if (location.state?.deliveryDate) {
        setDate(new Date(location.state?.deliveryDate));
        window.history.replaceState({}, document.title);
      }
    }
    return () => {
      // clear checkbox component didmount
      finishPendingRequests("cancelFetchETAs");
      setRoutesSelected([]);
      setRoutesStoppedSelected([]);
    };
    // eslint-disable-next-line
  }, []);

  const handleFetchandMergeETAIntoRoutes = (manifestsPayload: manifest[]) => {
    setETALoading(true);
    const idsToFetchETA = manifestsPayload
      .map((manifestItem: manifest) => {
        return manifestItem.manifestLines.length ? manifestItem.id : null;
      })
      .filter((id: string | null) => id);

    const idsToFetch = [] as any;
    const totalRoutes = manifestsPayload.length;
    const routePerGroup = Math.ceil(
      totalRoutes > 30
        ? totalRoutes / Math.ceil(totalRoutes / 10)
        : totalRoutes / 3
    );

    while (idsToFetchETA.length > 0) {
      idsToFetch.push(idsToFetchETA.splice(0, routePerGroup));
    }
    if (idsToFetch.length) {
      // Fetch etas by ids and merge into routes
      getETAsGroup(idsToFetch, manifestsPayload);
    } else {
      setETALoading(false);
    }
  };

  const getETAsGroup = async (idGroup: any[], manifestsPayload: manifest[]) => {
    let newManifests = [...manifestsPayload] as manifest[];
    await Promise.all(
      idGroup.map((ids) =>
        getRouteETAWithMapIds(ids).then((ETAs) => {
          newManifests = mergeETAsIntoRoutes(newManifests, ETAs) as manifest[];
          dispatch(replaceManifestsUpdatedETAs(newManifests));
        })
      )
    );
    setETALoading(false);
  };

  const handleSelectRouteList = (routeId: string, isStopped: boolean) => {
    setRoutesSelected(
      routesSelected.includes(routeId)
        ? routesSelected.filter((rId) => rId !== routeId)
        : routesSelected.concat(routeId)
    );
    if (isStopped) {
      setRoutesStoppedSelected(
        routesStoppedSelected.includes(routeId)
          ? routesStoppedSelected.filter((rId) => rId !== routeId)
          : routesStoppedSelected.concat(routeId)
      );
    }
  };

  const handleCheckAllRoutes = () => {
    if (
      routesSelected.length ===
      manifests.filter((mnf: manifest) =>
        CanModifyRouteByStatus.includes(mnf.status) && isHideEmptyRoute
          ? mnf.manifestLines.length
          : true
      ).length
    ) {
      setRoutesSelected(
        searchKeyword
          ? routesSelected.filter(
              (id: string) => !results.find((i: manifest) => i.id === id)
            )
          : []
      );
      setRoutesStoppedSelected(
        searchKeyword
          ? routesStoppedSelected.filter(
              (id: string) =>
                !results.find(
                  (i: manifest) =>
                    i.id === id && i.status === DeliveryStatusValue.STOPPED
                )
            )
          : []
      );
      unallocatedDocumentCount && setUnallocateChecked(false);
    } else {
      unallocatedDocumentCount && setUnallocateChecked(true);
      setRoutesSelected(
        results
          .filter((mnf: manifest) =>
            CanModifyRouteByStatus.includes(mnf.status) && isHideEmptyRoute
              ? mnf.manifestLines.length
              : true
          )
          .map((item: manifest) => item.id)
      );
      setRoutesStoppedSelected(
        results
          .filter(
            (mnf: manifest) =>
              CanModifyRouteByStatus.includes(mnf.status) &&
              mnf.status === DeliveryStatusValue.STOPPED
          )
          .map((item: manifest) => item.id)
      );
    }
  };

  // filter manifest line can check;
  const manifestCanCheck =
    (results &&
      results.length &&
      results.filter((mnf: manifest) =>
        CanModifyRouteByStatus.includes(mnf.status) && isHideEmptyRoute
          ? mnf.manifestLines.length
          : true
      )) ||
    [];
  // check all condition
  const isCheckedAll = manifestCanCheck.length
    ? routesSelected.length === manifestCanCheck.length &&
      manifestCanCheck.length !== 0
    : false;

  useEffect(() => {
    if (isHideEmptyRoute) {
      setRoutesSelected(
        routesSelected.filter((id: string) =>
          results.find(
            (mnf: manifest) => mnf.id === id && mnf.manifestLines.length
          )
        )
      );
    }
  }, [isHideEmptyRoute]);

  const timeFormat = (time?: null | string) =>
    time && time !== "00:00:00"
      ? moment(time, "HH:mm:ss").format("HH:mm")
      : null;

  // Optimize actions
  const handleOptimizeRoutes = () => {
    navigate(`/${PATH.DELIVERY._}/${PATH.DELIVERY.OPTIMIZING}`, {
      state: {
        OptimizeIds: routesSelected,
        OptimizeRoutes: routesSelected.map((id) => {
          const route = getRouteById(id);
          const routeETA = getETAByManifestId(id);
          return {
            id: route?.routeId,
            manifestId: id,
            workStartTime: timeFormat(route?.driver.workStartTime),
            workEndTime: timeFormat(route?.driver.workEndTime),
            breakStartTime: timeFormat(route?.driver.breakStartTime),
            breakEndTime: timeFormat(route?.driver.breakEndTime),
            returnToDepot: route?.driver.returnToDepot,
            excludeOptimize: false,
            totalTimeBefore: routeETA?.workingTime,
            totalDistanceBefore: routeETA?.distance,
          };
        }),
      },
    });
  };
  // Optimize actions

  // Move document to another route
  const [moveModal, setMoveModal] = useState<manifestLine>({} as manifestLine);
  const [manifestCurrent, setManifestCurrent] = useState<manifest>(
    {} as manifest
  );

  const saveEditingRoutes = useSaveEditingRoutes();
  const { isLoading: editIsSaving } = saveEditingRoutes;

  const handleMoveToAnotherRouteClick = useCallback(
    (manifestLine: manifestLine, route: manifest) => {
      setMoveModal(manifestLine);
      setManifestCurrent(route);
    },
    []
  );

  const removeManifestLine = (
    route: manifest,
    manifestLineRemove: manifestLine
  ) => {
    const newManifestLineFilter = route.manifestLines.filter(
      (manifestLine) => manifestLine.id !== manifestLineRemove.id
    );
    return newManifestLineFilter.map((r, index) => {
      return {
        ...r,
        position: index,
      };
    });
  };

  const handleMoveManifestLine = (
    manifestTarget?: manifest,
    position?: string,
    manifestLineMoving?: manifestLine
  ) => {
    const positionNumber = (
      position ? parseInt(position) - 1 : undefined
    ) as number;
    const routeChanged = [] as manifest[];
    const handleMovetoTarget = (manifest: manifest) => {
      const manifestLines = [...manifest.manifestLines];
      if (positionNumber >= 0 && manifestLineMoving) {
        manifestLines.splice(positionNumber, 0, manifestLineMoving);
      }
      const routeObj = {
        ...manifest,
        manifestLines: refillManifestLinePosition(
          manifestLines,
          manifestTarget?.id
        ),
      };
      routeChanged.push(routeObj);
      return routeObj;
    };

    if (manifestLineMoving && positionNumber >= 0 && manifestTarget) {
      if (checkCompletedDocumentPosition(manifestTarget, positionNumber)) {
        return SnackbarUtils.error(
          "This position is delivered. Please enter another position number."
        );
      }

      const entryManifest = {
        ...manifestCurrent,
        manifestLines: removeManifestLine(manifestCurrent, manifestLineMoving),
        polylines: "",
      };
      const targetManifest = {
        ...handleMovetoTarget(manifestTarget),
        polylines: "",
      };
      const manifestsMoved = [entryManifest, targetManifest];
      saveEditingRoutes.mutate(manifestsMoved, {
        onSuccess() {
          setETALoading(true);
          // Replace manifestline without polyline
          dispatch(
            moveAndApplyManifestLine({ manifestsUpdate: manifestsMoved })
          );
          setMoveModal({} as manifestLine);
          setManifestCurrent({} as manifest);

          SnackbarUtils.success(
            `Move order <b>#${manifestLineMoving.document.saleDocumentNumber}</b> to route <b>${manifestTarget.route.name}</b> successfully.`
          );
          // refetch ETA and polyline
          getRouteETAWithMapIds([entryManifest.id, targetManifest.id]).then(
            (ETAs) => {
              const manifestsMovedReplaceETA = mergeETAsIntoRoutes(
                manifestsMoved,
                ETAs
              ) as manifest[];
              // Replace polyline
              dispatch(
                moveAndApplyManifestLine({
                  manifestsUpdate: manifestsMovedReplaceETA,
                })
              );
              setETALoading(false);
            }
          );
        },
      });
    }
  };
  const alertEditedMsg =
    "There is a pending optimization. Leaving the page or changing date will discard the optimized results. Are you sure?";
  useEffect(() => {
    if (optimizingGroups.length) {
      const arr = [] as manifest[];
      optimizingGroups.map((optimizeGroup: any) => {
        if (optimizeGroup.solution) {
          optimizeGroup.manifests.map((mnf: manifest) => {
            // const slt = optimizeGroup.solution.find(
            //   (solution: solution) => solution.manifestId === mnf.id
            // );
            arr.push(mnf);
            // arr.push({
            //   ...mnf,
            //   distance: slt.totalDistanceAfter,
            //   workingTime: slt.totalTimeAfter,
            //   manifestLines:
            //     slt?.visits.map((vs: visits) => {
            //       return {
            //         ...vs.manifestLine,
            //         position: vs.position - 1,
            //         arrivalTime: hoursToISO(vs.arrivalTime),
            //       };
            //     }) || [],
            //   polylines: slt?.polyline,
            // });
            return mnf;
          });
        }
        return optimizeGroup;
      });
      setManifestsOptimizing(arr);
    } else {
      setManifestsOptimizing([] as manifest[]);
    }

    window.onbeforeunload = (event) => {
      if (optimizingGroups.length) {
        const e = event || window.event;
        if (e) {
          e.returnValue = alertEditedMsg;
        }
        return alertEditedMsg;
      }
    };
  }, [optimizingGroups]);

  const handleRefreshList = () => {
    if (optimizingGroups.length) {
      setModalConfirmCancel("refreshList");
    } else {
      fetchManifestList(date);
    }
  };

  const handleEditRoutesFirst = () => {
    if (optimizingGroups.length) {
      setModalConfirmCancel("edit");
    } else {
      handleEditRoutes();
    }
  };

  const handleChangeDateFirst = (date: any) => {
    if (optimizingGroups.length) {
      setModalConfirmCancel(date);
    } else {
      handleChangeDate(date);
    }
  };

  const handleCancelOptimize = () => {
    if (modalConfirmCancel === "refreshList") {
      fetchManifestList(date);
    }
    if (modalConfirmCancel === "edit") {
      handleEditRoutes();
    }
    if (
      modalConfirmCancel &&
      !["refreshList", "edit"].includes(modalConfirmCancel)
    ) {
      handleChangeDate(modalConfirmCancel);
    }
    setModalConfirmCancel("");
  };

  return (
    <>
      <div className="pb-5 flex items-center">
        <Common.Input
          wrapClassName="2xl:lg:w-[26.375rem] mr-3"
          iconRight={Icon.Search}
          iconType="stroke"
          disabled={loading}
          placeholder="Search customer, route, driver name..."
          value={searchKeyword}
          onChange={(e: any) => setSearchKeyword(e.target.value)}
          onClear={() => setSearchKeyword("")}
        />
        <div className="max-w-[12.5rem]">
          <Common.DatePicker
            disabled={loading}
            selected={startDate}
            onChange={handleChangeDateToFetch}
            onSelect={handleChangeDateFirst}
          />
        </div>
        <span data-tip="Refresh manifest list" className="ml-2">
          <Common.Button
            onClick={handleRefreshList}
            disabled={loading}
            iconLeft={Icon.Reload}
            iconType="stroke"
            id="btnRefreshManifestList"
          />
        </span>
        <StorageAddress />

        <Common.Button
          onClick={handleOptimizeRoutes}
          iconLeft={Icon.Split}
          outline
          data-tip="Select the route and click this button to optimize the way for your route"
          disabled={
            !routesSelected.length ||
            (routesStoppedSelected.length >= 1 && routesSelected.length > 1) ||
            !user.isRouteOptimisationAvailable
          }
          className="whitespace-nowrap"
        >
          Optimize routes
        </Common.Button>

        <Common.Button
          className="ml-3 whitespace-nowrap"
          iconLeft={Icon.Edit}
          outline
          onClick={handleEditRoutesFirst}
          disabled={!routesSelected.length || isETALoading}
        >
          Edit routes
        </Common.Button>
      </div>
      <div className="flex">
        <div className="w-[26.375rem] mr-4 relative">
          <div className="flex items-center mb-1 h-[1.5rem]">
            <Common.Checkbox
              label="ROUTES"
              id="ROUTES"
              ipSize="md"
              disabled={isETALoading || !manifestCanCheck.length}
              wrapClassName={clsx("ml-4", !manifests?.length && "hidden")}
              checked={
                unallocatedDocumentCount
                  ? isCheckedAll && unallocateChecked
                  : isCheckedAll
              }
              onChange={handleCheckAllRoutes}
            />
            <Common.Checkbox
              label="Hide routes without order"
              id="hideEmptyRoute"
              ipSize="md"
              wrapClassName={clsx(
                manifests?.length && "ml-4",
                !manifests?.length && "hidden"
              )}
              checked={isHideEmptyRoute}
              onChange={() => setHideEmptyRoute(!isHideEmptyRoute)}
            />
          </div>

          <DeliveryList
            date={date}
            manifests={results}
            isETAsLoading={isETALoading}
            routesChecked={routesSelected}
            onCheckRoute={handleSelectRouteList}
            searchKeyword={searchKeyword}
            unallocatedDocumentCount={unallocatedDocumentCount}
            unallocateChecked={unallocateChecked}
            onCheckUnallocate={setUnallocateChecked}
            onChangeDriver={setChangeDriverModal}
          />
        </div>
        <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
          <div className="z-0 w-full h-full">
            <GoogleMap
              storageAddress={storageAddress}
              manifests={
                manifestsOptimizing.length
                  ? results.concat(manifestsOptimizing)
                  : results
              }
              onMoveRoute={handleMoveToAnotherRouteClick}
            />
          </div>
        </div>
      </div>

      <ModalOptimize
        open={isOpenOptimizeModal}
        onClose={() => setOpenOptimizeModal(false)}
        onSubmit={handleSubmitOptimize}
      />

      <ModalMoveRouteEditScreen
        manifestData={manifestCurrent}
        // Remove unallocate data in first index of route
        manifests={manifests}
        manifestLineData={moveModal}
        open={Boolean(moveModal?.id)}
        onClose={() => setMoveModal({} as manifestLine)}
        onMoveItem={(target, position) =>
          handleMoveManifestLine(target, position, moveModal)
        }
        isMoving={editIsSaving}
      />

      <ModalChangeDriver
        open={Boolean(isChangeDriverModal?.id)}
        currentData={isChangeDriverModal}
        onClose={() => setChangeDriverModal({} as manifest)}
        onSubmit={() => {}}
        manifests={manifests}
      />

      <ModalInform
        open={Boolean(modalConfirmCancel)}
        type="warning"
        icon={<Icon.Alert size="24" className="fill-white" />}
        onClose={() => {}}
        title="Unsaved changes detected"
        description={alertEditedMsg}
        groupBtn={
          <div className="text-[1rem] font-semibold text-left flex justify-center mt-8">
            <Common.Button outline color="gray" onClick={handleCancelOptimize}>
              Cancel optimize
            </Common.Button>
            <Common.Button
              className="ml-3"
              color="blue"
              onClick={() => {
                setModalConfirmCancel("");
              }}
            >
              Continue optimize
            </Common.Button>
          </div>
        }
      />
    </>
  );
};

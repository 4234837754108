import { useState, useEffect } from "react";
import clsx from "clsx";
import { NavLink, useResolvedPath, useMatch } from "react-router-dom";
import { Nav, SubNav, userData } from "@/models";
import { Icon, RedirectToOUI } from "@/components";
import { MenuItem } from "./MenuItem";
import { checkIsRouteRender } from "@/utils";

interface Props {
  data: Nav;
  isCollapse: boolean;
  user: userData;
}

export function SubNavItem({ data, isCollapse, user }: Props) {
  const [isOpenSub, setOpenSub] = useState(false);

  const resolved = useResolvedPath(data.path);
  const isActiveParent = useMatch({ path: resolved.pathname, end: false });

  useEffect(() => {
    if (isActiveParent && !isOpenSub) {
      setOpenSub(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveToOpenSub = ({ isActive }: any) => {
    return clsx(
      "flex group hover:shadow-navActive hover:text-blue hover:bg-blue-5",
      isActive ? "shadow-navActive text-blue bg-blue-5" : "text-neutral-60"
    );
  };

  const handleNavLinkClick = (e: any) => {
    if (data.noPage) {
      e.preventDefault();
      setOpenSub(!isOpenSub);
    } else {
      !isOpenSub && setOpenSub(!isOpenSub);
    }
  };

  const handleOpenSub = (e: any) => {
    e.preventDefault();
    setOpenSub(!isOpenSub);
  };

  return (
    <>
      <NavLink
        onClick={handleNavLinkClick}
        to={data.path}
        className={handleActiveToOpenSub}
      >
        {({ isActive }) => (
          <>
            <div className="flex-1">
              <MenuItem
                data={data}
                isCollapse={isCollapse}
                isActive={isActive}
              />
            </div>
            {Boolean(
              data.subItems &&
                data.subItems?.filter((sItem) => sItem.isNavRender).length
            ) && (
              <div
                className={clsx(
                  "ml-auto p-[15px] cursor-pointer",
                  isCollapse && "hidden"
                  // isCollapse && "md:hidden"
                )}
                onClick={handleOpenSub}
              >
                {isOpenSub ? (
                  <Icon.AngleUp size="10" className="stroke-neutral-60" />
                ) : (
                  <Icon.AngleDown size="10" className="stroke-neutral-60" />
                )}
              </div>
            )}
          </>
        )}
      </NavLink>

      {data.subItems?.map((subItem: SubNav, index: number) => {
        const isRenderSub =
          subItem.isNavRender && checkIsRouteRender(subItem, user);
        return (
          isRenderSub &&
          (subItem.isDiffDomain ? (
            <RedirectToOUI
              to={subItem.path}
              key={subItem.name + index}
              className={clsx(
                "group hover:text-blue hover:bg-blue-5 text-neutral-60 cursor-pointer",
                isOpenSub ? "block" : "hidden",
                // isCollapse && "md:hidden"
                isCollapse && "hidden"
              )}
            >
              <div className="flex items-center h-10 my-0.5 pl-[1.875rem]">
                <div>
                  <div className="w-4 flex justify-center items-center relative">
                    {Boolean(index) && (
                      <div className="absolute h-10 left-1/2 -ml-[1px] bottom-1/2 border border-line z-0" />
                    )}
                    <div
                      className={clsx(
                        "w-2 h-2 rounded-full border group-hover:border-blue group-hover:bg-blue relative z-10 border-neutral-30 bg-white"
                      )}
                    />
                  </div>
                </div>
                <div className="ml-3 whitespace-nowrap overflow-hidden">
                  {subItem.name}
                </div>
              </div>
            </RedirectToOUI>
          ) : (
            <NavLink
              to={`${data.path}/${subItem.path}`}
              key={subItem.name + index}
              className={({ isActive }) =>
                clsx(
                  "group hover:text-blue hover:bg-blue-5",
                  isOpenSub ? "block" : "hidden",
                  isActive ? "text-blue bg-blue-5" : "text-neutral-60",
                  isCollapse && "hidden"
                  // isCollapse && "md:hidden"
                )
              }
            >
              {({ isActive }) => (
                <div className="flex items-center h-10 my-0.5 pl-[1.875rem]">
                  <div>
                    <div className="w-4 flex justify-center items-center relative">
                      {Boolean(index) && (
                        <div className="absolute h-10 left-1/2 -ml-[1px] bottom-1/2 border border-line z-0" />
                      )}
                      <div
                        className={clsx(
                          "w-2 h-2 rounded-full border group-hover:border-blue group-hover:bg-blue relative z-10",
                          isActive
                            ? "border-blue bg-blue"
                            : "border-neutral-30 bg-white"
                        )}
                      />
                    </div>
                  </div>
                  <div className="ml-3 whitespace-nowrap overflow-hidden">
                    {subItem.name}
                  </div>
                  {subItem.isBeta && (
                    <span className="inline-flex items-center rounded-full bg-yellow px-2.5 py-0.5 text-xs font-medium text-white ml-2">
                      Beta
                    </span>
                  )}
                </div>
              )}
            </NavLink>
          ))
        );
      })}
    </>
  );
}

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M14.125 14.125L11.5 11.5"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M7.125 12.375C10.0245 12.375 12.375 10.0245 12.375 7.125C12.375 4.22551 10.0245 1.875 7.125 1.875C4.22551 1.875 1.875 4.22551 1.875 7.125C1.875 10.0245 4.22551 12.375 7.125 12.375Z"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 7 6">
      <path d="M2.707 1.29276L0 3.99976L1.414 5.41376L3.414 3.41376L5.414 5.41376L6.828 3.99976L4.121 1.29276C3.414 0.58576 3.414 0.585806 2.707 1.29276Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 14">
      <path d="M5.707 1.29276L3 3.99976L4.414 5.41376L6.414 3.41376L8.414 5.41376L9.828 3.99976L7.121 1.29276C6.414 0.58576 6.414 0.585806 5.707 1.29276Z" />
      <path d="M6.414 10.5858L4.414 8.58575L3 9.99975L5.707 12.7068C6.414 13.4138 6.414 13.4138 7.121 12.7068L9.828 9.99975L8.414 8.58575L6.414 10.5858Z" />
    </SvgIcon>
  );
}

export default IconComponent;

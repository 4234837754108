import { FC } from "react";
import { Listbox } from "@headlessui/react";
import clsx from "clsx";

interface Props {
  value: any;
  content: (selected: boolean) => React.ReactNode;
  hoverClass?: string;
  activeClass?: string;
  nonActiveClass?: string;
}
const Index: FC<Props> = ({
  value,
  content,
  hoverClass,
  activeClass,
  nonActiveClass,
}: Props) => {
  activeClass = activeClass || "bg-amber-100 text-amber-900";
  nonActiveClass = nonActiveClass || "text-gray-900";
  return (
    <Listbox.Option
      className={({ active }) =>
        clsx(
          "relative cursor-default select-none py-2 pl-10 pr-4",
          hoverClass || "hover:bg-blue-10",
          active ? activeClass : nonActiveClass
        )
      }
      value={value}
    >
      {({ selected }) => <>{content(selected)}</>}
    </Listbox.Option>
  );
};
export default Index;

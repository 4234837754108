import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M22 12.414L11.586 2H3V0H12C12.2652 5.66374e-05 12.5195 0.105451 12.707 0.293L23.414 11L22 12.414Z" />
      <path d="M20.121 13.707L10.707 4.293C10.5195 4.10545 10.2652 4.00006 10 4H2C1.73478 4 1.48043 4.10536 1.29289 4.29289C1.10536 4.48043 1 4.73478 1 5V13C1.00006 13.2652 1.10545 13.5195 1.293 13.707L10.707 23.121C10.9856 23.3996 11.3163 23.6207 11.6803 23.7715C12.0443 23.9222 12.4345 23.9999 12.8285 23.9999C13.2225 23.9999 13.6127 23.9222 13.9767 23.7715C14.3407 23.6207 14.6714 23.3996 14.95 23.121L20.121 17.95C20.3996 17.6714 20.6207 17.3407 20.7715 16.9767C20.9222 16.6127 20.9999 16.2225 20.9999 15.8285C20.9999 15.4345 20.9222 15.0443 20.7715 14.6803C20.6207 14.3163 20.3996 13.9856 20.121 13.707ZM7 12C6.60444 12 6.21776 11.8827 5.88886 11.6629C5.55996 11.4432 5.30362 11.1308 5.15224 10.7654C5.00087 10.3999 4.96126 9.99778 5.03843 9.60982C5.1156 9.22186 5.30608 8.86549 5.58579 8.58579C5.86549 8.30608 6.22186 8.1156 6.60982 8.03843C6.99778 7.96126 7.39991 8.00087 7.76537 8.15224C8.13082 8.30362 8.44318 8.55996 8.66294 8.88886C8.8827 9.21776 9 9.60444 9 10C9 10.5304 8.78929 11.0391 8.41421 11.4142C8.03914 11.7893 7.53043 12 7 12Z" />
    </SvgIcon>
  );
}

export default IconComponent;

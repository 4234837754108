import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M14.1126 8.78402C14.1455 8.52802 14.1701 8.27202 14.1701 8.00002C14.1701 7.72802 14.1455 7.47202 14.1126 7.21602L15.8479 5.89602C16.0042 5.77602 16.0453 5.56002 15.9466 5.38402L14.3017 2.61603C14.203 2.44003 13.981 2.37603 13.8 2.44003L11.7522 3.24003C11.3245 2.92003 10.8639 2.65603 10.3622 2.45603L10.0497 0.336037C10.0361 0.24191 9.98765 0.155845 9.91345 0.093986C9.83926 0.0321264 9.74441 -0.00127818 9.6467 3.7431e-05H6.35693C6.15132 3.7431e-05 5.97861 0.144037 5.95393 0.336037L5.64141 2.45603C5.13972 2.65603 4.67915 2.92803 4.25148 3.24003L2.2036 2.44003C2.01443 2.36803 1.8006 2.44003 1.70191 2.61603L0.0570212 5.38402C-0.0498963 5.56002 -0.00054964 5.77602 0.155714 5.89602L1.89107 7.21602C1.85817 7.47202 1.8335 7.73602 1.8335 8.00002C1.8335 8.26402 1.85817 8.52802 1.89107 8.78402L0.155714 10.104C-0.00054964 10.224 -0.0416719 10.44 0.0570212 10.616L1.70191 13.384C1.8006 13.56 2.02266 13.624 2.2036 13.56L4.25148 12.76C4.67915 13.08 5.13972 13.344 5.64141 13.544L5.95393 15.664C5.97861 15.856 6.15132 16 6.35693 16H9.6467C9.85231 16 10.025 15.856 10.0497 15.664L10.3622 13.544C10.8639 13.344 11.3245 13.072 11.7522 12.76L13.8 13.56C13.9892 13.632 14.203 13.56 14.3017 13.384L15.9466 10.616C16.0453 10.44 16.0042 10.224 15.8479 10.104L14.1126 8.78402V8.78402ZM8.00182 10.8C6.4145 10.8 5.12327 9.54401 5.12327 8.00002C5.12327 6.45602 6.4145 5.20003 8.00182 5.20003C9.58913 5.20003 10.8804 6.45602 10.8804 8.00002C10.8804 9.54401 9.58913 10.8 8.00182 10.8Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { DetailRow, DetailRowProps } from "./DetailRow";

export interface ProductGroupProps {
  productId: string;
  productSKU: string;
  productName: string;
  unit: string;
  details: DetailRowProps[];
}

export const ProductGroup = (props: ProductGroupProps) => {
  const totalQuantity =
    Math.round(
      props.details
        .map((d) => d.quantity ?? 0)
        .reduce((preVal, nextVal) => {
          return preVal + nextVal;
        }) * 1000
    ) / 1000;
  return (
    <>
      <tr className="border border-line bg-neutral-20">
        <td className="px-2">
          <li className="flex items-center">
            <p className="font-bold pr-2">{`${props.productName} (${props.productSKU})`}</p>
            <p className="font-bold text-neutral-30">|</p>
            <p className="font-bold pl-2">{props.unit}</p>
          </li>
        </td>
        <td></td>
        <td></td>
        <td className="text-right font-bold px-2">Total: {totalQuantity}</td>
      </tr>

      {props.details.map((d, i) => (
        <DetailRow
          index={i}
          key={`${d.saleDocumentNumber}-${i}`}
          documentId={d.documentId}
          saleDocumentNumber={d.saleDocumentNumber}
          quantity={d.quantity}
          comment={d.comment}
          customerName={d.customerName}
          pickingStatus={d.pickingStatus}
        />
      ))}
    </>
  );
};

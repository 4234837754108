import { LayoutPartials, Common } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../../components";

export const Checkbox = () => {
  return (
    <LayoutPartials.BodyContent className="mb-4" pageTitle="Checkbox">
      <ComponentInfo
        title="Checkbox component"
        code={`import { Common } from "@/components";

<Common.Checkbox />`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  ipSize?: "lg" | "md";
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  className?: string;
  iconType?: "stroke" | "fill";
  iconRight?: React.ElementType;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  ipSize = "lg",
}`}
      />

      <DisplayComponent
        componentName="Checkbox"
        className="mb-3"
        RenderComp={() => (
          <>
            <Common.Checkbox id="checkbox1" label="Checkbox 1" ipSize="md" />
            <Common.Checkbox id="checkbox2" label="Checkbox 2" />
            <Common.Checkbox
              id="checkbox3"
              label="Checkbox 3"
              isError
              errorMessage="Error text here"
            />
            <Common.Checkbox
              id="checkbox4"
              label="Checkbox disabled"
              disabled
            />
          </>
        )}
        code={`<Common.Checkbox wrapClassName="mb-3" disabled />
<Common.Checkbox
  wrapClassName="mb-3"
  id="checkbox1"
  label="Checkbox 1"
  ipSize="md"
/>
<Common.Checkbox wrapClassName="mb-3" id="checkbox2" label="Checkbox 2" />
<Common.Checkbox
  wrapClassName="mb-3"
  id="checkbox3"
  label="Checkbox 3"
  isError
  errorMessage="Error text here"
/>
<Common.Checkbox
  wrapClassName="mb-3"
  id="checkbox4"
  label="Checkbox disabled"
/>`}
      />
    </LayoutPartials.BodyContent>
  );
};

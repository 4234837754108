import TextArea from "./TextArea";
import Support2 from "./Support2";
import Noti from "./Noti";
import NotiFill from "./NotiFill";
import Report from "./Report";
import Settings from "./Settings";
import Started from "./Started";
import Box from "./Box";
import Money from "./Money";
import Dashboard from "./Dashboard";
import Cart from "./Cart";
import CartPlus from "./CartPlus";
import Bill from "./Bill";
import Team from "./Team";
import Vehical from "./Vehical";
import Price from "./Price";
import Expand from "./Expand";
import Collapse from "./Collapse";
import Mobile from "./Mobile";
import NavigationMenu from "./NavigationMenu";
import Close from "./Close";
import LogOut from "./LogOut";
import Switch from "./Switch";
import Loading from "./Loading";
import AngleRight from "./AngleRight";
import AngleLeft from "./AngleLeft";
import AngleUp from "./AngleUp";
import AngleDown from "./AngleDown";
import ArrowDown from "./ArrowDown";
import ArrowUp from "./ArrowUp";
import ArrowRight from "./ArrowRight";
import Despatch from "./Despatch";
import Calendar from "./Calendar";
import DeliveryTime from "./DeliveryTime";
import Comment from "./Comment";
import DragIndicator from "./DragIndicator";
import Driving from "./Driving";
import Driver from "./Driver";
import Clock from "./Clock";
import Location from "./Location";
import Search from "./Search";
import Next from "./Next";
import Back from "./Back";
import Map from "./Map";
import Organize from "./Organize";
import CheckedFill from "./CheckedFill";
import CheckedCircle from "./CheckedCircle";
import Checked from "./Checked";
import Edit from "./Edit";
import Stock from "./Stock";
import SeeMore from "./SeeMore";
import InfoFill from "./InfoFill";
import Alert from "./Alert";
import TimeTo from "./TimeTo";
import PlusBorder from "./PlusBorder";
import Split from "./Split";
import Order from "./Order";
import EyeOpen from "./EyeOpen";
import EyeOff from "./EyeOff";
import EyeOn from "./EyeOn";
import Star from "./Star";
import StarFilled from "./StarFilled";
import Remove from "./Remove";
import InfomationOutline from "./InfomationOutline";
import Warning from "./Warning";
import WarningCircle from "./WarningCircle";
import Decline from "./Decline";
import Payment from "./Payment";
import TeamColor from "./TeamColor";
import Shop from "./Shop";
import SortBy from "./SortBy";
import SortByUp from "./SortByUp";
import SortByDown from "./SortByDown";
import App from "./App";
import CreditCard from "./CreditCard";
import Add from "./Add";
import TeamRounded from "./TeamRounded";
import List from "./List";
import Upload from "./Upload";
import Filter from "./Filter";
import AddCircle from "./AddCircle";
import Trash from "./Trash";
import Fruit from "./Fruit";
import Vegetable from "./Vegetable";
import Meat from "./Meat";
import HandShake from "./HandShake";
import Bank from "./Bank";
import Distant from "./Distant";
import Personal from "./Personal";
import Avatar from "./Avatar";
import Print from "./Print";
import History from "./History";
import Tag from "./Tag";
import Reload from "./Reload";
import Assign from "./Assign";
import Duplicate from "./Duplicate";
import World from "./World";
import Schedule from "./Schedule";
import ExpandAngle from "./ExpandAngle";
import CollapseAngle from "./CollapseAngle";
import DisableChecked from "./DisableChecked";
import DisableUnChecked from "./DisableUnChecked";
import MinusBorder from "./MinusBorder";
import Default from "./Default";
import Refresh from "./Refresh";
import Status from "./Status";
import ScheduleCheck from "./ScheduleCheck";
import MoveTo from "./MoveTo";

export const Icon = {
  Support2,
  Noti,
  NotiFill,
  Report,
  Settings,
  Started,
  Box,
  Money,
  Dashboard,
  Cart,
  CartPlus,
  Bill,
  Team,
  TeamRounded,
  Vehical,
  Price,
  Expand,
  Collapse,
  Mobile,
  LogOut,
  Switch,
  Loading,
  ArrowDown,
  ArrowUp,
  ArrowRight,
  Despatch,
  Calendar,
  DeliveryTime,
  DragIndicator,
  Driving,
  Driver,
  Clock,
  Location,
  Next,
  Back,
  Map,
  Organize,
  CheckedFill,
  CheckedCircle,
  Checked,
  Edit,
  Stock,
  SeeMore,
  InfoFill,
  Alert,
  TimeTo,
  PlusBorder,
  Split,
  Order,
  EyeOpen,
  StarFilled,
  Remove,
  InfomationOutline,
  Warning,
  WarningCircle,
  Payment,
  Shop,
  SortBy,
  SortByUp,
  SortByDown,
  App,
  CreditCard,
  Add,
  List,
  Upload,
  Filter,
  AddCircle,
  Trash,
  Fruit,
  Vegetable,
  Meat,
  HandShake,
  Bank,
  Distant,
  Personal,
  Avatar,
  Print,
  History,
  Tag,
  Assign,
  Duplicate,
  Schedule,
  ExpandAngle,
  CollapseAngle,
  DisableChecked,
  DisableUnChecked,
  MinusBorder,
  Default,
  Refresh,
  Status,
  ScheduleCheck,
  MoveTo,

  undefined,

  // stroke
  TextArea,
  NavigationMenu,
  Close,
  AngleRight,
  AngleLeft,
  AngleUp,
  AngleDown,
  Comment,
  Search,
  EyeOff,
  EyeOn,
  Star,
  Decline,
  Reload,
  TeamColor,
  World,
};

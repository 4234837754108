import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path d="M9.65715 11.753L9.47865 12.4845C8.87315 12.7231 6.77548 13.7241 5.56565 12.6589C5.20515 12.3422 5.0249 11.9397 5.0249 11.452C5.0249 10.5379 5.3259 9.74109 5.86606 7.82775C5.96115 7.46667 6.07781 6.99709 6.07781 6.62492C6.07781 5.98326 5.83457 5.81234 5.17365 5.81234C4.85107 5.81234 4.49407 5.92726 4.1709 6.04801L4.34998 5.31651C5.0704 5.02367 5.97515 4.66667 6.7504 4.66667C7.91298 4.66667 8.76756 5.2465 8.76756 6.349C8.76756 6.66692 8.71273 7.22342 8.59723 7.60842L7.92873 9.97326C7.79106 10.4516 7.54023 11.5051 7.92756 11.8178C8.30965 12.1263 9.21206 11.963 9.65715 11.753Z" />
      <path d="M8.45833 3.5C9.26375 3.5 9.91667 2.84708 9.91667 2.04167C9.91667 1.23625 9.26375 0.583336 8.45833 0.583336C7.65292 0.583336 7 1.23625 7 2.04167C7 2.84708 7.65292 3.5 8.45833 3.5Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <g clipPath="url(#clip0_3246_574932)">
        <path d="M9.75 11.375H6.5V14.625H9.75V11.375Z" />
        <path d="M14.625 11.375H11.375V14.625H14.625V11.375Z" />
        <path d="M9.75 16.25H6.5V19.5H9.75V16.25Z" />
        <path d="M14.625 16.25H11.375V19.5H14.625V16.25Z" />
        <path d="M19.5 11.375H16.25V14.625H19.5V11.375Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.375 3.25H21.125V0H17.875V3.25H14.625V0H11.375V3.25H8.125V0H4.875V3.25H1.625C0.726375 3.25 0 3.97638 0 4.875V24.375C0 25.2736 0.726375 26 1.625 26H14.2C14.0689 25.3538 14 24.6849 14 24C14 23.5767 14.0263 23.1595 14.0774 22.75H3.25V8.125H22.75V14.0774C23.1595 14.0263 23.5767 14 24 14C24.6849 14 25.3538 14.0689 26 14.2V4.875C26 3.97638 25.2736 3.25 24.375 3.25Z"
        />
        <g clipPath="url(#clip1_3246_574932)">
          <path d="M24 16C19.6 16 16 19.6 16 24C16 28.4 19.6 32 24 32C28.4 32 32 28.4 32 24C32 19.6 28.4 16 24 16ZM28 25H23V20H25V23H28V25Z" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3246_574932">
          <rect width="32" height="32" fill="white" />
        </clipPath>
        <clipPath id="clip1_3246_574932">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16 16)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "../Icons";
import { Common } from "../common";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  confirmBtnText?: string;
  cancelBtnText?: string;
  title: string;
  description?: string | ReactNode;
  icon?: ReactNode;
  type?: "error" | "warning" | "info" | "success";
  groupBtn?: React.ReactNode;
}

export default function ModalInform({
  open = false,
  onClose,
  onConfirm,
  confirmBtnText = "OK",
  cancelBtnText = "Cancel",
  icon,
  title,
  description,
  type = "error",
  groupBtn,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-16 pt-14 pb-10 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div>
                  {type === "error" && (
                    <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-status-bg-error">
                      <div className="flex items-center justify-center h-[3.25rem] w-[3.25rem] rounded-full bg-status-error">
                        {icon || (
                          <Icon.Alert size="24" className="fill-white" />
                        )}
                      </div>
                    </div>
                  )}

                  {type === "success" && (
                    <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-status-bg-success">
                      <div className="flex items-center justify-center h-[3.25rem] w-[3.25rem] rounded-full bg-status-success">
                        {icon || (
                          <Icon.Checked size="24" className="fill-white" />
                        )}
                      </div>
                    </div>
                  )}

                  {type === "info" && (
                    <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-status-bg-info">
                      <div className="flex items-center justify-center h-[3.25rem] w-[3.25rem] rounded-full bg-status-info">
                        {icon || (
                          <Icon.InfomationOutline
                            size="24"
                            className="fill-white"
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {type === "warning" && (
                    <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-status-bg-warning">
                      <div className="flex items-center justify-center h-[3.25rem] w-[3.25rem] rounded-full bg-status-warning">
                        {icon || (
                          <Icon.Warning size="24" className="fill-white" />
                        )}
                      </div>
                    </div>
                  )}

                  <div className="mt-3 text-center sm:mt-5">
                    {Boolean(title) && (
                      <Dialog.Title
                        as="h3"
                        className="text-hd4 leading-6 font-semibold"
                      >
                        {title}
                      </Dialog.Title>
                    )}
                    {Boolean(description) && (
                      <div className="mt-2">
                        <p className="text-sm">{description}</p>
                      </div>
                    )}
                  </div>
                </div>
                {groupBtn || (
                  <div className="mt-8 flex justify-center">
                    <Common.Button color="transparent" onClick={onClose}>
                      {cancelBtnText}
                    </Common.Button>
                    <Common.Button
                      color="red"
                      className="ml-4"
                      onClick={onConfirm}
                    >
                      {confirmBtnText}
                    </Common.Button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3848_16751)">
        <path d="M0.11257 14.6873C0.0433825 14.6181 0.00878906 14.5383 0.00878906 14.4482C0.00878906 14.3581 0.0433828 14.2785 0.11257 14.2092L3.5629 10.7588L2.0663 9.26234C1.93485 9.13072 1.86904 8.97497 1.86904 8.79477C1.86904 8.61457 1.935 8.45867 2.0663 8.32705C2.19807 8.19544 2.35413 8.12964 2.53418 8.12964H7.19012C7.37016 8.12964 7.52607 8.19545 7.65768 8.32705C7.78914 8.45882 7.8551 8.61472 7.8551 8.79477V13.4507C7.8551 13.6308 7.78914 13.7867 7.65768 13.9183C7.52607 14.0499 7.37017 14.1157 7.19012 14.1157C7.01007 14.1157 6.85402 14.0499 6.72224 13.9183L5.22592 12.4218L1.77559 15.8721C1.7064 15.9413 1.62676 15.9759 1.5365 15.9759C1.4464 15.9759 1.36692 15.9412 1.29742 15.8721L0.11257 14.6873Z" />
        <path d="M8.32242 7.66231C8.1908 7.53086 8.125 7.3748 8.125 7.19475V2.53881C8.125 2.35877 8.19081 2.20302 8.32242 2.07125C8.45403 1.93979 8.60993 1.87399 8.78998 1.87399C8.97002 1.87399 9.12608 1.93979 9.25754 2.07125L10.754 3.56757L14.2044 0.117241C14.2737 0.0480528 14.3533 0.0133057 14.4434 0.0133057C14.5335 0.0133057 14.6132 0.0480532 14.6825 0.117241L15.8673 1.30207C15.9365 1.37126 15.9711 1.4509 15.9711 1.54115C15.9711 1.63125 15.9365 1.71074 15.8673 1.78008L12.417 5.23041L13.9133 6.72674C14.045 6.8585 14.1109 7.01456 14.1109 7.19461C14.1109 7.37466 14.0449 7.53056 13.9133 7.66217C13.7819 7.79363 13.6258 7.85944 13.4458 7.85944H8.78984C8.60949 7.85959 8.45389 7.79409 8.32227 7.66217L8.32242 7.66231Z" />
      </g>
      <defs>
        <clipPath id="clip0_3848_16751">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M11.775 6.225L5.775 0.225C5.625 0.075 5.475 0 5.25 0H0.75C0.3 0 0 0.3 0 0.75V5.25C0 5.475 0.075 5.625 0.225 5.775L6.225 11.775C6.375 11.925 6.525 12 6.75 12C6.975 12 7.125 11.925 7.275 11.775L11.775 7.275C12.075 6.975 12.075 6.525 11.775 6.225ZM3 3.75C2.55 3.75 2.25 3.45 2.25 3C2.25 2.55 2.55 2.25 3 2.25C3.45 2.25 3.75 2.55 3.75 3C3.75 3.45 3.45 3.75 3 3.75Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { Icon } from "@/components";
import clsx from "clsx";

interface Props {
  onClick?: () => void;
  className?: string;
}

export const ViewDetailBtn = ({ onClick, className }: Props) => {
  return (
    <div
      className={clsx(
        "w-6 h-6 rounded-full flex items-center justify-center group bg-white border border-neutral-20 hover:bg-blue-10 hover:border-blue-10 cursor-pointer",
        className
      )}
      data-tip="View details"
      onClick={onClick}
    >
      <Icon.Next size="12" className="fill-blue" />
    </div>
  );
};

import { SvgIcon } from "./SvgIcon";
// Fill
function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 10 10">
      <path d="M8 4.99947C8 4.83647 7.9205 4.68347 7.7865 4.58997L2.7865 1.08997C2.634 0.982967 2.4345 0.970967 2.269 1.05597C2.1035 1.14247 2 1.31297 2 1.49947L2 8.50047C2 8.68697 2.1035 8.85747 2.269 8.94397C2.4345 9.02897 2.634 9.01697 2.7865 8.90997L7.7865 5.40997C7.9205 5.31647 8 5.16347 8 5.00047V4.99947Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { useState, useEffect, useRef, useCallback } from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  Common,
  Icon,
  LayoutPartials,
  LoadingOverlay,
  ModalContent,
  ModalInform,
  // ModalInform,
} from "@/components";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import {
  TabHolidayScheduleDetail,
  TabNormalScheduleDetail,
} from "../components";
import {
  fetchDetailScheduleAsync,
  renameScheduleAsync,
  saveChangeScheduleAsync,
} from "../providers/schedulePlanner/slice";
import {
  holidaySchedulesType,
  normalScheduleItemType,
  scheduleDetail,
} from "../models";
import { PATH } from "@/configuration/globalVariable";
import ModalEditNameSchedule from "../components/ModalEditNameSchedule";
import { scheduleListLoading } from "../providers/schedulePlanner/selector";
import { SnackbarUtils } from "@/utils";
import TabEditNormalSchedule from "../components/TabEditNormalSchedule";
import TabEditHolidaySchedule from "../components/TabEditHolidaySchedule";
import { formatAMPM } from "../utils";
import { LISTWEEKDAYDISPLAY } from "../configVariable";
import { ModalViolate } from "../components/ModalViolate";
import { useCountAffectedDocuments } from "../services/schedule";
import moment from "moment";

const tabs = [
  {
    name: "Schedule Settings",
    current: true,
  },
  // {
  //   name: "Customers",
  //   current: false,
  // },
];

const scheduleTabs = [
  {
    name: "Normal Schedules",
    current: true,
  },
  {
    name: "Holiday Schedules",
    current: false,
  },
];

type listWeekDayDisplayType = Partial<normalScheduleItemType> & {
  id: number;
  title: string;
};

interface listHolidayType extends holidaySchedulesType {
  isValid?: boolean;
}

const alertEditedMsg =
  "There are some unsaved changes for this schedule. Are you sure to cancel all changes without saving?";

const DeliveryScheduleDetail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const loading = useAppSelector(scheduleListLoading);

  const normalScheduleRef = useRef<{
    getEditNormalSchedule: () => normalScheduleItemType[];
    checkValidDateNormal: () => boolean;
  }>();
  const holidayScheduleRef = useRef<{
    getHolidaySchedule: () => holidaySchedulesType[];
    checkDataValid: () => boolean;
  }>();

  const [violateNumber, setViolateNumber] = useState(0);
  const [isOpenViolateModal, setOpenViolateModal] = useState(false);
  // const [isInformOpenViolateModal, setInformOpenViolateModal] = useState(false);
  const [activeTab, setActiveTab] = useState<any>(tabs[0].name);
  const [activeScheduleTab, setScheduleActiveTab] = useState<any>(
    scheduleTabs[0].name
  );
  const [scheduleDetail, setScheduleDetail] = useState<scheduleDetail | null>(
    null
  );
  const [displayName, setDisplayName] = useState<string>("");
  const [showModalEditName, setShowModalEditName] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [isEdited, setEdited] = useState({
    normal: false,
    holiday: false,
  });
  const [modalConfirmCancel, setModalConfirmCancel] = useState<boolean>(false);

  const [listWeekDayDisplay, setListWeekDayDisplay] =
    useState<listWeekDayDisplayType[]>(LISTWEEKDAYDISPLAY);

  const [listHoliday, setListHoliday] = useState<listHolidayType[]>([]);
  const [loadingSaveData, setLoadingSaveData] = useState<boolean>(false);

  const scheduleId = location.state?.scheduleId;

  useEffect(() => {
    if (!scheduleId) {
      navigate(`/${PATH.SCHEDULE_PLANNER._}`);
    }
    handleFetchScheduleDetail();
    handleGetCountAffectedDocuments();
  }, []);

  useEffect(() => {
    setListNormalSchedule();
  }, [scheduleDetail?.normalSchedule]);

  useEffect(() => {
    setListHolidaySchedule();
  }, [scheduleDetail?.holidaySchedules]);

  useEffect(() => {
    if (location.state?.editMode) setEditMode(true);
  }, [location.state?.editMode]);

  const countAffectedDocuments = useCountAffectedDocuments();

  const { data: countAffectedDocumentsData } = countAffectedDocuments;

  const handleGetCountAffectedDocuments = () => {
    countAffectedDocuments.mutate(scheduleId, {
      onSuccess: (number) => setViolateNumber(number),
    });
  };

  const setListNormalSchedule = () => {
    if (scheduleDetail) setDisplayName(scheduleDetail.name);

    const newList = listWeekDayDisplay.map((item) => {
      const daySchedule = (
        scheduleDetail?.normalSchedule.data.items || []
      ).find((i) => i.dayOfWeek === item.id);

      if (daySchedule) {
        return {
          ...item,
          ...daySchedule,
          cutOffTime: daySchedule.cutOffTime || "23:59:59",
        };
      }
      return {
        ...item,
        dayOfWeek: item.id,
        isActivated: false,
        cutOffTime: "23:59:59",
        despatchDay: 0,
        orderDay: 0,
        timeFrom: null,
        timeTo: null,
      };
    });

    setListWeekDayDisplay(newList);
  };

  const setListHolidaySchedule = () => {
    if (scheduleDetail?.holidaySchedules) {
      let list = scheduleDetail?.holidaySchedules.map((item) => {
        return {
          ...item,
          isValid: true,
          data: {
            ...item.data,
            items: item.data.items.map((i) => ({
              ...i,
              id: uuidv4(),
              cutOffTime: i.cutOffTime ? formatAMPM(i.cutOffTime) : "11:59 PM",
            })),
          },
        };
      });

      list = list.sort((a, b) => {
        if (moment(a.data.dateFrom).isSameOrBefore(moment(b.data.dateFrom))) {
          return -1;
        } else {
          return 1;
        }
      });
      setListHoliday(list);
    }
  };

  const handleFetchScheduleDetail = async () => {
    try {
      const response: scheduleDetail = await dispatch(
        fetchDetailScheduleAsync(scheduleId)
      ).unwrap();
      setScheduleDetail(response);
    } catch (err) {}
  };

  const toggleModalEditScheduleName = () => {
    setShowModalEditName(!showModalEditName);
  };

  const handleEditScheduleName = async (newValue: string) => {
    const error = await dispatch(
      renameScheduleAsync({ scheduleId: scheduleId, name: newValue })
    ).unwrap();
    if (!error) {
      toggleModalEditScheduleName();
      handleFetchScheduleDetail();
      SnackbarUtils.success(
        `<div><Icon.CheckedFill /><b>${newValue}</b> has been updated successfully.<div>`
      );
    }
  };

  const iconBackAction = () => {
    if (editMode) {
      if (isEdited.normal || isEdited.holiday) {
        handleCancelEdit();
      } else {
        handleBack();
      }
    } else {
      navigateToListSchedule();
    }
  };

  const navigateToListSchedule = () => {
    navigate(`/${PATH.SCHEDULE_PLANNER._}`);
  };

  const handleBack = () => {
    setEdited({ normal: false, holiday: false });
    toggleModalConfirmCancel(false);
    if (location.state?.editMode) {
      navigateToListSchedule();
    } else {
      setEditMode(false);
    }
  };

  const handleCancelEdit = () => {
    toggleModalConfirmCancel(true);
  };

  const toggleModalConfirmCancel = (status: boolean) => {
    setModalConfirmCancel(status);
  };

  const handleSetEditedHoliday = useCallback(
    (status: boolean) => {
      setEdited({ ...isEdited, holiday: status });
    },
    [isEdited.holiday]
  );
  const handleSetEditedNormal = useCallback(
    (status: boolean) => {
      setEdited({ ...isEdited, normal: status });
    },
    [isEdited.normal]
  );

  if (loading || !scheduleDetail) return <LoadingOverlay />;

  const {
    name: scheduleName,
    // futureOrders,
    // customerAssigned,
    // normalSchedule: {
    //   data: { items: listDateNormal },
    // },
    holidaySchedules,
    supplierTimeZone,
  } = scheduleDetail;

  const getDataUpdate = () => {
    setLoadingSaveData(true);

    const isErrorHoliday = holidayScheduleRef.current?.checkDataValid();
    const isErrorNormal = normalScheduleRef.current?.checkValidDateNormal();

    if (!isErrorHoliday && !isErrorNormal) {
      const normal = normalScheduleRef.current?.getEditNormalSchedule();
      const holiday = holidayScheduleRef.current?.getHolidaySchedule();

      const dataUpdate = { ...scheduleDetail };
      if (normal) {
        // normal
        dataUpdate.normalSchedule.data = { items: normal };
        dataUpdate.normalSchedule.isActivated = true;
        dataUpdate.normalSchedule.isBypassSupplier = true;
      }

      // holiday
      if (holiday) {
        dataUpdate.holidaySchedules = holiday;
      }

      saveChangeSchedule(dataUpdate);
      setEdited({ normal: false, holiday: false });
    } else {
      setModalConfirmCancel(false);
      setLoadingSaveData(false);
    }
  };

  const saveChangeSchedule = async (dataUpdate: scheduleDetail) => {
    try {
      const response = await dispatch(
        saveChangeScheduleAsync(dataUpdate)
      ).unwrap();
      if (response) {
        setEditMode(false);
        handleFetchScheduleDetail();
        // get count affect document
        countAffectedDocuments.mutate(scheduleId, {
          onSuccess: (res) => {
            setViolateNumber(res);
            // res && setInformOpenViolateModal(true);
          },
        });
      }
      SnackbarUtils.success(
        `<div><Icon.CheckedFill />Schedule <b>${scheduleDetail.name}</b> has been updated successfully.<div>`
      );
    } catch (err) {}
    setModalConfirmCancel(false);
    setLoadingSaveData(false);
  };

  const handleCloseViolateModal = (isRecounterViolateDocument?: boolean) => {
    isRecounterViolateDocument && handleGetCountAffectedDocuments();
    setOpenViolateModal(false);
  };

  const renderContent = () => {
    if (editMode) {
      return (
        <>
          <div
            className={clsx(
              "w-full relative flex-1 pt-4",
              activeScheduleTab !== scheduleTabs[0].name && "hidden"
            )}
          >
            <TabEditNormalSchedule
              ref={normalScheduleRef}
              listWeekDays={listWeekDayDisplay}
              onSetEditedNormal={handleSetEditedNormal}
            />
          </div>
          <div
            className={clsx(
              "w-full relative flex-1 pt-4",
              activeScheduleTab !== scheduleTabs[1].name && "hidden"
            )}
          >
            <TabEditHolidaySchedule
              ref={holidayScheduleRef}
              listHolidays={listHoliday}
              onSetEditedHoliday={handleSetEditedHoliday}
            />
          </div>

          <div className="text-left flex justify-between items-center mt-4">
            <div className="flex items-center px-1 text-neutral-100">
              {/* <span className="mr-2">
            <Icon.Bill size="16" className="fill-neutral-50" />
          </span>
          <div className="text-neutral-60 font-semibold">
            Future orders:&nbsp;
          </div>
          {futureOrders === 0 ? (
            <span className="text-neutral-40">--</span>
          ) : (
            <div className="text-neutral-100">
              {futureOrders} <span className="text-neutral-60"> of </span>{" "}
              {customerAssigned}{" "}
              {customerAssigned === 1 ? "customer" : "customers"}
            </div>
          )} */}
              {/* <div className="h-1.5 w-1.5 bg-neutral-30 rounded-full mx-3" /> */}
              <span className="mr-2">
                <Icon.World size="16" className="fill-neutral-50" />
              </span>
              <div className="text-neutral-60 font-semibold">
                Timezone:&nbsp;
              </div>
              <div className="text-neutral-100">{supplierTimeZone}</div>
            </div>
            <div>
              <Common.Button outline color="gray" onClick={handleCancelEdit}>
                Cancel
              </Common.Button>
              <Common.Button
                className="ml-3"
                color="blue"
                onClick={getDataUpdate}
                disabled={!isEdited.normal && !isEdited.holiday}
              >
                Save Changes
              </Common.Button>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div
          className={clsx(
            "w-full relative flex-1 pt-4",
            activeScheduleTab !== scheduleTabs[0].name && "hidden"
          )}
        >
          <TabNormalScheduleDetail listWeekDays={listWeekDayDisplay} />
        </div>
        <div
          className={clsx(
            "w-full relative flex-1 pt-4",
            activeScheduleTab !== scheduleTabs[1].name && "hidden"
          )}
        >
          <TabHolidayScheduleDetail holidaySchedule={holidaySchedules} />
        </div>
      </>
    );
  };

  return (
    <LayoutPartials.BodyContent
      pageTitle={
        <div className="flex items-center">
          <div className="text-hd4 font-semibold">
            <p className=" max-w-[40rem] truncate">{displayName}</p>
          </div>
          <Common.Button
            iconLeft={Icon.Edit}
            color="transparent"
            onClick={toggleModalEditScheduleName}
          />
        </div>
      }
      onTitleBack={iconBackAction}
      rightContent={
        <Common.TabUnderline
          data={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          wrapClassName="mt-1"
        />
      }
      className="!pb-3"
    >
      {Boolean(violateNumber) && (
        <div className="mb-2">
          <Common.Alert
            alertType="warning"
            iconRender={
              <Icon.WarningCircle size="20" className="fill-status-warning" />
            }
            className="py-1.5"
          >
            <div className="flex items-center justify-between">
              <div>
                There are <strong>{violateNumber}</strong> pending order(s) that
                violate the delivery schedule. You can cancel an order or move
                to other dates.
              </div>
              <Common.Button
                btSize="md"
                color={"gray"}
                className="bg-white whitespace-nowrap"
                onClick={() => setOpenViolateModal(true)}
              >
                Manage violated orders
              </Common.Button>
            </div>
          </Common.Alert>
        </div>
      )}
      <div
        className={clsx(
          "w-full relative flex flex-col -mt-2",
          activeTab !== tabs[0].name && "hidden",
          violateNumber ? "h-[calc(100vh-15rem)]" : "h-[calc(100vh-12rem)]"
        )}
      >
        <div className="flex items-center justify-between">
          <Common.TabUnderline
            data={scheduleTabs}
            activeTab={activeScheduleTab}
            setActiveTab={setScheduleActiveTab}
            className="h-[2.75rem]"
          />
          {!editMode && (
            <Common.Button
              iconLeft={Icon.Edit}
              onClick={() => setEditMode(true)}
            >
              Edit
            </Common.Button>
          )}
        </div>
        {renderContent()}
      </div>
      {/* modal */}
      <ModalContent
        open={showModalEditName}
        onClose={toggleModalEditScheduleName}
        onOverlayClick={toggleModalEditScheduleName}
        panelClassWidth="max-w-[32rem] w-full"
      >
        <ModalEditNameSchedule
          type="edit"
          scheduleName={scheduleName}
          onClose={toggleModalEditScheduleName}
          onSubmitName={handleEditScheduleName}
        />
      </ModalContent>

      <ModalInform
        open={modalConfirmCancel}
        type="warning"
        icon={<Icon.Alert size="24" className="fill-white" />}
        onClose={() => {}}
        title="Unsaved changes detected"
        description={alertEditedMsg}
        groupBtn={
          <div className="text-[1rem] font-semibold text-left flex justify-center mt-8">
            <Common.Button
              outline
              color="gray"
              onClick={() => {
                toggleModalConfirmCancel(false);
                handleBack();
              }}
            >
              Cancel changes
            </Common.Button>
            <Common.Button
              className="ml-3"
              color="blue"
              onClick={getDataUpdate}
              disabled={loadingSaveData}
              isLoading={loadingSaveData}
            >
              Save Changes
            </Common.Button>
          </div>
        }
      />

      <ModalViolate
        scheduleId={scheduleId}
        open={isOpenViolateModal}
        violateOrder={countAffectedDocumentsData}
        onClose={handleCloseViolateModal}
      />

      {/* <ModalInform
        open={isInformOpenViolateModal}
        icon={<Icon.Alert size="24" className="fill-white" />}
        type="warning"
        title="Warning! Wrong Delivery Date Orders"
        description={
          <div>
            There are{" "}
            <span className="font-semibold">{countAffectedDocumentsData}</span>{" "}
            pending orders that will have wrong delivery date if you save this
            schedule. Do you want to proceed?
          </div>
        }
        onClose={() => setInformOpenViolateModal(false)}
        groupBtn={
          <div className="mt-8 flex justify-center">
            <Common.Button
              color="gray"
              onClick={() => setInformOpenViolateModal(false)}
            >
              No
            </Common.Button>
            <Common.Button
              className="ml-4"
              onClick={() => {
                setInformOpenViolateModal(false);
                setOpenViolateModal(true);
              }}
            >
              Yes, proceed
            </Common.Button>
          </div>
        }
      /> */}
    </LayoutPartials.BodyContent>
  );
};

export default DeliveryScheduleDetail;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "@/assets/images";
import { PATH } from "@/configuration/globalVariable";
import { getUser } from "@/utils/authUtils";

interface Props {
  leftContent: React.ReactNode;
  children: React.ReactNode;
}

const Index = ({ leftContent, children }: Props) => {
  const navigate = useNavigate();
  const handleCheckLoginYet = () => {
    const user = getUser();
    if (user.token) {
      return navigate(PATH.HOME, { replace: true });
    }
  };

  useEffect(() => {
    handleCheckLoginYet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen">
      <div
        className="w-[31.25rem] bg-neutral-5 h-full bg-bottom bg-no-repeat bg-contain hidden lg:block"
        style={{ backgroundImage: `url(${Images.authLogoBg})` }}
      >
        <img src={Images.logo} alt="TOM app" className="ml-10 mt-10" />
        {leftContent}
      </div>
      <div className="flex-1 bg-white p-4 flex flex-col">
        <div className="mx-auto lg:hidden text-center">
          <img
            src={Images.logo}
            alt="TOM app"
            className="w-[6.25rem] inline-block"
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Index;

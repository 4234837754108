import { useState } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { Icon, ModalContent, CustomScrollbar } from "@/components";
import {
  //  EnhanceStep, UserAvatar,
  NavItem,
  SubNavItem,
} from "./components";
import { sidebarNavigation } from "@/configuration/sidebarNavigation";
import { checkIsRouteRender } from "@/utils";
import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector, userViewSelector } from "@/providers/auth/selector";
import { Nav } from "@/models";
import { Images } from "@/assets/images";

interface Props {
  mobileShow: boolean;
  onCloseSidebarClick?: () => void;
}

// const Index = ({ mobileShow, onCloseSidebarClick }: Props) => {
const Index = ({ mobileShow }: Props) => {
  const userView = useAppSelector(userViewSelector);
  const user = useAppSelector(userSelector);
  const [isCollapse, setCollapse] = useState(false);
  const [isDownloadMobileModal, setDownloadMobileModal] = useState(false);

  return (
    <>
      <div
        className={clsx(
          "w-80 h-full md:static absolute top-0 select-none",
          "transition-transform md:transition-width ease-in-out z-50",
          isCollapse && "w-[3.75rem]",
          // isCollapse && "md:w-[3.75rem]",
          mobileShow ? "translate-x-0" : "-translate-x-80 md:translate-x-0"
        )}
      >
        <div className="flex-1 flex flex-col min-h-0 border-r border-line bg-white h-full">
          {/* <div className="block md:hidden">
            <UserAvatar onClose={onCloseSidebarClick} />
            <EnhanceStep />
          </div> */}

          {/* Menu list */}
          <div className="flex-1 flex flex-col">
            <nav className="flex-1 space-y-1" aria-label="Sidebar">
              <CustomScrollbar>
                {sidebarNavigation.map(
                  (item: Nav, idx: number) =>
                    item.isNavRender &&
                    checkIsRouteRender(item, user) &&
                    userView &&
                    item.userView?.includes(userView) &&
                    (isEmpty(item.subItems) ? (
                      <NavItem
                        key={idx + item.name}
                        data={item}
                        isCollapse={isCollapse}
                      />
                    ) : (
                      <SubNavItem
                        key={idx + item.name}
                        user={user}
                        data={item}
                        isCollapse={isCollapse}
                      />
                    ))
                )}
              </CustomScrollbar>
            </nav>
          </div>
          {/* End Menu list */}

          {/* Mobile View */}
          {/* <div className="flex-shrink-0 shadow-overline py-2 block md:hidden">
            <div className="px-4">
              <button className="text-center flex items-center justify-center w-full bg-neutral-10 text-neutral-60 hover:text-blue hover:bg-blue-5 rounded h-10 group mb-4">
                <Icon.Mobile
                  size="16"
                  className="fill-neutral-60 group-hover:fill-blue"
                />
                <span className="ml-3">Download mobile app</span>
              </button>
            </div>
            <button className="h-10 my-0.5 px-[1.875rem] flex items-center text-neutral-60 group hover:text-blue">
              <Icon.Switch
                size="16"
                className="fill-neutral-60 group-hover:fill-blue"
              />
              <span className="ml-2">Switch to Supplier view</span>
            </button>
            <button className="h-10 my-0.5 px-[1.875rem] flex items-center text-neutral-60 group hover:text-blue">
              <Icon.Support2
                size="16"
                className="fill-neutral-60 group-hover:fill-blue"
              />
              <span className="ml-2">Help</span>
            </button>
            <button className="h-10 my-0.5 px-[1.875rem] flex items-center text-red group hover:text-blue">
              <Icon.LogOut size="16" className="fill-red group-hover:fill-blue" />
              <span className="ml-2">Log Out</span>
            </button>
          </div> */}

          {/* Tablet & desktop view */}
          <div className="flex-shrink-0 pt-4">
            <div className={clsx("px-4", isCollapse ? "px-2.5" : "px-4")}>
              <button
                className="text-center flex items-center justify-center w-full bg-neutral-10 text-neutral-60 hover:text-blue hover:bg-blue-5 rounded h-10 group mb-4"
                onClick={() => setDownloadMobileModal(true)}
              >
                <div>
                  <Icon.Mobile
                    size="16"
                    className="fill-neutral-40 group-hover:fill-blue"
                  />
                </div>
                <div
                  className={clsx(
                    "ml-3 whitespace-nowrap overflow-hidden",
                    // isCollapse && "md:hidden"
                    isCollapse && "hidden"
                  )}
                >
                  Download mobile app
                </div>
              </button>
            </div>
            <div
              className={clsx(
                "flex items-center shadow-overline",
                isCollapse ? "pl-0" : "pl-4"
              )}
            >
              {!isCollapse && (
                <div className="text-sm text-neutral-60 whitespace-nowrap overflow-hidden">
                  Collapse menu
                </div>
              )}
              <button
                className={clsx(
                  "px-4 py-3 ml-auto group",
                  isCollapse && "mr-1.5"
                )}
                onClick={() => setCollapse(!isCollapse)}
              >
                {isCollapse ? (
                  <Icon.Expand
                    size="16"
                    className="fill-neutral-60 group-hover:fill-blue-90"
                  />
                ) : (
                  <Icon.Collapse
                    size="16"
                    className="fill-neutral-60 group-hover:fill-blue-90"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalContent
        open={isDownloadMobileModal}
        onClose={() => setDownloadMobileModal(false)}
        onOverlayClick={() => setDownloadMobileModal(false)}
        panelClassWidth="max-w-[42rem] w-full"
      >
        <div className="text-[1.25rem] font-semibold text-center p-5 border-b border-neutral-20">
          Download Our App
        </div>
        <div className="p-3 md:py-5 md:px-8">
          <div className="md:flex items-center border-b border-neutral-20 pb-5 mb-5">
            <div className="md:w-1/2">
              Now you can optimise your works through smart phone app. Support
              both on:
            </div>
            <div className="flex space-x-4 justify-center mt-4 md:mt-0 md:ml-auto md:justify-end">
              <img src={Images.appStore} />
              <img src={Images.googlePlay} />
            </div>
          </div>
          <div className="font-semibold mb-4">
            Download by scanning QR codes bellow:
          </div>
          <div className="space-x-5 flex">
            <div className="bg-blue-5 p-3 md:p-5 flex flex-col items-center flex-1">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-white">
                <Icon.Personal size="24" className="fill-neutral-40" />
              </div>
              <div className="mt-2 mb-4">Custommer app</div>
              <img src={Images.CustomerApp} alt="Custommer app" />
            </div>
            <div className="bg-blue-5 p-3 md:p-5 flex flex-col items-center flex-1">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-white">
                <Icon.Vehical size="24" className="fill-neutral-40" />
              </div>
              <div className="mt-2 mb-4">Driver app</div>
              <img src={Images.DriverApp} alt="Custommer app" />
            </div>
          </div>
        </div>
      </ModalContent>
    </>
  );
};

export default Index;

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import { PantryList } from "../models/PantryList";
import {
  StartEditLineActionProps,
  SelectProductActionProps,
  EditLineActionProps,
  SortPantryLinesActionProps,
} from "./actionProps";

import { PantryLine } from "../models/PantryLine";
import { EPantryMode } from "../models/EPantryMode";
import { SnackbarUtils } from "@/utils";

const API_PATH = "PantryList/";

export const fetchPantryListAsync = createAsyncThunk(
  "fetchPantryList",
  async (data: {
    id: string;
    customerAccountId: string;
    supplierAccountId: string;
  }) => {
    return axios
      .get(`${API_PATH}List`, {
        params: {
          id: data.id,
          customerAccountId: data.customerAccountId,
          supplierAccountId: data.supplierAccountId,
        },
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data.errorMessage;
        }
        return res.data.data.pantryList;
      });
  }
);

export const fetchAutoPantryAsync = createAsyncThunk(
  "fetchAutoPantry",
  async (data: {
    id: string;
    customerAccountId: string;
    supplierAccountId: string;
    numberProducts: number;
  }) => {
    return axios
      .get(`${API_PATH}/GetAutoPantryItem`, {
        params: {
          id: data.id,
          customerAccountId: data.customerAccountId,
          supplierAccountId: data.supplierAccountId,
          numberProducts: data.numberProducts,
        },
      })
      .then((res) => {
        return res.data?.data?.pantryLines;
      })
      .catch((reason) => Promise.reject(reason.response));
  }
);

const initialState = {
  pantryList: {} as PantryList,
  initPantryList: {} as PantryList,
  loading: false,
  isIframe: false,
  isScrollToBottom: false,
  searchKeywords: "",
};

const getNewEmptyLine = (newId: string) => {
  const newLine: PantryLine = {
    id: newId,
    productId: "",
    productSKU: "",
    productName: "",
    comment: "",
    unit: "",
    price: 0,
    isPriceTBC: false,
    productCategoryId: "",
    productCategoryName: "",
    productImageUrl: "",
    universalProductId: null,
    isFromProductAgreement: false,
    isFavourite: false,
    isDeleted: false,
    isNew: true,
    isDirty: false,
    // UI props
    isEditing: true,
    isSelected: false,
  };
  return newLine;
};

const addProduct = (
  selectedProduct: SelectProductActionProps,
  pantryLinesState: PantryLine[]
) => {
  const { id, isNew, comment, product } = selectedProduct;
  let isAdded = false;
  if (!!id && !!product) {
    const newProps = {
      productId: product.id,
      productSKU: product.productSKU,
      productName: product.productName,
      comment,
      unit: product.unit,
      price: product.price,
      isPriceTBC: product.isPriceTBC,
      productCategoryId: product.productCategoryId,
      productCategoryName: product.productCategoryName,
      productImageUrl: product.productImageUrl,
      universalProductId: product.universalProductId,
      isFromProductAgreement: product.isFromProductAgreement,
      isFavourite: false,
      isDeleted: false,
      isEditing: false,
    };

    const deletedLineIndex = pantryLinesState.findIndex(
      (l) =>
        l.isDeleted &&
        (l.productId === product.id || (!l.productId && !product.id)) &&
        (l.universalProductId === product.universalProductId ||
          (!l.universalProductId && !product.universalProductId)) &&
        (l.comment === product.comment || (!l.comment && !product.comment))
    );
    const existingLineIndex = pantryLinesState.findIndex(
      (l) =>
        !l.isDeleted &&
        (l.productId === product.id || (!l.productId && !product.id)) &&
        (l.universalProductId === product.universalProductId ||
          (!l.universalProductId && !product.universalProductId)) &&
        (l.comment === product.comment || (!l.comment && !product.comment))
    );

    // Ignore if existed
    if (existingLineIndex > -1) {
      // Do something
    }
    // Bring back deleted line if exist
    else if (deletedLineIndex > -1) {
      pantryLinesState[deletedLineIndex] = {
        ...pantryLinesState[deletedLineIndex],
        ...newProps,
        isNew: false,
      };
      isAdded = true;
    } else {
      // If no deleted line found, just add a new line
      // const index = pantryLinesState.findIndex((line) => line.id === id);

      // pantryLinesState[index] = {
      //   ...pantryLinesState[index],
      //   ...newProps,
      //   id: uuidv4(),
      //   isNew,
      //   isDirty: !isNew,
      // };
      pantryLinesState.splice(pantryLinesState.length - 1, 0, {
        ...newProps,
        id: uuidv4(),
        isNew,
        isDirty: !isNew,
        isSelected: false,
      });
      isAdded = true;
    }
  }
  return { pantryLinesState, isAdded };
};

const pantryListSlice = createSlice({
  name: "pantryList",
  initialState,
  reducers: {
    tryEdit: (state) => {
      state.pantryList.isEditing = true;
      if (state.pantryList.mode === EPantryMode.Manual) {
        // Add new line at bottom of the list
        state.pantryList.pantryLines.push(getNewEmptyLine(uuidv4()));
      }
    },
    endEdit: (state) => {
      state.pantryList.isEditing = false;
      state.pantryList = { ...state.initPantryList };
    },
    startEditLine: (state, action: PayloadAction<StartEditLineActionProps>) => {
      const { id, isEditing } = action.payload;
      const index = state.pantryList.pantryLines.findIndex(
        (line) => line.id === id
      );
      state.pantryList.pantryLines[index].isEditing = isEditing;
    },
    editLine: (state, action: PayloadAction<EditLineActionProps>) => {
      const { id, changeProps } = action.payload;
      const index = state.pantryList.pantryLines.findIndex(
        (line) => line.id === id
      );
      state.pantryList.pantryLines[index] = {
        ...state.pantryList.pantryLines[index],
        ...changeProps,
        isDirty: true,
      };
    },
    deleteLines: (state, action: PayloadAction<string[]>) => {
      const ids = action.payload;
      ids.forEach((id) => {
        const index = state.pantryList.pantryLines.findIndex(
          (line) => line.id === id
        );
        state.pantryList.pantryLines[index].isDeleted = true;
      });
    },
    addMultipleProducts: (
      state,
      action: PayloadAction<SelectProductActionProps[]>
    ) => {
      let pantryLinesState: PantryLine[] = [
        ...current(state.pantryList.pantryLines),
      ];
      let addedCount = 0;
      let notAddedCount = 0;
      action.payload.map((selectedProduct) => {
        const addResult = addProduct(selectedProduct, [...pantryLinesState]);
        pantryLinesState = addResult.pantryLinesState;
        if (addResult.isAdded) {
          addedCount++;
        } else notAddedCount++;
        return null;
      });

      if (pantryLinesState.findIndex((l) => l.isEditing) === -1) {
        // Add new line at bottom of the list
        pantryLinesState.push(getNewEmptyLine(uuidv4()));
      }
      if (addedCount > 0) {
        SnackbarUtils.success(`Added ${addedCount} products successfully.`);
        // console.debug(`Added ${addedCount} products successfully.`);
      }
      if (notAddedCount > 0) {
        SnackbarUtils.warning(
          `There ${
            notAddedCount > 1 ? "are" : "is"
          } ${notAddedCount} existing product${
            notAddedCount > 1 ? "s" : ""
          } in this pantry list. These will not be added.`
        );
      }
      state.pantryList.pantryLines = pantryLinesState;
    },
    selectProduct: (state, action: PayloadAction<SelectProductActionProps>) => {
      const addResult = addProduct(action.payload, [
        ...current(state.pantryList.pantryLines),
      ]);
      const pantryLinesState = addResult.pantryLinesState;

      if (pantryLinesState.findIndex((l) => l.isEditing) === -1) {
        // Add new line at bottom of the list
        pantryLinesState.push(getNewEmptyLine(uuidv4()));
      }

      if (!addResult.isAdded) {
        SnackbarUtils.warning(
          " There is an existing product in this pantry list. No new product is added."
        );
      }

      state.pantryList.pantryLines = pantryLinesState;
    },
    sortPantryLines: (
      state,
      action: PayloadAction<SortPantryLinesActionProps>
    ) => {
      const { sortBy, sortDirection } = action.payload;
      const sortByKey = sortBy as keyof PantryLine;

      // Remove all editing pantry lines
      const editingLineIndex = state.pantryList.pantryLines.findIndex(
        (l) => !l.isDeleted && l.isEditing
      );
      if (editingLineIndex > -1) {
        state.pantryList.pantryLines.splice(editingLineIndex, 1);
      }
      // Handle column sort
      if (sortDirection === "ASC") {
        state.pantryList.pantryLines = state.pantryList.pantryLines.sort(
          (a, b) => {
            const aVal = a[sortByKey];
            const bVal = b[sortByKey];
            if (typeof aVal === "string" && typeof bVal === "string") {
              return aVal.localeCompare(bVal);
            }
            if (typeof aVal === "number" && typeof bVal === "number") {
              return aVal - bVal;
            }
            if (typeof aVal === "string") {
              return 1;
            }
            return -1;
          }
        );
      } else {
        state.pantryList.pantryLines = state.pantryList.pantryLines.sort(
          (a, b) => {
            const aVal = a[sortByKey];
            const bVal = b[sortByKey];
            if (typeof aVal === "string" && typeof bVal === "string") {
              return bVal.localeCompare(aVal);
            }
            if (typeof aVal === "number" && typeof bVal === "number") {
              return bVal - aVal;
            }
            if (typeof bVal === "string") {
              return 1;
            }
            return -1;
          }
        );
      }
      //
      if (editingLineIndex > -1) {
        // Add new line at bottom of the list
        state.pantryList.pantryLines.push(getNewEmptyLine(uuidv4()));
      }
    },
    selectPantryLines: (state, action: PayloadAction<string[]>) => {
      const ids = action.payload;

      state.pantryList.pantryLines.forEach((line) => {
        line.isSelected = ids.includes(line.id);
      });
    },
    setFavourite: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const index = state.pantryList.pantryLines.findIndex(
        (line) => line.id === id
      );
      state.pantryList.pantryLines[index].isFavourite =
        !state.pantryList.pantryLines[index].isFavourite;
      state.pantryList.pantryLines[index].isDirty = true;
    },
    switchShowFavouriteOnly: (state) => {
      state.pantryList.isShowFavouriteOnly =
        !state.pantryList.isShowFavouriteOnly;
    },
    setPantryMode: (state, action: PayloadAction<EPantryMode>) => {
      const newMode = action.payload;
      state.pantryList.mode = newMode;
      if (newMode === EPantryMode.Manual) {
        // Add new line at bottom of the list
        state.pantryList.pantryLines.push(getNewEmptyLine(uuidv4()));
      }
    },
    setNumberProducts: (state, action: PayloadAction<any>) => {
      state.pantryList.numberProduct = Math.round(
        Number.isNaN(Number(action.payload)) || Number(action.payload) < 0
          ? 0
          : Number(action.payload)
      );
    },
    setIsIframe: (state, action: PayloadAction<boolean>) => {
      state.isIframe = action.payload;
    },
    setIsScrollToBottom: (state, action: PayloadAction<boolean>) => {
      state.isScrollToBottom = action.payload;
    },
    setSearchKeywords: (state, action: PayloadAction<string>) => {
      state.searchKeywords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPantryListAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPantryListAsync.fulfilled, (state, action) => {
        state.loading = false;
        const initPantryList: PantryList = {
          ...action.payload,
          isEditing: false,
          isShowFavouriteOnly: false,
        };
        state.pantryList = initPantryList;
        state.initPantryList = initPantryList;
      })
      .addCase(fetchAutoPantryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAutoPantryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.pantryList.pantryLines = action.payload;
        state.pantryList.mode = EPantryMode.Auto;
      });
  },
});
export const {
  tryEdit,
  endEdit,
  startEditLine,
  editLine,
  deleteLines,
  addMultipleProducts,
  selectProduct,
  sortPantryLines,
  selectPantryLines,
  setFavourite,
  switchShowFavouriteOnly,
  setPantryMode,
  setNumberProducts,
  setIsIframe,
  setIsScrollToBottom,
  setSearchKeywords,
} = pantryListSlice.actions;
export default pantryListSlice;

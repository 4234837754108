import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M11.6204 2.84023C10.7999 1.23898 7.98517 0.13573 5.87767 1.12948C5.36489 1.41097 4.91534 1.79471 4.55683 2.25693C4.19833 2.71916 3.9385 3.25004 3.79342 3.81673C3.46942 4.65073 3.19342 5.37073 2.46142 5.72173L1.56142 6.17173C1.22703 6.31805 0.926187 6.53137 0.677479 6.79852C0.428771 7.06568 0.23748 7.38099 0.115424 7.72498C0.0126689 8.05131 -0.0211243 8.39543 0.016189 8.73551C0.0535022 9.07559 0.161107 9.4042 0.332174 9.70048C0.54439 10.0379 0.822707 10.3288 1.15036 10.5558C1.47801 10.7828 1.84821 10.9411 2.23867 11.0212C2.84042 11.1813 3.46102 11.2595 4.08367 11.2537C5.74787 11.2471 7.38213 10.8107 8.82817 9.98698C11.6189 8.33248 12.6202 4.78723 11.6204 2.84023ZM7.87492 5.99998C7.65242 5.99998 7.43491 5.934 7.24991 5.81038C7.0649 5.68677 6.92071 5.51107 6.83556 5.3055C6.75041 5.09993 6.72813 4.87373 6.77154 4.6555C6.81495 4.43728 6.92209 4.23682 7.07943 4.07948C7.23676 3.92215 7.43722 3.81501 7.65545 3.7716C7.87367 3.72819 8.09987 3.75047 8.30544 3.83562C8.51101 3.92076 8.68671 4.06496 8.81033 4.24996C8.93394 4.43497 8.99992 4.65248 8.99992 4.87498C8.99992 5.17335 8.8814 5.4595 8.67042 5.67048C8.45944 5.88145 8.17329 5.99998 7.87492 5.99998Z" />
    </SvgIcon>
  );
}

export default IconComponent;

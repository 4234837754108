import { useEffect, useState } from "react";
import clsx from "clsx";
import { Common, Icon } from "@/components";
import { Menu } from "@headlessui/react";
import ReactTooltip from "react-tooltip";
import { manifestLine } from "../models/DeliveryManifest";
import { addressGenerate, timeFormat, timeStopFormat } from "@/utils";
import { dropdownIds } from "../configVariable";
import { dropMenuEditItem } from "../models";
import { ETATimeFormat } from "../utils";

const DropdownMenu = [
  {
    id: dropdownIds.CHANGE_POSITION,
    name: "Change position",
    tooltipContent: "Cannot move orders to started routes",
  },
  {
    id: dropdownIds.UNALLOCATE,
    name: "Unallocated",
    tooltipContent: "Cannot move orders to started routes",
    color: "text-red",
  },
];

interface Props {
  className?: string;
  data: manifestLine;
  isCompleted?: boolean;
  manifestETALoading: boolean;
  positionChangedToHighlight?: number;
  routeStatus: number;
  onChangePositionClick: (data: manifestLine) => void;
  onMoveToAnotherRouteClick?: (data: manifestLine) => void;
  onUnallocateClick: (data: manifestLine) => void;
}

export const DestinationDetailEditingItem = ({
  className,
  data,
  isCompleted,
  manifestETALoading,
  positionChangedToHighlight,
  routeStatus,
  onMoveToAnotherRouteClick,
  onChangePositionClick,
  onUnallocateClick,
}: Props) => {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isChangePosirionHighlight, setChangePosirionHighlight] =
    useState<boolean>(false);
  const ETATime = ETATimeFormat(data, routeStatus);

  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  }, []);

  const handleDropdownItemClick = (item: dropMenuEditItem) => {
    if (item.id === dropdownIds.UNALLOCATE) {
      onUnallocateClick(data);
    }
    if (item.id === dropdownIds.MOVETR) {
      onMoveToAnotherRouteClick && onMoveToAnotherRouteClick(data);
    }
    if (item.id === dropdownIds.CHANGE_POSITION) {
      onChangePositionClick(data);
    }
  };

  useEffect(() => {
    let timer: any = null;
    if (positionChangedToHighlight === data.position) {
      setChangePosirionHighlight(true);
      timer = setTimeout(() => {
        setChangePosirionHighlight(false);
      }, 5000);
    }

    return () => {
      timer && clearTimeout(timer);
      setChangePosirionHighlight(false);
    };
  }, [positionChangedToHighlight, data.position]);

  return (
    <div
      className={clsx(
        "px-3 py-2 flex border rounded select-none",
        isCompleted
          ? "bg-neutral-30"
          : "group hover:bg-blue-5 hover:border-blue-80",
        isChangePosirionHighlight
          ? "bg-blue-5 border-blue-80"
          : "border-neutral-10 bg-white",
        className
      )}
    >
      <div className="flex items-center cursor-grab">
        {isCompleted ? (
          <div
            className={clsx(
              "w-5 h-5 rounded-full font-semibold flex items-center justify-center text-neutral-50 bg-green"
            )}
          >
            <Icon.Checked size="12" className="fill-white" />
          </div>
        ) : (
          <Icon.DragIndicator size="14" className="fill-neutral-30" />
        )}
      </div>
      <div className="flex-1 ml-4">
        <div className="font-semibold flex">
          <span className="text-blue whitespace-nowrap">
            #{data.document.saleDocumentNumber}
          </span>
          {data.document.customerTradingName
            ? ` - ${data.document.customerTradingName}`
            : ""}
          {isCompleted ? (
            <div>
              <div className="w-6 h-6" />
            </div>
          ) : (
            <Common.DropDowns
              onOpen={setDropdownOpen}
              className={clsx(
                "ml-auto",
                !isDropdownOpen && "opacity-0 group-hover:opacity-100"
              )}
              buttonRender={
                <div className="w-6 h-6 inline-flex items-center justify-center border font-medium group bg-white text-blue border-neutral-20 hover:border-blue-dark rounded">
                  <Icon.SeeMore size="14" className="fill-blue" />
                </div>
              }
              listRender={
                <div>
                  {DropdownMenu.map((item) => (
                    <Menu.Item key={item.name}>
                      <button
                        type="submit"
                        data-tip={item.tooltipContent}
                        className={clsx(
                          "block text-left px-4 py-2 border-t border-t-neutral-10  hover:bg-neutral-5 w-44 font-normal text-sm",
                          item.color
                        )}
                        onClick={() => handleDropdownItemClick(item)}
                      >
                        {item.name}
                      </button>
                    </Menu.Item>
                  ))}
                </div>
              }
            />
          )}
        </div>
        <div className="flex mt-2">
          <div className="flex items-center mr-5">
            <div data-tip="Earliest time - Latest time">
              <Icon.DeliveryTime size="12" className="fill-neutral-40" />
            </div>
            <span className="ml-1.5 text-neutral-70">
              {timeFormat(data.earliest, true)} -{" "}
              {timeFormat(
                data.latest === "00:00:00" ? "23:59:00" : data.latest,
                true
              )}
            </span>
          </div>
          <div className="flex items-center mr-5">
            <div data-tip="Stop time">
              <Icon.Stock size="12" className="fill-neutral-40" />
            </div>
            <span className="ml-1.5 text-neutral-70">
              {data?.stopTime != null &&
                data?.stopTime >= 0 &&
                timeStopFormat(data?.stopTime)}
            </span>
          </div>
          <div className="flex items-center">
            <div data-tip="Estimated time of arrival ">
              <Icon.Clock size="12" className="fill-blue-80" />
            </div>
            <span className="ml-1.5 text-blue">
              {manifestETALoading ? <Icon.Loading className="ml-2" /> : ETATime}
            </span>
          </div>
        </div>
        <div className="flex mt-2">
          <div className="mt-1" data-tip="Delivery address">
            <Icon.Location size="12" className="fill-neutral-40" />
          </div>
          <div className="ml-1.5 text-neutral-70">
            {addressGenerate(
              data.document.deliveryAddressLine1,
              data.document.deliveryAddressLine2,
              data.document.deliverySuburb,
              data.document.deliveryState,
              data.document.deliveryPostCode
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

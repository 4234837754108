import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 16">
      <path d="M10 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H10C11.103 16 12 15.103 12 14V2C12 0.897 11.103 0 10 0ZM6 14C5.448 14 5 13.552 5 13C5 12.448 5.448 12 6 12C6.552 12 7 12.448 7 13C7 13.552 6.552 14 6 14ZM10 10H2V2H10V10Z" />
    </SvgIcon>
  );
}

export default IconComponent;

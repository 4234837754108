import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath={`url(#clip${uid})`}>
        <path
          d="M6.60008 2.82663C7.05897 2.71921 7.52879 2.66552 8.00008 2.66663C12.6667 2.66663 15.3334 7.99996 15.3334 7.99996C14.9287 8.75703 14.4461 9.46978 13.8934 10.1266M9.41341 9.41329C9.23032 9.60979 9.00951 9.7674 8.76418 9.87671C8.51885 9.98602 8.25402 10.0448 7.98547 10.0495C7.71693 10.0543 7.45019 10.0049 7.20115 9.90429C6.95212 9.8037 6.7259 9.65398 6.53598 9.46406C6.34606 9.27414 6.19634 9.04792 6.09575 8.79888C5.99516 8.54985 5.94577 8.28311 5.9505 8.01457C5.95524 7.74602 6.01402 7.48119 6.12333 7.23586C6.23264 6.99053 6.39025 6.76972 6.58675 6.58663M0.666748 0.666626L15.3334 15.3333M11.9601 11.96C10.8205 12.8286 9.43282 13.3099 8.00008 13.3333C3.33341 13.3333 0.666748 7.99996 0.666748 7.99996C1.49601 6.45456 2.64617 5.10436 4.04008 4.03996L11.9601 11.96Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_3848_16743)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3064 0.0131836C15.4865 0.0131836 15.6426 0.0789861 15.774 0.210599L15.7739 0.210458C15.9058 0.342071 15.9713 0.497671 15.9711 0.67802V5.33396C15.9711 5.51401 15.9053 5.67007 15.7739 5.80152C15.6423 5.93313 15.4864 5.99909 15.3063 5.99909C15.1263 5.99909 14.9702 5.93314 14.8384 5.80152L13.3421 4.3052L9.89177 7.75553C9.82243 7.82472 9.74294 7.85931 9.65284 7.85931C9.56259 7.85931 9.48295 7.82472 9.41376 7.75553L8.22894 6.5707C8.15975 6.50136 8.125 6.42172 8.125 6.33162C8.125 6.24153 8.15975 6.16188 8.22894 6.09254L11.6793 2.64221L10.1829 1.14572C10.0515 1.01426 9.98568 0.858208 9.98568 0.678162C9.98568 0.498115 10.0515 0.342213 10.1829 0.210599C10.3147 0.07899 10.4705 0.0131836 10.6505 0.0131836H15.3064ZM6.32738 8.12964C6.41748 8.12964 6.49728 8.16423 6.56647 8.23342L7.75128 9.41827C7.82031 9.48776 7.85506 9.56725 7.85506 9.65735C7.85506 9.74761 7.82047 9.82725 7.75128 9.89644L4.30095 13.3468L5.79743 14.8431C5.92904 14.9749 5.99484 15.1309 5.99484 15.311C5.99484 15.491 5.92904 15.6469 5.79743 15.7785C5.66581 15.91 5.50991 15.9759 5.32987 15.9759H0.673925C0.493875 15.9759 0.337972 15.91 0.206205 15.7785C0.0745955 15.6469 0.00878906 15.491 0.00878906 15.311V10.655C0.00878906 10.475 0.0745916 10.3189 0.206205 10.1872C0.337822 10.0558 0.493725 9.98989 0.673925 9.98989C0.854125 9.98989 1.00987 10.0557 1.14149 10.1872L2.63797 11.6838L6.0883 8.23342C6.15764 8.16423 6.23728 8.12964 6.32738 8.12964Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3848_16743">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

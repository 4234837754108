import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M14.6705 6.76288C13.1265 4.91477 10.5656 2.96123 7.99703 3.0006C5.42851 2.96059 2.86757 4.9154 1.32354 6.76288C1.11189 7.02033 0.994873 7.35361 0.994873 7.69901C0.994873 8.04441 1.11189 8.37769 1.32354 8.63513C2.84948 10.4636 5.37422 12.3999 7.90305 12.3999H8.08285C10.6204 12.3999 13.1446 10.4636 14.6723 8.6345C14.8837 8.37692 15.0003 8.04362 15 7.69833C14.9997 7.35303 14.8824 7.01999 14.6705 6.76288V6.76288ZM5.31176 7.70028C5.31176 7.12248 5.46925 6.55765 5.76431 6.07722C6.05937 5.5968 6.47875 5.22235 6.96942 5.00124C7.46009 4.78012 8.00001 4.72227 8.5209 4.83499C9.0418 4.94772 9.52027 5.22595 9.89581 5.63452C10.2714 6.04309 10.5271 6.56364 10.6307 7.13034C10.7343 7.69704 10.6811 8.28444 10.4779 8.81826C10.2747 9.35207 9.93048 9.80834 9.48889 10.1293C9.0473 10.4504 8.52813 10.6217 7.99703 10.6217C7.28485 10.6217 6.60184 10.3139 6.09826 9.76603C5.59467 9.21816 5.31176 8.47509 5.31176 7.70028Z" />
      <path d="M7.9971 8.97042C8.6419 8.97042 9.16461 8.40174 9.16461 7.70024C9.16461 6.99873 8.6419 6.43005 7.9971 6.43005C7.3523 6.43005 6.82959 6.99873 6.82959 7.70024C6.82959 8.40174 7.3523 8.97042 7.9971 8.97042Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM9 6.75H6.75V9H5.25V6.75H3V5.25H5.25V3H6.75V5.25H9V6.75Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

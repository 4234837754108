import { useEffect, useState } from "react";
import clsx from "clsx";

import { Common, LoadingOverlay } from "@/components";
import { Icon } from "@/components/Icons";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { checkNewScheduleAsync } from "../providers/schedulePlanner/slice";

type Props = {
  type: "create" | "duplicate" | "edit" | "editHoliday";
  scheduleName?: string;
  onClose: () => void;
  onSubmitName: (text: string) => void;
};

const TEXT_GROUP = {
  create: {
    title: "Create new schedule",
    textBtn: "Create",
  },
  duplicate: {
    title: "Duplicate Schedule",
    textBtn: "Duplicate",
  },
  edit: {
    title: "Edit schedule",
    textBtn: "Save Change",
  },
  editHoliday: {
    title: "Edit schedule name",
    textBtn: "Update",
  },
};

const ModalEditNameSchedule = ({
  type,
  scheduleName = "",
  onClose,
  onSubmitName,
}: Props) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.deliverySlice.loading);

  const [name, setName] = useState<string>(scheduleName);
  const [error, setError] = useState<boolean>(false);
  const [isClickSubmit, setClickSubmit] = useState<boolean>(false);
  const [loadingCheckName, setLoadingCheckName] = useState<boolean>(false);

  useEffect(() => {
    document.getElementById(type)?.focus();
  }, []);

  const handleChangeScheduleName = (value: string) => {
    setName(value.length > 250 ? name : value);
    setClickSubmit(false);
  };

  const checkNewNameSchedule = async (value: string) => {
    setLoadingCheckName(true);
    const checked = await dispatch(checkNewScheduleAsync(value)).unwrap();
    return checked;
  };

  const handleSubmitName = async () => {
    const trimName = name.trim();
    if (trimName === scheduleName && type !== "duplicate") return onClose();

    if (type === "editHoliday") {
      onSubmitName(trimName);
    } else {
      const isValid = await checkNewNameSchedule(trimName);
      setError(!isValid);
      setClickSubmit(true);
      if (isValid) {
        onSubmitName(trimName);
      }
    }
    setLoadingCheckName(false);
  };

  if (loading) return <LoadingOverlay />;

  return (
    <div className="">
      <div className="text-[1rem] font-semibold text-left p-5 border-b border-neutral-20">
        {TEXT_GROUP[type].title}
      </div>
      <div className="p-6">
        <div className="mb-1">Enter schedule name</div>
        <Common.Input
          id={type}
          iconRight={
            isClickSubmit
              ? error
                ? Icon.WarningCircle
                : Icon.CheckedFill
              : undefined
          }
          placeholder="Enter name"
          value={name}
          defaultValue={scheduleName}
          onChange={(e) => handleChangeScheduleName(e.target.value)}
          iconClassName={
            isClickSubmit ? (error ? "fill-red" : "fill-green") : ""
          }
          className={clsx(
            `bg-neutral-10 focus:bg-white ${
              isClickSubmit
                ? error
                  ? "border-red focus:border-red"
                  : "border-green focus:border-green"
                : ""
            }`
          )}
        />
        {isClickSubmit && error && (
          <span className="text-red mt-1">
            This schedule name is already being used.
          </span>
        )}
      </div>
      <div className="text-[1rem] font-semibold text-left p-5 border-t border-neutral-20 flex justify-end">
        <Common.Button
          outline
          color="gray"
          onClick={loadingCheckName || loading ? () => {} : () => onClose()}
        >
          Cancel
        </Common.Button>
        <Common.Button
          className="ml-3"
          disabled={name === "" || loadingCheckName}
          onClick={
            loadingCheckName || loading ? () => {} : () => handleSubmitName()
          }
          isLoading={loadingCheckName}
        >
          {TEXT_GROUP[type].textBtn}
        </Common.Button>
      </div>
    </div>
  );
};

export default ModalEditNameSchedule;

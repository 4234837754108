import { Icon, Common } from "@/components";
import clsx from "clsx";
import { DOMAIN_URL } from "@/configuration/globalVariable";
import { Images } from "@/assets/images";

import {
  useRejectRelationshipConnect,
  useAcceptRelationshipConnect,
} from "../services/dashboard";
import { useEffect } from "react";

interface Props {
  isFirstItem?: boolean;
  data?: any;
  onActionComplete: () => void;
}

export const CustommerRequestItem = ({
  data,
  isFirstItem,
  onActionComplete,
}: Props) => {
  const acceptRelationshipConnect = useAcceptRelationshipConnect();
  const rejectRelationshipConnect = useRejectRelationshipConnect();

  const { isSuccess: acceptSuccessful, isLoading: accepting } =
    acceptRelationshipConnect;
  const { isSuccess: rejectSuccessful, isLoading: rejecting } =
    rejectRelationshipConnect;

  const handleAccept = () => {
    acceptRelationshipConnect.mutate({ relationshipId: data.relationshipId });
  };

  const handleReject = () => {
    rejectRelationshipConnect.mutate({ relationshipId: data.relationshipId });
  };

  useEffect(() => {
    if (acceptSuccessful || rejectSuccessful) {
      onActionComplete();
    }
  }, [acceptSuccessful, rejectSuccessful, onActionComplete]);

  const isDisabledButtons = accepting || rejecting;
  return (
    <div
      className={clsx("flex pt-3 pb-3.5", !isFirstItem && "shadow-overline")}
    >
      <div className="mr-4">
        <div
          className="w-10 h-10 rounded-full bg-blue-10 bg-contain"
          style={{
            backgroundImage: data.logo
              ? `url(${DOMAIN_URL}${data.logo})`
              : `url(${Images.noAvatar})`,
          }}
        ></div>
      </div>
      <div className="flex-1">
        <div className="flex items-center">
          <span className="text-neutral-80 font-semibold">{data.name}</span>
          {data.isVerified && (
            <div className="inline-flex">
              <Icon.CheckedFill size="12" className="ml-1.5 fill-blue" />
            </div>
          )}
          <div className="text-sm text-neutral-50 ml-auto">
            {data.requestedAt}
          </div>
        </div>
        <div className="line-clamp-1 mt-1">{data.address}</div>
        <div className="mt-2.5 flex">
          <Common.Button
            disabled={isDisabledButtons}
            isLoading={accepting}
            onClick={handleAccept}
          >
            Accept
          </Common.Button>
          <Common.Button
            disabled={isDisabledButtons}
            isLoading={rejecting}
            onClick={handleReject}
            outline
            color="red"
            className="ml-1.5"
          >
            Reject
          </Common.Button>
        </div>
      </div>
    </div>
  );
};

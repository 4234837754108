import React from "react";
import { useLocation } from "react-router-dom";

const DeliveryScheduleEdit = () => {
  const location = useLocation();
  console.log(location.state.scheduleId);

  return <div>DeliveryScheduleEdit</div>;
};

export default DeliveryScheduleEdit;

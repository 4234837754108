/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import {
  DeliveryLineStatusValue,
  DeliveryStatusValue,
  TIME_FORMAT,
} from "@/configuration/globalVariable";
import {
  drive,
  manifest,
  manifestDetailETA,
  manifestDetailETAPolyline,
  manifestLine,
  manifestLineETAPolyline,
  manifestLineETA,
  optimizeInfo,
  solution,
} from "./models";

export const refillManifestLinePosition = (
  manifestLine: manifestLine[],
  manifestId?: string
) => {
  const manifestLineCompleted = [] as manifestLine[];
  const manifestLineDelivering = [] as manifestLine[];
  const manifestLinePending = [] as manifestLine[];
  manifestLine.map((mfl: manifestLine) => {
    if (mfl.deliveryStatus === DeliveryLineStatusValue.COMPLETED) {
      manifestLineCompleted.push(mfl);
    }
    if (mfl.deliveryStatus === DeliveryLineStatusValue.DELIVERING) {
      manifestLineDelivering.push(mfl);
    }
    if (mfl.deliveryStatus === DeliveryLineStatusValue.PENDING) {
      manifestLinePending.push(mfl);
    }
    return mfl;
  });
  const resultChangePosition = [
    ...manifestLineCompleted,
    ...manifestLineDelivering,
    ...manifestLinePending,
  ].map((item: manifestLine, index: number) => {
    return {
      ...item,
      manifestId: manifestId || item.manifestId,
      position: index,
    };
  });
  return resultChangePosition;
};

export const sortManifestLine = (manifest?: manifest) => {
  if (!manifest || (manifest && !manifest.manifestLines.length))
    return manifest;

  const arrSort = [...manifest.manifestLines];
  const newManifestLines = arrSort.sort(
    (a: manifestLine, b: manifestLine) => a.position - b.position
  );
  return {
    ...manifest,
    manifestLines: refillManifestLinePosition(newManifestLines),
  };
};

// a little function to help us with reordering the result
export const reorderManifestLines = (
  list: manifestLine[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  const resultChangePosition = refillManifestLinePosition(
    result.map((manifestLine: manifestLine, index: number) => {
      return {
        ...manifestLine,
        position: index,
      };
    })
  );
  return resultChangePosition;
};

export const hoursToISO = (time: string) => {
  const isoDate = moment(time, "HH:mm");
  const ISOString = isoDate.toISOString();
  return ISOString;
};

export const mergeManifestOptimize = (
  manifest: manifest,
  solution?: solution,
  opInfo?: optimizeInfo
) => {
  return {
    ...manifest,
    distance: solution?.totalDistanceAfter,
    workingTime: solution?.totalTimeAfter,
    driver: {
      ...manifest.driver,
      workStartTime: opInfo?.workStartTime,
      workEndTime: opInfo?.workEndTime,
      breakStartTime: opInfo?.breakStartTime,
      breakEndTime: opInfo?.breakEndTime,
      returnToDepot: opInfo?.returnToDepot,
    },
    manifestLines:
      solution?.visits.map((vs) => {
        return {
          ...vs.manifestLine,
          position: vs.position - 1,
          arrivalTime: hoursToISO(vs.arrivalTime),
          finishTime: hoursToISO(vs.finishTime),
          longitude: vs.longitude,
          latitude: vs.latitude,
          stopTime: vs.duration,
        };
      }) || [],
    polylines: solution?.polyline,
    opInfo,
    startTime: solution?.startTime,
    endTime: solution?.endTime,
  };
};

export const ETATimeFormat = (
  manifestLine?: manifestLine,
  routeStatus?: number
) => {
  const ETAtime =
    manifestLine?.deliveryStatus === DeliveryLineStatusValue.COMPLETED
      ? manifestLine.deliveredOn
      : manifestLine?.manualETA || // manifestLine?.eta ||
        (routeStatus === DeliveryStatusValue.DELIVERING
          ? manifestLine?.pendingETA
          : manifestLine?.arrivalTime);
  console.log(ETAtime);
  return ETAtime
    ? moment.parseZone(ETAtime).format(TIME_FORMAT.ETA_TIME)
    : "--:--";
};

export const ETATimeFormatForReviewOptimize = (
  manifestLine?: manifestLine,
  routeStatus?: number
) => {
  const ETAtime =
    manifestLine?.deliveryStatus === DeliveryLineStatusValue.COMPLETED
      ? manifestLine.deliveredOn
      : manifestLine?.manualETA || // manifestLine?.eta ||
        (routeStatus === DeliveryStatusValue.DELIVERING
          ? manifestLine?.pendingETA
          : manifestLine?.arrivalTime);
  return ETAtime ? moment(ETAtime).format(TIME_FORMAT.ETA_TIME) : "--:--";
};
export const filterDupplicateManifestLine = (
  manifestLinesETA: manifestLine[]
) => {
  const newArr: manifestLine[] = [];
  manifestLinesETA &&
    manifestLinesETA.map((line) => {
      if (
        !newArr.some((item) => {
          return (
            item.latitude === line.latitude && item.longitude === line.longitude
          );
        })
      ) {
        newArr.push(line);
      }
      return line;
    });
  return newArr;
};

export const mergeETAData = (data: any) => {
  const newArr =
    data[0] && data[0].length
      ? data[0]
          .filter((e: manifestDetailETA) => Boolean(e))
          .map((eta: any) => {
            const polyline =
              data[1] &&
              data[1].find(
                (pll: manifestDetailETAPolyline) =>
                  eta?.manifestId === pll?.manifestId
              );
            return {
              ...eta,
              ...polyline,
              manifestLine: eta.manifestLine.map((etaLine: manifestLineETA) => {
                const newPllLine =
                  polyline &&
                  polyline.manifestLine.find(
                    (pllLine: manifestLineETAPolyline) =>
                      pllLine?.id === etaLine?.id
                  );
                return {
                  ...newPllLine,
                  ...etaLine,
                };
              }),
              startTime:
                eta.startTime == eta.endTime
                  ? polyline.startTime
                  : eta.startTime,
              endTime:
                eta.startTime == eta.endTime ? polyline.endTime : eta.endTime,
            };
          })
      : data[1].filter((e: manifestDetailETA) => Boolean(e));
  return newArr;
};

export const mergeETARouteData = (data: any) => {
  const eta = data[0];
  const polyline = data[1];
  const newArr = {
    ...eta,
    ...polyline,
    manifestLine: eta.manifestLine.map((etaLine: manifestLineETA) => {
      const newPllLine =
        polyline &&
        polyline.manifestLine.find(
          (pllLine: manifestLineETAPolyline) => pllLine?.id === etaLine?.id
        );
      return {
        ...newPllLine,
        ...etaLine,
      };
    }),
  };
  return newArr;
};

export const mergeETAIntoRoute = (route: manifest, eta: manifestDetailETA) => {
  const newData = {
    ...route,
    ...eta,
    manifestLines: route.manifestLines.map((manifestLine: manifestLine) => {
      const etaManifestLine = eta.manifestLine.find(
        (etaMnfl) => etaMnfl.id === manifestLine.id
      );
      return etaManifestLine
        ? { ...manifestLine, ...etaManifestLine }
        : manifestLine;
    }),
  };
  return newData;
};

export const mergeETAsIntoRoutes = (
  routes: manifest[],
  etas: manifestDetailETA[]
) => {
  const newRoutes = routes.map((route) => {
    const eta = etas.find((eta) => eta?.manifestId === route.id);
    if (eta) {
      return mergeETAIntoRoute(route, eta);
    } else {
      return route;
    }
  });
  return newRoutes;
};

export const checkCompletedDocumentPosition = (
  manifest: manifest,
  position: number
) => {
  return (
    manifest &&
    manifest.status === DeliveryStatusValue.STOPPED &&
    manifest.manifestLines[
      position >= manifest.manifestLines.length - 1
        ? manifest.manifestLines.length - 1
        : position
    ]?.deliveryStatus === DeliveryLineStatusValue.COMPLETED
  );
};

export const driverName = (driver: drive) =>
  `${driver?.givenName}${driver?.lastName ? ` ${driver?.lastName}` : ""}`;

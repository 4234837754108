import { useState } from "react";
import { LayoutPartials, Common } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../../components";

export const Select = () => {
  const [selectValue, setValue] = useState("");

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    setValue(value);
  };
  return (
    <LayoutPartials.BodyContent className="mb-4" pageTitle="Select Input">
      <ComponentInfo
        title="Selectbox component"
        code={`import { Common } from "@/components";

<Common.Selectbox
  options={[
    { id: 1, text: "Option 1", value: 1 },
    { id: 2, text: "Option 2", value: 2 },
  ]}
/>`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  label?: string;
  wrapClassName?: string;
  id?: string;
  ipSize?: "lg" | "md";
  isError?: boolean;
  errorMessage?: string;
  className?: string;
  options: selectItem[];
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  ipSize = "lg",
}`}
      />

      <DisplayComponent
        componentName="Selecbox input"
        className="mb-3"
        RenderComp={() => (
          <Common.Selectbox
            name="SelectBox"
            value={selectValue}
            onChange={handleSelectChange}
            options={[
              { id: 1, text: "Option 1", value: 1 },
              { id: 2, text: "Option 2", value: 2 },
            ]}
          />
        )}
        code={`const handleSelectChange = (
  e: React.ChangeEvent<HTMLSelectElement>
): void => {
  const { name, value } = e.target;
  console.log(name, ": ", value);
};          
          
<Common.Selectbox
  name="SelectBox"
  onChange={handleSelectChange}
  options={[
    { id: 1, text: "Option 1", value: 1 },
    { id: 2, text: "Option 2", value: 2 },
  ]}
/>`}
      />
    </LayoutPartials.BodyContent>
  );
};

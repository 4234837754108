import { useEffect, useState } from "react";
import moment from "moment";
import {
  Card,
  Common,
  Icon,
  RedirectToOUI,
  CustomScrollbar,
} from "@/components";
import { TodayRouteItem } from "./TodayRouteItem";

import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector } from "@/providers/auth/selector";

import { useGetRoutesSummary } from "../services/dashboard";

export const TodayRoutes = () => {
  const [todayRoutes, setTodayRoutes] = useState([]);
  const user = useAppSelector(userSelector);
  const routesSummary = useGetRoutesSummary();
  const { isSuccess, isLoading, data } = routesSummary;

  useEffect(() => {
    routesSummary.mutate({
      accountId: user.accountId,
      deliveryDate: moment(new Date()).format("DD/MM/yyyy"),
      pageSize: 10,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTodayRoutes(data);
    }
    // eslint-disable-next-line
  }, [isSuccess, data]);

  return (
    <Card
      title="Today routes"
      className="mb-3 lg:h-72 xl:h-1/2 flex-1 xl:flex-auto"
      pathViewAll={`/delivery/deliverymanifests`}
      isLoading={isLoading}
    >
      {Boolean(todayRoutes.length) && (
        <div className="h-full w-full py-2 lg:absolute">
          <CustomScrollbar
            renderView={(props) => (
              <div {...props} className="!static lg:!absolute" />
            )}
          >
            <div className="px-5">
              {todayRoutes.map((item: any, index) => (
                <TodayRouteItem
                  key={item.routeId}
                  data={item}
                  isFirstItem={!index}
                />
              ))}
            </div>
          </CustomScrollbar>
        </div>
      )}
      {!isLoading && !todayRoutes.length && (
        <Common.NoData
          iconRender={Icon.Split}
          title="You haven't created any route yet"
          bottomElement={
            <RedirectToOUI to={`/delivery/routes/create`}>
              <Common.Button className="mt-5" iconLeft={Icon.PlusBorder}>
                Create new route
              </Common.Button>
            </RedirectToOUI>
          }
        />
      )}
      {isLoading && (
        <Common.NoData
          iconRender={Icon.Split}
          title="Loading your routes ..."
        />
      )}
    </Card>
  );
};

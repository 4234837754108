import { useEffect } from "react";
import { Common, Icon } from "@/components";
import { EPickingStatus } from "@/configuration/globalVariable";
import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector } from "@/providers/auth/selector";
import {
  PickingStatusGroup,
  PickingStatusGroupProps,
} from "./components/PickingStatusGroup";
import { useGetPickingProductsReportGroupByPickingStatus } from "./services/groupByPickingStatus";
import { addSpaceBeforeUppercaseChars, getQueryString } from "@/utils";

const Index = () => {
  const getPickingProductsReport =
    useGetPickingProductsReportGroupByPickingStatus();
  const { data, isLoading, isSuccess } = getPickingProductsReport;

  const user = useAppSelector(userSelector);

  const fetchData = () => {
    getPickingProductsReport.mutate(getQueryString());
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      window.print();
    }
  }, [isSuccess, data]);

  const statusGroups: PickingStatusGroupProps[] = data?.statuses;
  const totalDocuments: number = data?.totalDocuments;
  return (
    <>
      {statusGroups && Boolean(statusGroups?.length) && (
        <div className="relative min-h-screen overflow-hidden bg-gray-50">
          <div className="container relative bg-white px-6 pt-10 pb-8 sm:mx-auto sm:rounded-lg sm:px-10">
            <div className="text-base leading-7 text-gray-600">
              <ul className="space-y-4">
                <li className="flex items-center">
                  <p className="mr-2 text-hd4 font-semibold">
                    Product Totals by Customer
                  </p>
                  <p className="p-1 shadow-sm bg-neutral-30 rounded-full"></p>
                  <p className="ml-2 text-hd4 font-semibold">
                    {totalDocuments} Orders
                  </p>
                </li>
                <li className="flex items-center">
                  <p className="font-semibold">Supplier: {user.companyName}</p>
                </li>
              </ul>

              {statusGroups.map((g, index) => (
                <PickingStatusGroup
                  key={EPickingStatus[g.pickingStatus]}
                  isFirst={index === 0}
                  // add space before uppercase capital letters
                  groupName={addSpaceBeforeUppercaseChars(
                    EPickingStatus[g.pickingStatus]
                  )}
                  pickingStatus={g.pickingStatus}
                  totalProducts={g.totalProducts}
                  products={g.products}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      {!isLoading && !statusGroups?.length && statusGroups !== undefined && (
        <div className="h-[calc(100%-39px)]">
          <Common.NoData title="No data." />
        </div>
      )}
      {isLoading && (
        <div className="w-full h-[calc(100vh-6.25rem)] flex items-center justify-center">
          Loading...
          <Icon.Loading className="ml-3" />
        </div>
      )}
    </>
  );
};

export default Index;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14C6.5 14 5.2 13.5 4.1 12.6C4.7 11.8 4.8 10.8 4.2 10C3.7 9.3 2.9 8.6 2.9 8C2.9 7.2 4.8 6.9 5.4 6.1C6.1 5.3 5.4 3.8 4.7 3C5.5 2.5 6.4 2.1 7.5 2C7.7 2.5 8.4 3.1 8.7 3.5C9.2 4.3 9.3 4.8 10 4.8C10.2 4.8 12 4.8 13 4.5C13.6 5.5 14 6.7 14 8C14 11.3 11.3 14 8 14Z" />
      <path d="M10.2 6.7002C8.99998 6.7002 7.89998 7.5002 7.99998 8.6002C8.29998 11.1002 7.49998 12.0002 8.59998 12.5002C9.89998 13.0002 12.5 10.6002 12.4 8.6002C12.4 7.5002 11.5 6.7002 10.2 6.7002Z" />
    </SvgIcon>
  );
}

export default IconComponent;

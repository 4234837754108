import { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import {
  Card,
  Common,
  Table,
  Th,
  Td,
  Icon,
  RedirectToOUI,
  CustomScrollbar,
} from "@/components";
import { useGetPendingCreditsList } from "../services/dashboard";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import { dashBoardTableItem } from "../models";

const buttonDateFilter = [
  {
    id: 1,
    text: "Last 7 days",
    value: 0,
  },
  {
    id: 2,
    text: "This month",
    value: 1,
  },
  {
    id: 3,
    text: "Last month",
    value: 2,
  },
];

export const CUSPendingCredits = () => {
  const [dateFilterActive, setDateFilterActive] = useState(
    buttonDateFilter[0].value
  );
  const [pendingOrdersList, setPendingOrdersList] = useState<any>({});

  const getPendingOrdersList = useGetPendingCreditsList();
  const { data, isLoading, isSuccess } = getPendingOrdersList;

  const handleChangeTimestamp = (dateFilterActive: number) => {
    setDateFilterActive(dateFilterActive);
  };

  const fetchPendingOrdersList = () => {
    getPendingOrdersList.mutate({
      timeStamp: dateFilterActive,
      pageNumber: 1,
      pageSize: 1000,
    });
  };

  useEffect(() => {
    fetchPendingOrdersList();
    // eslint-disable-next-line
  }, [dateFilterActive]);

  useEffect(() => {
    if (isSuccess) {
      setPendingOrdersList(data);
    }
  }, [isSuccess, data]);

  // Last 7 days
  let fromdate = moment().subtract(7, "days").format(TIME_FORMAT.REQUEST);
  let todate = moment().format(TIME_FORMAT.REQUEST);

  // THis month
  if (dateFilterActive === 1) {
    fromdate = moment().startOf("month").format(TIME_FORMAT.REQUEST);
    todate = moment().endOf("month").format(TIME_FORMAT.REQUEST);
  }

  // Last month
  if (dateFilterActive === 2) {
    fromdate = moment()
      .subtract(1, "month")
      .startOf("month")
      .format(TIME_FORMAT.REQUEST);
    todate = moment()
      .subtract(1, "month")
      .endOf("month")
      .format(TIME_FORMAT.REQUEST);
  }

  const pathViewAll = `/purchases/documents/invoices?supplierid=&number=&status=00000000-0000-0006-0124-000000000000%2c00000000-0000-0006-0224-000000000000&fromdate=${fromdate}&todate=${todate}&paymethod=&routename=&routeid=&rangefor=1&doctype=4`;

  return (
    <Card
      title="Pending Credits"
      className="flex-1"
      pathViewAll={pathViewAll}
      isLoading={isLoading}
      middleHeaderContent={
        <>
          <div className="space-x-2 ml-6 text-right pr-10 hidden lg:block">
            {buttonDateFilter.map((item, index) => (
              <button
                key={index + item.text}
                onClick={() => handleChangeTimestamp(item.value)}
                className={clsx(
                  "h-8 px-3 rounded align-middle !font-medium",
                  dateFilterActive === item.value
                    ? "text-blue bg-blue-5 cursor-default"
                    : "hover:bg-blue-5"
                )}
              >
                {item.text}
              </button>
            ))}
          </div>
        </>
      }
    >
      <div>
        <div className="space-x-2 text-center lg:hidden bg-white -mt-2 shadow-underline pb-3">
          {buttonDateFilter.map((item, index) => (
            <button
              key={index + item.text}
              onClick={() => handleChangeTimestamp(item.value)}
              className={clsx(
                "h-8 px-3 rounded align-middle !font-medium",
                dateFilterActive === item.value
                  ? "text-blue bg-blue-5 cursor-default"
                  : "hover:bg-blue-5"
              )}
            >
              {item.text}
            </button>
          ))}
        </div>
        <div className="p-3">
          <div className="xl:min-h-[14.7rem] xl:h-[calc((100vh-27.7rem)/2)]">
            <CustomScrollbar
              renderView={(props) => (
                <div {...props} className="!static xl:!absolute" />
              )}
            >
              <Table>
                <thead className="hidden lg:table-header-group">
                  <tr>
                    <Th className="hidden lg:table-cell">Order #</Th>
                    <Th sort="ASC">Delivery date</Th>
                    <Th sort="DESC">Supplier</Th>
                    <Th className="hidden lg:table-cell">Status</Th>
                    <Th className="text-right hidden lg:table-cell">Total</Th>
                    <Th className="w-20"></Th>
                  </tr>
                </thead>

                {pendingOrdersList?.items &&
                  Boolean(pendingOrdersList?.items?.length) && (
                    <tbody className="bg-white">
                      {pendingOrdersList.items.map(
                        (item: dashBoardTableItem, i: number) => (
                          <tr
                            key={item.id + i}
                            className={clsx(
                              "text-sm font-medium text-neutral-90",
                              i % 2 && "lg:bg-neutral-5"
                            )}
                          >
                            <Td className="hidden lg:table-cell">
                              {item.purchaseDocumentNumber}
                            </Td>
                            <Td className="hidden lg:table-cell">
                              {item.deliveryDateFormmated}
                            </Td>
                            <Td
                              className={clsx(
                                "px-0 lg:px-3",
                                i &&
                                  "border-t lg:border-0 border-neutral-20 mt-1"
                              )}
                            >
                              {/* Desktop  */}
                              <div className="hidden lg:flex items-center">
                                {item.supplierTradingName}{" "}
                                {item.isAccountVerified && (
                                  <div className="inline-flex">
                                    <Icon.CheckedFill
                                      size="12"
                                      className="ml-1.5 fill-blue"
                                    />
                                  </div>
                                )}
                              </div>

                              {/* Small screen */}
                              <div className="flex lg:hidden">
                                <div
                                  className={
                                    "w-9 h-9 lg:w-[3.25rem] lg:h-[3.25rem] rounded bg-status-bg-warning flex justify-center items-center mr-3"
                                  }
                                >
                                  <Icon.Cart
                                    size="16"
                                    className="fill-status-warning"
                                  />
                                </div>
                                <div className="flex-1">
                                  <div className="flex justify-between">
                                    <div className="flex items-center">
                                      {item.customerTradingName}{" "}
                                      {item.isAccountVerified && (
                                        <div className="inline-flex">
                                          <Icon.CheckedFill
                                            size="12"
                                            className="ml-1.5 fill-blue"
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <Common.Label
                                      className="ml-3"
                                      style={{
                                        backgroundColor:
                                          item.statusColor.bgColor,
                                        color: item.statusColor.textColor,
                                      }}
                                    >
                                      {item.status}
                                    </Common.Label>
                                  </div>
                                  <div className="flex mt-1">
                                    <Icon.Calendar
                                      size="16"
                                      className="fill-neutral-50 mr-2"
                                    />
                                    {item.deliveryDateFormmated}
                                  </div>
                                  <div className="flex justify-between mt-1">
                                    <div className="flex">
                                      <Icon.List
                                        size="16"
                                        className="fill-neutral-50 mr-2"
                                      />
                                      #{item.saleDocumentNumber}
                                    </div>
                                    <div>{item.totalFomatted}</div>
                                  </div>
                                </div>
                              </div>
                            </Td>
                            <Td className="hidden lg:table-cell">
                              <Common.Label
                                style={{
                                  backgroundColor: item.statusColor.bgColor,
                                  color: item.statusColor.textColor,
                                }}
                              >
                                {item.status}
                              </Common.Label>
                            </Td>
                            <Td className="text-right hidden lg:table-cell">
                              {item.totalFomatted}
                            </Td>
                            <Td>
                              <RedirectToOUI
                                to={`/Purchases/Documents/Invoices/InvoiceDetail?id=${item.id}`}
                              >
                                <Common.Button btSize="sm">View</Common.Button>
                              </RedirectToOUI>
                            </Td>
                          </tr>
                        )
                      )}
                    </tbody>
                  )}
              </Table>
              {!(pendingOrdersList?.items as any)?.length && !isLoading && (
                <div className="h-[calc(100%-39px)]">
                  <Common.NoData title="No pending credit data." />
                </div>
              )}
              {isLoading && pendingOrdersList?.items === undefined && (
                <div className="h-[calc(100%-39px)]">
                  <Common.NoData title="Loading..." />
                </div>
              )}
            </CustomScrollbar>
          </div>
          {/* <div className="flex lg:items-center flex-col lg:flex-row border-t border-neutral-20 pt-4">
            <div className="flex items-center">
              <Common.Selectbox
                wrapClassName="mr-2"
                className="!text-blue !border-blue"
                value={pageSize}
                onChange={handlePageSizeChange}
                options={recordsPerPage}
              />{" "}
              of{" "}
              <span className="font-medium mx-1">
                {pendingOrdersList?.total || 0}
              </span>{" "}
              orders
            </div>
            <div className="mt-3 mx-auto lg:ml-auto lg:mt-0 lg:mr-0">
              <Pagination
                pageNumber={pendingOrdersList?.pageNumber || 0}
                pageSize={pendingOrdersList?.pageSize || 0}
                total={pendingOrdersList?.total || 0}
                pageTotal={pendingOrdersList?.pageTotal || 0}
                pagerCenterPoint={pendingOrdersList?.pagerCenterPoint || 0}
                startPage={pendingOrdersList?.startPage || 0}
                onPageClick={(page: number) => setPageNumber(page)}
              />
            </div>
          </div> */}
        </div>
      </div>
    </Card>
  );
};

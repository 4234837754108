import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath={`url(#clip${uid})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8 7.20005C11.0091 7.20005 12.8 5.40919 12.8 3.20005C12.8 3.07416 12.7942 2.94963 12.7828 2.82671H14.6797C14.7583 2.82671 14.8358 2.84503 14.9061 2.8802C14.9764 2.91538 15.0375 2.96646 15.0846 3.02938C15.1345 3.08999 15.1696 3.16135 15.1871 3.23785C15.2047 3.31434 15.2043 3.39388 15.1859 3.47018L13.1611 10.5635C13.1305 10.6731 13.0639 10.7692 12.9721 10.8363C12.8802 10.9034 12.7685 10.9376 12.6549 10.9334H4.55577C4.44215 10.9376 4.33043 10.9034 4.2386 10.8363C4.14678 10.7692 4.08019 10.6731 4.04957 10.5635L2.05516 3.51578L1.3971 2.19845L1.01239 1.81338H0.506196C0.371945 1.81338 0.243192 1.76 0.148261 1.66498C0.0533312 1.56996 0 1.44109 0 1.30672C0 1.17234 0.0533312 1.04347 0.148261 0.948448C0.243192 0.85343 0.371945 0.800049 0.506196 0.800049H1.51859C1.61207 0.800332 1.70364 0.826519 1.78316 0.875706C1.86268 0.924893 1.92704 0.995159 1.9691 1.07872L2.84482 2.82671H4.81719C4.80582 2.94963 4.8 3.07416 4.8 3.20005C4.8 5.40919 6.59086 7.20005 8.8 7.20005Z"
        />
        <path d="M12.655 11.44H4.55585C4.4216 11.44 4.29284 11.4934 4.19791 11.5884C4.10298 11.6835 4.04965 11.8123 4.04965 11.9467C4.04965 12.0811 4.10298 12.21 4.19791 12.305C4.29284 12.4 4.4216 12.4534 4.55585 12.4534H12.655C12.7892 12.4534 12.918 12.4 13.0129 12.305C13.1079 12.21 13.1612 12.0811 13.1612 11.9467C13.1612 11.8123 13.1079 11.6835 13.0129 11.5884C12.918 11.4934 12.7892 11.44 12.655 11.44Z" />
        <path d="M5.56824 16.0001C6.40693 16.0001 7.08683 15.3196 7.08683 14.4801C7.08683 13.6406 6.40693 12.9601 5.56824 12.9601C4.72955 12.9601 4.04965 13.6406 4.04965 14.4801C4.04965 15.3196 4.72955 16.0001 5.56824 16.0001Z" />
        <path d="M11.6426 16.0001C12.4813 16.0001 13.1612 15.3196 13.1612 14.4801C13.1612 13.6406 12.4813 12.9601 11.6426 12.9601C10.8039 12.9601 10.124 13.6406 10.124 14.4801C10.124 15.3196 10.8039 16.0001 11.6426 16.0001Z" />
        <circle cx="8.80001" cy="3.2" r="3.2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.3 4.99999C8.3 5.27614 8.52386 5.49999 8.8 5.49999C9.07614 5.49999 9.3 5.27614 9.3 4.99999V3.69999H10.6C10.8761 3.69999 11.1 3.47614 11.1 3.19999C11.1 2.92385 10.8761 2.69999 10.6 2.69999H9.3V1.39999C9.3 1.12385 9.07614 0.899994 8.8 0.899994C8.52386 0.899994 8.3 1.12385 8.3 1.39999V2.69999H7C6.72386 2.69999 6.5 2.92385 6.5 3.19999C6.5 3.47614 6.72386 3.69999 7 3.69999H8.3V4.99999Z"
        />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="16" height="16" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M11.2178 11.9551C11.0407 11.8741 10.9278 11.6961 10.9278 11.5001L10.9278 9.50008H4.49485C4.22169 9.50008 4 9.27608 4 9.00008V7.00008C4 6.72408 4.22169 6.50008 4.49485 6.50008L10.9278 6.50008V4.50008C10.9278 4.30408 11.0417 4.12608 11.2178 4.04508C11.395 3.96308 11.6018 3.99508 11.7483 4.12408L15.7071 7.62408C15.8149 7.71908 15.8763 7.85608 15.8763 8.00008C15.8763 8.14408 15.8139 8.28108 15.7071 8.37608L11.7483 11.8761C11.6028 12.0061 11.395 12.0361 11.2178 11.9551Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 0C1.77614 0 2 0.223858 2 0.5L2 15.5C2 15.7761 1.77614 16 1.5 16H0.5C0.223858 16 0 15.7761 0 15.5L0 0.5C0 0.223858 0.223858 0 0.5 0H1.5Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { Common, Icon } from "@/components";
import clsx from "clsx";
import { useState } from "react";

interface Props {
  className?: string;
}

export const EditTabAdvanced = ({ className }: Props) => {
  const [isEdit, setEdit] = useState(false);

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { name, value } = e.target;
    console.log(name, ": ", value);
  };

  return (
    <div className={clsx("flex flex-col h-full", className)}>
      <div className="flex-1 relative">
        <div className="absolute top-0 left-0 w-full h-full overflow-y-auto">
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold mb-1.5 text-hd5">Billing Name</div>
              <div className="text-neutral-60 max-w-[16.25rem]">
                Complete business profile are having better visibility on our
                system.
              </div>
            </div>
            <div className="flex-1">
              <div className="font-semibold mb-1.5">Customer billing name</div>
              <div className="mb-5">That Armore Cheese</div>

              <div className="font-semibold mb-1.5">My billing name</div>
              <div className="mb-5">
                <Common.Input value="ABC bakery" />
              </div>
              <div className="font-semibold mb-1.5">
                Order delegation{" "}
                <span data-tip="Order email">
                  <Icon.InfoFill
                    size="12"
                    className="ml-2 fill-neutral-50 inline"
                  />
                </span>
              </div>
              <div className="mb-5">
                <Common.Label color="blue">Allowed</Common.Label>
              </div>
              <div className="font-semibold mb-1.5">
                Customer locked price{" "}
                <span data-tip="Allocate this customer to a pre-set price list">
                  <Icon.InfoFill
                    size="12"
                    className="ml-2 fill-neutral-50 inline"
                  />
                </span>
              </div>
              <div>
                <Common.Selectbox
                  name="SelectBox"
                  onChange={handleSelectChange}
                  options={[
                    { id: 1, text: "Default", value: 1 },
                    { id: 2, text: "Gold Pricing", value: 2 },
                    { id: 3, text: "Sliver Pricing", value: 3 },
                    { id: 4, text: "Bronze Pricing", value: 4 },
                    { id: 5, text: "Default Pricing", value: 5 },
                  ]}
                />
              </div>
              <div className="mt-2">
                <strong>12</strong> products are locked prices.{" "}
                <a href="#" className="text-blue">
                  Click here to manage
                </a>
              </div>
            </div>
          </div>
          <div className="border-b border-neutral-20 my-8"></div>
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold text-hd5">Pricing Adjustment</div>
              <div className="text-neutral-60 max-w-[16.25rem]">
                Complete business profile are having better visibility on our
                system.
              </div>
            </div>
            <div className="flex-1">
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <div className="font-semibold mb-1.5">
                    Discount %{" "}
                    <span data-tip="Order email">
                      <Icon.InfoFill
                        size="12"
                        className="ml-2 fill-neutral-50 inline"
                      />
                    </span>
                  </div>
                  <div>
                    <Common.Input />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="font-semibold mb-1.5">Surcharge %</div>
                  <div>
                    <Common.Input />
                  </div>
                  <div className="mt-3">
                    <Common.Checkbox
                      label="Only apply to parent list"
                      id="parentList"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-neutral-20 my-8"></div>
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold text-hd5">Pricing Adjustment</div>
              <div className="text-neutral-60 max-w-[16.25rem]">
                Complete business profile are having better visibility on our
                system.
              </div>
            </div>
            <div className="flex-1">
              <div className="mb-5">
                <Common.Checkbox
                  label="Inherit from global settings"
                  id="parentList"
                />
              </div>
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <div className="font-semibold mb-1.5">
                    Minimum Order Amount{" "}
                    <span data-tip="Order email">
                      <Icon.InfoFill
                        size="12"
                        className="ml-2 fill-neutral-50 inline"
                      />
                    </span>
                  </div>
                  <div>
                    <Common.Input />
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="font-semibold mb-1.5">
                    Delivery Fee{" "}
                    <span data-tip="Order email">
                      <Icon.InfoFill
                        size="12"
                        className="ml-2 fill-neutral-50 inline"
                      />
                    </span>
                  </div>
                  <div>
                    <Common.Input />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-neutral-20 my-8"></div>
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold text-hd5">Pick / Pack Settings</div>
              <div className="text-neutral-60 max-w-[16.25rem]">
                Complete business profile are having better visibility on our
                system.
              </div>
            </div>

            <div className="flex-1">
              <div className="font-semibold mb-1.5">
                Partial despatch allowed{" "}
                <span data-tip="Order email">
                  <Icon.InfoFill
                    size="12"
                    className="ml-2 fill-neutral-50 inline"
                  />
                </span>
              </div>
              <div className="flex space-x-8">
                <Common.Radio
                  label="Inherit (Yes)"
                  id="Inherit"
                  name="Partial"
                />
                <Common.Radio label="Yes" id="yes" name="Partial" />
                <Common.Radio label="No" id="no" name="Partial" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end space-x-2 pt-4">
        {isEdit ? (
          <>
            <Common.Button color="transparent" onClick={() => setEdit(false)}>
              Cancel
            </Common.Button>
            <Common.Button onClick={() => setEdit(false)}>
              Save changes
            </Common.Button>
          </>
        ) : (
          <Common.Button onClick={() => setEdit(true)}>Edit</Common.Button>
        )}
      </div>
    </div>
  );
};

import axios from "axios";
import { useMutation } from "react-query";
import { PantryList } from "../models/PantryList";

const API_PATH = "pantrylist";

export const useSupplierSearchProducts = (props: {
  controller: AbortController;
  setController: React.Dispatch<React.SetStateAction<AbortController>>;
}) =>
  useMutation(
    (data: {
      keywords: string;
      customerAccountId: string;
      productCategoryId: string | undefined;
    }) => {
      props.controller.abort();
      const newController = new AbortController();
      props.setController(newController);
      return axios
        .get(`${API_PATH}/SupplierSearchProducts`, {
          params: {
            keywords: data.keywords,
            customerAccountId: data.customerAccountId,
            productCategoryId: data.productCategoryId,
          },
          signal: newController.signal,
        })
        .then((res) => {
          if (res.data.errorMessage) {
            return res.data.errorMessage;
          }
          return res.data.data.products;
        })
        .catch((reason) => Promise.reject(reason.response));
    }
  );

export const useTryEdit = () =>
  useMutation((id: string) =>
    axios
      .post(`${API_PATH}/tryedit`, { id })
      .then((res) => {
        return res.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useEndEdit = () =>
  useMutation((id: string) =>
    axios
      .post(`${API_PATH}/endedit`, { id })
      .then((res) => {
        return res.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useSavePantryList = () =>
  useMutation((data: PantryList) =>
    axios
      .post(`${API_PATH}/save`, data)
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetHistoryLogs = () =>
  useMutation(
    (data: {
      id: string;
      customerAccountId: string;
      supplierAccountId: string;
    }) =>
      axios
        .get(`${API_PATH}/GetHistoryLogs`, {
          params: {
            id: data.id,
            customerAccountId: data.customerAccountId,
            supplierAccountId: data.supplierAccountId,
          },
        })
        .then((res) => {
          if (res.data.errorMessage) {
            return res.data;
          }
          return res.data.data.historyLogs;
        })
        .catch((reason) => Promise.reject(reason.response))
  );

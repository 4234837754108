/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { useResizeDetector } from "react-resize-detector";
import { Common, Icon, ModalInform, CustomScrollbar } from "@/components";
import { SnackbarUtils } from "@/utils";
import {
  DeliveryLineStatusValue,
  TIME_FORMAT,
} from "@/configuration/globalVariable";
import { useAppDispatch } from "@/sharedStore/hooks";
import { EditRouteItem, EditRouteCol } from ".";
import {
  manifest,
  manifestDetailETA,
  manifestLine,
  route,
} from "../models/DeliveryManifest";
import {
  checkCompletedDocumentPosition,
  refillManifestLinePosition,
  reorderManifestLines,
} from "../utils";
import { ModalMoveRouteEditScreen } from "./ModalMoveRouteEditScreen";
import { ModalChangePosition } from "./ModalChangePosition";
import {
  useSaveEditingRoutes,
  useGetUnallocatedList,
  useGetManifestDetailByRoute,
  getManifestDetailTimeETA,
} from "../services/deliveryManifest";
import { clearManifest } from "../providers/deliveryManifest/slice";
import { destination } from "../models";
import ReactTooltip from "react-tooltip";
import { useGetRouteDetailByRouteId } from "../providers/deliveryManifest/hooks";

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: manifestLine[],
  destination: manifestLine[],
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",
  // styles we need to apply on draggables
  ...draggableStyle,
});

interface Props {
  routeNumber: (num: number) => void;
  setEditing: (isEditing: boolean) => void;
}

export function EditRoutesListTab({ routeNumber, setEditing }: Props) {
  const { ref } = useResizeDetector();
  const getRouteDetailById = useGetRouteDetailByRouteId();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [routeETATempIdIsLoading, setRouteETATempIdIsLoading] = useState<
    string[]
  >([]);
  const [routes, setRoutes] = useState((location.state as any)?.routesActive);
  // const [routeDetail, setRouteDetail] = useState<manifest | any>();
  const [routeIdsChanged, setRouteIdsChanged] = useState<string[]>([]);
  const [routeIdsToSave, setRouteIdsToSave] = useState<string[]>([]);
  const [routesToAdd, setRoutesToAdd] = useState([]);
  const [positionChangedToHighlight, setPositionChangedToHighlight] =
    useState<destination>();
  const isDisplayUnallocateList = (location.state as any)?.unallocate;
  const deliveryDate = (location.state as any)?.deliveryDate;
  const [unallocateList, setUnallocateList] = useState<manifestLine[]>([]);
  const [addRouteSelect, setAddRouteSelect] = useState<string>();
  const [isAddCol, setIsAddCol] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isApplyForFuture, setIsApplyForFuture] = useState<boolean>(false);
  const [isCantSaveInform, setIsCantSaveInform] = useState<boolean>(false);
  const date = moment(deliveryDate).format(TIME_FORMAT.REQUEST);

  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorderManifestLines(
        routes[sInd].manifestLines,
        source.index,
        destination.index
      );
      const newState: manifest[] = [...routes];
      newState[sInd].manifestLines = items;
      addEditRoute([newState[sInd].id]);
      handleSetRoute(newState);
    } else {
      const result = move(
        routes[sInd].manifestLines,
        routes[dInd].manifestLines,
        source,
        destination
      );
      const newState: manifest[] = [...routes];
      newState[sInd].manifestLines = refillManifestLinePosition(
        result[sInd],
        newState[sInd].id
      );
      newState[dInd].manifestLines = refillManifestLinePosition(
        result[dInd],
        newState[dInd].id
      );
      addEditRoute([newState[sInd].id, newState[dInd].id]);
      handleSetRoute(newState);
    }
    setPositionChangedToHighlight(destination);
  };

  const addEditRoute = (rs: string[]) => {
    setRouteIdsChanged(
      rs.filter((id) => routeIdsChanged.indexOf(id) < 0).concat(routeIdsChanged)
    );
    setRouteIdsToSave(
      rs.filter((id) => routeIdsToSave.indexOf(id) < 0).concat(routeIdsToSave)
    );
  };

  useEffect(() => {
    setEditing(Boolean(routeIdsChanged.length));
  }, [routeIdsToSave]);

  const fetchETAToReview = () => {
    if (routeIdsChanged && routeIdsChanged.length) {
      setRouteETATempIdIsLoading(routeIdsChanged);
      getManifestDetailTimeETA(
        routes.filter((r: manifest) => routeIdsChanged.includes(r.id))
      )
        .then((res: manifestDetailETA[]) => {
          const newRoutes = routes.map((route: manifest) => {
            const etaItem = res.find(
              (eta: manifestDetailETA) => eta && eta.manifestId === route.id
            );
            if (etaItem) {
              return {
                ...route,
                manifestLines: route.manifestLines.map(
                  (manifestLine: manifestLine) => {
                    const mflETA = etaItem.manifestLine.find(
                      (mflETA) => mflETA.id === manifestLine.id
                    );
                    if (mflETA) {
                      return {
                        ...manifestLine,
                        ...mflETA,
                      };
                    }
                    return manifestLine;
                  }
                ),
              };
            }
            return route;
          });
          setRoutes(newRoutes);
          // setRouteDetail(newRoutes);

          const ids = res.map((id) => id?.manifestId);
          const idsLoaded = routeETATempIdIsLoading.filter(
            (id: string) => !ids.includes(id)
          );
          setRouteETATempIdIsLoading(idsLoaded);
          setRouteIdsChanged(idsLoaded);
          SnackbarUtils.success("Estimated times have been updated.");
        })
        .catch(() => {
          setRouteETATempIdIsLoading([]);
        });
    }
  };

  // Get manifest by route
  const getManifestDetailByRoute = useGetManifestDetailByRoute();
  const {
    data: getManifestDetailByRouteData,
    isSuccess: getManifestDetailByRouteIsSuccess,
    isLoading: getManifestDetailByRouteIsLoading,
  } = getManifestDetailByRoute;

  const handleAddRouteSelect = (route: route) => {
    setAddRouteSelect(route.id);
  };

  const handleFetchAndAddRoute = (addRouteSelectDetail: manifest) => {
    const newRoutesAdded = [...routes, addRouteSelectDetail];
    routeNumber(
      isDisplayUnallocateList
        ? newRoutesAdded.slice(1).length
        : newRoutesAdded.length
    );
    handleSetRoute(newRoutesAdded);
    setRoutesToAdd(
      routesToAdd.filter(
        (routesAdd: route) => routesAdd.id !== addRouteSelectDetail.route?.id
      )
    );
    handleCancelAddRouteToEdit();
  };

  useEffect(() => {
    if (getManifestDetailByRouteIsSuccess && getManifestDetailByRouteData[0]) {
      const addRouteSelectDetail = getManifestDetailByRouteData[0];
      handleFetchAndAddRoute(addRouteSelectDetail);
    }
    // eslint-disable-next-line
  }, [getManifestDetailByRouteIsSuccess]);

  const handleAddRouteToEdit = () => {
    if (addRouteSelect) {
      const checkExistingRoute = getRouteDetailById(addRouteSelect);
      if (checkExistingRoute) {
        handleFetchAndAddRoute({ ...checkExistingRoute });
      } else {
        addRouteSelect &&
          getManifestDetailByRoute.mutate({ date, routeId: addRouteSelect });
      }
    }
  };

  const handleCancelAddRouteToEdit = () => {
    setIsAddCol(false);
    setAddRouteSelect(undefined);
  };

  const results = useMemo(
    () =>
      routesToAdd.length
        ? routesToAdd.filter((item: route) => {
            if (
              item.name &&
              item.name.toUpperCase().indexOf(searchKeyword.toUpperCase()) > -1
            )
              return true;
            return false;
          })
        : [],
    [searchKeyword, routesToAdd]
  );

  // Handle Save
  const saveEditingRoutes = useSaveEditingRoutes();
  const {
    isSuccess: saveEditSuccess,
    isLoading: editIsSaving,
    data: saveEditingData,
  } = saveEditingRoutes;

  const handleSaveEditing = () => {
    // Remove unallocate list in display allocate column
    const routesTosave = routes
      .filter((r: manifest) => routeIdsToSave.includes(r.id))
      .map((manifestItem: manifest) => {
        return {
          ...manifestItem,
          isApplyFuture: isApplyForFuture,
          polylines: "",
          manifestLines: manifestItem.manifestLines.map(
            (mnfLine: manifestLine) => {
              return {
                ...mnfLine,
                isApplyFuture: isApplyForFuture,
                manifestId: manifestItem.id,
              };
            }
          ),
        };
      });
    // Filter unallocate list
    saveEditingRoutes.mutate(routesTosave.filter((r: manifest) => r.id));
  };

  useEffect(() => {
    if (saveEditSuccess) {
      if (!saveEditingData.success) {
        setIsCantSaveInform(true);
        return;
      }
      // Clear to refetch manifest after back page
      dispatch(clearManifest());
      const unallocated = isDisplayUnallocateList
        ? routes[0].manifestLines.length
        : unallocateList.length;
      SnackbarUtils.success(
        unallocated
          ? `Routes have been saved and ${unallocated} order${
              unallocated ? "s" : ""
            } unallocated.`
          : `Routes have been saved.`
      );
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [saveEditSuccess]);
  // End handle save

  // handle move dropdown menu

  const [manifestLineUnallocate, setManifestLineUnallocate] =
    useState<manifestLine>({} as manifestLine);
  const [moveModal, setMoveModal] = useState<manifestLine>({} as manifestLine);
  const [manifestCurrent, setManifestCurrent] = useState<manifest>(
    {} as manifest
  );
  const [manifestLineChangePosition, setManifestLineChangePosition] =
    useState<manifestLine>();

  const removeManifestLine = (
    route: manifest,
    manifestLineRemove: manifestLine
  ) => {
    const newManifestLineFilter = route.manifestLines.filter(
      (manifestLine) => manifestLine.id !== manifestLineRemove.id
    );
    return newManifestLineFilter.map((r, index) => {
      return {
        ...r,
        position: index,
      };
    });
  };

  const handleUnllocate = () => {
    console.log("EditRoutesListTab");
    if (manifestLineUnallocate) {
      if (!isDisplayUnallocateList) {
        const list = [...unallocateList, manifestLineUnallocate];
        setUnallocateList(list);
      }
      let tempRoute = {} as manifest;
      handleSetRoute(
        routes.map((route: manifest, index: number) => {
          if (isDisplayUnallocateList && !index) {
            return (tempRoute = {
              ...route,
              manifestLines: route.manifestLines.concat([
                manifestLineUnallocate,
              ]),
            });
          }
          if (route.id === manifestLineUnallocate.manifestId) {
            return (tempRoute = {
              ...route,
              manifestLines: removeManifestLine(route, manifestLineUnallocate),
            });
          }
          return route;
        })
      );
      addEditRoute([tempRoute.id]);
    }
    setManifestLineUnallocate({} as manifestLine);
  };

  const handleSethighlightPositionItem = (
    manifestId?: string | null,
    position?: number
  ) => {
    position !== undefined &&
      routes.some((r: manifest, i: number) => {
        const isMatch = r.id === manifestId;
        if (isMatch) {
          setPositionChangedToHighlight({
            droppableId: i.toString(),
            index:
              position < 0
                ? 0
                : position > r.manifestLines.length
                ? r.manifestLines.length
                : position,
          });
        }
        return isMatch;
      });
  };

  const handleChangePosition = (position: number) => {
    if (
      manifestLineChangePosition &&
      manifestLineChangePosition?.position >= 0
    ) {
      const manifestChangePosition = routes.find(
        (mnf: manifest) => mnf.id === manifestLineChangePosition.manifestId
      );
      if (checkCompletedDocumentPosition(manifestChangePosition, position)) {
        return SnackbarUtils.error(
          "This position is delivered. Please enter another position number."
        );
      }

      handleSethighlightPositionItem(
        manifestLineChangePosition?.manifestId,
        position
      );
      const newRoutes = routes.map((route: manifest) => {
        if (route.id === manifestLineChangePosition.manifestId) {
          const newRouteObj = {
            ...route,
            manifestLines: reorderManifestLines(
              route.manifestLines,
              manifestLineChangePosition?.position,
              position
            ),
          };
          addEditRoute([newRouteObj.id]);
          return newRouteObj;
        }
        return route;
      });
      handleSetRoute(newRoutes);

      setManifestLineChangePosition({} as manifestLine);
    }
  };

  const handleMoveManifestLine = (
    manifestTarget?: manifest,
    position?: string,
    manifestLineMoving?: manifestLine
  ) => {
    const positionNumber = (
      position ? parseInt(position) - 1 : undefined
    ) as number;
    const routeChanged = [] as manifest[];
    const handleMovetoTarget = (manifest: manifest) => {
      const manifestLines = [...manifest.manifestLines];
      if (positionNumber >= 0 && manifestLineMoving) {
        manifestLines.splice(positionNumber, 0, manifestLineMoving);
      }
      const routeObj = {
        ...manifest,
        manifestLines: refillManifestLinePosition(
          manifestLines,
          manifestTarget?.id
        ),
      };
      routeChanged.push(routeObj);
      return routeObj;
    };

    if (manifestLineMoving && positionNumber >= 0 && manifestTarget) {
      if (checkCompletedDocumentPosition(manifestTarget, positionNumber)) {
        return SnackbarUtils.error(
          "This position is delivered. Please enter another position number."
        );
      }

      const newRoutes = routes.map((route: manifest) => {
        // remove manifestline item
        if (route.id === manifestCurrent.id) {
          const routeObj = {
            ...route,
            manifestLines: removeManifestLine(route, manifestLineMoving),
          };
          routeChanged.push(routeObj);
          return routeObj;
        }
        // Add manifestline
        if (route.id === manifestTarget.id) {
          return handleMovetoTarget(route);
        }
        return route;
      });

      addEditRoute(routeChanged.map((r: manifest) => r.id));
      handleSetRoute(newRoutes);
      positionNumber >= 0 &&
        handleSethighlightPositionItem(manifestTarget?.id, positionNumber);
      setMoveModal({} as manifestLine);
    }
  };
  // end handle move dropdown menu

  // Unallocate
  const getUnallocatedList = useGetUnallocatedList();
  const {
    data: unallocatedDocument,
    isLoading: getUnallocatedListLoading,
    isSuccess: getUnallocatedListSuccess,
  } = getUnallocatedList;

  useEffect(() => {
    // Get unallocate documents list and route to add list
    getUnallocatedList.mutate(date);

    return () => {
      ReactTooltip.hide();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getUnallocatedListSuccess) {
      ReactTooltip.rebuild();
      if (isDisplayUnallocateList) {
        if (routes.length == 1) {
          handleSetRoute([
            {
              manifestLines: unallocatedDocument?.unAllocatedDocs || [],
            },
          ]);
        } else {
          handleSetRoute((revRoute: manifest[]) =>
            revRoute.map((route: manifest, index: number) => {
              if (!index) {
                return {
                  manifestLines: unallocatedDocument?.unAllocatedDocs || [],
                };
              }
              return route;
            })
          );
        }
      }
      const filterRoutes =
        ((location.state as any)?.routesUnActive &&
          (location.state as any)?.routesUnActive.map(
            (filterRoutes: manifest) => filterRoutes.route.id
          )) ||
        [];
      setRoutesToAdd(
        unallocatedDocument.routes.filter(
          (r: route) => !filterRoutes.includes(r.id)
        )
      );
    }
    // eslint-disable-next-line
  }, [getUnallocatedListSuccess]);
  // End Unallocate

  // optimize performance
  const handleChangePositionClick = useCallback(
    (manifestLine: manifestLine) => setManifestLineChangePosition(manifestLine),
    []
  );

  const handleMoveToAnotherRouteClick = useCallback(
    (manifestLine: manifestLine, route: manifest) => {
      setMoveModal(manifestLine);
      setManifestCurrent(route);
    },
    []
  );

  const handleUnallocateClick = useCallback((manifestLine: manifestLine) => {
    setManifestLineUnallocate(manifestLine);
  }, []);

  const handleSetRoute = useCallback((rs: manifest[] | any) => {
    setRoutes(rs);
  }, []);
  // optimize performance

  return (
    <>
      {Boolean(routeETATempIdIsLoading.length) && (
        <div className="absolute top-0 left-0 w-full h-full z-50 bg-black bg-opacity-40 flex justify-center flex-col items-center rounded">
          <Icon.Loading size="48" className="mb-4" />
          <div className="font-semibold text-hd4 text-white text-center">
            Updating estimated times of your routes.
            <br />
            It will take a few seconds, please wait
          </div>
        </div>
      )}
      <CustomScrollbar>
        <div className="space-x-4 flex" ref={ref}>
          {routes && routes.length && (
            <DragDropContext onDragEnd={onDragEnd}>
              {routes.map((route: manifest, ind: number) => {
                return (
                  // unallocate list will be first col in routes list
                  <EditRouteCol
                    key={ind + route.id}
                    data={route}
                    unallocate={isDisplayUnallocateList && !ind}
                    data-route-id={route.id}
                    data-manifest-id={route.routeId}
                  >
                    <Droppable droppableId={`${ind}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={clsx(
                            "min-h-full relative rounded",
                            !isEmpty(route.manifestLines) && "p-2",
                            snapshot.isDraggingOver &&
                              "bg-blue-10 relative before:content-[''] before:absolute",
                            snapshot.isDraggingOver &&
                              "before:top-0.5 before:bottom-0.5 before:left-0.5 before:right-0.5 before:rounded",
                            snapshot.isDraggingOver &&
                              "before:border before:border-dashed before:border-neutral-40"
                          )}
                          data-isdraggingOver={snapshot.isDraggingOver}
                        >
                          {!isEmpty(route.manifestLines) ? (
                            route.manifestLines.map(
                              (manifestLine: manifestLine, index: number) => {
                                const isCompleted =
                                  manifestLine.deliveryStatus ===
                                  DeliveryLineStatusValue.COMPLETED;
                                return isCompleted ? (
                                  <div
                                    className={clsx(index && "mt-2")}
                                    key={index + manifestLine.id}
                                    data-manifestline-id={manifestLine.id}
                                    data-manifest-id={manifestLine.manifestId}
                                  >
                                    <EditRouteItem
                                      isUnllocatedItem={
                                        isDisplayUnallocateList && !ind
                                      }
                                      data={manifestLine}
                                      route={route}
                                      index={index + 1}
                                      onChangePositionClick={
                                        handleChangePositionClick
                                      }
                                      onMoveToAnotherRouteClick={
                                        handleMoveToAnotherRouteClick
                                      }
                                      onUnallocateClick={handleUnallocateClick}
                                      col={ind}
                                      positionChangedToHighlight={
                                        positionChangedToHighlight
                                      }
                                      isRefreshETATemp={routeETATempIdIsLoading.includes(
                                        route.id
                                      )}
                                      isCompleted={isCompleted}
                                      routeStatus={route.status}
                                    />
                                  </div>
                                ) : (
                                  <Draggable
                                    key={index + manifestLine.id}
                                    draggableId={manifestLine.id}
                                    index={index}
                                    isDragDisabled={isCompleted}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                        className={clsx(index && "mt-2")}
                                        data-manifestline-id={manifestLine.id}
                                        data-manifest-id={
                                          manifestLine.manifestId
                                        }
                                      >
                                        <EditRouteItem
                                          isUnllocatedItem={
                                            isDisplayUnallocateList && !ind
                                          }
                                          data={manifestLine}
                                          route={route}
                                          index={index + 1}
                                          className={clsx(
                                            snapshot.isDragging &&
                                              "bg-blue-5 !border-blue"
                                          )}
                                          onChangePositionClick={
                                            handleChangePositionClick
                                          }
                                          onMoveToAnotherRouteClick={
                                            handleMoveToAnotherRouteClick
                                          }
                                          onUnallocateClick={
                                            handleUnallocateClick
                                          }
                                          col={ind}
                                          positionChangedToHighlight={
                                            positionChangedToHighlight
                                          }
                                          isRefreshETATemp={routeETATempIdIsLoading.includes(
                                            route.id
                                          )}
                                          isCompleted={isCompleted}
                                          routeStatus={route.status}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              }
                            )
                          ) : (
                            // Handle unallocate list message status
                            <>
                              {isDisplayUnallocateList && !ind ? (
                                <>
                                  {getUnallocatedListLoading ? (
                                    <div className="h-full w-full absolute">
                                      <Common.NoData
                                        wrapIconClassName="!bg-white"
                                        iconRender={Icon.Loading}
                                        title={
                                          "Unallocate document is loading..."
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <div className="h-full w-full absolute">
                                      <Common.NoData
                                        wrapIconClassName="!bg-white"
                                        iconRender={Icon.List}
                                        title={
                                          "Great! You don't have unallocated orders yet"
                                        }
                                      />
                                    </div>
                                  )}
                                </>
                              ) : (
                                // Handle normal route list message status
                                <div className="h-full w-full absolute">
                                  <Common.NoData
                                    wrapIconClassName="!bg-white"
                                    iconRender={Icon.List}
                                    title={
                                      "This route doesn't have any order yet."
                                    }
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </EditRouteCol>
                );
              })}
            </DragDropContext>
          )}
          <div className="w-[18rem] min-w-[18rem]">
            {!isAddCol ? (
              <div
                className="flex items-center justify-center text-blue font-medium rounded bg-neutral-10 hover:bg-blue-10 px-2.5 py-2 cursor-pointer whitespace-nowrap"
                onClick={() => setIsAddCol(true)}
              >
                <Icon.PlusBorder className="fill-blue mr-3" /> Add another route
              </div>
            ) : (
              <div className="px-4 py-2 bg-neutral-10">
                <div className="relative">
                  <Common.Input
                    iconRight={Icon.Search}
                    iconType="stroke"
                    placeholder="Search route name"
                    value={searchKeyword}
                    onChange={(e) => setSearchKeyword(e.target.value)}
                  />
                  <div className="absolute rounded border border-neutral-20 shadow w-full py-2 bg-white">
                    {results.length ? (
                      <div className="max-h-[11.875rem] overflow-y-auto">
                        {results.map((routeUnActive: route, index: number) => (
                          <div
                            key={routeUnActive.id}
                            className={clsx(
                              "flex items-center px-3 py-2 hover:bg-neutral-20",
                              index && "shadow-overline",
                              routeUnActive.id === addRouteSelect
                                ? "bg-neutral-20"
                                : "cursor-pointer"
                            )}
                            onClick={() => handleAddRouteSelect(routeUnActive)}
                          >
                            <div>
                              <Common.DotColor
                                style={{
                                  backgroundColor: routeUnActive.colour,
                                }}
                              />
                            </div>
                            <span className="ml-2 font-semibold text-sm text-neutral-80 line-clamp-1">
                              {routeUnActive.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {routesToAdd?.length && searchKeyword ? (
                          <Common.NoData
                            iconRender={Icon.Split}
                            title="No Route founded."
                          />
                        ) : (
                          <Common.NoData title="Empty route." />
                        )}
                      </div>
                    )}
                    <div className="flex justify-end px-4 pt-2 shadow-overline">
                      <Common.Button
                        color="transparent"
                        onClick={handleCancelAddRouteToEdit}
                      >
                        Cancel
                      </Common.Button>
                      <Common.Button
                        disabled={
                          !addRouteSelect || getManifestDetailByRouteIsLoading
                        }
                        isLoading={getManifestDetailByRouteIsLoading}
                        onClick={handleAddRouteToEdit}
                        className="ml-2"
                      >
                        Add
                      </Common.Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </CustomScrollbar>
      <div className="flex items-center justify-end pt-2">
        <div className="text-status-warning">
          Review the updated estimated time for the {routeIdsChanged.length}{" "}
          route(s) before applying the change.
        </div>
        <Common.Button
          color="transparent"
          onClick={fetchETAToReview}
          disabled={
            !routeIdsChanged ||
            !routeIdsChanged.length ||
            Boolean(routeETATempIdIsLoading.length)
          }
          isLoading={Boolean(routeETATempIdIsLoading.length)}
          className="mr-auto"
        >
          <span
            className={clsx(
              "flex items-center",
              !routeIdsChanged ||
                !routeIdsChanged.length ||
                Boolean(routeETATempIdIsLoading.length)
                ? "text-neutral-40"
                : "text-blue"
            )}
          >
            {!routeETATempIdIsLoading.length && (
              <Icon.EyeOpen
                className={clsx(
                  "mr-2",
                  !routeIdsChanged ||
                    !routeIdsChanged.length ||
                    Boolean(routeETATempIdIsLoading.length)
                    ? "fill-neutral-40"
                    : "fill-blue"
                )}
              />
            )}
            Review
          </span>
        </Common.Button>
        <Common.Checkbox
          id="applyFuture"
          name="applyFuture"
          label="Apply for the future"
          checked={isApplyForFuture}
          onChange={(e) => setIsApplyForFuture(e.target.checked)}
          disabled={!routeIdsToSave.length}
        />
        <Common.Button
          className="mx-4"
          onClick={handleSaveEditing}
          isLoading={editIsSaving}
          disabled={
            editIsSaving ||
            Boolean(routeETATempIdIsLoading.length) ||
            !routeIdsToSave.length
          }
        >
          Apply
        </Common.Button>
      </div>

      <ModalInform
        open={Boolean(manifestLineUnallocate?.id)}
        onClose={() => setManifestLineUnallocate({} as manifestLine)}
        onConfirm={handleUnllocate}
        confirmBtnText="Unallocate"
        title={`Are you sure you want to unallocate order #${manifestLineUnallocate?.document?.saleDocumentNumber}?`}
        type="error"
      />
      <ModalMoveRouteEditScreen
        manifestData={manifestCurrent}
        // Remove unallocate data in first index of route
        manifests={isDisplayUnallocateList ? routes.slice(1) : routes}
        manifestLineData={moveModal}
        open={Boolean(moveModal?.id)}
        onClose={() => setMoveModal({} as manifestLine)}
        onMoveItem={(target, position) =>
          handleMoveManifestLine(target, position, moveModal)
        }
      />
      <ModalChangePosition
        open={Boolean(manifestLineChangePosition?.id)}
        data={manifestLineChangePosition}
        onClose={() => setManifestLineChangePosition({} as manifestLine)}
        onSubmit={handleChangePosition}
      />
      <ModalInform
        open={isCantSaveInform}
        title={saveEditingData?.messageError}
        type="warning"
        onClose={() => {}}
        groupBtn={
          <div className="mt-8 flex justify-center">
            <Common.Button color="transparent" onClick={() => navigate(-1)}>
              Cancel changes
            </Common.Button>
            <Common.Button
              className="ml-4"
              onClick={() => setIsCantSaveInform(false)}
            >
              Save changes again
            </Common.Button>
          </div>
        }
      />
    </>
  );
}

import React, { useEffect } from "react";
import DatePickerComponent from "react-datepicker";
import ReactTooltip from "react-tooltip";

import { Common, Icon } from "@/components";

type Props = {
  onSelectDateRange: (startDate: Date | null, endDate: Date | null) => void;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  error?: boolean;
  errorMsg?: string;
};

const DoubleDatePicker = ({
  onSelectDateRange,
  startDate,
  endDate,
  error,
  errorMsg,
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  });

  const selectDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    onSelectDateRange(start, end);
  };

  const renderHeader = ({ monthDate, decreaseMonth, increaseMonth }: any) => {
    return (
      <div className="bg-white flex justify-between items-center p-4">
        <span onClick={decreaseMonth}>
          <Icon.AngleLeft className="stroke-neutral" />
        </span>
        <span className="text-neutral font-semibold">
          {monthDate.toLocaleString("en-US", {
            month: "short",
            year: "numeric",
          })}
        </span>
        <span onClick={increaseMonth}>
          <Icon.AngleRight className="stroke-neutral" />
        </span>
      </div>
    );
  };

  const customInput = () => {
    return (
      <Common.Input ipSize="md" iconRight={Icon.Calendar} isError={error} />
    );
  };

  return (
    <div>
      <DatePickerComponent
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={selectDate}
        monthsShown={2}
        renderCustomHeader={renderHeader}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        customInput={customInput()}
        placeholderText="Start date -> End date"
        minDate={new Date()}
        popperPlacement="bottom-end"
      />
      <div className="relative">
        {error && (
          <div
            className="absolute right-9 bottom-2 cursor-pointer"
            data-tip
            data-for="error-date-range"
          >
            <Icon.WarningCircle className="fill-red" />
          </div>
        )}
      </div>
      {error && errorMsg && (
        <ReactTooltip
          id="error-date-range"
          place="top"
          type="error"
          className="px-4 py-2"
        >
          {errorMsg}
        </ReactTooltip>
      )}
    </div>
  );
};

export default DoubleDatePicker;

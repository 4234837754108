import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M8.95229 1C7.17157 1 5.46378 1.70739 4.20462 2.96655C2.94546 4.22571 2.23807 5.9335 2.23807 7.71422H0.540318C0.340927 7.71422 0.241072 7.95529 0.382063 8.09628L2.90203 10.6162C2.92774 10.6677 2.99623 10.6788 3.03693 10.6382L5.58498 8.09647C5.72624 7.95556 5.62645 7.71422 5.42693 7.71422H3.73012C3.73012 4.8271 6.06518 2.49205 8.95229 2.49205C11.8394 2.49205 14.1745 4.8271 14.1745 7.71422C14.1745 10.6013 11.8394 12.9364 8.95229 12.9364C7.59325 12.9364 6.35385 12.4113 5.42857 11.5556C5.33756 11.4715 5.19569 11.4708 5.10805 11.5585L4.3654 12.3011C4.27816 12.3883 4.27771 12.53 4.36758 12.6145C4.95473 13.1667 5.63746 13.6086 6.38303 13.918C7.19737 14.2561 8.07058 14.4295 8.95229 14.4284C10.733 14.4284 12.4408 13.721 13.7 12.4619C14.9591 11.2027 15.6665 9.49494 15.6665 7.71422C15.6665 5.9335 14.9591 4.22571 13.7 2.96655C12.4408 1.70739 10.733 1 8.95229 1ZM8.43007 4.73012C8.30647 4.73012 8.20626 4.83032 8.20626 4.95393V8.33275C8.20626 8.41178 8.24794 8.48495 8.31592 8.52526L11.1856 10.2268C11.2914 10.2896 11.4281 10.2551 11.4915 10.1497L11.8353 9.5782C11.8992 9.47185 11.8645 9.33375 11.7578 9.27039L9.3253 7.82612V4.95393C9.3253 4.83032 9.2251 4.73012 9.10149 4.73012H8.43007Z" />
    </SvgIcon>
  );
}

export default IconComponent;

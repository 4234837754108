import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo,
} from "react";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import clsx from "clsx";

import { Common, CustomScrollbar, Icon, Table, Td, Th } from "@/components";
import { normalScheduleItemType } from "../models";
import {
  calculateNumberPreviousWeek,
  covertTime12ToDate,
  defineWeekDayPrevious,
  formatAMPM,
} from "../utils";
import CustomEditDayScheduleInput from "./InputDayNormalSchedule";
import { CUT_OFF_TIME_REG } from "../configVariable";
import { SnackbarUtils } from "@/utils";

const LIST_QUICK_CHANGE = [
  { id: 1, title: "Everyday", value: [1, 2, 3, 4, 5, 6, 0] },
  { id: 2, title: "6 days per week", value: [1, 2, 3, 4, 5, 6] },
  { id: 3, title: "Mon - Wed - Fri", value: [1, 3, 5] },
  { id: 4, title: "Tue - Thu - Sat", value: [2, 4, 6] },
];

type listWeekDayDisplayType = Partial<normalScheduleItemType> & {
  despatchDayError?: boolean;
  orderDayError?: boolean;
  cutoffTimeError?: boolean;
};

type Props = {
  listWeekDays: listWeekDayDisplayType[];
  onSetEditedNormal: (status: boolean) => void;
};

const TH_STYLE = "bg-neutral-20 text-neutral-50 !py-1 z-20";
const TD_STYLE = "py-2 border-b border-neutral-20 text-neutral text-sm px-1";

const TabEditNormalSchedule = forwardRef(
  ({ listWeekDays, onSetEditedNormal }: Props, ref) => {
    const timePickerRef = useRef<any[]>([]);

    const [listWeekdayEdit, setListWeekdayEdit] = useState<
      listWeekDayDisplayType[]
    >([]);
    const [checkAll, setCheckAll] = useState(false);
    const [isIndeterminate, setIndeterminate] = useState(false);
    const [isEditedNormalSchedule, setEditedNormalSchedule] =
      useState<boolean>(false);

    useEffect(() => {
      resetData();
    }, [listWeekDays]);

    useImperativeHandle(ref, () => {
      return {
        getEditNormalSchedule,
        checkValidDateNormal,
      };
    });

    const getEditNormalSchedule = () => {
      const dataSave = listWeekdayEdit.map((item) => {
        delete item.cutoffTimeError;
        delete item.despatchDayError;
        delete item.orderDayError;
        delete item.id;
        delete item.title;

        const newItem = { ...item };

        if (!newItem.isActivated) {
          newItem.despatchDay = 0;
          newItem.orderDay = 0;
          newItem.cutOffTime = "23:59:59";
        } else {
          newItem.cutOffTime = moment(item.cutOffTime, ["h:mm A"]).format(
            "HH:mm:ss"
          );
        }
        return { ...newItem };
      });

      return dataSave;
    };

    const checkValidDateNormal = () => {
      const isInvalid = listWeekdayEdit.some(
        (item) =>
          item.despatchDayError || item.orderDayError || item.cutoffTimeError
      );

      if (isInvalid) {
        SnackbarUtils.error(
          `<div>There is invalid data in normal schedule. Please fix it before saving changes.<div>`
        );
      }

      return isInvalid;
    };

    const resetData = () => {
      const newList = listWeekDays.map((item) => {
        return {
          ...item,
          despatchDayError: false,
          orderDayError: false,
          cutoffTimeError: false,
          cutOffTime: item.cutOffTime ? formatAMPM(item.cutOffTime) : "",
        };
      });
      setListWeekdayEdit(newList);
    };

    const toggleCheck = (id: number | string) => {
      let newList = [...listWeekdayEdit];
      if (id === "all") {
        // click check/uncheck all
        if (isIndeterminate) {
          newList = newList.map((item) => {
            return {
              ...item,
              isActivated: false,
            };
          });
          setCheckAll(false);
          setIndeterminate(false);
        } else {
          newList = newList.map((item) => {
            return {
              ...item,
              isActivated: !checkAll,
            };
          });
          setCheckAll(!checkAll);
        }
      } else {
        // click check/uncheck item
        const index = newList.findIndex((i) => i.id === id);
        const newItem = { ...newList[index] };
        newItem.isActivated = !newList[index].isActivated;
        newList[index] = newItem;

        // current status is checkall
        if (checkAll) {
          setIndeterminate(true);
          setCheckAll(false);
        } else {
          // current status is not check all
          const isAllChecked = newList.every((id) => id.isActivated === true);
          const isAllUnChecked = newList.every(
            (id) => id.isActivated === false
          );

          if (isAllChecked) {
            setCheckAll(true);
            setIndeterminate(false);
          } else if (isAllUnChecked) {
            setCheckAll(false);
            setIndeterminate(false);
          } else {
            setIndeterminate(true);
          }
        }
      }

      setListWeekdayEdit([...newList]);
      handleSetEdited();
    };

    const changeNumberDay = (
      column: "despatchDay" | "orderDay",
      id: number,
      value: number
    ) => {
      const newList = [...listWeekdayEdit];
      const weekDayIndex = newList.findIndex((item) => item.id === id);
      let newItem = { ...newList[weekDayIndex] };

      const intValue = +value;
      newItem[column] =
        isNaN(intValue) || intValue < 0 ? newItem[column] : intValue;
      newItem = errorSelectDay(newItem, column);

      newList[weekDayIndex] = newItem;

      setListWeekdayEdit([...newList]);
      handleSetEdited();
    };

    const incDecNumberDay = (
      column: "despatchDay" | "orderDay",
      id: number,
      type: "inc" | "dec"
    ) => {
      const newList = [...listWeekdayEdit];
      const weekDayIndex = newList.findIndex((item) => item.id === id);
      let newItem = { ...newList[weekDayIndex] };
      const previousValue = newItem[column] || 0;

      if (type === "dec") {
        newItem[column] = previousValue === 0 ? 0 : previousValue - 1;
      } else if (previousValue < 99999) {
        newItem[column] = previousValue + 1;
      }

      newItem = errorSelectDay(newItem, column);

      newList[weekDayIndex] = newItem;
      setListWeekdayEdit([...newList]);
      handleSetEdited();
    };

    const changeCutOffTime = (id: number, value: string) => {
      const newList = [...listWeekdayEdit];
      const weekDayIndex = newList.findIndex((item) => item.id === id);
      const newItem = { ...newList[weekDayIndex] };
      newItem.cutOffTime = value.toUpperCase();
      newItem.cutoffTimeError = value !== "" && !CUT_OFF_TIME_REG.test(value);

      newList[weekDayIndex] = newItem;
      setListWeekdayEdit(newList);
      handleSetEdited();
    };

    const errorSelectDay = (
      weekDay: listWeekDayDisplayType,
      column: "despatchDay" | "orderDay"
    ) => {
      if ((weekDay.despatchDay || 0) > (weekDay.orderDay || 0)) {
        weekDay.despatchDayError = column === "despatchDay";
        weekDay.orderDayError = column === "orderDay";
      } else {
        weekDay.despatchDayError = false;
        weekDay.orderDayError = false;
      }
      return weekDay;
    };

    const onClickQuickChange = (listDay: number[]) => {
      let newList = [...listWeekdayEdit];
      newList = newList.map((day) => {
        return {
          ...day,
          isActivated: listDay.includes(day.id),
        };
      });
      setListWeekdayEdit(newList);
      setCheckAll(listDay.length === 7);
      setIndeterminate(listDay.length !== 7);
      handleSetEdited();
    };

    const handleSetEdited = () => {
      if (!isEditedNormalSchedule) setEditedNormalSchedule(true);
      onSetEditedNormal(true);
    };

    const handleReset = () => {
      onSetEditedNormal(false);
      setEditedNormalSchedule(false);
      if (checkAll) setCheckAll(false);
      if (isIndeterminate) setIndeterminate(false);
      resetData();
    };

    return (
      <div className="h-full flex flex-col">
        <div className="flex">
          <div className="mr-4 text-neutral-60">Quick change:</div>
          {LIST_QUICK_CHANGE.map((item) => (
            <Common.Label
              key={item.id}
              className="!rounded !bg-neutral-10 border border-neutral-20 !text-neutral mx-1 cursor-pointer px-4 py-2"
            >
              <span onClick={() => onClickQuickChange(item.value)}>
                {item.title}
              </span>
            </Common.Label>
          ))}
          {isEditedNormalSchedule && (
            <div
              className="flex items-center ml-4 cursor-pointer"
              onClick={handleReset}
            >
              <span>
                <Icon.Refresh className="fill-yellow-dark" />{" "}
              </span>
              <span className="ml-2 text-yellow-dark font-medium text-base">
                Reset
              </span>
            </div>
          )}
        </div>
        <div className={clsx("mt-4 border border-neutral-20 flex-1")}>
          <CustomScrollbar>
            <Table className="relative w-full">
              <thead className="bg-neutral-20">
                <tr>
                  <Th className={`${TH_STYLE} px-1  text-center`}>
                    <span>
                      <Common.Checkbox
                        ipSize="md"
                        indeterminate={isIndeterminate}
                        checked={checkAll}
                        onChange={() => toggleCheck("all")}
                      />
                    </span>
                  </Th>
                  <Th className={`${TH_STYLE} px-1 `}>DELIVERY DATE</Th>
                  <Th className={`${TH_STYLE} px-1 `}>DESPATCH DATE</Th>
                  <Th className={`${TH_STYLE} px-1 `}></Th>
                  <Th className={`${TH_STYLE} px-1 `}></Th>
                  <Th className={`${TH_STYLE} px-1 `}>ORDER DATE</Th>
                  <Th className={`${TH_STYLE} px-1 `}></Th>
                  <Th className={`${TH_STYLE} text-right px-4 pr-1`}>
                    CUT-OFF TIME
                  </Th>
                  <Th className={`${TH_STYLE} px-0`}></Th>
                </tr>
              </thead>
              <tbody className="bg-white border-right border-neutral-20">
                {listWeekdayEdit.map((item, index) => {
                  const {
                    title,
                    despatchDay = 0,
                    orderDay = 0,
                    cutOffTime,
                    isActivated,
                    despatchDayError,
                    orderDayError,
                    cutoffTimeError,
                  } = item;

                  const tdStyle = `${TD_STYLE} ${
                    isActivated ? "bg-neutral-10" : "bg-white"
                  }`;

                  return (
                    <tr
                      className="text-sm font-medium text-neutral-90 group"
                      key={item.id}
                    >
                      <Td className={`${tdStyle} pb-8 text-center`}>
                        <div className="inline-flex items-center">
                          <span>
                            <Common.Checkbox
                              ipSize="md"
                              checked={isActivated}
                              onChange={() => toggleCheck(item.id)}
                            />
                          </span>
                        </div>
                      </Td>
                      <Td className={`${tdStyle} pb-8 text-base`}>
                        <div className="w-100 flex justify-between">
                          <span>{title}</span>

                          <span className="text-neutral-60 ml-3">
                            will be despatched
                          </span>
                        </div>
                      </Td>
                      <Td className={`${tdStyle}`}>
                        <div>
                          <CustomEditDayScheduleInput
                            value={despatchDay}
                            isActivated={isActivated}
                            onChangeInput={(value) =>
                              changeNumberDay("despatchDay", item.id, value)
                            }
                            onIncDecButton={(type) =>
                              incDecNumberDay("despatchDay", item.id, type)
                            }
                            errorMsg={
                              despatchDayError
                                ? "Despatch date cannot be smaller than order date."
                                : ""
                            }
                          />
                          {despatchDay > 0 ? (
                            <div className="mt-1">
                              <span className="text-neutral-80 font-medium">
                                {defineWeekDayPrevious(title, despatchDay)}{" "}
                              </span>
                              <span className="text-neutral-60">
                                {calculateNumberPreviousWeek(
                                  title,
                                  despatchDay
                                )}
                              </span>
                            </div>
                          ) : (
                            "--"
                          )}
                        </div>
                      </Td>

                      <Td
                        className={`${tdStyle} pb-8 text-neutral-60 text-left`}
                      >
                        <span className="text-neutral-60">
                          day(s) in advance
                        </span>
                      </Td>
                      <Td className={`${tdStyle} pb-8 text-neutral-60`}>
                        <span className="text-neutral-60 pl-3">
                          and customer orders
                        </span>
                      </Td>
                      <Td className={`${tdStyle}`}>
                        <div>
                          <CustomEditDayScheduleInput
                            value={orderDay}
                            isActivated={isActivated}
                            onChangeInput={(value) =>
                              changeNumberDay("orderDay", item.id, value)
                            }
                            onIncDecButton={(type) =>
                              incDecNumberDay("orderDay", item.id, type)
                            }
                            errorMsg={
                              orderDayError
                                ? "Order date cannot be greater than despatch date."
                                : ""
                            }
                          />
                          {orderDay > 0 ? (
                            <div className="mt-1">
                              <span className="text-neutral-80 font-medium">
                                {defineWeekDayPrevious(title, orderDay)}{" "}
                              </span>
                              <span className="text-neutral-60">
                                {calculateNumberPreviousWeek(title, orderDay)}
                              </span>
                            </div>
                          ) : (
                            "--"
                          )}
                        </div>
                      </Td>
                      <Td
                        className={`${tdStyle} pb-8 text-neutral-60 text-left`}
                      >
                        <span className="text-neutral-60">
                          day(s) in advance
                        </span>
                      </Td>
                      <Td className={`${tdStyle} pb-8`}>
                        <div className="flex justify-end items-center">
                          <span className="text-neutral-60 mr-1">by</span>
                          <div className="relative">
                            <Common.TimePicker
                              ref={(el) => (timePickerRef.current[index] = el)}
                              selected={covertTime12ToDate(cutOffTime)}
                              onChange={(value) => {
                                if (moment(value, ["hh:mm:ss"]).isValid()) {
                                  changeCutOffTime(
                                    item.id,
                                    moment(value, ["hh:mm:ss"]).format("h:mm A")
                                  );
                                }
                              }}
                              wrapClassName="px-2"
                              inputClassname="text-right"
                              isError={cutoffTimeError}
                              popperPlacement="bottom-end"
                              customInput={false}
                              className={clsx(
                                "max-w-[7rem] border text-base text-right rounded py-[0.375rem] pr-10 focus:shadow-none",
                                cutoffTimeError
                                  ? "border-red focus:outline-none focus:ring-red focus-visible:outline-none focus:border-red"
                                  : "border-neutral-20 focus:ring-blue focus:border-blue focus-visible:outline-none",
                                !isActivated
                                  ? "bg-neutral-20 text-neutral-40"
                                  : ""
                              )}
                              disabledTimePicker={!isActivated}
                            />
                            <div
                              className="absolute top-2.5 right-3 cursor-pointer"
                              onClick={() =>
                                timePickerRef.current[index].setOpen(true)
                              }
                            >
                              <Icon.Clock className="fill-neutral-40" />
                            </div>
                          </div>
                        </div>
                      </Td>
                      <Td className={`${tdStyle} pb-8`}></Td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CustomScrollbar>
        </div>

        <ReactTooltip
          id="cutofferror"
          place="bottom"
          type="error"
          className="px-4 py-2"
        >
          Invalid time format
        </ReactTooltip>
      </div>
    );
  }
);

export default memo(TabEditNormalSchedule);

import clsx from "clsx";
import { Icon } from "../Icons";
import { RedirectToOUI } from "../RedirectToOUI";

interface Props {
  children: React.ReactNode;
  title?: React.ReactNode;
  middleHeaderContent?: React.ReactNode;
  rightHeaderContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  onViewAll?: () => void;
  pathViewAll?: string;
  isLoading?: boolean;
}

export const Card = ({
  children,
  title,
  middleHeaderContent,
  rightHeaderContent,
  footerContent,
  onViewAll,
  pathViewAll,
  className,
  titleClassName,
  isLoading,
}: Props) => {
  return (
    <div
      className={clsx(
        "bg-white rounded border border-neutral-20",
        isLoading && "relative",
        className
      )}
    >
      {isLoading && (
        <div className="absolute top-0 left-0 h-full w-full z-10 bg-black bg-opacity-30 flex justify-center items-center rounded">
          <Icon.Loading size="28" />
        </div>
      )}
      <div className="flex items-center shadow-underline h-[3.75rem] px-3 md:px-5">
        <div
          className={clsx(
            "text-base md:text-hd5 font-semibold",
            titleClassName
          )}
        >
          {title}
        </div>
        <div className="flex-1">{middleHeaderContent}</div>
        {rightHeaderContent && <div className="">{rightHeaderContent}</div>}
        {onViewAll && (
          <button
            onClick={onViewAll}
            className="text-blue font-medium flex items-center"
          >
            View all <Icon.AngleRight size="12" className="stroke-blue ml-2" />
          </button>
        )}
        {pathViewAll && (
          <RedirectToOUI
            to={pathViewAll}
            className="text-blue font-medium flex items-center"
          >
            View all <Icon.AngleRight size="12" className="stroke-blue ml-2" />
          </RedirectToOUI>
        )}
      </div>
      <div className="h-[calc(100%-3.75rem)] relative">
        {children}
        {footerContent && (
          <div className="flex shadow-overline">{footerContent}</div>
        )}
      </div>
    </div>
  );
};

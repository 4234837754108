import { FC, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { GOOGLE_API_KEY } from "@/configuration/globalVariable";
import {
  latlng,
  // manifest,
  manifestDetailETA,
  // manifestETA,
  // manifestLine,
  storageAddress,
} from "@/pages/Delivery/models";
import Map from "./Map";
import PolyLine from "./Polyline";
// import { EyeMapDropdown } from "./EyeMapDropdown";
import Marker from "./Marker";

interface Props {
  manifestDetailETA?: manifestDetailETA[];
  storageAddress?: storageAddress;
  makers?: latlng[];
  polylines?: string[];
  isLoadingManifestDetailETAQuery?: boolean;
}

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return (
        <div className="w-full h-full flex items-center justify-center">
          Map is loading.....
        </div>
      );
    case Status.FAILURE:
      return (
        <div className="w-full h-full flex items-center justify-center">
          Map loaded error.
        </div>
      );
    default:
      return (
        <div className="w-full h-full flex items-center justify-center">
          Loading.....
        </div>
      );
  }
};

export const GoogleMap: FC<Props> = ({
  manifestDetailETA,
  storageAddress,
  // makers,
  // polylines,
  // isLoadingManifestDetailETAQuery,
}) => {
  // console.log(
  //   "==> ",
  //   manifestDetailETA,
  //   storageAddress,
  //   makers,
  //   polylines,
  //   isLoadingManifestDetailETAQuery
  // );

  // Center and fit zoom Australia country in view of the first load
  const [zoom] = useState<number>(5); // initial zoom
  const [center] = useState({
    lat: -25.344,
    lng: 131.031,
  });

  const [allMarkerlatlng] = useState<google.maps.LatLng[]>([]);
  // const [routesDisplay, setRoutesDisplay] = useState<manifestDetailETA[]>(
  //   manifests || []
  // );

  // useEffect(() => {
  //   manifests && setRoutesDisplay(manifests);
  // }, [manifests]);

  // useEffect(() => {
  //   if (storageAddress) {
  //     console.log(2222);
  //     setCenter({
  //       lat: storageAddress?.latitude || 0,
  //       lng: storageAddress?.longitude || 0,
  //     });
  //     // Todo set zoom after load all location
  //     setZoom(11);
  //   }
  // }, [storageAddress]);

  // useEffect(() => {
  //   if (manifests?.length) {
  //     const latlng = [
  //       new google.maps.LatLng(
  //         storageAddress?.latitude || 0,
  //         storageAddress?.longitude || 0
  //       ),
  //     ];
  //     manifests.map((manifest: manifest) => {
  //       if (manifest.manifestLines.length) {
  //         manifest.manifestLines.map((manifestLine: manifestLine) => {
  //           return latlng.push(
  //             new google.maps.LatLng(
  //               manifestLine?.latitude || 0,
  //               manifestLine?.longitude || 0
  //             )
  //           );
  //         });
  //       }
  //       return manifest;
  //     });
  //     latlng.length && setAllMarkerlatlng(latlng);
  //   }
  // }, [manifests]);

  const onIdle = (m: any) => {
    console.debug("onIdle: ", m);
    // setZoom(m.getZoom());
    // setCenter(m.getCenter()?.toJSON());
  };

  // Generate ETA polyline data
  const ParseETAPolyline = (polyline: string) => {
    const polylines = JSON.parse(polyline)[0];

    if (polylines) {
      const decode = google.maps.geometry.encoding.decodePath(polylines);
      const listposition = [];

      for (let c = 0; c < decode.length; c++) {
        const coords = new google.maps.LatLng(
          decode[c].lat() / 10,
          decode[c].lng() / 10
        );
        listposition.push(coords);
      }
      return listposition;
    }
  };

  // const handleShowHideRoute = (route: manifestDetailETA) => {
  //   setRoutesDisplay(
  //     routesDisplay.some((rd) => rd.manifestId === route.manifestId)
  //       ? routesDisplay.filter((rd) => rd.manifestId !== route.manifestId)
  //       : routesDisplay.concat([route])
  //   );
  // };

  return GOOGLE_API_KEY ? (
    <div className="ralative w-full h-full">
      <Wrapper
        apiKey={GOOGLE_API_KEY}
        version="beta"
        libraries={["geometry", "marker"]}
        render={render}
      >
        <Map
          center={center}
          onIdle={onIdle}
          zoom={zoom}
          allMarkerlatlng={allMarkerlatlng}
          className="w-full h-full"
        >
          {manifestDetailETA?.length &&
            manifestDetailETA.map(
              (manifestDetailETA) =>
                manifestDetailETA?.polylines && (
                  <PolyLine
                    key={manifestDetailETA.manifestId}
                    path={ParseETAPolyline(manifestDetailETA.polylines)}
                    strokeColor={"#ff0000"}
                    // strokeColor={manifest.route.colour}
                  />
                )
            )}

          {storageAddress && (
            <Marker
              position={{
                lat: storageAddress?.latitude || 0,
                lng: storageAddress?.longitude || 0,
              }}
              data={storageAddress}
              isStore
            />
          )}
        </Map>
      </Wrapper>
      {/* <div className="absolute bottom-3 right-3 z-10">
        <EyeMapDropdown
          manifests={manifests}
          onShowHideClick={handleShowHideRoute}
          routesActiveShow={routesDisplay}
        />
      </div> */}
    </div>
  ) : null;
};

import { Icon } from "@/components";
import { useEffect, useState } from "react";
import { DashboardTopItem } from "./DashboardTopItem";

import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector, userViewSelector } from "@/providers/auth/selector";

import { useGetPendingCreditsSummary } from "../services/dashboard";
import moment from "moment";
import { TIME_FORMAT, USER_VIEW } from "@/configuration/globalVariable";

export const DashboardTopPendingCredits = () => {
  const [dataRender, setDatarender] = useState({
    title: "PENDING credit requests",
    count: 0,
    amount: 0,
    countSuffix: "Request",
    isLoading: true,
  });
  const user = useAppSelector(userSelector);
  const pendingCreditsSummary = useGetPendingCreditsSummary();
  const { isLoading, isSuccess, data } = pendingCreditsSummary;
  const userView = useAppSelector(userViewSelector);

  const path =
    userView === USER_VIEW.SUPPLIER
      ? `/Sales/documents/Invoices?customerid=&number=&status=00000000-0000-0006-0124-000000000000&fromdate=${moment()
          .subtract(30, "days")
          .format(TIME_FORMAT.REQUEST)}&todate=${moment(new Date()).format(
          TIME_FORMAT.REQUEST
        )}&paymethod=&routename=&routeid=&rangefor=1&doctype=4`
      : `/purchases/documents/Invoices?supplierid=&number=&status=00000000-0000-0006-0124-000000000000%2C00000000-0000-0006-0124-000000000000%2C00000000-0000-0006-0224-000000000000&fromdate=${moment()
          .subtract(30, "days")
          .format(TIME_FORMAT.REQUEST)}&todate=${moment(new Date()).format(
          TIME_FORMAT.REQUEST
        )}&paymethod=&routename=&routeid=&rangefor=1&doctype=4`;

  useEffect(() => {
    pendingCreditsSummary.mutate({ accountId: user.accountId });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setDatarender({
        ...dataRender,
        isLoading: false,
        amount: data ? data[0]?.totalAmount : 0,
        count: data ? data[0]?.totalCount : 0,
      });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <DashboardTopItem
      isFirst
      title={dataRender.title}
      note="(Last 30 days)"
      isLoading={isLoading}
      icon={
        <div>
          <Icon.Money
            size="24"
            className="fill-status-warning hidden lg:block"
          />
          <Icon.Money size="16" className="fill-status-warning lg:hidden" />
        </div>
      }
      count={dataRender.count}
      amount={dataRender.amount}
      countSuffix={dataRender.countSuffix}
      pathViewMore={path}
    />
  );
};

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path d="M13.125 1.75H11.375V0H9.625V1.75H7.875V0H6.125V1.75H4.375V0H2.625V1.75H0.875C0.391125 1.75 0 2.14113 0 2.625V13.125C0 13.6089 0.391125 14 0.875 14H13.125C13.6089 14 14 13.6089 14 13.125V2.625C14 2.14113 13.6089 1.75 13.125 1.75ZM12.25 12.25H1.75V4.375H12.25V12.25Z" />
      <path d="M5.25 6.125H3.5V7.875H5.25V6.125Z" />
      <path d="M7.875 6.125H6.125V7.875H7.875V6.125Z" />
      <path d="M5.25 8.75H3.5V10.5H5.25V8.75Z" />
      <path d="M7.875 8.75H6.125V10.5H7.875V8.75Z" />
      <path d="M10.5 6.125H8.75V7.875H10.5V6.125Z" />
      <path d="M10.5 8.75H8.75V10.5H10.5V8.75Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import DatePickerComponent from "react-datepicker";
import clsx from "clsx";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import { Icon } from "@/components/Icons";
import { WrapInput } from "./WrapInput";

interface Props {
  ipSize?: "lg" | "md";
  className?: string;
  wrapClassName?: string;
  label?: string;
  isError?: boolean;
  isWarning?: boolean;
  disabled?: boolean;
  selected: Date | null;
  placeholder?: string;
  onChange: (date: Date) => void;
  onSelect?: (date: Date) => void;
  minDate?: Date | null | undefined;
  id?: string;
  iconType?: "stroke" | "fill";
  iconRight?: React.ElementType;
  iconClassName?: string;
  errorMessage?: string;
  isLoading?: boolean;
}

export type Ref = HTMLInputElement;
export const DatePicker = ({
  ipSize = "lg",
  className,
  wrapClassName,
  label,
  isError,
  isWarning,
  disabled,
  selected,
  placeholder,
  isLoading,
  onChange,
  onSelect,
  minDate,
  iconType = "fill",
  iconRight: IconRight = Icon.Calendar,
  id,
  iconClassName,
  errorMessage,
  ...rest
}: Props) => {
  return (
    <WrapInput
      label={label}
      isWarning={isWarning}
      errorMessage={errorMessage}
      wrapClassName={wrapClassName}
      iconType={iconType}
      iconRight={isLoading ? Icon.Loading : IconRight}
      id={id}
      iconClassName={iconClassName}
    >
      <DatePickerComponent
        {...rest}
        dateFormat={TIME_FORMAT.INPUT_DISPLAY}
        placeholderText={placeholder}
        className={clsx(
          "block w-full border text-base rounded text-neutral-60 focus:ring-0 pr-8 relative bg-transparent",
          ipSize === "md" ? "py-1 pl-2" : "py-[0.375rem] pl-3",
          isError
            ? "border-red focus:outline-none focus:ring-red focus-visible:outline-none focus:border-red"
            : isWarning
            ? "border-yellow-dark focus:outline-none focus:ring-yellow-dark focus-visible:outline-none focus:border-yellow-dark"
            : "border-neutral-20 focus:ring-blue focus:border-blue focus-visible:outline-none",
          "pr-3",
          disabled ? "bg-neutral-20 text-neutral-40" : "text-neutral",
          className
        )}
        onKeyDown={(e: any) => {
          e.code == "Enter" && onSelect && onSelect(selected as Date);
        }}
        selected={selected}
        onChange={onChange}
        onSelect={onSelect}
        minDate={minDate}
        disabled={disabled}
      />
    </WrapInput>
  );
};

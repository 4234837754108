import { useEffect } from "react";
import base64 from "base-64";
import isEmpty from "lodash/isEmpty";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icon } from "@/components";
import { setToken } from "@/utils/authUtils";
import { useAppDispatch } from "@/sharedStore/hooks";
import { setUser } from "@/providers/auth/authSlice";
import { navigateToSignin, SnackbarUtils } from "@/utils";
import { BUILD_MODE, ENV, USER_VIEW } from "@/configuration/globalVariable";
import { userData } from "@/models";
// import { PATH } from "@/configuration/globalVariable";

const Index = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const transferInfo = params.get("TokenInfo");
    const isChangeUserView = params.get("isChangeUserView");
    const isChangeAccount = params.get("isChangeAccount");
    [ENV.DEV, ENV.STG].includes(BUILD_MODE as any) &&
      console.log(
        `http://127.0.0.1:3000/#/transfer?TokenInfo=${transferInfo}&isChangeUserView=${isChangeUserView}&isChangeAccount=${isChangeAccount}`
      );

    if (transferInfo) {
      const userInfo: userData = JSON.parse(
        base64.decode(transferInfo) as any
      )?.data;

      if (!isEmpty(userInfo)) {
        setToken(userInfo.token);
        dispatch(setUser(userInfo));
        if (isChangeAccount === "true") {
          SnackbarUtils.success(
            `Switched to <b>${userInfo.companyName}</b> successfully.`
          );
        } else if (isChangeUserView === "true") {
          SnackbarUtils.success(
            `Switched to ${
              userInfo.userView === USER_VIEW.SUPPLIER ? "supplier" : "customer"
            } view successfully.`
          );
        }
        userInfo.path && navigate(userInfo.path, { replace: true });
      } else {
        navigateToSignin();
        // navigate(`/${PATH.AUTH}/${PATH.SIGN_IN}`, { replace: true });
      }
    } else {
      navigateToSignin();
      // navigate(`/${PATH.AUTH}/${PATH.SIGN_IN}`, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full h-[calc(100vh-6.25rem)] flex items-center justify-center">
      Authenticating...
      <Icon.Loading size="36" className="ml-3" />
    </div>
  );
};

export default Index;

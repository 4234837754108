interface Props {
  title: string;
  code: string;
}

export const ComponentInfo = ({ title, code }: Props) => {
  return (
    <div className="mb-4">
      <div className="font-bold">{title}</div>
      <pre className="text-sm bg-neutral-10 p-4 rounded">{code}</pre>
    </div>
  );
};

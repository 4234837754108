import { CopyToClipboard } from "react-copy-to-clipboard";
import { SnackbarUtils } from "@/utils";
import { Common, Icon } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../components";
import React from "react";

const btSize = ["lg", "md", "sm"];
const btColor = ["blue", "red", "green", "yellow", "gray", "transparent"];

const data = [
  {
    id: 1,
    element: ({ btSize, color, onClick }: any) => (
      <Common.Button onClick={onClick} color={color} btSize={btSize}>
        Button Text
      </Common.Button>
    ),
    elementCopy: (props: string) =>
      `<Common.Button ${props}>Button Text</Common.Button>`,
  },
  {
    id: 2,
    element: ({ btSize, color, onClick }: any) => (
      <Common.Button
        onClick={onClick}
        color={color}
        btSize={btSize}
        iconType="stroke"
        iconLeft={Icon.AngleLeft}
      >
        Button Text
      </Common.Button>
    ),
    elementCopy: (props: string) =>
      `<Common.Button ${props} iconType="stroke" iconLeft={Icon.AngleLeft}>Button Text</Common.Button>`,
  },
  {
    id: 3,
    element: ({ btSize, color, onClick }: any) => (
      <Common.Button
        onClick={onClick}
        color={color}
        btSize={btSize}
        iconType="stroke"
        iconRight={Icon.AngleRight}
      >
        Button Text
      </Common.Button>
    ),
    elementCopy: (props: string) =>
      `<Common.Button ${props} iconType="stroke" iconRight={Icon.AngleRight}>Button Text</Common.Button>`,
  },
  {
    id: 4,
    element: ({ btSize, color, onClick }: any) => (
      <Common.Button outline onClick={onClick} color={color} btSize={btSize}>
        Button Text
      </Common.Button>
    ),
    elementCopy: (props: string) =>
      `<Common.Button ${props} outline>Button Text</Common.Button>`,
  },
  {
    id: 5,
    element: ({ btSize, color, onClick }: any) => (
      <Common.Button disabled onClick={onClick} color={color} btSize={btSize}>
        Button Text
      </Common.Button>
    ),
    elementCopy: (props: string) =>
      `<Common.Button ${props} disabled>Button Text</Common.Button>`,
  },
  {
    id: 6,
    element: ({ btSize, color, onClick }: any) => (
      <Common.Button
        onClick={onClick}
        color={color}
        btSize={btSize}
        iconLeft={Icon.Box}
        rounded
      />
    ),
    elementCopy: (props: string) =>
      `<Common.Button ${props} iconType="fill" iconLeft={Icon.Box} rounded>Button Text</Common.Button>`,
  },
];

const Index = () => {
  return (
    <div className="p-4 bg-white rounded shadow">
      <div className="text-hd5 font-medium pb-4 mb-4 shadow-underline">
        Button components
      </div>

      <ComponentInfo
        title="Import component"
        code={`import { Common } from "@/components";

<Common.Button>Button Text</Common.Button>`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  size?: "lg" | "md";
  rounded?: boolean;
  outline?: boolean;
  color?: "red" | "blue" | "gray" | "green" | "yellow" | "transparent";
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  iconType?: "stroke" | "fill";
  iconLeft?: React.ElementType;
  iconRight?: React.ElementType;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  size = "lg",
  rounded = false,
  outline = false,
  color = "blue",
  className = "",
  disabled = false,
  isLoading = false,
  children = null,
  iconType = "fill",
  iconLeft = undefined,
  iconRight = undefined,
}`}
      />
      <div>
        <strong>
          <em>* Click button to copy code:</em>
        </strong>
        <DisplayComponent
          RenderComp={() => (
            <>
              {btSize.map((btSize, index) => (
                <React.Fragment key={index}>
                  <div className="text-hd4 font-semibold">
                    Button size: {btSize}
                  </div>
                  {btColor.map((color, index) => (
                    <React.Fragment key={index}>
                      <div className="flex flex-wrap items-center space-x-2 mb-2">
                        {data.map((Btn, index) => (
                          <React.Fragment key={index}>
                            <CopyToClipboard
                              text={Btn.elementCopy(
                                `color={"${color}"} btSize={"${btSize}"}`
                              )}
                              onCopy={() => {
                                SnackbarUtils.success(
                                  `Copied <b>Button code</b> code success to clipboard.`
                                );
                              }}
                            >
                              <Btn.element
                                onClick
                                btSize={btSize}
                                color={color}
                              />
                            </CopyToClipboard>
                          </React.Fragment>
                        ))}
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="mb-4" />
                </React.Fragment>
              ))}
              <div className="text-hd4 font-semibold">Loading state</div>
              <div className="flex flex-wrap items-center">
                <CopyToClipboard
                  text={`<Common.Button isLoading> Button Text </Common.Button>`}
                  onCopy={() => {
                    SnackbarUtils.success(
                      `Copied <b>Button code</b> code success to clipboard.`
                    );
                  }}
                >
                  <Common.Button className="mr-2 mb-2" isLoading>
                    Button Text
                  </Common.Button>
                </CopyToClipboard>
                <CopyToClipboard
                  text={`<Common.Button iconLeft={Icon.AngleLeft} isLoading>Button Text</Common.Button>`}
                  onCopy={() => {
                    SnackbarUtils.success(
                      `Copied <b>Button code</b> code success to clipboard.`
                    );
                  }}
                >
                  <Common.Button
                    className="mr-2 mb-2"
                    iconLeft={Icon.AngleLeft}
                    isLoading
                  >
                    Button Text
                  </Common.Button>
                </CopyToClipboard>
                <CopyToClipboard
                  text={`<Common.Button iconRight={Icon.AngleRight} isLoading >Button Text</Common.Button>`}
                  onCopy={() => {
                    SnackbarUtils.success(
                      `Copied <b>Button code</b> code success to clipboard.`
                    );
                  }}
                >
                  <Common.Button
                    className="mr-2 mb-2"
                    iconRight={Icon.AngleRight}
                    isLoading
                  >
                    Button Text
                  </Common.Button>
                </CopyToClipboard>
                <CopyToClipboard
                  text={`<Common.Button isLoading iconLeft={Icon.Box} rounded />`}
                  onCopy={() => {
                    SnackbarUtils.success(
                      `Copied <b>Button code</b> code success to clipboard.`
                    );
                  }}
                >
                  <Common.Button
                    className="mr-2 mb-2"
                    isLoading
                    iconLeft={Icon.Box}
                    rounded
                  />
                </CopyToClipboard>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default Index;

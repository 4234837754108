import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 7C0.75 6.44772 1.19772 6 1.75 6H12.25C12.8023 6 13.25 6.44772 13.25 7C13.25 7.55228 12.8023 8 12.25 8H1.75C1.19772 8 0.75 7.55228 0.75 7Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M9.9375 6.37499H9.92587C9.4745 6.36762 9.03119 6.25419 8.63175 6.04387C8.22647 6.26003 7.77445 6.37371 7.31512 6.37499C6.85654 6.36987 6.40576 6.25572 6 6.04199C5.59544 6.26058 5.14284 6.37504 4.683 6.37504C4.22316 6.37504 3.77056 6.26058 3.366 6.04199C2.97122 6.25034 2.53351 6.36433 2.08725 6.37499V6.37499C1.88991 6.37434 1.69314 6.35361 1.5 6.31312V11.25C1.5 11.3494 1.53951 11.4448 1.60984 11.5152C1.68016 11.5855 1.77554 11.625 1.875 11.625H4.875V8.99999H7.125V11.625H10.125C10.2245 11.625 10.3198 11.5855 10.3902 11.5152C10.4605 11.4448 10.5 11.3494 10.5 11.25V6.31724C10.3149 6.35534 10.1265 6.37469 9.9375 6.37499Z" />
      <path d="M11.9153 3.525L9.67508 0.525C9.64015 0.478426 9.59486 0.440625 9.54279 0.41459C9.49072 0.388554 9.4333 0.375 9.37508 0.375H2.62508C2.56686 0.375 2.50945 0.388554 2.45738 0.41459C2.40531 0.440625 2.36001 0.478426 2.32508 0.525L0.0848312 3.525C0.0280448 3.60133 0.00219377 3.69629 0.0124562 3.79088C0.0688058 4.29669 0.310183 4.76381 0.690163 5.10239C1.07014 5.44098 1.56189 5.62711 2.07083 5.625C2.54555 5.61466 3.00308 5.44538 3.37021 5.14425C3.74043 5.45548 4.20879 5.62577 4.69246 5.625C5.17053 5.61927 5.63215 5.44956 6.00008 5.14425C6.36957 5.45015 6.83292 5.61987 7.31258 5.625C7.79362 5.62523 8.25928 5.45549 8.62733 5.14575C8.99695 5.44973 9.45907 5.61876 9.93758 5.625C10.445 5.62509 10.9347 5.43809 11.313 5.09977C11.6912 4.76146 11.9314 4.29558 11.9877 3.79125C11.9981 3.69655 11.9722 3.60143 11.9153 3.525V3.525Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { Icon } from "../Icons";

export const AccessDeniedPage = () => {
  return (
    <div className="h-[calc(100vh-6.25rem)] flex items-center justify-center">
      <div className="flex w-[30rem] items-center p-8 border border-neutral-20 bg-white rounded">
        <div className="mr-6">
          <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-status-bg-error">
            <div className="flex items-center justify-center h-[3.25rem] w-[3.25rem] rounded-full bg-status-error">
              <Icon.Alert size="24" className="fill-white" />
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="font-semibold text-hd4 mb-2">Access Denied</div>
          You are not allowed to access this functionality. Please contact your
          account administrator for more information.
        </div>
      </div>
    </div>
  );
};

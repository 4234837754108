import { CustomerView, SupplierView } from "./Screens";
import { useAppSelector } from "@/sharedStore/hooks";
import { userViewSelector } from "@/providers/auth/selector";
import { USER_VIEW } from "@/configuration/globalVariable";

const Index = () => {
  const userView = useAppSelector(userViewSelector);
  return !userView ? (
    <div className="bg-white h-full w-full flex justify-center items-center border border-neutral-20 rounded">
      Authenticating.....
    </div>
  ) : userView === USER_VIEW.CUSTOMER ? (
    <CustomerView />
  ) : (
    <SupplierView />
  );
};

export default Index;

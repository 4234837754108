import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { Icon } from "@/components/Icons";
import { Common } from "@/components";
import ListBoxOption from "@/components/common/ListBox/ListBoxOption";

const people = [
  { id: 1, name: "Wade Cooper" },
  { id: 2, name: "Arlene Mccoy" },
  { id: 3, name: "Devon Webb" },
  { id: 4, name: "Tom Cook" },
  { id: 5, name: "Tanya Fox" },
  { id: 6, name: "Hellen Schmidt Agras" },
];
const StaticUsage = () => {
  const [selected, setSelected] = useState(people[0]);
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-neutral-20 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
          <span className="block truncate">{selected.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <Icon.AngleDown
              className="stroke-neutral-60"
              aria-hidden="true"
              iconType="stroke"
              size={12}
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="origin-top-right absolute right-0 mt-2 max-h-60 w-full overflow-auto rounded-md shadow-modal bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 transform opacity-100 scale-100">
            {people.map((person, personIdx) => (
              <Listbox.Option
                key={personIdx}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 hover:bg-blue-10 ${
                    active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                  }`
                }
                value={person}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {person.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                        <Icon.Checked
                          iconType="fill"
                          className="h-5 w-5 fill-neutral-90"
                        />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

const ComponentUsage = () => {
  const [selected, setSelected] = useState(people[3]);
  const buttonRender = (
    <>
      <span className="block truncate">{selected.name}</span>
      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <Icon.AngleDown
          className="stroke-neutral-60"
          aria-hidden="true"
          iconType="stroke"
          size={12}
        />
      </span>
    </>
  );
  const listRender = (
    <>
      {people.map((person) => (
        <ListBoxOption
          key={person.id}
          value={person}
          content={(selected) => {
            return (
              <>
                <span
                  className={`block truncate ${
                    selected ? "font-medium" : "font-normal"
                  }`}
                >
                  {person.name}
                </span>
                {selected ? (
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                    <Icon.Checked
                      iconType="fill"
                      className="h-5 w-5 fill-neutral-90"
                    />
                  </span>
                ) : null}
              </>
            );
          }}
        ></ListBoxOption>
      ))}
    </>
  );
  return (
    <Common.ListBox
      buttonRender={buttonRender}
      listRender={listRender}
      selected={selected}
      onChange={setSelected}
    />
  );
};
const Index = () => {
  return (
    <div className="p-4 bg-white rounded shadow">
      <div className="mb-4">
        <div className="font-bold">Static usage</div>
        <StaticUsage />
      </div>
      <div className="mb-4">
        <div className="font-bold">Component usage</div>
        <ComponentUsage />
      </div>
    </div>
  );
};

export default Index;

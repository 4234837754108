import clsx from "clsx";
import { Images } from "@/assets/images";
import { Common, Icon, RedirectToOUI } from "@/components";
import { CUSDashboardTopTotalSpending } from "./CUSDashboardTopTotalSpending";

export const CUSDashboardTop = () => {
  return (
    <>
      <div className="xl:flex">
        <div className="flex-1 bg-white border border-neutral-20 rounded mb-3 overflow-hidden">
          <div
            className="w-full h-full bg-no-repeat bg-[left_2rem_center]"
            style={{ backgroundImage: `url(${Images.cusTopLeftBg})` }}
          >
            <div
              className={clsx(
                "w-full h-full py-5 lg:flex lg:justify-between lg:items-center lg:py-14 px-4 lg:pr-6 lg:pl-28 ",
                "bg-no-repeat bg-[right_0_center] bg-contain"
              )}
              style={{ backgroundImage: `url(${Images.cusTopRightBg})` }}
            >
              <div className="text-blue font-semibold text-hd4 mb-5 lg:text-[1.25rem] lg:mb-0">
                <div>Create a new order &amp; explore our platform</div>
              </div>
              <RedirectToOUI to={"/purchases/placeorder/documents/placeorder"}>
                <Common.Button iconLeft={Icon.PlusBorder}>
                  Create new order
                </Common.Button>
              </RedirectToOUI>
            </div>
          </div>
        </div>
        <div className="lg:flex xl:flex-col xl:ml-3 xl:w-1/3 mb-3 xl:mt-0 lg:space-x-4 xl:space-x-0 bg-white lg:justify-between lg:py-5 border border-neutral-20 rounded px-4 lg:px-0">
          <CUSDashboardTopTotalSpending />
        </div>
      </div>
    </>
  );
};

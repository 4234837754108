import { useState } from "react";
import clsx from "clsx";
import { Common, Icon, ModalInform } from "@/components";

interface Props {
  className?: string;
}

export const EditTabPayment = ({ className }: Props) => {
  const [isOpenPaymenmodal, setOpenPaymentModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { name, value } = e.target;
    console.log(name, ": ", value);
  };

  return (
    <div className={clsx("flex flex-col h-full", className)}>
      <div className="flex-1 relative">
        <div className="absolute top-0 left-0 w-full h-full overflow-y-auto">
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold mb-1.5 text-hd5">
                Online Payment
              </div>
              <div className="text-neutral-60 max-w-[16.25rem]">
                <span className="inline">
                  <Icon.HandShake
                    size="14"
                    className="fill-green inline mr-2"
                  />
                </span>
                <a className="text-blue" href="#">
                  That Armore Cheese
                </a>{" "}
                is accepting online payment.
              </div>
            </div>
            <div className="flex-1">
              <div className="border border-yellow bg-yellow-light p-4 max-w-lg rounded">
                <div className="font-semibold mb-1.5">
                  You haven&apos;t set up a payment method yet
                </div>
                <div className="text-neutral-70">
                  Enable online payment to connect your digital wallet and start
                  receiving payments from your customers.
                </div>
              </div>
              <div className="mt-6 flex items-center mb-4">
                <div className="mr-2">
                  <Common.Switch />
                </div>
                disabled
              </div>

              <div className="font-semibold mb-1.5">
                Bank account for pay-out
              </div>
              <div className="mb-4 flex items-center text-neutral-40 border border-neutral-20 p-4 rounded max-w-lg">
                <Icon.Bank size="32" className="fill-neutral-40 mr-4" />
                <div className="mr-auto">
                  <div>**** **** **** ****</div>
                  <div>CARDHOLDER NAME</div>
                </div>
                <Common.Button disabled>Go to payment settings</Common.Button>
              </div>

              <div className="font-semibold mb-1.5">
                Who will pay Transaction Fee?
              </div>
              <div className="flex max-w-lg justify-between space-x-4">
                <div className="px-3 py-2.5 rounded border border-neutral-20 bg-neutral-10 flex-1">
                  <Common.Radio
                    wrapClassName="mb-0"
                    name="transactionFee"
                    label="ABC Bakery (You)"
                    disabled
                  />
                </div>
                <div className="px-3 py-2.5 rounded border border-neutral-20 bg-neutral-10 flex-1">
                  <Common.Radio
                    wrapClassName="mb-0"
                    name="transactionFee"
                    label="That Armore Cheese"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-neutral-20 my-8"></div>
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold mb-1.5 text-hd5">Payment Terms</div>
            </div>
            <div className="flex-1">
              <div className="font-semibold mb-1.5">
                Order delegation{" "}
                <span data-tip="Order email">
                  <Icon.InfoFill
                    size="12"
                    className="ml-2 fill-neutral-50 inline"
                  />
                </span>
              </div>
              <div className="mb-5">
                <Common.Selectbox
                  name="SelectBox"
                  onChange={handleSelectChange}
                  options={[
                    {
                      id: 1,
                      text: "Number of days from end of invoice month",
                      value: 1,
                    },
                    {
                      id: 2,
                      text: "Number of days from end of invoice month",
                      value: 2,
                    },
                  ]}
                />
              </div>

              <div className="font-semibold mb-1.5">
                Payment term days{" "}
                <span data-tip="Order email">
                  <Icon.InfoFill
                    size="12"
                    className="ml-2 fill-neutral-50 inline"
                  />
                </span>
              </div>
              <div className="mb-5">
                <Common.Input />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2 pt-4">
        {isEdit ? (
          <>
            <Common.Button color="transparent" onClick={() => setEdit(false)}>
              Cancel
            </Common.Button>
            <Common.Button onClick={() => setEdit(false)}>
              Save changes
            </Common.Button>
          </>
        ) : (
          <Common.Button onClick={() => setEdit(true)}>Edit</Common.Button>
        )}
      </div>

      <ModalInform
        open={isOpenPaymenmodal}
        title={"Remove 3 products from this pricing list?"}
        description={
          "Any future pricing updates may required manual adjustments. Would you like to proceed?"
        }
        onClose={() => setOpenPaymentModal(false)}
        onConfirm={() => setOpenPaymentModal(false)}
        confirmBtnText="Remove"
      />
    </div>
  );
};

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M0.795166 5.29016V15.0011C0.795166 15.3341 1.07262 15.5931 1.38707 15.5931H14.594C14.927 15.5931 15.1859 15.3156 15.1859 15.0011V5.29016H0.795166V5.29016ZM10.9871 9.00808C10.9871 9.34103 10.7096 9.59999 10.3952 9.59999H5.58592C5.25297 9.59999 4.99401 9.32253 4.99401 9.00808V7.80577C4.99401 7.47282 5.27147 7.21386 5.58592 7.21386H10.3952C10.7281 7.21386 10.9871 7.49132 10.9871 7.80577V9.00808Z" />
      <path d="M15.3896 0.406982H0.591908C0.25896 0.406982 0 0.684439 0 0.99889V4.49484H16V1.01739C15.9815 0.684439 15.7225 0.406982 15.3896 0.406982Z" />
    </SvgIcon>
  );
}

export default IconComponent;

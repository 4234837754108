import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { useCheckRole } from "@/providers/auth/hooks";
import { AccessDeniedPage } from "@/components";
import { ROLE } from "@/configuration/globalVariable";
import { userSelector } from "@/providers/auth/selector";
import { fetchStorageAddressAsync } from "./providers/deliveryManifest/sliceServices";

const Index = () => {
  const [date, setDate] = useState(new Date());
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);

  const checkRole = useCheckRole();

  useEnhancedEffect(() => {
    dispatch(fetchStorageAddressAsync());
  }, []);
  const checkPermission = checkRole(ROLE.ADMIN);

  return checkPermission ? (
    <Outlet context={[date, setDate]} />
  ) : (
    <>{!isEmpty(user) && <AccessDeniedPage />}</>
  );
};

export default Index;

import axios from "axios";
import { useMutation } from "react-query";
import { documentViolate } from "../models";

const API_PATH = "DeliverySchedule/";

export const useGetAffectedDocuments = () =>
  useMutation((scheduleId: string) => {
    return axios
      .post(`${API_PATH}GetAffectedDocuments`, {
        search: "",
        date: "",
        pageNumber: 1,
        pageSize: 1000,
        scheduleId,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data.items;
      })
      .catch((reason) => Promise.reject(reason.response));
  });

export const useCountAffectedDocuments = () =>
  useMutation((scheduleId: string) => {
    return axios
      .post(`${API_PATH}CountAffectedDocuments`, {
        scheduleId,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((reason) => Promise.reject(reason.response));
  });

export const useCheckDeliveryDate = () =>
  useMutation((data: { scheduleId: string; inputDate: string }) => {
    return axios
      .post(`${API_PATH}CheckDeliveryDay`, data)
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((reason) => Promise.reject(reason.response));
  });

export const useSaveAffectedDocuments = () =>
  useMutation((affectedDocuments: documentViolate[]) => {
    return axios
      .post(`${API_PATH}SaveAffectedDocuments`, {
        affectedDocuments,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      })
      .catch((reason) => Promise.reject(reason.response));
  });

import { LayoutPartials, Common } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../../components";

export const Radio = () => {
  return (
    <LayoutPartials.BodyContent className="mb-4" pageTitle="Radio Input">
      <ComponentInfo
        title="Radio input component"
        code={`import { Common } from "@/components";
        
<Common.Radio />`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  ipSize = "lg",
}`}
      />

      <DisplayComponent
        componentName="Radio input"
        className="mb-3"
        RenderComp={() => (
          <>
            <Common.Radio name="radio" id="radio1" label="radio 1" />
            <Common.Radio name="radio" id="radio2" label="radio 2" />
            <Common.Radio name="radio" id="radio3" label="radio 3" />
            <Common.Radio
              name="radio"
              id="radio4"
              label="radio 4"
              ipSize="md"
            />
          </>
        )}
        code={`<Common.Radio
  name="radio"
  id="radio1"
  label="radio 1"
/>
<Common.Radio
  name="radio"
  id="radio2"
  label="radio 2"
/>
<Common.Radio
  name="radio"
  id="radio3"
  label="radio 3"
/>
<Common.Radio
  name="radio"
  id="radio4"
  label="radio 4"
  ipSize="md"
/>`}
      />
    </LayoutPartials.BodyContent>
  );
};

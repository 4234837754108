import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M13.592 15.5639C13.824 15.4009 14.132 15.0989 14.132 14.3499C14.132 13.8179 13.771 13.4989 13 13.1689V15.8279C13.225 15.7589 13.432 15.6769 13.592 15.5639Z" />
      <path d="M12 0C5.383 0 0 5.383 0 12C0 18.617 5.383 24 12 24C18.617 24 24 18.617 24 12C24 5.383 18.617 0 12 0ZM16.132 14.351C16.132 15.9 15.376 16.756 14.741 17.202C14.244 17.551 13.645 17.759 13 17.879V20H11V17.963C9.849 17.876 8.675 17.602 7.66 17.236L6.72 16.897L7.399 15.016L8.34 15.356C9.26 15.688 10.174 15.886 11 15.962V12.453C9.411 11.883 7.648 10.993 7.648 8.731C7.648 7.603 8.171 6.672 9.122 6.11C9.664 5.79 10.316 5.617 11 5.536V4H13V5.551C14.177 5.688 15.255 6.011 15.856 6.343L16.733 6.824L15.77 8.577L14.894 8.095C14.461 7.857 13.754 7.663 13 7.558V11.022C14.528 11.562 16.132 12.344 16.132 14.351Z" />
      <path d="M10.1409 7.83291C9.79595 8.03691 9.64795 8.30491 9.64795 8.73091C9.64795 9.48791 10.0769 9.90091 11 10.3019V7.55591C10.672 7.61291 10.3699 7.69691 10.1409 7.83291Z" />
    </SvgIcon>
  );
}

export default IconComponent;

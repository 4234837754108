import { useEffect, useState, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import moment from "moment";
import { Images } from "@/assets/images";
import {
  ModalContent,
  Card,
  Common,
  Icon,
  Table,
  Th,
  Td,
  RedirectToOUI,
} from "@/components";
import { addressGenerate, currencyFormatter, percentFormatter } from "@/utils";
import { accountIdSelector } from "@/providers/auth/selector";
import { useAppSelector } from "@/sharedStore/hooks";
import { useLoadDoc } from "../services/deliveryManifest";
import {
  manifestLine,
  orderDetail,
  orderItem,
  manifest,
  referredDocument,
} from "../models/DeliveryManifest";
import { TIME_FORMAT } from "@/configuration/globalVariable";

interface Props {
  open: boolean;
  data?: manifestLine;
  routeInfo?: manifest;
  orderLineNumber?: number;
  ETATime: string;
  manifestETALoading?: boolean;
  onClose: () => void;
}

export const ModalOrderDetails = ({
  open,
  data,
  routeInfo,
  orderLineNumber,
  ETATime,
  manifestETALoading,
  onClose,
}: Props) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const accountId = useAppSelector(accountIdSelector);
  const loadDoc = useLoadDoc();
  const {
    data: loadDocData,
    isSuccess,
    isLoading,
  }: { data?: orderDetail; isSuccess: boolean; isLoading: boolean } = loadDoc;

  useEffect(() => {
    if (open) {
      data && loadDoc.mutate({ docId: data.document.id, accountId });
      ReactTooltip.rebuild();
    } else {
      ReactTooltip.hide();
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    isSuccess && ReactTooltip.rebuild();
  }, [isSuccess]);

  const results = useMemo(
    () =>
      loadDocData && loadDocData.documentLines
        ? loadDocData.documentLines.filter(
            (item: orderItem) =>
              item.productSKU
                .toUpperCase()
                .indexOf(searchKeyword.toUpperCase()) > -1 ||
              item.productName
                .toUpperCase()
                .indexOf(searchKeyword.toUpperCase()) > -1
          )
        : [],
    [loadDocData, searchKeyword]
  );

  return (
    <ModalContent
      open={open}
      onClose={onClose}
      panelClassWidth="max-w-[71.625rem] w-full"
    >
      <Card
        title={
          <div className="flex items-center">
            <div className="w-5 h-5 rounded-full flex items-center justify-center bg-neutral-10 text-neutral-50 mr-2">
              {orderLineNumber}
            </div>
            #{data?.document.saleDocumentNumber}
            {" - "}
            {data?.document.customerTradingName}
          </div>
        }
        isLoading={isLoading}
      >
        <div className="p-4">
          <div className="px-4 py-2 mb-5 border rounded border-blue-10 bg-blue-5 flex items-center">
            <span className="text-neutral-50 font-semibold mr-4">
              Route details:
            </span>
            <div>
              <Common.DotColor
                style={{ backgroundColor: routeInfo?.route.colour }}
                className="mr-2"
              />
            </div>
            <span className="font-semibold">{routeInfo?.route.name}</span>
            <span className="mx-4 text-neutral-30">|</span>
            <Icon.Driver size="16" className="fill-neutral-40" />
            <div className="ml-1.5 mr-4">
              {routeInfo?.driver.givenName} {routeInfo?.driver.lastName}
            </div>
            <div data-tip="Estimated time of arrival">
              <Icon.Clock size="12" className="fill-blue-80" />
            </div>
            <span className="ml-1.5 text-blue">
              {manifestETALoading ? <Icon.Loading className="ml-2" /> : ETATime}
            </span>
          </div>

          <div className="flex space-x-4 font-medium">
            <div className="w-1/3">
              <div className="flex space-x-6 mb-3">
                <span className="text-neutral-50 w-[8rem]">Customer:</span>
                <span className="flex-1">
                  {loadDocData?.accountBillingName}
                </span>
              </div>
              <div className="flex space-x-6 mb-3">
                <span className="text-neutral-50 w-[8rem]">
                  Delivery Address:
                </span>
                <span className="flex-1">
                  {loadDocData
                    ? addressGenerate(
                        loadDocData?.deliveryAddressLine1,
                        loadDocData?.deliveryAddressLine2,
                        loadDocData?.deliverySuburb,
                        loadDocData?.deliveryState,
                        loadDocData?.deliveryPostcode,
                        loadDocData?.deliveryCountry
                      )
                    : ""}
                </span>
              </div>
              <div className="flex space-x-6 mb-3">
                <span className="text-neutral-50 w-[8rem]">Note:</span>
                <span className="flex-1">{loadDocData?.note || "N/A"}</span>
              </div>
            </div>
            <div className="w-1/3">
              <div className="flex space-x-6 mb-3">
                <span className="text-neutral-50 w-[8rem]">Status:</span>
                <span className="flex-1">
                  {loadDocData?.workflowState && (
                    <Common.Label
                      style={{
                        backgroundColor: (loadDocData as orderDetail)
                          ?.workflowState.bgColor,
                        color: loadDocData?.workflowState.textColor,
                      }}
                    >
                      {loadDocData?.workflowState.name}
                    </Common.Label>
                  )}
                </span>
              </div>
              <div className="flex space-x-6 mb-3">
                <span className="text-neutral-50 w-[8rem]">
                  Billing Address:
                </span>
                <span className="flex-1">
                  {loadDocData
                    ? addressGenerate(
                        loadDocData?.billingAddressLine1,
                        loadDocData?.billingAddressLine2,
                        loadDocData?.billingSuburb,
                        loadDocData?.billingState,
                        loadDocData?.billingPostcode,
                        loadDocData?.billingCountry
                      )
                    : ""}
                </span>
              </div>
            </div>
            <div className="w-1/3">
              <div className="flex space-x-6 mb-3">
                <span className="text-neutral-50 w-[8rem]">Due date:</span>
                <span className="flex-1">
                  {loadDocData?.dueDate
                    ? moment(loadDocData?.dueDate).format(
                        TIME_FORMAT.GENERAL_DISPLAY
                      )
                    : "N/A"}
                </span>
              </div>
              <div className="flex space-x-6 mb-3">
                <span className="text-neutral-50 w-[8rem]">Delivery date:</span>
                <span className="flex-1">
                  {loadDocData?.deliveryDate
                    ? moment(loadDocData?.deliveryDate).format(
                        TIME_FORMAT.GENERAL_DISPLAY
                      )
                    : ""}
                </span>
              </div>
              <div className="flex space-x-6 mb-3">
                <span className="text-neutral-50 w-[8rem]">Delivery fee:</span>
                <span className="w-20 text-right">
                  {currencyFormatter.format(
                    loadDocData?.deliveryFeeAmount || 0
                  )}
                </span>
              </div>
              <div className="flex space-x-6">
                <span className="text-neutral-50 w-[8rem]">Total amount:</span>
                <span className="w-20 text-right">
                  {currencyFormatter.format(loadDocData?.total || 0)}
                </span>
              </div>
              <div className="text-neutral-50 text-xs italic flex items-center">
                <Icon.WarningCircle
                  size="10"
                  className="fill-neutral-50 mr-1"
                />{" "}
                inclusive of tax amount:{" "}
                {currencyFormatter.format(loadDocData?.totalTaxAmount || 0)}
              </div>
            </div>
          </div>
          <div className="flex space-x-6 mb-3 z-10">
            <span className="text-neutral-50 w-[8rem] whitespace-nowrap mr-4">
              Reference documents:
            </span>
            <span className="text-blue flex-1">
              {loadDocData &&
                loadDocData.referredDocuments &&
                loadDocData.referredDocuments.map(
                  (referredDocument: referredDocument) => (
                    <RedirectToOUI
                      className="font-semibold mr-3"
                      to={`/Sales/Documents/${
                        referredDocument.type === "OR"
                          ? "Orders/OrderDetail"
                          : "Invoices/InvoiceDetail"
                      }?id=${referredDocument.id}`}
                      key={referredDocument.id}
                    >
                      #{referredDocument.saleDocumentNumber} (
                      {referredDocument.type})
                    </RedirectToOUI>
                  )
                )}
            </span>
          </div>
          <div className="mt-2">
            <Common.Input
              iconRight={Icon.Search}
              placeholder="Search for product name or SKU"
              iconType="stroke"
              value={searchKeyword}
              disabled={isLoading}
              onChange={(e: any) => setSearchKeyword(e.target.value)}
              onClear={() => setSearchKeyword("")}
            />
          </div>
          <div className="h-[calc(100vh-35rem)] overflow-y-auto mt-5">
            {data && (
              <div>
                <Table>
                  <thead>
                    <tr>
                      <Th>#</Th>
                      <Th>SKU</Th>
                      <Th>Products</Th>
                      <Th>UNIT</Th>
                      <Th className="text-center">Quantity</Th>
                      <Th className="text-right">Comment</Th>
                      <Th className="text-right">Price</Th>
                      <Th className="text-right">TAX code</Th>
                      <Th className="text-right">Amount</Th>
                    </tr>
                  </thead>
                  {isSuccess && loadDocData?.documentLines.length && (
                    <tbody>
                      {!results.length && (
                        <tr>
                          <Td colSpan={9} className="text-center">
                            <Common.NoData
                              title={
                                searchKeyword
                                  ? `The "${searchKeyword}" in any documents`
                                  : "No document."
                              }
                            />
                          </Td>
                        </tr>
                      )}
                      {results.map((item: orderItem, index: number) => (
                        <tr
                          key={item.id}
                          className={clsx(index % 2 && "bg-neutral-5")}
                        >
                          <Td>{index + 1}</Td>
                          <Td>{item.productSKU}</Td>
                          <Td className="!whitespace-normal">
                            <div className="flex items-center">
                              <img
                                className="h-9 mr-3"
                                src={item.productImageUrl || Images.noImage}
                                alt={item.productName || ""}
                              />
                              <div className="flex-1 min-w-0">
                                <div className="w-full">{item.productName}</div>
                              </div>
                            </div>
                          </Td>
                          <Td>{item.unitOfMeasure}</Td>
                          <Td className="text-center">{item.quantity}</Td>
                          <Td className="text-right">
                            <div className="w-56">
                              <div className="flex items-center">
                                <div className="text-sm mr-2 flex-1 min-w-0 line-clamp-1 truncate !block">
                                  {item.comment}
                                </div>
                                {item.comment && (
                                  <div data-tip={item.comment}>
                                    <Icon.Comment
                                      size="12"
                                      className="stroke-blue"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Td>
                          <Td className="text-right">
                            {currencyFormatter.format(item.sellPrice)}
                          </Td>
                          <Td className="text-right">
                            {`${item.taxCode} (${percentFormatter.format(
                              item.taxPercentage
                            )})`}
                          </Td>
                          <Td className="text-right">
                            {currencyFormatter.format(
                              item.sellPrice * item.quantity
                            )}
                          </Td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {!loadDocData?.documentLines.length && (
                  <div className="h-[calc(100% - 2.438rem)]">
                    {isLoading && (
                      <Common.NoData
                        iconRender={Icon.Loading}
                        title="Order detail is loading...."
                      />
                    )}
                    {isSuccess && !loadDocData?.documentLines.length && (
                      <Common.NoData title="No order detail data." />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Card>
    </ModalContent>
  );
};

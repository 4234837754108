import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M2 11L8 5L14 11"
        fill="none"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default IconComponent;

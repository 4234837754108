import axios from "axios";
import { useMutation } from "react-query";

const API_PATH = "notification/";

export const useGetCountUnreadMessages = () =>
  useMutation(() =>
    axios
      .post(`${API_PATH}GetCountUnreadMessages`)
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetAccountMessages = () =>
  useMutation(
    (data: {
      pageSize: number;
      pageNumber: number;
      shouldIncludeArchivedMessages: boolean;
    }) =>
      axios
        .post(`${API_PATH}GetAccountMessages`, data)
        .then((res) => {
          return res.data.data;
        })
        .catch((reason) => Promise.reject(reason.response))
  );

import DropDowns from "./DropDowns";
import { DropdownItem } from "./DropDowns/DropdownItem";
import Button from "./Button";
import { ViewDetailBtn } from "./Button/ViewDetail";
import Input from "./Form/Input";
import { InputSearchContent } from "./Form/InputSearchContent";
import { Selectbox } from "./Form/Selectbox";
import { Checkbox } from "./Form/Checkbox";
import { Radio } from "./Form/Radio";
import { DatePicker } from "./Form/Datepicker";
import { TimePicker } from "./Form/TimePicker";
import { TimeRangePicker } from "./Form/TimeRangePicker";
import { CounterInput } from "./Form/CounterInput";
import { TabButton } from "./Tab/Button";
import { TabUnderline } from "./Tab/Underline";
import { Label } from "./Label";
import { DotColor } from "./DotColor";
import { CheckedCircle } from "./CheckedCircle";
import { NoData } from "./NoData";
import { Alert } from "./Alert";
import { Switch } from "./Switch";
import { CustomCombobox } from "./Form/CustomCombobox";
import ListBox from "./ListBox";
import { Timeline } from "./Timeline";

export const Common = {
  DropDowns,
  DropdownItem,
  Button,
  ViewDetailBtn,
  Input,
  Selectbox,
  InputSearchContent,
  CustomCombobox,
  Checkbox,
  Radio,
  Switch,
  DatePicker,
  TimePicker,
  TimeRangePicker,
  CounterInput,
  TabButton,
  TabUnderline,
  Label,
  DotColor,
  CheckedCircle,
  NoData,
  Alert,
  ListBox,
  Timeline,
};

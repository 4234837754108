import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 19 21">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.98 2.64094C11.8026 1.15337 10.5367 0 9.0013 0C7.46596 0 6.2 1.15337 6.02257 2.64094C3.5199 3.77546 1.77908 6.29555 1.77908 9.22222V14.2399L0.108582 17.5494C-0.227141 18.2145 0.25626 19 1.0013 19H6.17201C6.58385 20.1652 7.69509 21 9.0013 21C10.3075 21 11.4188 20.1652 11.8306 19H17.0013C17.7463 19 18.2297 18.2145 17.894 17.5494L16.2235 14.2399V9.22222C16.2235 6.29555 14.4827 3.77546 11.98 2.64094Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

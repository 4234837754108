import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  createAction,
} from "@reduxjs/toolkit";
import demoListSlice from "@/providers/demodata/demoListSlice";
import authSlice from "@/providers/auth/authSlice";

// Delivery slice
import deliverySlice from "@/pages/Delivery/providers/deliveryManifest/slice";
import scheduleSlice from "@/pages/SchedulePlanner/providers/schedulePlanner/slice";
import pantryListSlice from "@/pages/PantryList/providers/slice";

const rootReducer = combineReducers({
  demoListReducer: demoListSlice.reducer,
  authReducer: authSlice.reducer,
  deliverySlice: deliverySlice.reducer,
  scheduleSlice: scheduleSlice.reducer,
  pantryListReducer: pantryListSlice.reducer,
});

export const resetRootStateAction = createAction("@@RESET");

export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === "@@RESET") {
      state = undefined;
    }
    return rootReducer(state, action);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

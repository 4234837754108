import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  Common,
  Icon,
  LayoutPartials,
  GoogleMap,
  ModalInform,
} from "@/components";
import { EditRoutesListTab, EyeMapDropdown } from "../components";
import { PATH } from "@/configuration/globalVariable";
import { useAppSelector } from "@/sharedStore/hooks";
import { manifestsSelector } from "../providers/deliveryManifest/selector";

const tabs = [
  {
    name: "List View",
    icon: Icon.Organize,
    current: true,
  },
  {
    name: "View Map",
    icon: Icon.Map,
    current: false,
    disable: true,
  },
];

const EditRoutes = () => {
  const location = useLocation();
  const manifests = useAppSelector(manifestsSelector);
  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const [routeNumber, setRouteNumber] = useState<number>(
    (location.state as any)?.unallocate
      ? (location.state as any)?.routesActive.slice(1).length
      : (location.state as any)?.routesActive.length
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isOpenBackInformModal, setIsOpenBackInformModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Back to manifest if no data
    if (!(location.state as any)?.routesActive || !manifests) {
      navigate(`/${PATH.DELIVERY._}/${PATH.DELIVERY.DELIVERY_MANIFESTS}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditing]);

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      "There are some changes have been made. Are you sure to cancel all changes?";

    if (isEditing) {
      e.returnValue = message;
    } else {
      delete e.returnValue;
    }
    return message;
  };

  return (
    <LayoutPartials.BodyContent
      pageTitle={`Edit ${routeNumber} route${routeNumber > 1 ? "s" : ""}`}
      onTitleBack={() =>
        isEditing ? setIsOpenBackInformModal(true) : navigate(-1)
      }
      rightContent={
        <Common.TabButton
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      }
    >
      <div
        className={clsx(
          "w-full h-[calc(100vh-15.375rem)] relative",
          activeTab !== tabs[0].name && "hidden"
        )}
      >
        <EditRoutesListTab
          routeNumber={setRouteNumber}
          setEditing={setIsEditing}
        />
      </div>

      {/* Tab map */}
      {!tabs[1].disable && (
        <div
          className={clsx(
            "w-full h-[calc(100vh-12.625rem)] relative",
            activeTab !== tabs[1].name && "hidden"
          )}
        >
          <div className="ralative z-0 w-full h-full">
            <GoogleMap />
          </div>
          <div className="absolute bottom-3 right-3 z-10">
            <EyeMapDropdown />
          </div>
        </div>
      )}
      {/*  */}
      <ModalInform
        open={isOpenBackInformModal}
        type="warning"
        title="There are some changes have been made. Are you sure to cancel all changes?"
        onClose={() => setIsOpenBackInformModal(false)}
        groupBtn={
          <div className="mt-8 flex justify-center">
            <Common.Button
              color="transparent"
              onClick={() => setIsOpenBackInformModal(false)}
            >
              Close
            </Common.Button>
            <Common.Button className="ml-4" onClick={() => navigate(-1)}>
              Cancel editing
            </Common.Button>
          </div>
        }
      />
    </LayoutPartials.BodyContent>
  );
};

export default EditRoutes;

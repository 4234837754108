import { role } from "@/models";
import { RootState } from "../../sharedStore/store";

export const userSelector = (state: RootState) => state.authReducer.user;
export const accountsSelector = (state: RootState) =>
  state.authReducer.user?.activeAccounts;
export const accountIdSelector = (state: RootState) =>
  state.authReducer.user?.accountId;
export const userViewSelector = (state: RootState) =>
  state.authReducer.user?.userView;
export const userRolesSelector = (state: RootState) =>
  state.authReducer.user?.roles.map((i: role) => i.id);

import clsx from "clsx";
import { countDecimals } from "@/utils/extensions";
interface Props {
  defaultValue?: number;
  value: string | number;
  min?: number;
  max?: number;
  setValue: (input: string | number) => void;
  inputClass?: string;
}
export const CounterInput = ({
  defaultValue,
  value,
  setValue,
  min,
  max,
  inputClass,
}: Props) => {
  const parsedValue = Number.isNaN(Number(value)) ? 0 : Number(value);
  const decimalsCount = countDecimals(parsedValue);

  const setNewValue = (
    newValue: string | number,
    isParseToNumber: boolean = true
  ) => {
    if (isParseToNumber) {
      newValue = Number.isNaN(Number(newValue)) ? 0 : Number(newValue);
      if (min && newValue < min) newValue = min;
      if (max && newValue > max) newValue = max;
    }
    setValue(newValue);
  };

  const increase = () => {
    setNewValue((parsedValue + 1).toFixed(decimalsCount));
  };
  const decrease = () => {
    setNewValue((parsedValue - 1).toFixed(decimalsCount));
  };
  return (
    <div className="flex items-center h-10 w-full rounded-lg relative bg-transparent">
      <button
        className="bg-white h-full border-b border-t border-l border-neutral-20 w-10 rounded-l-lg cursor-pointer outline-none"
        onClick={decrease}
      >
        <span className="m-auto text-hd4 text-neutral-40">−</span>
      </button>
      <input
        className={clsx(
          "focus:border-t focus:border-b focus:border-t-neutral-20 focus:border-b-neutral-20 focus:outline-none focus:border-transparent",
          "border-t border-b border-t-neutral-20 border-b-neutral-20",
          "w-full h-full bg-white text-base text-center",
          inputClass
        )}
        defaultValue={defaultValue || 0}
        value={value || 0}
        onChange={(e) => {
          setNewValue(e.target.value, false);
        }}
        onBlur={(e) => {
          setNewValue(e.target.value);
        }}
        autoFocus
      />
      <button
        className="bg-white h-full border-b border-t border-r border-neutral-20 w-10 rounded-r-lg cursor-pointer outline-none"
        onClick={increase}
      >
        <span className="m-auto text-hd4 text-neutral-40">+</span>
      </button>
    </div>
  );
};

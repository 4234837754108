import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M1.23071 7.38464L3.23071 10.3846L6.15379 8.00003"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.2306 13.5385C10.326 13.5385 11.3968 13.2136 12.3076 12.6051C13.2184 11.9965 13.9283 11.1315 14.3475 10.1195C14.7667 9.10747 14.8763 7.99387 14.6626 6.91951C14.4489 5.84515 13.9214 4.8583 13.1469 4.08373C12.3723 3.30916 11.3855 2.78167 10.3111 2.56797C9.23674 2.35427 8.12314 2.46395 7.11112 2.88314C6.0991 3.30233 5.23411 4.01221 4.62554 4.92301C4.01696 5.8338 3.69214 6.90461 3.69214 8.00001V8.87101"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { Common } from "../common";
import {
  DeliveryLineStatus,
  DeliveryStatus,
} from "@/configuration/globalVariable";

interface Props {
  status: number;
  isManifestLine?: boolean;
  className?: string;
}

export const DeliveryStatusLabel = ({
  status,
  isManifestLine,
  className,
}: Props) => {
  const statusObj = isManifestLine
    ? DeliveryLineStatus[status as keyof typeof DeliveryLineStatus]
    : DeliveryStatus[status as keyof typeof DeliveryStatus];
  return (
    <Common.Label className={className} size="md" color={statusObj.colourClass}>
      {statusObj.label}
    </Common.Label>
  );
};

import {
  CUSDashboardTop,
  CUSRecentOrders,
  CustommerRequest,
  CUSPendingCredits,
  CUSRecentlyOrderedSuppliers,
} from "../components";

export const CustomerView = () => {
  return (
    <div className="xl:h-full">
      <CUSDashboardTop />
      <div className="flex-1 flex flex-col">
        <div className="mb-3 xl:flex">
          <CUSRecentOrders />
          <div className="lg:flex xl:flex-col xl:ml-3 xl:w-1/3 mt-3 xl:mt-0 lg:space-x-4 xl:space-x-0">
            <CUSRecentlyOrderedSuppliers />
          </div>
        </div>
        <div className="xl:flex">
          <CUSPendingCredits />
          <div className="lg:flex xl:flex-col xl:ml-3 xl:w-1/3 mt-3 xl:mt-0 lg:space-x-4 xl:space-x-0">
            <CustommerRequest />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerView;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M15.4267 3.8232L13.6767 2.0732C13.6298 2.02633 13.5662 2 13.4999 2C13.4337 2 13.3701 2.02633 13.3232 2.0732L5.74995 9.64645L2.6767 6.5732C2.62981 6.52633 2.56624 6.5 2.49995 6.5C2.43366 6.5 2.37008 6.52633 2.3232 6.5732L0.573197 8.3232C0.526329 8.37008 0.5 8.43366 0.5 8.49995C0.5 8.56624 0.526329 8.62981 0.573197 8.6767L5.5732 13.6767C5.62008 13.7236 5.68366 13.7499 5.74995 13.7499C5.81624 13.7499 5.87981 13.7236 5.9267 13.6767L15.4267 4.1767C15.4736 4.12981 15.4999 4.06624 15.4999 3.99995C15.4999 3.93366 15.4736 3.87008 15.4267 3.8232V3.8232Z" />
    </SvgIcon>
  );
}

export default IconComponent;

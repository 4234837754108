import { Icon, RedirectToOUI } from "@/components";
import { addressGenerate } from "@/utils";
import { useAppSelector } from "@/sharedStore/hooks";
import {
  storageAddressSelector,
  storageAddressLoadedSelector,
  storageAddressLoadingSelector,
} from "../providers/deliveryManifest/selector";
import { storageAddress } from "../models/DeliveryManifest";

export const StorageAddress = () => {
  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;
  const isLoaded = useAppSelector(storageAddressLoadedSelector);
  const isLoading = useAppSelector(storageAddressLoadingSelector);

  return (
    <div className="flex-1 flex items-center text-neutral-60 px-4">
      <div>
        <Icon.Shop className="fill-neutral-60 mr-2" />{" "}
      </div>
      {isLoaded ? (
        <>
          {!storageAddress && (
            <RedirectToOUI
              to={"/delivery/settings#tab2"}
              className="ml-2 font-semibold cursor-pointer text-blue hover:underline"
            >
              Add depot address.
            </RedirectToOUI>
          )}
          {storageAddress && (
            <>
              <span className="font-semibold whitespace-nowrap mr-2 hidden 2xl:inline-block">
                Depot address:{" "}
              </span>
              <span className="line-clamp-1 min-w-[10rem]">
                {addressGenerate(
                  storageAddress?.addressLine1,
                  storageAddress?.addressLine2,
                  storageAddress?.suburb,
                  storageAddress?.state,
                  storageAddress?.postcode,
                  storageAddress?.country
                )}
              </span>
              <RedirectToOUI
                to={"/delivery/settings#tab2"}
                className="ml-2 font-semibold cursor-pointer text-blue hover:underline"
              >
                change
              </RedirectToOUI>
            </>
          )}
        </>
      ) : (
        isLoading && <Icon.Loading className="ml-2" />
      )}
    </div>
  );
};

import { Common, Icon } from "@/components";
import clsx from "clsx";
import { useState } from "react";

interface Props {
  className?: string;
}

export const EditTabBasic = ({ className }: Props) => {
  const [isEdit, setEdit] = useState(false);

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { name, value } = e.target;
    console.log(name, ": ", value);
  };

  return (
    <div className={clsx("flex flex-col h-full", className)}>
      <div className="flex-1 relative">
        <div className="absolute top-0 left-0 w-full h-full overflow-y-auto">
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold mb-1.5 text-hd5">
                Business Infomation
              </div>
              <div className="text-neutral-60 max-w-[16.25rem]">
                Complete business profile are having better visibility on our
                system.
              </div>
            </div>
            <div className="flex-1">
              <div className="font-semibold mb-1.5">Business name</div>
              <div className="mb-5">That Armore Cheese</div>
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <div className="font-semibold mb-1.5">Business email</div>
                  <div className="mb-5">thatamorecheese@yopmail.com</div>
                </div>
                <div className="w-1/2">
                  <div className="font-semibold mb-1.5">
                    Business phone number
                  </div>
                  <div className="mb-5">0419837677</div>
                </div>
              </div>
              <div className="font-semibold mb-1.5">Delivery address</div>
              <div className="mb-5">
                166 Balnarring Road Merricks north, VIC, 3199, Australia
              </div>
              <div className="font-semibold mb-1.5">Billing address</div>
              <div className="mb-5">
                166 Balnarring Road Merricks north, VIC, 3199, Australia
              </div>
              <div className="text-neutral-50 mb-5">
                <Icon.InfoFill
                  size="12"
                  className="fill-neutral-50 mr-2 inline"
                />{" "}
                If the address is not correct please contact the customer
                directly or{" "}
                <a href="#" className="text-blue">
                  contact with TOM
                </a>{" "}
                for assistance
              </div>
              <div className="font-semibold mb-1.5">Status</div>
              <div>
                <Common.Checkbox label="Active" id="active" />
              </div>
            </div>
          </div>
          <div className="border-b border-neutral-20 my-8"></div>
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold text-hd5">Contact Infomation</div>
              <div className="text-neutral-60 max-w-[16.25rem]">
                Complete business profile are having better visibility on our
                system.
              </div>
            </div>
            <div className="flex-1">
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <div className="font-semibold mb-1.5">Contact name</div>
                  <div>John Smith</div>
                </div>
                <div className="w-1/2">
                  <div className="font-semibold mb-1.5">
                    Order email{" "}
                    <span data-tip="Order email">
                      <Icon.InfoFill
                        size="12"
                        className="ml-2 fill-neutral-50 inline"
                      />
                    </span>
                  </div>
                  <div>johnsmith@gmail.com</div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-b border-neutral-20 my-8"></div>
          <div className="flex space-x-4">
            <div className="w-[29.375rem]">
              <div className="font-semibold text-hd5">Delivery Route</div>
              <div className="text-neutral-60 max-w-[16.25rem]">
                Complete business profile are having better visibility on our
                system.
              </div>
            </div>
            <div className="flex-1">
              <div className="font-semibold mb-1.5">Route</div>
              {isEdit ? (
                <div className="">
                  <Common.Selectbox
                    name="SelectBox"
                    onChange={handleSelectChange}
                    options={[
                      { id: 1, text: "Option 1", value: 1 },
                      { id: 2, text: "Option 2", value: 2 },
                    ]}
                  />
                </div>
              ) : (
                <div>Default route</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2 pt-4">
        {isEdit ? (
          <>
            <Common.Button color="transparent" onClick={() => setEdit(false)}>
              Cancel
            </Common.Button>
            <Common.Button onClick={() => setEdit(false)}>
              Save changes
            </Common.Button>
          </>
        ) : (
          <Common.Button onClick={() => setEdit(true)}>Edit</Common.Button>
        )}
      </div>
    </div>
  );
};

import clsx from "clsx";
import { tab } from "@/models";
import { RedirectToOUI } from "@/components/RedirectToOUI";

interface Props {
  data: tab[];
  activeTab: string;
  setActiveTab: (name: string | number) => void;
  wrapClassName?: string;
  className?: string;
}

export const TabUnderline = ({
  data,
  activeTab,
  setActiveTab,
  wrapClassName,
  className,
}: Props) => {
  return (
    <div className={clsx("border-b border-neutral-20", wrapClassName)}>
      <nav className="-mb-px flex" aria-label="Tabs">
        {data.map((item) =>
          item.linkNavigate ? (
            <RedirectToOUI to={item.linkNavigate} key={item.name}>
              <span
                className={clsx(
                  item.name === activeTab || item.id === activeTab
                    ? "border-blue text-blue"
                    : "border-transparent text-neutral-50 hover:text-blue hover:border-blue",
                  "cursor-pointer whitespace-nowrap px-3 border-b-2 font-medium text-sm inline-flex items-center h-[3.125rem]"
                )}
              >
                {item.name}
                {typeof item.number === "number" && (
                  <span className="h-6 w-6 rounded-full bg-neutral-20 ml-2 flex items-center justify-center font-medium text-neutral-80">
                    {item.number}
                  </span>
                )}
              </span>
            </RedirectToOUI>
          ) : (
            <span
              key={item.name}
              onClick={() => setActiveTab(item.id || item.name)}
              className={clsx(
                item.name === activeTab || item.id === activeTab
                  ? "border-blue text-blue"
                  : "border-transparent text-neutral-50 hover:text-blue hover:border-blue",
                "cursor-pointer whitespace-nowrap px-3 border-b-2 font-medium text-sm inline-flex items-center h-[3.125rem]",
                className
              )}
            >
              {item.name}
              {typeof item.number === "number" && (
                <span className="h-6 w-6 rounded-full bg-neutral-20 ml-2 flex items-center justify-center font-medium text-neutral-80">
                  {item.number}
                </span>
              )}
            </span>
          )
        )}
      </nav>
    </div>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { manifest } from "../../models/DeliveryManifest";
// import { sortManifestLine } from "../../utils";
// import { store } from "@/sharedStore/store";
import { optimizeRequest } from "../../models";

const API_PATH = "Delivery/";

export const fetchOptimizeRequestJobIdAsync = createAsyncThunk(
  "optimizeRequestJobId",
  async (data: optimizeRequest) => {
    return axios.post(`${API_PATH}Optimize`, data).then((res) => {
      return res.data.data;
    });
  }
);

export const fetchoptimizeByJobIdAsync = createAsyncThunk(
  "optimizeByJobId",
  async (jobId: string) => {
    return axios
      .get(`${API_PATH}Optimize`, {
        params: {
          jobId,
        },
      })
      .then((res) => {
        return res.data.data;
      });
  }
);

import React from "react";
import { GOOGLE_MAP_ID } from "@/configuration/globalVariable";
// import { useDeepCompareEffectForMaps } from "./utils";

const Map = ({
  onClick,
  onIdle,
  children,
  className,
  allMarkerlatlng,
  ...options
}: any) => {
  const ref = React.useRef(null);
  const [map, setMap] = React.useState<any>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          mapId: GOOGLE_MAP_ID,
        } as google.maps.MapOptions)
      );
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (map) {
      // Fit all marker in map view after loaded manifests of date
      const latlngbounds = new google.maps.LatLngBounds();
      for (let i = 0; i < allMarkerlatlng.length; i++) {
        latlngbounds.extend(allMarkerlatlng[i]);
      }
      map.fitBounds(latlngbounds);
    }
  }, [map, allMarkerlatlng]);

  if (map) {
    map.setOptions(options as google.maps.MapOptions);
  }
  // console.log("map2: ", map);
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  // useDeepCompareEffectForMaps(() => {
  //   if (map) {
  //     map.setOptions(options);
  //   }
  // }, [map, options]);

  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );

      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} className={className} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map } as any);
        }
      })}
    </>
  );
};

export default Map;

import clsx from "clsx";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Common, CustomScrollbar, Icon } from "@/components";
import { manifest } from "@/pages/Delivery/models";

interface Props {
  manifests?: manifest[];
  onShowHideClick: (route: manifest) => void;
  routesActiveShow: manifest[];
}

export const EyeMapDropdown = ({
  manifests,
  onShowHideClick,
  routesActiveShow,
}: Props) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <div>
          <Menu.Button>
            <div className="w-7 h-7 inline-flex items-center justify-center bg-blue hover:blue-dark rounded">
              {!open ? (
                <Icon.EyeOpen size="14" className="fill-white" />
              ) : (
                <Icon.Close size="14" className="stroke-white" />
              )}
            </div>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute bottom-9 mt-2 w-64 rounded-md shadow-lg bg-white">
              <div className="py-1">
                <div
                  className={clsx(
                    manifests && manifests?.length > 8 && "h-[20rem]"
                  )}
                >
                  <CustomScrollbar
                    renderView={(props) => (
                      <div
                        {...props}
                        className={clsx(
                          manifests && manifests?.length > 8
                            ? "!absolute"
                            : "!static !overflow-auto w-100 !h-100 !m-0"
                        )}
                      />
                    )}
                  >
                    {manifests?.length ? (
                      manifests.map((item: manifest, index) => (
                        <div
                          key={item.route.name}
                          className={clsx(
                            "flex items-center p-3",
                            Boolean(index) && "shadow-overline"
                          )}
                        >
                          <Common.DotColor
                            style={{ backgroundColor: item.route.colour }}
                          />
                          <div className="font-semibold text-sm flex-1 mx-1.5">
                            {item.route.name}
                          </div>
                          <button onClick={() => onShowHideClick(item)}>
                            {routesActiveShow.some(
                              (acItem: manifest) =>
                                acItem?.id === item.id &&
                                item.manifestLines.length
                            ) ? (
                              <Icon.EyeOn size="14" className="stroke-blue" />
                            ) : (
                              <Icon.EyeOff size="14" className="stroke-blue" />
                            )}
                          </button>
                        </div>
                      ))
                    ) : (
                      <Common.NoData
                        iconRender={Icon.Split}
                        title="No route."
                      />
                    )}
                  </CustomScrollbar>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

import { useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import {
  Card,
  Common,
  Icon,
  RedirectToOUI,
  Table,
  Td,
  Th,
  CustomScrollbar,
} from "@/components";
// import { CustommerRequestItem } from "./CustommerRequestItem";
import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector } from "@/providers/auth/selector";

import { useGetRecentlyOrderedSuppliers } from "../services/dashboard";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import { imgUrl } from "@/utils";
import { recentlyOrderedSupplier } from "../models";

export const CUSRecentlyOrderedSuppliers = () => {
  // const [listCustomer, setListCustomer] = useState<any>([]);
  const user = useAppSelector(userSelector);
  const getRecentlyOrderedSuppliers = useGetRecentlyOrderedSuppliers();
  const { data, isLoading } = getRecentlyOrderedSuppliers;

  useEffect(() => {
    getRecentlyOrderedSuppliers.mutate({
      defaultView: user.userView,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Card
      title={"Recently Ordered Suppliers"}
      className="mb-4 xl:mb-0 lg:h-96 xl:h-1/2 flex-1 xl:flex-auto"
      pathViewAll={"/relationship/accounts/mysuppliers"}
      isLoading={isLoading}
    >
      <div className="h-full w-full py-2 lg:absolute">
        <CustomScrollbar
          renderView={(props) => (
            <div {...props} className="!static lg:!absolute" />
          )}
        >
          <div className="px-5">
            <Table>
              <thead>
                <tr>
                  <Th></Th>
                  <Th className="w-28">Name</Th>
                  <Th>Category</Th>
                  <Th>Last order</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              {data && Boolean(data?.length) && (
                <tbody className="bg-white">
                  {data.map((item: recentlyOrderedSupplier, i: number) => (
                    <tr
                      key={i + item.id}
                      className={clsx(i % 2 && "lg:bg-neutral-5")}
                    >
                      <Td className="!pl-0">
                        <div
                          className="w-8 h-8 rounded-full bg-cover bg-center"
                          style={{
                            backgroundImage: `url(${imgUrl(item.logo, "img")})`,
                          }}
                        />
                      </Td>
                      <Td>
                        <div className="w-28 whitespace-normal">
                          {item.name}
                        </div>
                      </Td>
                      <Td>{item.categories}</Td>
                      <Td>
                        {item.lastOrderedDate
                          ? moment(item.lastOrderedDate).format(
                              TIME_FORMAT.GENERAL_DISPLAY
                            )
                          : "N/A"}
                      </Td>
                      <Td className="!pr-0">
                        <RedirectToOUI
                          to={`/purchases/placeorder/documents/placeorder?supplierid=${item.id}`}
                        >
                          <Common.Button btSize="sm">Order</Common.Button>
                        </RedirectToOUI>
                      </Td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
        </CustomScrollbar>
      </div>
      {/* )} */}
      {!isLoading && !data?.length && (
        <Common.NoData
          iconRender={Icon.TeamRounded}
          title="No recently ordered suppliers data."
        />
      )}
      {isLoading && (
        <Common.NoData
          iconRender={Icon.TeamRounded}
          title="Loading recently ordered suppliers..."
        />
      )}
    </Card>
  );
};

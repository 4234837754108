import { ReactNode, MouseEvent, useEffect } from "react";
import { Menu } from "@headlessui/react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";

interface Props {
  icon?: ReactNode;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export const DropdownItem = ({
  icon,
  children,
  className,
  onClick,
  disabled,
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  });

  return (
    <Menu.Item>
      <button
        onClick={onClick}
        className={clsx(
          "px-4 py-2 flex items-center hover:bg-neutral-5 cursor-pointer whitespace-nowrap w-full",
          disabled && "pointer-events-none bg-neutral-20 opacity-50",
          className
        )}
      >
        {icon && icon}
        {children}
      </button>
    </Menu.Item>
  );
};

export const WEEKDAY = [
  "",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const LISTWEEKDAYDISPLAY = [
  { id: 1, title: "Monday" },
  { id: 2, title: "Tuesday" },
  { id: 3, title: "Wednesday" },
  { id: 4, title: "Thursday" },
  { id: 5, title: "Friday" },
  { id: 6, title: "Saturday" },
  { id: 0, title: "Sunday" },
];

export const LIMIT_SCHEDULE_PER_PAGE = [10, 20, 50, 100];

export const CUT_OFF_TIME_REG = /^(1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM)$/i;

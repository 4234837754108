import { Fragment, forwardRef, useImperativeHandle } from "react";
import clsx from "clsx";
import { Menu, Transition } from "@headlessui/react";

interface Props {
  className?: string;
  listClassName?: string;
  buttonRender: JSX.Element;
  listRender: JSX.Element;
  disabled?: boolean;
  onOpen?: (open: boolean) => void;
  id?: string;
}

const Index = forwardRef(
  (
    {
      className,
      listClassName,
      buttonRender,
      listRender,
      disabled,
      onOpen,
      id,
    }: Props,
    // eslint-disable-next-line
    ref
  ) => {
    let op: boolean = false;
    useImperativeHandle(ref, () => ({
      isOpen: {
        op,
      },
    }));

    return (
      <Menu
        as="div"
        className={clsx("relative flex-shrink-0 group block", className)}
        id={id}
      >
        {(open) => {
          op = open?.open;
          onOpen && onOpen(Boolean(open?.open));
          return (
            <>
              <Menu.Button disabled={disabled}>{buttonRender}</Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={clsx(
                    "origin-top-right absolute right-0 mt-2 min-w-56 rounded-md shadow-modal bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10",
                    listClassName
                  )}
                >
                  <div>{listRender}</div>
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    );
  }
);

export default Index;

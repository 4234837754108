import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { DeliveryScheduleType, scheduleDetail } from "../../models";

const API_PATH_SCHEDULE = "DeliverySchedule/";

export const fetchListDeliveryScheduleAsync = createAsyncThunk(
  "fetchListDeliverySchedule",
  async () => {
    return axios.post(`${API_PATH_SCHEDULE}GetSchedules`).then((res) => {
      if (res.data.errorMessage) {
        return res.data;
      }
      return res.data.data;
    });
  }
);

export const setDefaultScheduleAsync = createAsyncThunk(
  "fetchListDeliverySchedule",
  async (scheduleId: string) => {
    return axios
      .post(`${API_PATH_SCHEDULE}SetDefaultSchedule`, {
        scheduleId: scheduleId,
      })
      .then((res) => {
        return res.data.isError;
      });
  }
);

export const fetchDetailScheduleAsync = createAsyncThunk(
  "fetchDetailSchedule",
  async (scheduleId: string) => {
    return axios
      .post(`${API_PATH_SCHEDULE}GetScheduleDetail`, {
        scheduleId: scheduleId,
      })
      .then((res) => {
        if (res.data.errorMessage) {
          return res.data;
        }
        return res.data.data;
      });
  }
);

export const renameScheduleAsync = createAsyncThunk(
  "renameSchedule",
  async ({ scheduleId, name }: { scheduleId: string; name: string }) => {
    return axios
      .post(`${API_PATH_SCHEDULE}RenameSchedule`, {
        scheduleId,
        name,
      })
      .then((res) => {
        return res.data.isError;
      });
  }
);

export const createScheduleAsync = createAsyncThunk(
  "createSchedule",
  async (name: string) => {
    const response = await axios.post(`${API_PATH_SCHEDULE}CreateNewSchedule`, {
      name,
    });
    if (response.data.errorMessage) {
      return response.data;
    }
    return response.data.data;
  }
);

export const checkNewScheduleAsync = createAsyncThunk(
  "checkNewSchedule",
  async (name: string) => {
    const response = await axios.post(`${API_PATH_SCHEDULE}CheckNewSchedule`, {
      name,
    });
    return response.data.data;
  }
);

export const dublicateScheduleAsync = createAsyncThunk(
  "dublicateSchedule",
  async ({ name, scheduleId }: { name: string; scheduleId: string }) => {
    const response = await axios.post(`${API_PATH_SCHEDULE}DublicateSchedule`, {
      name,
      scheduleId,
    });
    if (response.data.errorMessage) {
      return response.data;
    }
    return response.data.data;
  }
);

export const deleteScheduleAsync = createAsyncThunk(
  "deleteSchedule",
  async (scheduleId: string) => {
    const response = await axios.post(`${API_PATH_SCHEDULE}DeleteSchedule`, {
      scheduleId,
    });
    return response.data.data;
  }
);

export const saveChangeScheduleAsync = createAsyncThunk(
  "saveChangeSchedule",
  async (data: scheduleDetail) => {
    const response = await axios.post(
      `${API_PATH_SCHEDULE}SaveChangeSchedule`,
      data
    );
    return response.data.data;
  }
);

export const checkIsDeleteScheduleAsync = createAsyncThunk(
  "checkIsDeleteSchedule",
  async (scheduleId: string) => {
    const response = await axios.post(`${API_PATH_SCHEDULE}IsDeleteSchedule`, {
      scheduleId,
    });
    return response.data.data;
  }
);

const initialState = {
  loading: false,
  loaded: false,

  scheduleList: [] as DeliveryScheduleType[],
};

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchListDeliveryScheduleAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchListDeliveryScheduleAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.scheduleList = action.payload.scheduleList;
      })
      .addCase(fetchDetailScheduleAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDetailScheduleAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(dublicateScheduleAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(dublicateScheduleAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteScheduleAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteScheduleAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(renameScheduleAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(renameScheduleAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createScheduleAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createScheduleAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(checkIsDeleteScheduleAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkIsDeleteScheduleAsync.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default scheduleSlice;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path d="M5 1H9L8.33333 8H5.66667L5 1Z" />
      <circle cx="7" cy="11" r="2" />
    </SvgIcon>
  );
}

export default IconComponent;

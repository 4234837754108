import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M2.13333 0.5H13.8667C14.7687 0.5 15.5 1.23127 15.5 2.13333V13.8667C15.5 14.7687 14.7687 15.5 13.8667 15.5H2.13333C1.23127 15.5 0.5 14.7687 0.5 13.8667V2.13333C0.5 1.23127 1.23127 0.5 2.13333 0.5Z"
        fill="#F1F5F9"
        stroke="#E2E8F0"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { useState } from "react";
import { Outlet } from "react-router-dom";
import { LayoutPartials } from "./LayoutPartials";
import withAuthGuard from "@/hocs/withAuthGuard";

const Index = () => {
  const [isOpenSidebar, setOpenSidebar] = useState(false);

  return (
    <div className="flex flex-col h-screen">
      <LayoutPartials.Header
        onHamburgerClick={() => setOpenSidebar(!isOpenSidebar)}
      />
      <div className="flex h-[calc(100vh-3.75rem)] relative md:static">
        <LayoutPartials.Sidebar
          mobileShow={isOpenSidebar}
          onCloseSidebarClick={() => setOpenSidebar(!isOpenSidebar)}
        />

        <div className="overflow-y-auto h-full w-full p-3 md:p-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default withAuthGuard(Index);

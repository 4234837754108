import { LayoutPartials, Common } from "@/components";
import { ComponentInfo, DisplayComponent } from "../../components";

const Index = () => {
  return (
    <LayoutPartials.BodyContent pageTitle="Label">
      <div className="text-hd5 font-medium pb-4 mb-4 shadow-underline">
        Label components
      </div>

      <ComponentInfo
        title="Import component"
        code={`import { Common } from "@/components";

<Common.Label>Label Text</Common.Label>`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  size?: "lg" | "md" | "sm";
  children: React.ReactNode;
  onRemove?: () => void;
  color?: "neutral" | "red" | "blue" | "yellow" | "green";
  isStatus?: boolean;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  size = "lg",
  color = "neutral",
  children,
  onRemove,
  isStatus = false,
}`}
      />

      <div className="text-hd3">Lables with size</div>
      <DisplayComponent
        RenderComp={() => (
          <div className="space-x-4 space-y-4 mb-2">
            <Common.Label>Delivering</Common.Label>
            <Common.Label size="md">Delivering</Common.Label>
            <Common.Label size="sm">Delivering</Common.Label>
            <Common.Label onRemove={() => {}}>Delivering</Common.Label>
          </div>
        )}
        code={`<Common.Label>Delivering</Common.Label>
<Common.Label size="md">Delivering</Common.Label>
<Common.Label size="sm">Delivering</Common.Label>
<Common.Label onRemove={() => {}}>Delivering</Common.Label>`}
      />
      <div className="text-hd3">Lable with colors</div>
      <DisplayComponent
        RenderComp={() => (
          <div className="space-x-4 space-y-4 mb-2">
            <Common.Label color="red">Delivering</Common.Label>
            <Common.Label color="blue">Delivering</Common.Label>
            <Common.Label color="green">Delivering</Common.Label>
            <Common.Label color="yellow">Delivering</Common.Label>
          </div>
        )}
        code={`<Common.Label color="red">Delivering</Common.Label>
<Common.Label color="blue">Delivering</Common.Label>
<Common.Label color="green">Delivering</Common.Label>
<Common.Label color="yellow">Delivering</Common.Label>`}
      />
      <div className="text-hd3">Lable with colors and status</div>
      <DisplayComponent
        RenderComp={() => (
          <div className="space-x-4 space-y-4 mb-2">
            <Common.Label isStatus color="red">
              Delivering
            </Common.Label>

            <Common.Label isStatus color="blue">
              Delivering
            </Common.Label>

            <Common.Label isStatus color="green">
              Delivering
            </Common.Label>

            <Common.Label isStatus color="yellow">
              Delivering
            </Common.Label>
          </div>
        )}
        code={`<Common.Label isStatus color="red">
  Delivering
</Common.Label>

<Common.Label isStatus color="blue">
  Delivering
</Common.Label>

<Common.Label isStatus color="green">
  Delivering
</Common.Label>

<Common.Label isStatus color="yellow">
  Delivering
</Common.Label>`}
      />
    </LayoutPartials.BodyContent>
  );
};

export default Index;

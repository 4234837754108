import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { detect } from "detect-browser";
import { SnackbarUtils } from "@/utils";
import { Common } from "@/components/common";
import { Icon } from "@/components/Icons";
import AddMulProductsModal from "./components/AddMultiProductsModal";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import {
  initPantryListSelector,
  isPantryListLoadingSelector,
  pantryListSelector,
  searchKeywordsSelector,
} from "./providers/selector";
import {
  deleteLines,
  endEdit,
  fetchPantryListAsync,
  setIsIframe,
  setPantryMode,
  setSearchKeywords,
  switchShowFavouriteOnly,
  tryEdit,
} from "./providers/slice";
import SwitchToggle from "@/components/common/Form/Switch";
import {
  useEndEdit,
  useSavePantryList,
  useTryEdit,
} from "./services/pantryList";
import { EPantryMode } from "./models/EPantryMode";
import ChangeAutoModeModal from "./components/ChangeAutoModeModal";
import { RedirectToOUI } from "@/components";
import {
  useGetFilterPantryLines,
  useGetSelectedPantryLines,
  useIsPantryListEdited,
} from "./providers/hooks";
import AutoNumberProductInput from "./components/AutoNumberProductInput";
import PantryModeListBox from "./components/PantryModeListBox";
import HistoryLogsModal from "./components/HistoryLogsModal";
import clsx from "clsx";
import PantryLines from "./components/PantryLines";

const Index = (props: { isIframe?: boolean }) => {
  const browser = detect();

  const dispatch = useAppDispatch();
  const pantry = useAppSelector(pantryListSelector);
  const initPantry = useAppSelector(initPantryListSelector);
  const isPantryLoading = useAppSelector(isPantryListLoadingSelector);
  const searchKeywords = useAppSelector(searchKeywordsSelector);
  const isPantryEdited = useIsPantryListEdited()();

  // Load tooltips
  useEffect(() => {
    dispatch(setIsIframe(props.isIframe || false));
    ReactTooltip.rebuild();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isIframe]);

  const alertEditedMsg =
    "The pantry list has been changed. Make sure you save all the changes before exit.";
  // Alert if edited
  useEffect(() => {
    window.onbeforeunload = (event) => {
      if (isPantryEdited) {
        const e = event || window.event;
        if (e) {
          e.returnValue = alertEditedMsg;
        }
        return alertEditedMsg;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pantry.pantryLines]);

  const [isShowAddMulProductsModal, setShowAddMulProductsModal] =
    useState(false);
  const [isShowChangeAutoModal, setShowChangeAutoModal] = useState(false);
  const [isShowHistoryLogsModal, setShowHistoryLogsModal] = useState(false);
  // const [searchKeywords, setSearchKeywords] = useState("");

  const getSelectedLines = useGetSelectedPantryLines();
  const selectedLines = getSelectedLines();

  const filteredLines = useGetFilterPantryLines();

  const tryEditMuta = useTryEdit();
  const endEditMuta = useEndEdit();
  const savePantryListMuta = useSavePantryList();

  const onTryEdit = async () => {
    const result = await tryEditMuta.mutateAsync(pantry.id);
    if (!result?.errorMessage) {
      dispatch(tryEdit());
    }
  };

  const onEndEdit = async () => {
    if (!isPantryEdited || (isPantryEdited && window.confirm(alertEditedMsg))) {
      const result = await endEditMuta.mutateAsync(pantry.id);
      if (!result?.errorMessage) {
        dispatch(endEdit());
      }
    }
  };

  const onClickSaveChanges = async () => {
    console.log(JSON.stringify(pantry));
    const result = await savePantryListMuta.mutateAsync(pantry);
    if (!result?.errorMessage) {
      dispatch(
        fetchPantryListAsync({
          id: initPantry.id,
          customerAccountId: initPantry.customerAccountId,
          supplierAccountId: initPantry.supplierAccountId,
        })
      );
      SnackbarUtils.success("Save successfully");
    }
  };

  const onChangePantryMode = async (value: EPantryMode) => {
    if (value === EPantryMode.Auto) {
      setShowChangeAutoModal(true);
    } else {
      dispatch(setPantryMode(value));
    }
  };

  return (
    <>
      <div
        className={clsx(
          "flex flex-col p-4 bg-white rounded shadow",
          props.isIframe ? "h-screen" : "h-full",
          isPantryLoading && "relative"
        )}
      >
        {/* Uncomment these code if want to loading the whole page */}
        {/* {isPantryLoading && (
          <div className="absolute top-0 left-0 h-full w-full z-10 bg-black bg-opacity-30 flex justify-center items-center rounded">
            <Icon.Loading size="28" />
          </div>
        )} */}
        {!props.isIframe && (
          <div className="flex items-center text-hd5 font-medium pb-4 mb-4 shadow-underline">
            <RedirectToOUI
              to={`/relationship/accounts/editcustomer/${pantry.customerAccountId}`}
            >
              <Icon.Back iconType="fill" className="fill-neutral-90" />
            </RedirectToOUI>
            <p className="mx-2">
              {isPantryLoading ? "..." : pantry.customerTradingName}
            </p>
            {!isPantryLoading && pantry.customerIsVerified && (
              <Icon.CheckedCircle iconType="fill" className="fill-neutral-90" />
            )}
          </div>
        )}

        <div className="flex items-center pb-4">
          <p className="font-bold">
            Pantry list of{" "}
            {isPantryLoading ? "..." : pantry.customerTradingName}
          </p>
          <div className="p-2 mx-2 flex items-center text-sm bg-neutral-10  rounded">
            <p># Product: </p>
            <p className="ml-1 font-bold">
              {isPantryLoading
                ? "..."
                : filteredLines?.filter((l) => !l.isEditing).length}
            </p>
            {pantry.isEditing && (
              <>
                <p className="mx-2 text-neutral-30">|</p>
                <Icon.Trash size="12" className="fill-red" />
                <p className="mx-2">Removed:</p>
                <p className="font-bold">
                  {
                    pantry.pantryLines.filter((l) => l.isDeleted && !l.isNew)
                      .length
                  }
                </p>
                <p className="mx-2 text-neutral-30">|</p>
                <Icon.AddCircle size="12" className="fill-blue" />
                <p className="mx-2">Added:</p>
                <p className="font-bold">
                  {
                    pantry.pantryLines.filter(
                      (l) => l.isNew && !l.isEditing && !l.isDeleted
                    ).length
                  }
                </p>
              </>
            )}
          </div>

          <div className="ml-auto flex items-center">
            <PantryModeListBox onChange={onChangePantryMode} />

            {!pantry.isEditing && browser?.name !== "safari" && (
              <RedirectToOUI
                target={"_blank"}
                to={`/orderforms/forms/print?id=${pantry.id}&supplierAccountId=${pantry.supplierAccountId}&customerAccountId=${pantry.customerAccountId}&type=0`}
              >
                <Common.Button
                  btSize={"lg"}
                  iconType="fill"
                  iconLeft={Icon.Print}
                  outline
                  className="ml-2"
                  isLoading={isPantryLoading}
                  disabled={isPantryLoading}
                >
                  Print
                </Common.Button>
              </RedirectToOUI>
            )}
            {!pantry.isEditing && (
              <Common.Button
                btSize={"lg"}
                iconType="fill"
                iconLeft={Icon.Edit}
                className="ml-2"
                outline
                onClick={onTryEdit}
                isLoading={isPantryLoading}
                disabled={isPantryLoading}
              >
                Edit
              </Common.Button>
            )}
            <Common.Button
              btSize={"lg"}
              iconType="fill"
              iconLeft={Icon.History}
              outline
              className="ml-2"
              onClick={() => setShowHistoryLogsModal(true)}
            ></Common.Button>
          </div>
        </div>

        <div className="p-2 flex items-center border border-line">
          <div className="w-1/4">
            <Common.Input
              iconRight={Icon.Search}
              iconType="stroke"
              className="flex-none"
              placeholder="Filter the pantry list by SKU or product name..."
              value={searchKeywords || ""}
              onChange={(e) =>
                dispatch(setSearchKeywords(e.target.value || ""))
              }
              onClear={() => dispatch(setSearchKeywords(""))}
            />
          </div>

          <SwitchToggle
            className="mx-2"
            label="Show only favourites"
            labelPosition="front"
            checked={pantry.isShowFavouriteOnly}
            onChange={() => {
              dispatch(switchShowFavouriteOnly());
            }}
          />

          <div className="ml-auto flex items-center">
            {selectedLines?.length > 0 && (
              <>
                <p className="mx-1 text-blue font-bold">
                  {selectedLines?.length}
                </p>
                <p>products selected</p>
              </>
            )}

            {pantry.isEditing &&
              pantry.mode === EPantryMode.Manual &&
              selectedLines?.length === 0 && (
                <Common.Button
                  btSize={"lg"}
                  iconType="fill"
                  iconLeft={Icon.Trash}
                  outline
                  disabled
                  className="mx-2"
                >
                  Remove
                </Common.Button>
              )}
            {pantry.isEditing &&
              pantry.mode === EPantryMode.Manual &&
              selectedLines?.length > 0 && (
                <Common.Button
                  btSize={"lg"}
                  iconType="fill"
                  iconLeft={Icon.Trash}
                  outline
                  color={"red"}
                  className="mx-2 border border-red"
                  onClick={() => {
                    dispatch(deleteLines(selectedLines?.map((l) => l.id)));
                  }}
                >
                  Remove
                </Common.Button>
              )}

            {pantry.isEditing && pantry.mode === EPantryMode.Manual && (
              <Common.Button
                btSize={"lg"}
                iconType="fill"
                iconLeft={Icon.PlusBorder}
                className="ml-2"
                outline
                onClick={() => setShowAddMulProductsModal(true)}
              >
                Add multiple products
              </Common.Button>
            )}
            {pantry.isEditing && pantry.mode === EPantryMode.Auto && (
              <>
                <p>Number of products:</p>
                <div className="mx-1 w-24">
                  <AutoNumberProductInput />
                </div>
              </>
            )}
          </div>
        </div>

        <PantryLines />

        {pantry.isEditing && (
          <div className="pt-2 flex items-center">
            {pantry.pantryLines.some(
              (l) => !l.isFromProductAgreement && !l.isDeleted && !l.isEditing
            ) && (
              <>
                <Icon.WarningCircle
                  iconType="fill"
                  className="mx-2 fill-yellow-dark"
                  size={12}
                />

                <div className="flex flex-col">
                  <p>
                    Some products are not in {pantry.customerTradingName}&apos;s
                    agreed list.
                  </p>
                  <p>
                    They will be also added to the agreed list after saving
                    changes.
                  </p>
                </div>
              </>
            )}

            <Common.Button
              className="ml-auto"
              color={"transparent"}
              btSize={"lg"}
              outline
              onClick={onEndEdit}
              isLoading={isPantryLoading || savePantryListMuta.isLoading}
              disabled={isPantryLoading || savePantryListMuta.isLoading}
            >
              Cancel
            </Common.Button>
            <Common.Button
              color={"blue"}
              btSize={"lg"}
              onClick={onClickSaveChanges}
              isLoading={isPantryLoading || savePantryListMuta.isLoading}
              disabled={isPantryLoading || savePantryListMuta.isLoading}
            >
              Save changes
            </Common.Button>
          </div>
        )}
      </div>
      <AddMulProductsModal
        isOpen={isShowAddMulProductsModal}
        onClose={() => {
          setShowAddMulProductsModal(false);
        }}
        onOverlayClick={() => {
          setShowAddMulProductsModal(false);
        }}
        onCancel={() => {
          setShowAddMulProductsModal(false);
        }}
        onSubmit={() => {
          setShowAddMulProductsModal(false);
        }}
      />

      <ChangeAutoModeModal
        isOpen={isShowChangeAutoModal}
        onClose={() => {
          setShowChangeAutoModal(false);
        }}
        onOverlayClick={() => {
          setShowChangeAutoModal(false);
        }}
        onCancel={() => {
          setShowChangeAutoModal(false);
        }}
        onSubmit={() => {
          setShowChangeAutoModal(false);
        }}
      />

      <HistoryLogsModal
        isOpen={isShowHistoryLogsModal}
        onClose={() => setShowHistoryLogsModal(false)}
        onOverlayClick={() => setShowHistoryLogsModal(false)}
      />
    </>
  );
};

export default Index;

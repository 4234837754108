import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path d="M11.9369 8.01055L9.69473 5.1668C9.65384 5.11488 9.60172 5.07291 9.54229 5.04402C9.48285 5.01513 9.41765 5.00008 9.35156 5H8.46563C8.37402 5 8.32344 5.10527 8.37949 5.17773L10.3523 7.67969H2.07813C2.01797 7.67969 1.96875 7.72891 1.96875 7.78906V8.60938C1.96875 8.66953 2.01797 8.71875 2.07813 8.71875H11.5924C11.9588 8.71875 12.1625 8.29766 11.9369 8.01055Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import moment from "moment";

import {
  deliveryDisplayType,
  DeliveryScheduleDisplayType,
  holidayDisplayType,
} from "./models";
import { WEEKDAY } from "./configVariable";

export const sortDefaultScheduleToTop = (
  list: DeliveryScheduleDisplayType[]
): DeliveryScheduleDisplayType[] => {
  const newList = [...list];
  const indexDefault = newList.findIndex((item) => item.isDefault);
  if (indexDefault > -1) {
    const itemDefault = newList[indexDefault];
    newList.splice(indexDefault, 1);
    newList.unshift(itemDefault);
  }
  return newList;
};

export const defineWeekDayPrevious = (
  weekday: string,
  numberOfDay: number = 0
) => {
  const diffenceWeekday = numberOfDay % 7;
  if (diffenceWeekday === 0) return weekday;

  const index = WEEKDAY.findIndex((i) => i === weekday);
  const newWeekDayIndex =
    index > diffenceWeekday
      ? index - diffenceWeekday
      : index + 7 - diffenceWeekday;

  return WEEKDAY[newWeekDayIndex];
};

export const formatAMPM = (time: string) => {
  const timeSplit = time.split(":");

  let hours = +timeSplit[0];
  const minutes = timeSplit[1];
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours > 0 ? hours : 12; // the hour '0' should be '12'

  return hours + ":" + minutes + " " + ampm;
};

export const calculateNumberPreviousWeek = (
  weekday: string,
  numberOfDay: number = 0
) => {
  let numberOfWeek = Math.floor(numberOfDay / 7);
  const previousWeekDay = defineWeekDayPrevious(weekday, numberOfDay);

  if (
    WEEKDAY.findIndex((item) => item === previousWeekDay) >
    WEEKDAY.findIndex((item) => item === weekday)
  ) {
    numberOfWeek += 1;
  }

  if (numberOfWeek === 0) {
    return "(Current week)";
  } else if (numberOfWeek === 1) {
    return "(Last week)";
  } else {
    return `(Last ${numberOfWeek} weeks)`;
  }
};

export const compareDateRange = (
  s1: moment.Moment,
  e1: moment.Moment,
  s2: moment.Moment,
  e2: moment.Moment
): boolean => {
  if (s1.isSameOrBefore(s2) && s2.isSameOrBefore(e1)) return true;
  if (s1.isSameOrBefore(e2) && e2.isSameOrBefore(e1)) return true;
  if (s2.isBefore(s1) && s1.isBefore(e2)) return true;
  return false;
};

export const validateDelivedayInRange = (
  list: deliveryDisplayType[],
  startDate: Date | null,
  endDate: Date | null
) => {
  const startDateMoment = startDate ? moment(startDate) : null;
  const endDateMoment = endDate ? moment(endDate) : null;

  let error = false;
  let newList: deliveryDisplayType[] = list;
  let errorMsg = "";

  newList = list.map((item) => {
    const dateMoment = moment(item.date);
    if (
      startDateMoment &&
      endDateMoment &&
      (dateMoment.isAfter(endDateMoment) ||
        dateMoment.isBefore(startDateMoment))
    ) {
      item.dateError = true;
      item.dateErrorMsg = ` Invalid delivery date. It should be within the holiday date range of ${startDateMoment.format(
        "DD/MM/yyyy"
      )} - ${endDateMoment.format("DD/MM/yyyy")}`;
      error = true;
      errorMsg = "Date range is invalid";
    } else {
      item.dateError = false;
      item.dateRangeError = item.dateRangeError || false;
      error = error || false;
      errorMsg = "";
    }
    item.despatchDateError = false;
    item.orderDateError = false;
    return {
      ...item,
    };
  });

  return { listDeliveryDay: newList, error, errorMsg };
};

export const validateDuplicateDeliveryDate = (list: deliveryDisplayType[]) => {
  const listHolidayDuplicateDate: deliveryDisplayType[] = list.filter(
    (obj, index) => {
      return (
        obj.date &&
        list.findIndex((item) => moment(item.date).isSame(moment(obj.date))) !==
          index
      );
    }
  );

  let newList = [...list];

  if (listHolidayDuplicateDate.length > 0) {
    newList = newList.map((item) => {
      const duplicateItem = listHolidayDuplicateDate.find((i) =>
        moment(i.date).isSame(moment(item.date))
      );
      item.dateError = item.dateError || !!duplicateItem;

      if (duplicateItem) {
        item.dateError = true;
        item.dateErrorMsg = `Invalid delivery date. There is already a delivery date ${moment(
          item.date
        ).format("DD/MM/YYYY")} in this holiday date range`;
      }

      return { ...item };
    });
  }

  return newList;
};

export const validateSequenceDate = (
  deliveryDate: deliveryDisplayType
): deliveryDisplayType => {
  const newDeliveryDate = { ...deliveryDate };

  const isSetDespatchDate = newDeliveryDate.despatchDate !== "";
  const isSetOrderDate = newDeliveryDate.orderDate !== "";

  const dateMoment = moment(newDeliveryDate.date);
  const despatchDateMoment = moment(newDeliveryDate.despatchDate);
  const orderDateMoment = moment(newDeliveryDate.orderDate);

  if (despatchDateMoment.isBefore(orderDateMoment)) {
    newDeliveryDate.despatchDateError = true;
    newDeliveryDate.orderDateError = true;
    newDeliveryDate.despatchDateErrorMsg =
      "Despatch date cannot be smaller than order date.";
    newDeliveryDate.orderDateErrorMsg =
      "Order date cannot be greater than despatch date.";
  }

  if (dateMoment.isBefore(orderDateMoment)) {
    newDeliveryDate.dateError = true;
    newDeliveryDate.dateErrorMsg =
      "Delivery date cannot be smaller than order date.";
    newDeliveryDate.orderDateError = true;
    newDeliveryDate.orderDateErrorMsg =
      "Order date cannot be greater than delivery date.";
  }

  if (dateMoment.isBefore(despatchDateMoment)) {
    newDeliveryDate.dateError = true;
    newDeliveryDate.dateErrorMsg =
      "Delivery date cannot be smaller than despatch date.";
    newDeliveryDate.despatchDateError = true;
    newDeliveryDate.despatchDateErrorMsg =
      "Despatch date cannot be greater than delivery date.";
  }

  if (
    dateMoment.isAfter(despatchDateMoment) &&
    despatchDateMoment.isAfter(orderDateMoment)
  ) {
    newDeliveryDate.despatchDateError =
      newDeliveryDate.despatchDateError || false;
    newDeliveryDate.orderDateError = newDeliveryDate.orderDateError || false;
  }

  if (dateMoment.isAfter(despatchDateMoment) && !isSetOrderDate) {
    newDeliveryDate.despatchDateError =
      newDeliveryDate.despatchDateError || false;
  }

  if (dateMoment.isAfter(orderDateMoment) && !isSetDespatchDate) {
    newDeliveryDate.orderDateError = newDeliveryDate.orderDateError || false;
  }

  return newDeliveryDate;
};

export const validateOverlapDateRange = (
  list: holidayDisplayType[]
): holidayDisplayType[] => {
  const newList = list.map((item) => {
    const dateFrom = moment(item.data?.dateFrom);
    const dateTo = moment(item.data?.dateTo);

    const newItem = {
      ...item,
      dateRangeError: false,
      dateRangeOverlap: false,
      dateRangeErrorMessage: "",
    };

    const isOverlap = list.some((obj) => {
      const objDateFrom: moment.Moment = moment(obj.data?.dateFrom);
      const objDateTo: moment.Moment = moment(obj.data?.dateTo);
      const isOverlap =
        item.id !== obj.id &&
        dateFrom.isValid() &&
        dateTo.isValid() &&
        objDateFrom.isValid() &&
        objDateTo.isValid() &&
        compareDateRange(dateFrom, dateTo, objDateFrom, objDateTo);
      return isOverlap;
    });

    newItem.dateRangeError = isOverlap;
    newItem.dateRangeOverlap = isOverlap;

    return newItem;
  });

  return newList;
};

export const validateAllHolidayDeliveryDateInRange = (
  list: holidayDisplayType[]
) => {
  const newListHoliday = list.map((holiday) => {
    const data = holiday.data;

    const { listDeliveryDay, error, errorMsg } = validateDelivedayInRange(
      data?.items || [],
      moment(data?.dateFrom).toDate(),
      moment(data?.dateTo).toDate()
    );

    return {
      ...holiday,
      data: { ...holiday.data, items: listDeliveryDay },
      dateRangeError: holiday.dateRangeError || error,
      dateRangeErrorMessage:
        holiday.dateRangeErrorMessage !== ""
          ? holiday.dateRangeErrorMessage
          : errorMsg,
    };
  });

  return newListHoliday;
};

export const validateAllHolidayDuplicateDeliveryDate = (
  list: holidayDisplayType[]
) => {
  const newListHoliday = list.map((holiday) => {
    const items = holiday.data?.items || [];
    const newItems = validateDuplicateDeliveryDate(items);
    return { ...holiday, data: { ...holiday.data, items: newItems } };
  });
  return newListHoliday;
};

export const validateAllHolidaySequenceDate = (list: holidayDisplayType[]) => {
  const newListHoliday = list.map((holiday) => {
    let newItems = holiday.data?.items || [];
    newItems = newItems.map((i) => validateSequenceDate(i));
    return { ...holiday, data: { ...holiday.data, items: newItems } };
  });
  return newListHoliday;
};

export const covertTime12ToDate = (time: string | null = "11:59 PM") => {
  return moment(time, ["h:mm A"]).toDate();
};

export interface DetailRowProps {
  saleDocumentNumber: string;
  quantity: number | null;
  comment: string | null;
  customerName: string;
}
export const DetailRow = (props: DetailRowProps) => (
  <>
    <tr>
      <td className="border border-line pl-8 pr-2 w-6/12">
        {`${props.saleDocumentNumber} - ${props.customerName}`}
      </td>
      <td className="border border-line px-2 text-right w-1/12">
        {props.quantity}
      </td>
      <td className="border border-line px-2 w-5/12">{props.comment}</td>
    </tr>
  </>
);

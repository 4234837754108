import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M13.3657 9.00635L11.4512 8.45947C11.2964 8.41523 11.1569 8.32918 11.0479 8.21078C10.9389 8.09237 10.8647 7.9462 10.8334 7.78835L10.7162 7.19772C11.1734 6.98876 11.561 6.6529 11.8329 6.23006C12.1048 5.80722 12.2496 5.31519 12.25 4.81247V3.61022C12.2606 2.91411 12.0018 2.24084 11.5276 1.7311C11.0534 1.22137 10.4006 0.914624 9.70553 0.874974C9.18667 0.859318 8.67482 0.997848 8.23469 1.27305C7.79455 1.54825 7.44589 1.94777 7.23278 2.4211C7.6502 3.02859 7.87408 3.74815 7.87503 4.48522V5.68747C7.87346 6.00577 7.82782 6.32232 7.7394 6.6281C7.96623 6.86605 8.23574 7.05927 8.5339 7.19772L8.41665 7.78747C8.38536 7.94533 8.31114 8.0915 8.20215 8.2099C8.09316 8.3283 7.95363 8.41436 7.7989 8.4586L7.06128 8.66947L8.35628 9.0396C8.72133 9.14505 9.04233 9.36606 9.27109 9.66945C9.49986 9.97284 9.62405 10.3423 9.62503 10.7222V12.6875C9.62358 12.8369 9.59635 12.9849 9.54453 13.125H13.5625C13.6786 13.125 13.7898 13.0789 13.8719 12.9968C13.9539 12.9148 14 12.8035 14 12.6875V9.84722C13.9999 9.65719 13.938 9.47234 13.8235 9.32064C13.7091 9.16893 13.5484 9.05861 13.3657 9.00635V9.00635Z" />
        <path d="M8.11562 9.88133L6.20112 9.33445C6.04628 9.29014 5.90668 9.20396 5.79768 9.08539C5.68868 8.96682 5.61452 8.82047 5.58338 8.66245L5.46613 8.07183C5.92322 7.86293 6.31074 7.5272 6.58265 7.10453C6.85455 6.68186 6.9994 6.19002 7 5.68745V4.4852C7.01061 3.78909 6.75178 3.11582 6.27759 2.60608C5.8034 2.09635 5.15056 1.7896 4.4555 1.74995C4.10407 1.73917 3.75405 1.7991 3.42622 1.92618C3.09839 2.05326 2.79943 2.24491 2.54708 2.48975C2.29474 2.73458 2.09415 3.02762 1.95723 3.35146C1.82031 3.6753 1.74983 4.02335 1.75 4.37495V5.68745C1.75043 6.19017 1.8952 6.6822 2.16712 7.10504C2.43903 7.52787 2.82664 7.86374 3.28388 8.0727L3.16663 8.66245C3.13533 8.82031 3.06111 8.96648 2.95212 9.08488C2.84313 9.20328 2.7036 9.28934 2.54888 9.33358L0.634375 9.88045C0.451522 9.93275 0.290687 10.0432 0.176222 10.1951C0.0617571 10.347 -0.000105557 10.532 1.35206e-07 10.7222V12.6875C1.35206e-07 12.8035 0.0460938 12.9148 0.128141 12.9968C0.210188 13.0789 0.321468 13.125 0.4375 13.125H8.3125C8.42853 13.125 8.53981 13.0789 8.62186 12.9968C8.70391 12.9148 8.75 12.8035 8.75 12.6875V10.7222C8.74992 10.5322 8.68797 10.3473 8.57351 10.1956C8.45906 10.0439 8.29833 9.93359 8.11562 9.88133V9.88133Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="14" height="14" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { PATH } from "@/configuration/globalVariable";
import {
  LayoutPartials,
  Common,
  Icon,
  Table,
  Th,
  Td,
  Pagination,
} from "@/components";

const tabs = [
  {
    id: "my_customer",
    name: "My Customers",
    current: true,
    number: 45,
  },
  {
    id: "sent_requests",
    name: "Sent Requests",
    current: false,
    number: 7,
  },
  {
    id: "pending_customer_requests",
    name: "Pending Customer Requests",
    current: false,
    number: 5,
  },
  {
    id: "TOM_customers",
    name: "TOM Customers",
    current: false,
    number: 0,
  },
];

const recordsPerPage = [
  {
    id: 1,
    text: "Show 5",
    value: 5,
  },
  {
    id: 2,
    text: "Show 20",
    value: 20,
  },
  {
    id: 3,
    text: "Show 50",
    value: 50,
  },
];

const data = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7, 8, 9, 8, 7, 6, 5, 4, 3, 2, 1,
  2, 3, 4, 5, 6, 7, 8, 9,
];

const MyCustomer = () => {
  const [activeTab, setActiveTab] = useState<any>(tabs[0].name);
  const [pageNumber, setPageNumber] = useState<number>(1);
  console.log("pageNumber: ", pageNumber);

  const handlePageSizeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    console.log(value);
  };

  return (
    <LayoutPartials.BodyContent
      pageTitle="Customer Management"
      rightContent={
        <div className="flex items-center">
          <Common.Button
            className="mr-3"
            color="blue"
            outline
            iconLeft={Icon.Upload}
          >
            Upload CSV file
          </Common.Button>
          <Common.Button
            className="mr-3"
            color="blue"
            outline
            iconLeft={Icon.Filter}
          >
            Filter
          </Common.Button>
          <Common.DropDowns
            buttonRender={
              <Common.Button iconLeft={Icon.PlusBorder}>
                Add customer
              </Common.Button>
            }
            listRender={
              <div className="py-1">
                <Common.DropdownItem
                  icon={<Icon.Search className="mr-2 stroke-neutral-50" />}
                >
                  Browse TOM customers
                </Common.DropdownItem>
                <Common.DropdownItem
                  icon={<Icon.AddCircle className="mr-2 fill-neutral-50" />}
                >
                  Create manually
                </Common.DropdownItem>
              </div>
            }
          />
        </div>
      }
    >
      <Common.TabUnderline
        data={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        wrapClassName="mt-1 border-b-0"
      />
      <div className="max-w-sm mt-4 mb-4">
        <Common.Input
          placeholder="Search for customer name"
          iconRight={Icon.Search}
          iconType="stroke"
        />
      </div>

      {/* <div className="p-0"> */}
      <div className="xl:h-[calc(100vh-23.3rem)] overflow-y-auto">
        <Table>
          <thead className="hidden lg:table-header-group">
            <tr>
              <Th>Order #</Th>
              <Th sort="ASC">Address</Th>
              <Th sort="DESC">CONTACT INFO</Th>
              <Th>PRICING LIST</Th>
              <Th className="text-right" />
            </tr>
          </thead>

          <tbody className="bg-white">
            {data.map((item, index) => (
              <tr
                className={clsx(
                  "text-sm font-medium text-neutral-90 group hover:bg-neutral-5"
                )}
                key={index}
              >
                <Td>
                  Fastrac Foodservice {item}{" "}
                  <div className="inline-flex">
                    <Icon.CheckedFill size="12" className="ml-1.5 fill-blue" />
                  </div>
                </Td>
                <Td className="!whitespace-normal">
                  166 Balnarring Road Merricks north, VIC, 3199, Australia
                </Td>
                <Td
                  className={clsx(
                    "px-0 lg:px-3",
                    "border-t lg:border-0 border-neutral-20 mt-1 !whitespace-normal"
                  )}
                >
                  {`david@thatamorecheese.com
                  +61 491 570 156`}
                </Td>
                <Td>Platium Pricing</Td>
                <Td className="text-right">
                  <div className="flex items-center justify-end space-x-2">
                    <Link
                      to={`/${PATH.CUSTOMER_MANAGEMENT}/${PATH.EDIT_MY_CUSTOMER}/customerId`}
                    >
                      <Common.Button
                        outline
                        color="blue"
                        className="opacity-0 group-hover:opacity-100"
                      >
                        Edit
                      </Common.Button>
                    </Link>
                    <Common.DropDowns
                      className={clsx("opacity-0 group-hover:opacity-100")}
                      buttonRender={
                        <div className="w-6 h-6 inline-flex items-center justify-center font-medium group text-blue hover:border-blue-dark rounded">
                          <Icon.SeeMore size="18" className="fill-blue" />
                        </div>
                      }
                      listRender={
                        <div className="py-1">
                          <Common.DropdownItem
                            onClick={() => {}}
                            icon={
                              <Icon.Clock className="mr-2 fill-neutral-50" />
                            }
                          >
                            Order history
                          </Common.DropdownItem>
                          <Common.DropdownItem
                            onClick={() => {}}
                            className="text-red"
                            icon={<Icon.Trash className="mr-2 fill-red" />}
                          >
                            Disconnect
                          </Common.DropdownItem>
                        </div>
                      }
                    />
                  </div>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
        {/* {!(pendingOrdersList?.items as any)?.length &&
            pendingOrdersList.items !== undefined && (
              <Common.NoData title="No pending order data." />
            )}
          {isLoading && pendingOrdersList.items === undefined && (
            <Common.NoData title="Loading..." />
          )} */}
      </div>
      <div className="flex lg:items-center flex-col lg:flex-row border-t border-neutral-20 pt-4">
        <div className="flex items-center">
          <Common.Selectbox
            wrapClassName="mr-2"
            className="!text-blue !border-blue"
            value={5}
            onChange={handlePageSizeChange}
            options={recordsPerPage}
          />{" "}
          of <span className="font-medium mx-1">{0}</span> orders
        </div>
        <div className="mt-3 mx-auto lg:ml-auto lg:mt-0 lg:mr-0">
          <Pagination
            pageNumber={1 || 0}
            pageSize={5 || 0}
            total={20 || 0}
            totalPages={2 || 0}
            pagerCenterPoint={1 || 0}
            startPage={0 || 0}
            onPageClick={(page: number) => setPageNumber(page)}
          />
        </div>
      </div>
      {/* </div> */}
    </LayoutPartials.BodyContent>
  );
};

export default MyCustomer;

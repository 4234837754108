import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M6 9H1C0.448 9 0 8.552 0 8V1C0 0.448 0.448 0 1 0H6C6.552 0 7 0.448 7 1V8C7 8.552 6.552 9 6 9Z" />
      <path d="M6 16H1C0.448 16 0 15.552 0 15V12C0 11.448 0.448 11 1 11H6C6.552 11 7 11.448 7 12V15C7 15.552 6.552 16 6 16Z" />
      <path d="M15 6H10C9.448 6 9 5.552 9 5V1C9 0.448 9.448 0 10 0H15C15.552 0 16 0.448 16 1V5C16 5.552 15.552 6 15 6Z" />
      <path d="M15 16H10C9.448 16 9 15.552 9 15V9C9 8.448 9.448 8 10 8H15C15.552 8 16 8.448 16 9V15C16 15.552 15.552 16 15 16Z" />
    </SvgIcon>
  );
}

export default IconComponent;

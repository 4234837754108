import { ReactNode, memo } from "react";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import {
  Common,
  DeliveryStatusLabel,
  Icon,
  CustomScrollbar,
} from "@/components";
import { manifest, manifestLine } from "../models/DeliveryManifest";
import { distanceFormat, timeFormat, timeWorkingFormat } from "@/utils";
import {
  DeliveryLineStatusValue,
  DeliveryStatusValue,
} from "@/configuration/globalVariable";
import { driverName } from "../utils";

interface Props {
  children: ReactNode;
  data: manifest;
  unallocate?: boolean;
}

export const EditRouteCol = memo(
  ({ children, data, unallocate, ...props }: Props) => {
    const workingTime = data?.workingTime || 0;
    const distance = distanceFormat(data?.distance || 0);

    return (
      <>
        <div
          {...props}
          className={clsx(
            "w-[18rem] min-w-[18rem] rounded pt-4",
            unallocate ? "bg-yellow-light" : "bg-neutral-10"
          )}
        >
          <div className="px-2">
            {unallocate && (
              <div>
                <div className="flex items-center text-status-warning font-semibold mb-2">
                  <Icon.WarningCircle
                    size="12"
                    className="fill-status-warning mr-1.5"
                  />{" "}
                  Unallocated orders
                </div>
                <div className="text-neutral-50 font-medium mb-4 h-[1.375rem]">
                  {data.manifestLines && (
                    <span className="whitespace-nowrap">
                      <span className="mr-2">{data.manifestLines.length}</span>
                      order(s)
                    </span>
                  )}
                </div>
              </div>
            )}
            {!unallocate && (
              <div className="flex mb-2.5">
                <div className="-mt-0.5">
                  <Common.DotColor
                    style={{ backgroundColor: data.route.colour }}
                  />
                </div>
                <span className="ml-2 mr-1.5 font-semibold text-sm text-neutral-80 line-clamp-1">
                  {data.route.name}
                </span>
                <div className="mt-0.5">
                  <span data-tip data-for={`global-${data.id}`}>
                    <Icon.InfoFill size="12" className="fill-neutral-40" />
                  </span>
                </div>
              </div>
            )}
            {!unallocate && (
              <div className="flex items-center mb-4">
                <Icon.Driver size="16" className="fill-neutral-40" />
                <div
                  className="mr-2.5 ml-1.5 line-clamp-1"
                  data-tip
                  data-for={`driver-breaktime-${data.id}`}
                >
                  {driverName(data.driver)}
                </div>
                <span className="text-neutral-20">|</span>{" "}
                <span className="ml-2.5 text-sm text-blue font-medium cursor-pointer hover:underline whitespace-nowrap">
                  {data.manifestLines.length} order(s)
                </span>
              </div>
            )}
          </div>
          <div className="h-[calc(100vh-21.1rem)] relative rounded overflow-hidden">
            {/* {isReloadingManifest && (
              <div className="absolute top-0 left-0 w-full h-full z-50 bg-black bg-opacity-40 flex justify-center items-center rounded">
                <Icon.Loading size="24" />
              </div>
            )} */}
            <CustomScrollbar>{children}</CustomScrollbar>
          </div>
        </div>

        {!unallocate && (
          <ReactTooltip
            id={`global-${data.id}`}
            aria-haspopup="true"
            effect="solid"
            multiline={true}
            backgroundColor={"var(--color-white)"}
            className="!p-3 rounded text-sm border border-neutral-10 shadow-tooltip !opacity-100"
          >
            <div className="ml-4 text-sm text-neutral-70">
              <div className="flex items-center mb-2">
                <Common.DotColor
                  style={{ backgroundColor: data.route.colour }}
                />
                <div className="font-semibold text-neutral-80 mx-2 mr-3">
                  {data.route.name}
                </div>
                {data.status !== DeliveryStatusValue.PENDING && (
                  <DeliveryStatusLabel status={data.status} />
                )}
              </div>
              <div className="flex items-center mb-2">
                <div>
                  <Icon.Distant size="16" className="fill-neutral-40" />
                </div>
                <div className=" ml-1.5">
                  <span className="text-neutral-50">Total distance: </span>
                  {distance}
                </div>
                <Icon.Clock size="12" className="fill-neutral-40 ml-4 mr-1.5" />
                <div>
                  <span className="text-neutral-50">Total time: </span>
                  {timeWorkingFormat(workingTime)}
                </div>
              </div>
              <div className="flex items-center">
                <Icon.DeliveryTime
                  size="12"
                  className="fill-neutral-40 mr-1.5"
                />
                <div>
                  <span className="text-neutral-50">Completed order: </span>
                  {
                    data.manifestLines.filter(
                      (mnf: manifestLine) =>
                        mnf.deliveryStatus === DeliveryLineStatusValue.COMPLETED
                    ).length
                  }{" "}
                  / {data.manifestLines.length} order
                  {data.manifestLines.length > 1 && "s"}
                </div>
              </div>
            </div>
          </ReactTooltip>
        )}
        {!unallocate && (
          <ReactTooltip
            id={`driver-breaktime-${data.id}`}
            aria-haspopup="true"
            effect="solid"
            backgroundColor={"var(--color-white)"}
            className="!p-3 rounded text-sm border border-neutral-10 shadow-tooltip !opacity-100"
          >
            <div>
              <div className="flex items-center">
                <div className="text-neutral-70 text-sm">
                  <div className="text-xs text-neutral-50">Start time</div>
                  {timeFormat(data?.driver?.workStartTime)}
                </div>
                <Icon.TimeTo size="12" className="fill-neutral-40 mx-2.5" />
                <div className="text-neutral-70 text-sm">
                  <div className="text-xs text-neutral-50">End time</div>
                  {timeFormat(data?.driver?.workEndTime)}
                </div>

                {data && (
                  <div className="text-neutral-70 text-sm ml-10">
                    <div className="text-xs text-neutral-50">
                      Driver break period
                    </div>
                    {`${timeFormat(data.driver.breakStartTime)} - ${timeFormat(
                      data.driver.breakEndTime
                    )}`}
                  </div>
                )}
              </div>
            </div>
          </ReactTooltip>
        )}
      </>
    );
  }
);

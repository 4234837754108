import React, { forwardRef, InputHTMLAttributes } from "react";
import clsx from "clsx";
import { WrapInput } from "./WrapInput";
interface Props extends InputHTMLAttributes<HTMLInputElement> {
  ipSize?: "lg" | "md";
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  className?: string;
  iconType?: "stroke" | "fill";
  iconRight?: React.ElementType;
  onClear?: () => void;
  iconClassName?: string;
}

const Input = forwardRef(
  (
    {
      ipSize = "lg",
      label,
      isError,
      errorMessage,
      className,
      wrapClassName,
      iconType = "fill",
      iconRight: IconRight = undefined,
      disabled,
      id,
      onClear,
      value,
      iconClassName,
      ...rest
    }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <WrapInput
        label={label}
        errorMessage={errorMessage}
        wrapClassName={wrapClassName}
        iconType={iconType}
        iconRight={IconRight}
        id={id}
        onClear={onClear}
        isDisplayOnClear={Boolean(onClear) && Boolean(value)}
        iconClassName={iconClassName}
      >
        <input
          {...rest}
          value={value}
          id={id}
          ref={ref}
          className={clsx(
            "block w-full border text-base rounded ",
            ipSize === "md" ? "py-1 pl-2" : "py-[0.375rem] pl-3",
            isError
              ? "border-red focus:outline-none focus:ring-red focus-visible:outline-none focus:border-red"
              : "border-neutral-20 focus:ring-blue focus:border-blue focus-visible:outline-none",
            IconRight ? "pr-10" : "pr-3",
            disabled ? "bg-neutral-20 text-neutral-40" : "text-neutral",
            className
          )}
          disabled={disabled}
        />
      </WrapInput>
    );
  }
);

export default Input;

import React from "react";

const PolyLine = (options: any) => {
  const [polyline, setPolyline] = React.useState<any>();
  React.useEffect(() => {
    if (!polyline) {
      setPolyline(new google.maps.Polyline());
    }
    // remove marker from map on unmount
    return () => {
      if (polyline) {
        polyline.setMap(null);
      }
    };
  }, [polyline]);
  React.useEffect(() => {
    if (polyline) {
      polyline.setOptions({
        ...options,
        strokeOpacity: 0.7,
        strokeWeight: 4,
        geodesic: true,
      });
    }
  }, [polyline, options]);

  return null;
};
export default PolyLine;

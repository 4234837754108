import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M9.16659 15C9.16659 15.9167 8.41659 16.6667 7.49992 16.6667C6.58325 16.6667 5.83325 15.9167 5.83325 15C5.83325 14.0834 6.58325 13.3334 7.49992 13.3334C8.41659 13.3334 9.16659 14.0834 9.16659 15ZM7.49992 8.33337C6.58325 8.33337 5.83325 9.08337 5.83325 10C5.83325 10.9167 6.58325 11.6667 7.49992 11.6667C8.41659 11.6667 9.16659 10.9167 9.16659 10C9.16659 9.08337 8.41659 8.33337 7.49992 8.33337ZM7.49992 3.33337C6.58325 3.33337 5.83325 4.08337 5.83325 5.00004C5.83325 5.91671 6.58325 6.66671 7.49992 6.66671C8.41659 6.66671 9.16659 5.91671 9.16659 5.00004C9.16659 4.08337 8.41659 3.33337 7.49992 3.33337ZM12.4999 6.66671C13.4166 6.66671 14.1666 5.91671 14.1666 5.00004C14.1666 4.08337 13.4166 3.33337 12.4999 3.33337C11.5833 3.33337 10.8333 4.08337 10.8333 5.00004C10.8333 5.91671 11.5833 6.66671 12.4999 6.66671ZM12.4999 8.33337C11.5833 8.33337 10.8333 9.08337 10.8333 10C10.8333 10.9167 11.5833 11.6667 12.4999 11.6667C13.4166 11.6667 14.1666 10.9167 14.1666 10C14.1666 9.08337 13.4166 8.33337 12.4999 8.33337ZM12.4999 13.3334C11.5833 13.3334 10.8333 14.0834 10.8333 15C10.8333 15.9167 11.5833 16.6667 12.4999 16.6667C13.4166 16.6667 14.1666 15.9167 14.1666 15C14.1666 14.0834 13.4166 13.3334 12.4999 13.3334Z" />
    </SvgIcon>
  );
}

export default IconComponent;

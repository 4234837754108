/* eslint-disable @typescript-eslint/no-unused-vars */
import { forwardRef } from "react";
import clsx from "clsx";
import { Icon } from "@/components";
import { addressGenerate, timeFormatDepot } from "@/utils";
import { useAppSelector } from "@/sharedStore/hooks";
import { storageAddressSelector } from "../providers/deliveryManifest/selector";
import {
  storageAddress,
  manifestLine,
  manifest,
} from "../models/DeliveryManifest";
import { DeliveryLineStatusValue } from "@/configuration/globalVariable";
import { ETATimeFormat } from "../utils";

interface Props {
  className?: string;
  data?: manifestLine;
  routeInfo?: manifest;
  manifestETALoading?: boolean;
  isTop?: boolean;
}

export const DepotItem = forwardRef(
  ({ className, data, routeInfo, manifestETALoading, isTop }: Props, ref) => {
    const storageAddress = useAppSelector(
      storageAddressSelector
    ) as storageAddress;

    // const isCompleted =
    //   data?.deliveryStatus === DeliveryLineStatusValue.COMPLETED;
    // const isDelivering =
    //   data?.deliveryStatus === DeliveryLineStatusValue.DELIVERING;
    // const ETATime = timeFormat(routeInfo?.opInfo?.workStartTime); // ETATimeFormat(data, routeInfo?.status);

    return (
      <div
        ref={ref as any}
        className={clsx(
          "px-3 py-2 flex border rounded mx-4 cursor-pointer",
          "border-neutral-10 bg-white",
          className
        )}
      >
        <div>
          <div
            className={clsx(
              "w-5 h-5 rounded-full font-semibold flex items-center justify-center",
              "bg-neutral-10 text-neutral-50"
            )}
          >
            <Icon.Shop size="12" className="fill-neutral-40" />
          </div>
        </div>
        <div className="flex-1 ml-4">
          <div className="font-semibold">Depot</div>
          <div className="flex mt-2">
            <div className="mt-1" data-tip="Depot address">
              <Icon.Location size="12" className="fill-neutral-40" />
            </div>
            <div className="ml-1.5 text-neutral-70">
              {addressGenerate(
                storageAddress?.addressLine1,
                storageAddress?.addressLine2,
                storageAddress?.suburb,
                storageAddress?.state,
                storageAddress?.postcode,
                storageAddress?.country
              )}
            </div>
            {routeInfo && (
              <div className="flex items-center">
                <div data-tip="Estimated time of arrival">
                  <Icon.Clock size="12" className="fill-blue-80" />
                </div>
                <span className="ml-1.5 text-blue depot">
                  {manifestETALoading ? (
                    <Icon.Loading className="ml-2" />
                  ) : isTop ? (
                    timeFormatDepot(routeInfo?.startTime)
                  ) : (
                    timeFormatDepot(routeInfo?.endTime)
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

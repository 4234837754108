import { useEffect, useState } from "react";
import clsx from "clsx";
// import ReactTooltip from "react-tooltip";
import { Common, Icon, ModalInform } from "@/components";
import { SnackbarUtils } from "@/utils";
import { OptimizingItem } from "./OptimizingItem";
import { ModalOptimized } from "./ModalOptimized";
import { manifest, optimizeInfo, solution } from "../models";
import { useFetchOptimizeByJobId } from "../services/deliveryOptimize";
import { useAppDispatch } from "@/sharedStore/hooks";
import {
  addOptimizedGroupsInfo,
  applyOptimizeGroup,
  discardOptimizeGroup,
} from "../providers/deliveryManifest/slice";
import { mergeManifestOptimize } from "../utils";

interface Props {
  isFirst?: boolean;
  // isWarning?: boolean;
  // isSuccess?: boolean;
  className?: string;
  data: any;
  groupIndex: number;
}

export const OptimizingGroup = ({
  isFirst,
  className,
  // isWarning,
  // isSuccess,
  data,
  groupIndex,
}: Props) => {
  let timer = null as any;
  const fetchOptimizeByJobId = useFetchOptimizeByJobId();
  const dispatch = useAppDispatch();
  const [isOpenOptimizedModal, setOpenOptimizedModal] = useState(false);
  const [isOpenDiscardModal, setOpenDiscardModal] = useState(false);
  const [isFirstRefreshed, setFirstRefreshed] = useState(false);
  const {
    data: fetchOptimizeByJobIdData,
    isSuccess: fetchOptimizeByJobIdSuccess,
  } = fetchOptimizeByJobId;
  const isWarning = Boolean(data?.unserved?.length);

  // useEffect(() => {
  //   if (isWarning) {
  //     SnackbarUtils.warning(
  //       `Don't have enough route to deliver all orders. <span style='color: var(--color-yellow); font-weight: 600;'>${data?.unserved?.length} unallocated orders</span> is waiting for allocating.`
  //     );
  //     ReactTooltip.rebuild();
  //     return () => {
  //       ReactTooltip.hide();
  //     };
  //   }
  // }, [isWarning]);

  const handleDiscardOptimized = () => {
    setOpenOptimizedModal(false);
    setOpenDiscardModal(true);
  };

  const handleCancelDiscard = () => {
    setOpenDiscardModal(false);
    setOpenOptimizedModal(true);
  };
  const handleDiscard = () => {
    dispatch(
      discardOptimizeGroup({
        jobId: data.jobId,
        manifests: data.manifests,
      })
    );
    setOpenDiscardModal(false);
  };

  const handleApplyOptimized = () => {
    dispatch(
      applyOptimizeGroup({
        jobId: data.jobId,
        manifests: data.manifests.map((manifest: manifest) => {
          const slt = data.solution.find(
            (solution: solution) => solution.manifestId === manifest.id
          ) as solution;
          const opInfo = data.optimizeInfo.find(
            (opInfoItem: optimizeInfo) => opInfoItem.manifestId === manifest.id
          ) as optimizeInfo;
          return mergeManifestOptimize(manifest, slt, opInfo);
        }),
        unallocatedDocumentCount:
          fetchOptimizeByJobIdData?.unserved?.length || 0,
      })
    );
    setOpenOptimizedModal(false);
  };

  useEffect(() => {
    if (!data.status || data.status === "processing") {
      fetchOptimizeByJobId.mutate(data.jobId);
    }

    return () => {
      timer && clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (fetchOptimizeByJobIdData?.status === "processing") {
      fetchData();
    }
    if (fetchOptimizeByJobIdData?.status === "finished") {
      if (fetchOptimizeByJobIdData.unserved.length) {
        SnackbarUtils.warning(
          `Don't have enough route to deliver all orders. <span style='color: var(--color-yellow); font-weight: 600;'>${fetchOptimizeByJobIdData.unserved?.length} unallocated orders</span> is waiting for allocating.`
        );
      } else {
        SnackbarUtils.success(
          `Optimized ${fetchOptimizeByJobIdData.solution.length} routes successfully. Please preview and apply to start delivery process.`
        );
      }
      dispatch(
        addOptimizedGroupsInfo({ fetchOptimizeByJobIdData, jobId: data.jobId })
      );
    }
  }, [fetchOptimizeByJobIdSuccess]);

  const fetchData = () => {
    timer = setTimeout(
      () => {
        !isFirstRefreshed && setFirstRefreshed(true);
        fetchOptimizeByJobId.mutate(data.jobId);
      },
      isFirstRefreshed ? 2000 : data.estimateTimeInterval * 1000
    );
  };

  return (
    <>
      <div
        className={clsx(
          "py-3 px-2 rounded bg-blue-10",
          !isFirst && "mt-2.5",
          isWarning ? "!bg-status-bg-warning" : "!bg-status-bg-success",
          className
        )}
      >
        <div className="flex items-center min-h-[1.75rem] mb-2">
          <div className="text-sm font-semibold mr-2">
            Group {groupIndex}: {data.manifests.length} route
            {data.manifests.length > 1 ? "s" : ""} optimizing
          </div>
          {isWarning && (
            <div
              data-tip={`Don't have enough route to deliver all orders.<br/>${fetchOptimizeByJobIdData.unserved.length} unallocated orders is waiting for allocating.`}
            >
              <Icon.InfoFill size="16" className="fill-yellow" />
            </div>
          )}
          {data?.status === "finished" && (
            <Common.Button
              outline
              btSize="sm"
              className="ml-auto"
              onClick={() => setOpenOptimizedModal(true)}
            >
              Preview and Apply
            </Common.Button>
          )}
        </div>
        {data.manifests.map((manifest: manifest, index: number) => {
          return (
            <OptimizingItem
              key={index}
              isFirst={!index}
              isOptimizing={!(data?.status === "finished")}
              manifest={manifest}
              solution={
                data?.solution && data?.solution.length
                  ? data?.solution.find(
                      (sl: any) => sl.manifestId === manifest.id
                    )
                  : undefined
              }
            />
          );
        })}
      </div>
      {data?.solution && (
        <ModalOptimized
          open={isOpenOptimizedModal}
          onClose={() => setOpenOptimizedModal(false)}
          onDiscard={handleDiscardOptimized}
          onSubmit={handleApplyOptimized}
          manifests={data.manifests}
          unserved={data.unserved}
          solutions={data.solution}
          optimizeInfo={data.optimizeInfo}
        />
      )}
      <ModalInform
        open={isOpenDiscardModal}
        onClose={handleCancelDiscard}
        onConfirm={handleDiscard}
        confirmBtnText="Discard"
        title={`Are you sure you want to discard ${
          data.manifests.length
        } route${data.manifests.length > 1 ? "s" : ""} in Group ${groupIndex}?`}
        description={`If you discard now, ${data.manifests.length} route${
          data.manifests.length > 1 ? "s" : ""
        } will not be saved.`}
        type="error"
      />
    </>
  );
};

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M13 7.1V7C13 4.2 10.8 2 8 2C5.5 2 3.4 3.8 3.1 6.3C1.3 6.9 0 8.5 0 10.5C0 13 2 15 4.5 15C6.2 15 10.4 15 12 15C14.2 15 16 13.2 16 11C16 9.1 14.7 7.6 13 7.1ZM9 10V13H7V10H4L8 6L12 10H9Z" />
    </SvgIcon>
  );
}

export default IconComponent;

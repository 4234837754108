import { Icon } from "@/components/Icons";
import clsx from "clsx";

interface Props {
  iconRender?: React.ReactNode;
  alertType?: "error" | "warning" | "info" | "success";
  className?: string;
  children: React.ReactNode;
}

export const Alert = ({
  iconRender,
  children,
  alertType = "error",
  className,
}: Props) => {
  const typeWrapClass = () => {
    let classType = "border-status-error bg-status-bg-error";

    if (alertType === "warning") {
      classType = "border-status-warning bg-status-bg-warning";
    }
    if (alertType === "info") {
      classType = "border-status-info bg-status-bg-info";
    }
    if (alertType === "success") {
      classType = "border-status-success bg-status-bg-success";
    }
    return classType;
  };

  const typeIconClass = () => {
    let classType = "bg-status-error";

    if (alertType === "warning") {
      classType = "bg-status-warning";
    }
    if (alertType === "info") {
      classType = "bg-status-info";
    }
    if (alertType === "success") {
      classType = "bg-status-success";
    }
    return classType;
  };

  return (
    <div
      className={clsx(
        "flex border p-3 pl-5 rounded font-medium",
        typeWrapClass(),
        className
      )}
    >
      <div className="mt-1.5">
        {iconRender || (
          <div
            className={clsx(
              "w-[1.875rem] h-[1.875rem] rounded-full flex items-center justify-center",
              typeIconClass()
            )}
          >
            <Icon.Close size="10" className="stroke-white" />
          </div>
        )}
      </div>
      <div className="ml-4 flex-1">{children}</div>
    </div>
  );
};

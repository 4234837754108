import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M7.4998 9.22498C6.8998 9.52498 6.1498 9.67498 5.4748 9.67498C5.0998 9.67498 4.7248 9.59998 4.4248 9.52498C4.9498 11.025 4.8748 12 4.8748 12H7.0498C7.1248 12 6.8998 10.65 7.4998 9.22498Z" />
        <path d="M9.825 2.7C9.675 1.5 8.625 0.525 7.35 0.525C6.675 0.525 6 0.825 5.55 1.275C5.1 0.525 4.275 0 3.3 0C1.8 0 0.6 1.2 0.6 2.7C0.6 3 0.675 3.3 0.75 3.525C0.3 3.9 0 4.35 0 4.875C0 5.775 0.75 6.525 1.65 6.525C1.95 6.525 2.25 6.45 2.475 6.3C3 7.425 4.125 8.175 5.4 8.175C6.6 8.175 7.725 7.5 8.25 6.45C8.625 6.825 9.15 7.05 9.75 7.05C10.95 7.05 11.925 6.075 11.925 4.875C12 3.675 11.25 2.7 9.825 2.7Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { useState } from "react";
import clsx from "clsx";
import { SnackbarUtils } from "@/utils";
import { Images } from "@/assets/images";
import { Common, Icon, Table, Td, Th, ModalInform } from "@/components";

const data = [
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
];

interface Props {
  className?: string;
}

export const EditTabPricingList = ({ className }: Props) => {
  const [isOpenRemoveModal, setOpenRemoveModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { name, value } = e.target;
    console.log(name, ": ", value);
  };

  const handleUpdate = () => {
    SnackbarUtils.info(
      "You have successfully updated 3 prices in custom price list."
    );
    setEdit(false);
  };

  const handleDelete = () => setOpenRemoveModal(true);

  return (
    <div className={clsx("flex flex-col h-full", className)}>
      <div className="flex-1 flex flex-col">
        <div className="flex space-x-4">
          <div className="w-[29.375rem]">
            <div className="font-semibold mb-1.5 text-hd5">Pricing List</div>
            <div className="text-neutral-60 max-w-[16.25rem]">
              Select pricing list and customize the price for this customer
              only*
            </div>
          </div>
          <div className="flex-1">
            <div className="font-semibold mb-1.5">Select Pricing List</div>
            <Common.Selectbox
              name="SelectBox"
              onChange={handleSelectChange}
              options={[
                { id: 1, text: "Platium Tier", value: 1 },
                { id: 2, text: "Platium Tier 2", value: 2 },
                { id: 3, text: "Platium Tier 3", value: 3 },
              ]}
            />
          </div>
        </div>
        <div className="border-b border-neutral-20 my-8"></div>
        <div>
          <div className="font-semibold mb-1.5">Custom Price</div>
          <div className="flex space-x-4 pb-4">
            <div className="max-w-[28.125rem] w-full mr-auto">
              <Common.Input
                placeholder="Search for product name, SKU"
                iconRight={Icon.Search}
                iconType="stroke"
              />
            </div>
            <Common.Button
              className="mr-3"
              color="blue"
              outline
              iconLeft={Icon.Filter}
            >
              Filter
            </Common.Button>
            <Common.Button
              className="mr-3"
              color="blue"
              iconLeft={Icon.PlusBorder}
            >
              Add product
            </Common.Button>
          </div>
        </div>
        <div className="flex-1 relative">
          <div className="absolute w-full h-full top-0 left-0 overflow-y-auto">
            <Table>
              <thead>
                <tr>
                  <Th className="w-11">
                    <Common.Checkbox />
                  </Th>
                  <Th onSortClick={() => {}}>SKU</Th>
                  <Th>Product</Th>
                  <Th>Unit</Th>
                  <Th className="text-right">COST</Th>
                  <Th className="text-right w-32">Custom price</Th>
                  <Th className="text-right">
                    Profit{" "}
                    <span className="ml-2">
                      <Icon.InfoFill size="12" className="fill-neutral-40" />
                    </span>
                  </Th>
                  <Th className="w-20"></Th>
                </tr>
              </thead>

              <tbody className="bg-white text-neutral-90 text-sm font-medium">
                {data.map((item, index) => (
                  <tr key={item + index}>
                    <Td>
                      <Common.Checkbox />
                    </Td>
                    <Td className="text-neutral-60">FRT-112</Td>
                    <Td>
                      <div className="flex">
                        <div className="mr-3">
                          <div
                            className="w-[2.125rem] h-[2.125rem] bg-center bg-cover"
                            style={{
                              backgroundImage: `url(${Images.noImage})`,
                            }}
                          />
                        </div>
                        <div>
                          <div className="font-medium">
                            Apple Granny Smith Juicing (2kg)
                          </div>
                          <div className="text-neutral-60 flex items-center">
                            <Icon.Fruit
                              size="12"
                              className="fill-neutral-60 mr-2"
                            />
                            Fruits
                          </div>
                          {/* <div className="text-neutral-60 flex items-center">
                            <Icon.Meat
                              size="12"
                              className="fill-neutral-60 mr-2"
                            />
                            Meat
                          </div>
                          <div className="text-neutral-60 flex items-center">
                            <Icon.Vegetable
                              size="12"
                              className="fill-neutral-60 mr-2"
                            />
                            Vegetables
                          </div> */}
                        </div>
                      </div>
                    </Td>
                    <Td>BOX</Td>
                    <Td className="text-right">$51.33</Td>
                    <Td>
                      <Common.Input className="text-right" />
                    </Td>
                    <Td className="text-right">5%</Td>
                    <Td className="text-center">
                      <Common.Button
                        onClick={handleDelete}
                        color="transparent"
                        className="group"
                      >
                        <Icon.Trash
                          size="16"
                          className="fill-neutral-40 group-hover:fill-red"
                        />
                      </Common.Button>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="flex justify-end space-x-2 pt-4">
        {isEdit ? (
          <>
            <Common.Button color="transparent" onClick={() => setEdit(false)}>
              Cancel
            </Common.Button>
            <Common.Button onClick={handleUpdate}>Update</Common.Button>
          </>
        ) : (
          <Common.Button onClick={() => setEdit(true)}>Edit</Common.Button>
        )}
      </div>

      <ModalInform
        open={isOpenRemoveModal}
        title={"Remove 3 products from this pricing list?"}
        description={
          "Any future pricing updates may required manual adjustments. Would you like to proceed?"
        }
        onClose={() => setOpenRemoveModal(false)}
        onConfirm={() => setOpenRemoveModal(false)}
        confirmBtnText="Remove"
      />
    </div>
  );
};

import { SvgIcon } from "./SvgIcon";
// Fill
function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M8.00078 11.9998C8.20452 11.9998 8.39576 11.9004 8.51264 11.7329L12.8875 5.4831C13.0213 5.29248 13.0363 5.04311 12.93 4.83624C12.8219 4.62937 12.6088 4.5 12.3757 4.5H3.62464C3.39152 4.5 3.17841 4.62937 3.07028 4.83624C2.96404 5.04311 2.97904 5.29248 3.11278 5.4831L7.48766 11.7329C7.60454 11.9004 7.79578 11.9998 7.99953 11.9998H8.00078Z" />
    </SvgIcon>
  );
}

export default IconComponent;

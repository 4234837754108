import {
  Input,
  Select,
  Datepicker,
  TimePicker,
  TimeRangePicker,
  Checkbox,
  Radio,
} from "./components";

const Index = () => {
  return (
    <>
      <Input />
      <Select />
      <Datepicker />
      <TimePicker />
      <TimeRangePicker />
      <Checkbox />
      <Radio />
    </>
  );
};

export default Index;

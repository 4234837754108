import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M14.3161 11.8744C17.5898 11.8744 20.2528 9.21094 20.2528 5.93719C20.2528 2.66344 17.5898 0 14.3161 0C11.0423 0 8.37891 2.66344 8.37891 5.93719C8.37891 9.21094 11.0423 11.8744 14.3161 11.8744ZM13.613 2.44734V2.28609C13.613 1.8975 13.9275 1.58297 14.3161 1.58297C14.7042 1.58297 15.0192 1.8975 15.0192 2.28609V2.44828C15.8559 2.62781 16.4855 3.37313 16.4855 4.26281C16.4855 4.65094 16.1705 4.96594 15.7823 4.96594C15.3937 4.96594 15.0792 4.65094 15.0792 4.26281C15.0792 4.01484 14.8772 3.81281 14.6292 3.81281H13.9988C13.7508 3.81281 13.5487 4.01484 13.5487 4.26281C13.5487 4.40484 13.6167 4.53984 13.7311 4.62422L14.3161 5.05734L15.7373 6.10922C16.2042 6.45469 16.4855 7.00734 16.4892 7.58859V7.59516C16.4925 8.09109 16.3027 8.55891 15.9539 8.91187C15.6952 9.17391 15.3717 9.34969 15.0192 9.42375V9.58828C15.0192 9.97688 14.7042 10.2914 14.3161 10.2914C13.9275 10.2914 13.613 9.97688 13.613 9.58828V9.42609C13.2698 9.35297 12.9544 9.18422 12.6994 8.93203C12.3464 8.58375 12.1505 8.11875 12.1477 7.62281C12.1453 7.23469 12.458 6.91781 12.8461 6.91547H12.8508C13.237 6.91547 13.5516 7.22766 13.5539 7.61438C13.5548 7.85705 13.7501 8.06156 14.0063 8.06156C14.3948 8.05922 14.25 8.06006 14.6358 8.05781C14.8853 8.05584 15.0849 7.8533 15.083 7.60453V7.59797C15.082 7.45734 15.0141 7.32328 14.9006 7.23938L14.3161 6.80672L12.8944 5.75484C12.4238 5.40609 12.1425 4.84828 12.1425 4.26281C12.1425 3.37125 12.7739 2.62547 13.613 2.44734Z" />
      <path d="M4.69625 15.2527C4.51602 14.9403 4.11566 14.8316 3.80127 15.0129L0.49386 16.9222C0.180689 17.1034 0.073251 17.504 0.254048 17.8176L3.63439 23.6723C3.81533 23.9854 4.21564 24.0929 4.52938 23.9122L7.83678 22.0025C8.15042 21.8216 8.25744 21.4211 8.07659 21.1075L4.69625 15.2527Z" />
      <path d="M23.6473 14.0841C23.329 13.6421 22.7131 13.5422 22.2715 13.8605C20.9318 14.8256 18.3593 16.6791 18.155 16.8267C18.0607 16.9069 17.9618 16.9796 17.8587 17.0438C17.4542 17.2983 16.985 17.4342 16.4951 17.4342H13.1271C12.739 17.4342 12.424 17.1197 12.424 16.7311C12.424 16.3421 12.7395 16.028 13.1271 16.028H16.729C17.2582 16.028 17.682 15.5874 17.66 15.0572C17.6393 14.5542 17.21 14.1642 16.7065 14.1642H13.9648C13.7792 13.9678 13.5757 13.7878 13.3578 13.6271C12.6082 13.0744 11.682 12.7477 10.6793 12.7477C8.89667 12.7477 7.12292 13.8708 6.38745 15.3699L9.2637 20.3513H14.659C15.7184 20.3513 16.7618 20.0803 17.6801 19.5521C17.9984 19.3692 18.3392 19.1494 18.7085 18.8841C20.2564 17.7722 23.4209 15.4622 23.4232 15.4608C23.8657 15.143 23.966 14.5261 23.6473 14.0841Z" />
    </SvgIcon>
  );
}

export default IconComponent;

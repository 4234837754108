import { Icon } from "@/components";

const Index = () => {
  return (
    <div className="backdrop-blur-md bg-white/30 fixed w-full h-full flex items-center justify-center top-0 left-0 z-50">
      <Icon.Loading size="40" />
    </div>
  );
};

export default Index;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M7.64132 1.72665C7.78805 1.42938 8.21195 1.42938 8.35869 1.72665L10.1818 5.42001C10.24 5.53805 10.3527 5.61987 10.4829 5.6388L14.5582 6.23099C14.8863 6.27866 15.0173 6.68187 14.7799 6.91328L11.8308 9.78771C11.7365 9.8796 11.6935 10.012 11.7157 10.1418L12.4117 14.2016C12.4678 14.5283 12.1248 14.7775 11.8314 14.6232L8.18615 12.7066C8.06962 12.6454 7.93039 12.6454 7.81385 12.7066L4.16865 14.6232C3.87521 14.7775 3.53223 14.5283 3.58826 14.2016L4.28429 10.1418C4.30654 10.012 4.26351 9.8796 4.16923 9.78771L1.22009 6.91328C0.982664 6.68187 1.11366 6.27866 1.44176 6.23099L5.51707 5.6388C5.64735 5.61987 5.75997 5.53805 5.81824 5.42001L7.64132 1.72665Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import clsx from "clsx";
import { Nav } from "@/models";

interface Props {
  data: Nav;
  isCollapse: boolean;
  isActive?: boolean;
}

export function MenuItem({ data, isCollapse, isActive }: Props) {
  return (
    <div
      className={clsx(
        "flex items-center h-10 pl-[1.875rem]",
        isCollapse && "pl-[1.5rem]"
        // isCollapse && "md:pl-[1.5rem]"
      )}
    >
      <div>
        <data.icon
          size="16"
          className={clsx(
            "group-hover:fill-blue",
            isActive ? "fill-blue" : "fill-neutral-40"
          )}
        />
      </div>
      <div
        className={clsx(
          "ml-3 whitespace-nowrap overflow-hidden",
          isCollapse && "hidden"
          // isCollapse && "md:hidden"
        )}
      >
        {data.name}
      </div>
      {data.isBeta && (
        <span className="inline-flex items-center rounded-full bg-yellow px-2.5 py-0.5 text-xs font-medium text-white ml-2">
          Beta
        </span>
      )}
    </div>
  );
}

import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M10.875 0H1.125C1.02554 0 0.930161 0.0395088 0.859835 0.109835C0.789509 0.180161 0.75 0.275544 0.75 0.375V11.625C0.75 11.7245 0.789509 11.8198 0.859835 11.8902C0.930161 11.9605 1.02554 12 1.125 12H10.875C10.9745 12 11.0698 11.9605 11.1402 11.8902C11.2105 11.8198 11.25 11.7245 11.25 11.625V0.375C11.25 0.275544 11.2105 0.180161 11.1402 0.109835C11.0698 0.0395088 10.9745 0 10.875 0V0ZM9.375 9.75H2.625V9H9.375V9.75ZM9.375 7.5H2.625V6.75H9.375V7.5ZM9.375 5.25H2.625V2.25H9.375V5.25Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import {
  Common,
  CustomScrollbar,
  GoogleMap,
  Icon,
  LayoutPartials,
} from "@/components";
import { EyeMapDropdown } from "../components";
// import { OptimizedReviewItem } from "../components/OptimizedReviewItem";

const Index = () => {
  return (
    <LayoutPartials.BodyContent
      pageTitle="Optimized 5 routes"
      onTitleBack={() => {}}
    >
      <div className="flex flex-col h-[calc(100vh-12.75rem)]">
        <div className="flex space-x-4 p-4 flex-1">
          <div className="w-[26.5rem] h-full flex flex-col">
            <div className="flex border border-yellow bg-yellow-light py-2.5 px-4 rounded mb-4">
              <div className="mt-1">
                <Icon.InfoFill size="16" className="fill-yellow" />
              </div>
              <div className="flex-1 flex items-center">
                <div className="flex-1 mx-2">
                  Don&apos;t have enough route to deliver all orders.{" "}
                  <span className="text-yellow">10 unallocated orders</span> is
                  waiting for allocating.
                </div>
                <div>
                  <Common.ViewDetailBtn onClick={() => {}} />
                </div>
              </div>
            </div>

            <div className="flex-1">
              <CustomScrollbar>
                {/* <OptimizedReviewItem onOpenDetail={() => {}} isFirst />
                <OptimizedReviewItem onOpenDetail={() => {}} />
                <OptimizedReviewItem onOpenDetail={() => {}} />
                <OptimizedReviewItem onOpenDetail={() => {}} />
                <OptimizedReviewItem onOpenDetail={() => {}} /> */}
              </CustomScrollbar>
            </div>
          </div>
          <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
            <div className="ralative z-0 w-full h-full">
              <GoogleMap />
            </div>
            <div className="absolute bottom-3 right-3 z-10">
              <EyeMapDropdown />
            </div>
          </div>
        </div>
        <div className="shadow-overline px-4 pt-3 flex space-x-2 items-center">
          <div className="flex-1 flex mr-4">
            <Common.Button onClick={() => {}} color="transparent">
              Cancel
            </Common.Button>
          </div>
          <Common.Button onClick={() => {}} outline color="red">
            Discard
          </Common.Button>
          <Common.Button onClick={() => {}}>Optimize</Common.Button>
        </div>
      </div>
    </LayoutPartials.BodyContent>
  );
};

export default Index;

import groupBy from "lodash/groupBy";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "@/sharedStore/hooks";
import {
  initPantryListSelector,
  pantryLinesSelector,
  pantryListSelector,
  searchKeywordsSelector,
} from "./selector";
import { Product } from "../models/Product";

export const useGetSelectedPantryLines = () => {
  const pLines = useAppSelector(pantryLinesSelector);
  return useCallback(() => {
    return pLines?.filter((l) => l.isSelected && !l.isDeleted);
  }, [pLines]);
};

export const useGetFilterPantryLines = () => {
  const pantry = useAppSelector(pantryListSelector);
  const searchKeywords = useAppSelector(searchKeywordsSelector);
  return useMemo(() => {
    return pantry?.pantryLines?.filter(
      (d) =>
        !d.isDeleted &&
        (d.productSKU?.toLowerCase().includes(searchKeywords.toLowerCase()) ||
          d.productName
            ?.toLowerCase()
            .includes(searchKeywords.toLowerCase())) &&
        ((d.isFavourite && pantry.isShowFavouriteOnly) ||
          !pantry.isShowFavouriteOnly)
    );
  }, [pantry?.pantryLines, pantry.isShowFavouriteOnly, searchKeywords]);
};

export const useGetPantryLineProductIds = () => {
  const pantryLines = useAppSelector(pantryLinesSelector);
  return useMemo(() => {
    return pantryLines?.map((l) => l.productId);
  }, [pantryLines]);
};

export const useIsPantryListEdited = () => {
  const pantry = useAppSelector(pantryListSelector);
  const initPantry = useAppSelector(initPantryListSelector);
  return useCallback(() => {
    return (
      pantry.mode !== initPantry.mode ||
      pantry?.pantryLines?.some(
        (l) => l.isDirty || (l.isNew && !l.isEditing) || l.isDeleted
      )
    );
  }, [pantry?.pantryLines, pantry.mode, initPantry.mode]);
};

export const useGetProductGroups = (products: Product[]) => {
  return useCallback(() => {
    return groupBy(products, "productCategoryName");
  }, [products]);
};

export const useGetProductCategories = (products: Product[]) => {
  const getProductsGroups = useGetProductGroups(products);
  return useCallback(() => {
    return Object.getOwnPropertyNames(getProductsGroups());
    // eslint-disable-next-line
  }, [products]);
};

import { DashboardTopPendingOrders } from "./DashboardTopPendingOrders";
import { DashboardTopPendingCredits } from "./DashboardTopPendingCredits";
import { DashboardTopOverduePayments } from "./DashboardTopOverduePayments";

export const DashboardTop = () => {
  return (
    <div className="xl:flex">
      <div className="flex-1 bg-white border border-neutral-20 rounded mb-3 lg:flex lg:justify-between lg:py-5 px-4 lg:px-0">
        <DashboardTopPendingCredits />
        <DashboardTopPendingOrders />
        <DashboardTopOverduePayments />
      </div>
      {/* <div className="lg:flex xl:flex-col xl:ml-3 xl:w-1/3 mb-3 xl:mt-0 lg:space-x-4 xl:space-x-0 bg-white lg:justify-between lg:py-5 border border-red rounded px-4 lg:px-0">
        <DashboardTopOverduePayments />
      </div> */}
    </div>
  );
};

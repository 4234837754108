import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M10 18C9.99752 16.5949 10.3658 15.214 11.0677 13.9968C11.7695 12.7796 12.7801 11.769 13.9974 11.0673C15.2147 10.3655 16.5956 9.99733 18.0007 9.99992C19.4058 10.0025 20.7853 10.3758 22 11.082V1C22 0.734784 21.8946 0.48043 21.7071 0.292893C21.5196 0.105357 21.2652 0 21 0L1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1L0 23C0 23.2652 0.105357 23.5196 0.292893 23.7071C0.48043 23.8946 0.734784 24 1 24H12.726C11.8704 23.2519 11.1845 22.3295 10.7143 21.2947C10.2442 20.2599 10.0006 19.1366 10 18ZM10 6H17V8H10V6ZM8 18H5V16H8V18ZM8 13H5V11H8V13ZM8 8H5V6H8V8Z" />
        <path d="M18 12C16.8133 12 15.6533 12.3519 14.6666 13.0112C13.6799 13.6705 12.9109 14.6075 12.4567 15.7039C12.0026 16.8003 11.8838 18.0067 12.1153 19.1705C12.3468 20.3344 12.9182 21.4035 13.7574 22.2426C14.5965 23.0818 15.6656 23.6532 16.8295 23.8847C17.9933 24.1162 19.1997 23.9974 20.2961 23.5433C21.3925 23.0892 22.3295 22.3201 22.9888 21.3334C23.6481 20.3467 24 19.1867 24 18C24 16.4087 23.3679 14.8826 22.2426 13.7574C21.1174 12.6321 19.5913 12 18 12ZM21 19H19V21H17V19H15V17H17V15H19V17H21V19Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import clsx from "clsx";
import { Icon } from "../Icons";
export const CheckedCircle = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsx(
        "w-3 h-3 rounded-full bg-neutral-10 inline-flex items-center justify-center",
        className
      )}
    >
      <Icon.Checked size="8" className="fill-blue" />
    </div>
  );
};

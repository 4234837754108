import { useState } from "react";
import clsx from "clsx";
import { Common, Icon } from "@/components";
import { manifest, solution, visits } from "../models";
import { driverName } from "../utils";
import { DeliveryLineStatusValue } from "@/configuration/globalVariable";
import { distanceFormat, timeWorkingFormat } from "@/utils";

interface Props {
  isOptimizing?: boolean;
  isFirst?: boolean;
  manifest: manifest;
  solution?: solution;
}

export const OptimizingItem = ({
  isFirst,
  manifest,
  isOptimizing,
  solution,
}: Props) => {
  const [isChecked, setChecked] = useState(false);
  const workingTime = timeWorkingFormat(solution?.totalTimeAfter || 0);
  const distance = distanceFormat(solution?.totalDistanceAfter || 0);

  return (
    <div
      className={clsx(
        "flex px-4 py-2.5 border border-neutral-10 rounded group bg-white",
        !isFirst && "mt-2"
      )}
    >
      <div>
        <Common.Checkbox
          checked
          disabled
          onChange={() => setChecked(!isChecked)}
        />
      </div>
      <div className="ml-4 text-sm text-neutral-70">
        <div className="flex items-center mb-2">
          <Common.DotColor style={{ backgroundColor: manifest.route.colour }} />
          <div
            className="font-semibold text-neutral-80 ml-2 mr-3"
            data-tip={manifest.route.name}
          >
            {manifest.route.name}
          </div>

          {isOptimizing ? (
            <div>
              <Icon.Loading size="16" />
            </div>
          ) : (
            <div data-tip="Optimized">
              <Icon.CheckedCircle size="16" />
            </div>
          )}
        </div>
        <div className="flex items-center mb-2">
          <Icon.Distant size="16" className="fill-neutral-40" />
          <div className="ml-1.5 h-4 flex items-center">
            {isOptimizing ? <Icon.Loading size="12" /> : distance}
          </div>
          <Icon.Clock size="12" className="fill-neutral-40 ml-4 mr-1.5" />
          <div className="h-4 flex items-center">
            {isOptimizing ? <Icon.Loading size="12" /> : workingTime}
          </div>
        </div>
        <div className="flex items-center">
          <Icon.Driver size="16" className="fill-neutral-40" />
          <div className=" ml-1.5">
            {driverName(manifest.driver) || "No driver"}
          </div>
          <Icon.DeliveryTime
            size="12"
            className="fill-neutral-40 ml-4 mr-1.5"
          />
          {isOptimizing ? (
            <div>
              <Icon.Loading size="16" />
            </div>
          ) : (
            <div>
              {solution &&
                solution.visits.filter(
                  (visits: visits) =>
                    visits.manifestLine.deliveryStatus ===
                    DeliveryLineStatusValue.COMPLETED
                ).length}{" "}
              / {solution?.visits ? solution?.visits.length : 0} order
              {solution?.visits && solution?.visits.length > 1 && "s"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { DetailRow, DetailRowProps } from "./DetailRow";

export interface DocumentGroupProps {
  documentId: string;
  saleDocumentNumber: string;
  customerName: string;
  details: DetailRowProps[];
}

export const DocumentGroup = (props: DocumentGroupProps) => (
  <>
    <tr className="border border-line bg-neutral-20">
      <td className="px-2">
        <li className="flex items-center">
          <p className="font-bold pr-2">{`#${props.saleDocumentNumber}`}</p>
          <p className="font-bold text-neutral-30">|</p>
          <p className="font-bold pl-2">{props.customerName}</p>
        </li>
      </td>
      <td></td>
      <td></td>
      <td></td>
      <td className="text-right font-bold px-2">
        Total Products: {props.details.length}
      </td>
    </tr>

    {props.details.map((d, i) => (
      <DetailRow
        index={i + 1}
        key={`${d.productName}-${i}`}
        productId={d.productId}
        productSKU={d.productSKU}
        productName={d.productName}
        unit={d.unit}
        pickingStatus={d.pickingStatus}
        quantity={d.quantity}
        comment={d.comment}
      />
    ))}
  </>
);

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M5.5105 4.89004C5.5105 5.03415 5.56782 5.1724 5.66968 5.27425C5.77153 5.3762 5.90977 5.43343 6.05389 5.43343V4.34674C5.91234 4.35451 5.77864 4.4143 5.67841 4.51454C5.57807 4.61479 5.51838 4.74848 5.51051 4.89003L5.5105 4.89004Z" />
      <path d="M6.59766 5.97678V7.06347C6.79181 7.06347 6.97117 6.95991 7.0682 6.79183C7.16532 6.62374 7.16532 6.41661 7.0682 6.24844C6.97117 6.08036 6.79179 5.9768 6.59766 5.9768V5.97678Z" />
      <path d="M9.58573 8.41093e-05H3.06573C2.84325 0.00434784 2.63186 0.0974868 2.47866 0.258848C2.32545 0.420206 2.2434 0.636248 2.25069 0.85853V11.1276C2.25126 11.2255 2.30546 11.3153 2.39196 11.3612C2.478 11.4096 2.58307 11.4096 2.66902 11.3612L4.42398 10.2745L6.17895 11.3612H6.17905C6.2684 11.4185 6.38304 11.4185 6.4724 11.3612L8.22737 10.2745L9.98233 11.3612H9.98243C10.0665 11.4112 10.1707 11.4131 10.2565 11.3662C10.3424 11.3193 10.3972 11.2308 10.4008 11.1331V0.858446C10.408 0.636161 10.326 0.420139 10.1728 0.258764C10.0196 0.097405 9.80819 0.00426906 9.58572 0L9.58573 8.41093e-05ZM6.5974 7.60678V7.88393C6.5974 7.95594 6.56878 8.02511 6.51781 8.07599C6.46693 8.12697 6.39776 8.15558 6.32575 8.15558C6.17566 8.15558 6.05411 8.03392 6.05411 7.88394V7.60679C5.76587 7.60679 5.4895 7.49233 5.28568 7.28852C5.08185 7.08472 4.96741 6.80833 4.96741 6.52019C4.96741 6.3701 5.08907 6.24845 5.23906 6.24845C5.31116 6.24845 5.38023 6.27706 5.43121 6.32804C5.48209 6.37901 5.5107 6.44808 5.5107 6.52019C5.5107 6.66421 5.56803 6.80246 5.66988 6.90431C5.77174 7.00626 5.90998 7.06349 6.0541 7.06349V5.97679C5.76587 5.97679 5.48949 5.86234 5.28567 5.65853C5.08185 5.45473 4.96741 5.17833 4.96741 4.8901C4.97688 4.60499 5.09447 4.334 5.2962 4.13229C5.49801 3.93047 5.7689 3.81299 6.0541 3.8035V3.53176C6.0541 3.38177 6.17566 3.26012 6.32574 3.26012C6.39775 3.26012 6.46692 3.28873 6.5178 3.33971C6.56878 3.39068 6.59739 3.45975 6.59739 3.53177V3.80351C6.88305 3.81165 7.15472 3.92877 7.35672 4.13087C7.55873 4.33287 7.67583 4.60451 7.68408 4.8901C7.68408 4.96221 7.65547 5.03128 7.60449 5.08226C7.55361 5.13323 7.48444 5.16185 7.41243 5.16185C7.26235 5.16185 7.14079 5.04019 7.14079 4.89011C7.13293 4.74855 7.07323 4.61485 6.97289 4.51462C6.87265 4.41438 6.73896 4.35459 6.59741 4.34682V5.43351C6.9857 5.43351 7.34442 5.64064 7.53847 5.9768C7.73261 6.31306 7.73261 6.72731 7.53847 7.0635C7.34442 7.39966 6.9857 7.60678 6.59741 7.60678L6.5974 7.60678Z" />
    </SvgIcon>
  );
}

export default IconComponent;

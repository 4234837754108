import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";

import { Common, Icon, Td } from "@/components";
import { DatePicker } from "@/components/common/Form/Datepicker";
import { deliveryDisplayType } from "../models";
import ReactDatePicker from "react-datepicker";
import { covertTime12ToDate } from "../utils";

let timer: ReturnType<typeof setTimeout>;

type Props = {
  item: deliveryDisplayType;
  onSelectDate: (
    deliveryDateId: string,
    column: "despatchDate" | "orderDate" | "date",
    date: Date | null
  ) => void;
  onDeleteDelivery: (dayId: string) => void;
  onChangeCutoffTime: (value: string) => void;
};

const ItemRowHolidayEdit = ({
  item,
  onSelectDate,
  onDeleteDelivery,
  onChangeCutoffTime,
}: Props) => {
  const timePickerRef = useRef<ReactDatePicker>();
  const [cutOffTime, setCutoffTime] = useState<Date | null>(
    covertTime12ToDate(item?.cutOffTime)
  );
  const [dateGroup, setDateGroup] = useState<{
    date: Date | null;
    despatchDate: Date | null;
    orderDate: Date | null;
  }>({
    date: item.date ? moment(item.date).toDate() : null,
    despatchDate: item.despatchDate ? moment(item.despatchDate).toDate() : null,
    orderDate: item.orderDate ? moment(item.orderDate).toDate() : null,
  });

  const [selectField, setSelectField] = useState<
    "despatchDate" | "orderDate" | "date"
  >("date");

  useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      onChangeCutoffTime(moment(cutOffTime, ["hh:mm:ss"]).format("h:mm A"));
    }, 500);
  }, [cutOffTime]);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      onSelectDate(item.id || "", selectField, dateGroup[selectField]);
    }, 500);
  }, [dateGroup]);

  const handleScrollIntoView = () => {
    const ele = document.getElementById(item.id || "");
    const wrapper = document.getElementById("holiday-edit");
    if (ele && wrapper && wrapper.offsetHeight < 340) {
      ele.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCutOffTime = (value: Date) => {
    setCutoffTime(value);
  };

  const handleStopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const handleSelectTime = (
    value: Date,
    field: "date" | "despatchDate" | "orderDate"
  ) => {
    setDateGroup({ ...dateGroup, [field]: value });
    setSelectField(field);
  };

  return (
    <tr
      key={item.id}
      className="text-sm font-medium text-neutral-90 group hover:bg-neutral-5"
      id={item.id}
    >
      <Td className="py-3 border-b border-l border-neutral-20">
        <div className="relative" onClick={handleScrollIntoView}>
          <DatePicker
            selected={dateGroup.date}
            onChange={(value) => {
              handleSelectTime(value, "date");
            }}
            placeholder="Select date"
            isError={item.dateError}
            minDate={new Date()}
          />
          {item.dateError && (
            <div
              className="absolute right-12 top-2.5 cursor-pointer"
              data-tip
              data-for={`error-date-${item.id}`}
              onClick={handleStopPropagation}
            >
              <Icon.WarningCircle className="fill-red" />
            </div>
          )}
        </div>
      </Td>
      <Td className="py-3 border-b border-neutral-20">
        <div className="relative" onClick={handleScrollIntoView}>
          <DatePicker
            selected={dateGroup.despatchDate}
            onChange={(value) => {
              handleSelectTime(value, "despatchDate");
            }}
            placeholder="Select date"
            isError={item.despatchDateError}
            minDate={new Date()}
          />
          {item.despatchDateError && (
            <div
              className="absolute right-12 top-2.5 cursor-pointer"
              data-tip
              data-for={`error-despatch-date-${item.id}`}
              onClick={handleStopPropagation}
            >
              <Icon.WarningCircle className="fill-red" />
            </div>
          )}
        </div>
      </Td>
      <Td className="py-3 border-b border-neutral-20">
        <div className="relative" onClick={handleScrollIntoView}>
          <DatePicker
            selected={dateGroup.orderDate}
            onChange={(value) => {
              handleSelectTime(value, "orderDate");
            }}
            placeholder="Select date"
            isError={item.orderDateError}
            minDate={new Date()}
          />
          {item.orderDateError && (
            <div
              className="absolute right-12 top-2.5 cursor-pointer"
              data-tip
              data-for={`error-order-date-${item.id}`}
              onClick={handleStopPropagation}
            >
              <Icon.WarningCircle className="fill-red" />
            </div>
          )}
        </div>
      </Td>
      <Td className="py-3 border-b border-neutral-20">
        <div className="inline-block relative">
          <Common.TimePicker
            ref={timePickerRef}
            selected={cutOffTime}
            onChange={handleCutOffTime}
            wrapClassName="px-2"
            inputClassname="text-right"
            isError={item.cutoffTimeError}
            popperPlacement="bottom-end"
            customInput={false}
            className={clsx(
              "max-w-[10rem] border text-base text-right rounded py-[0.375rem] bg-transparent pr-10 focus:shadow-none",
              item.cutoffTimeError
                ? "border-red focus:outline-none focus:ring-red focus-visible:outline-none focus:border-red"
                : "border-neutral-20 focus:ring-blue focus:border-blue focus-visible:outline-none"
            )}
          />
          <div
            className="absolute top-2.5 right-2 cursor-pointer"
            onClick={() => timePickerRef.current?.setOpen(true)}
          >
            <Icon.Clock className="fill-neutral-40" />
          </div>
        </div>
      </Td>
      <Td className="py-3 border-b border-r border-neutral-20">
        <div
          className="flex items-center justify-center bg-neutral-10 w-8 h-8 rounded"
          onClick={() => {
            onDeleteDelivery(item.id || "");
          }}
        >
          <Icon.Trash size="16" className="fill-neutral-50 cursor-pointer " />
        </div>
      </Td>

      {item.dateError && item.dateErrorMsg && (
        <ReactTooltip
          id={`error-date-${item.id}`}
          place="bottom"
          type="error"
          className="px-4 py-2"
        >
          {item.dateErrorMsg}
        </ReactTooltip>
      )}

      {item.despatchDateError && item.despatchDateErrorMsg && (
        <ReactTooltip
          id={`error-despatch-date-${item.id}`}
          place="bottom"
          type="error"
          className="px-4 py-2"
        >
          {item.despatchDateErrorMsg}
        </ReactTooltip>
      )}

      {item.orderDateError && item.orderDateErrorMsg && (
        <ReactTooltip
          id={`error-order-date-${item.id}`}
          place="bottom"
          type="error"
          className="px-4 py-2"
        >
          {item.orderDateErrorMsg}
        </ReactTooltip>
      )}
    </tr>
  );
};

export default ItemRowHolidayEdit;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.964 0.0293333C15.6787 0.00933333 15.3987 0 15.1234 0C8.89803 0 5.48003 4.95066 4.38936 8.67266L7.34136 11.6247C11.1947 10.388 16 7.10666 16 0.962666V0.923333C15.9987 0.632 15.9874 0.334 15.964 0.0293333ZM4.95333 4.3333C3.768 4.32664 2.24533 4.94064 1.10933 6.07597C0.676 6.50863 0.294666 7.01463 0 7.5813C1.02533 6.80597 2.152 6.5913 3.43133 7.3093C3.80866 6.3133 4.31133 5.29997 4.95333 4.3333ZM5.71065 11.8947C5.08065 13.536 3.25865 14.5693 1.33532 14.6687C1.42799 12.8207 2.45799 10.93 4.14132 10.2933L4.48132 10.6407C3.45865 11.656 3.10732 12.484 3.05199 12.948C3.52132 12.8913 4.38932 12.5427 5.37398 11.5507L5.71065 11.8947ZM9.93 14.8907C9.496 15.3247 8.99067 15.7067 8.42267 16C9.198 14.976 9.40666 13.8467 8.68867 12.5667C9.58266 12.2173 10.616 11.7227 11.666 11.036C11.678 12.2233 11.0687 13.7533 9.93 14.8907ZM10.6667 4.00002C10.6667 4.73668 11.2633 5.33335 12 5.33335C12.7367 5.33335 13.3333 4.73668 13.3333 4.00002C13.3333 3.26335 12.7367 2.66669 12 2.66669C11.2633 2.66669 10.6667 3.26335 10.6667 4.00002Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { Link, LinkProps } from "react-router-dom";
import base64 from "base-64";
import { PATH } from "@/configuration/globalVariable";

interface Props extends LinkProps {
  to: string;
  children: React.ReactNode;
}
// extends ButtonHTMLAttributes<HTMLButtonElement>
export const RedirectToOUI = ({ to, children, ...rest }: Props) => {
  const path = base64.encode(to);
  return (
    <Link to={`/${PATH.REDIRECT}?path=${path}`} {...rest}>
      {children}
    </Link>
  );
};

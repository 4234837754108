import clsx from "clsx";
import { tab } from "@/models";

interface Props {
  tabs: tab[];
  activeTab: string;
  setActiveTab: (name: string) => void;
  className?: string;
}

export const TabButton = ({
  tabs,
  activeTab,
  className,
  setActiveTab,
}: Props) => {
  return (
    <nav
      className={clsx(
        "flex bg-neutral-20 border-neutral-20 p-1 rounded-sm space-x-1",
        className
      )}
      aria-label="Tabs"
    >
      {tabs.map((tab: tab) => {
        const { icon: Icon } = tab;
        const isActive = tab.name === activeTab;
        return (
          <div
            key={tab.name}
            className={clsx(
              "flex items-center cursor-pointer h-[1.875rem] px-3.5 rounded-sm",
              isActive ? "text-white bg-blue" : "text-neutral-40",
              tab.disable && "pointer-events-none"
            )}
            onClick={() => setActiveTab(tab.name)}
          >
            {Icon && (
              <Icon
                size="16"
                className={clsx(
                  "mr-2",
                  isActive ? "fill-white" : "fill-neutral-40"
                )}
              />
            )}
            {tab.name}
          </div>
        );
      })}
    </nav>
  );
};

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97444 3.09916L12.9013 7.02597L5.99736 13.9299C5.93454 13.9926 5.85526 14.0363 5.76867 14.0559L1.56837 14.9893C1.53506 14.9963 1.50113 15 1.46709 15C1.32546 15 1.19143 14.9359 1.10277 14.8255C1.0141 14.715 0.980391 14.5703 1.01112 14.432L1.94453 10.2317C1.96411 10.1451 2.0078 10.0659 2.07054 10.003L8.97444 3.09916ZM10.6644 1.40877C11.2117 0.863742 12.0967 0.863742 12.6441 1.40877L14.5912 3.35631C15.1361 3.90366 15.1363 4.78844 14.5916 5.33605L13.5612 6.36606L9.63435 2.43925L10.6644 1.40877Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

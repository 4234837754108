import { Icon, RedirectToOUI } from "@/components";
import { currencyFormatter } from "@/utils";
import clsx from "clsx";
import React from "react";
interface Props {
  title: string;
  icon: React.ReactNode;
  amount: number;
  count: number;
  countSuffix: string;
  note?: string;
  isFirst?: boolean;
  isRed?: boolean;
  isLoading?: boolean;
  className?: string;
  pathViewMore: string;
}

export const DashboardTopItem = ({
  title,
  icon,
  amount,
  count,
  countSuffix,
  isFirst,
  isRed,
  isLoading,
  note,
  className,
  pathViewMore,
}: Props) => {
  return (
    <div
      className={clsx(
        "lg:flex justify-start lg:px-5 w-full py-4",
        !isFirst && "border-t lg:border-t-0 lg:border-l border-neutral-20",
        className
      )}
    >
      <div className="flex">
        <div
          className={clsx(
            "w-9 h-9 lg:w-[3.25rem] lg:h-[3.25rem] rounded bg-status-bg-warning flex justify-center items-center",
            isRed ? "bg-status-bg-error" : "bg-status-bg-warning"
          )}
        >
          {icon}
        </div>
        <div className="ml-6 flex-1">
          <div className="text-sm font-medium text-neutral-60 uppercase">
            {title}
          </div>
          <div className="mb-1.5 text-xs text-neutral-50 italic">{note}</div>
          <div className="flex justify-between items-center w-full lg:block">
            <div
              className={clsx(
                "text-[1.25rem] md:text-hd1 text-neutral font-semibold lg:mb-1.5",
                isRed && "text-red"
              )}
            >
              {isLoading ? "$..." : `${currencyFormatter.format(amount)}`}
            </div>
            <RedirectToOUI to={pathViewMore}>
              <div className="flex items-center text-base text-neutral-60 font-medium cursor-pointer hover:text-blue">
                {isLoading ? "..." : count} {countSuffix}
                <Icon.AngleRight size="12" className="ml-3 stroke-blue" />
              </div>
            </RedirectToOUI>
          </div>
        </div>
      </div>
    </div>
  );
};

import { BUILD_MODE } from "@/configuration/globalVariable";
import { Nav, SubNav, userData } from "@/models";

export const checkIsRouteRender = (
  routeItem: Nav | SubNav,
  user?: userData
) => {
  let isAccess = true;
  if (routeItem.settingDisplay && user) {
    // Check access setting optional
    isAccess = user[routeItem.settingDisplay as keyof typeof user];
  }
  const isEnvRender =
    isAccess &&
    (!routeItem.envDisplay ||
      (routeItem.envDisplay &&
        routeItem.envDisplay.includes(BUILD_MODE as string)));
  return isEnvRender;
};

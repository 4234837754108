import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 7 6">
      <path d="M3.414 2.58576L1.414 0.585754L0 1.99975L2.707 4.70675C3.414 5.41375 3.414 5.41375 4.121 4.70675L6.828 1.99975L5.414 0.585754L3.414 2.58576Z" />
    </SvgIcon>
  );
}

export default IconComponent;

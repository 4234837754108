import { AUTH, DOMAIN_URL } from "@/configuration/globalVariable";

export const setToken = (token: string) => {
  localStorage.setItem(AUTH.ACCESS_TOKEN_KEY, token);
};

export const getToken = () => {
  return localStorage.getItem(AUTH.ACCESS_TOKEN_KEY);
};

export const setUser = (user: object) => {
  localStorage.setItem(AUTH.ACCESS_TOKEN_KEY, JSON.stringify(user));
};

export const getUser = () => {
  const user = localStorage.getItem(AUTH.ACCESS_TOKEN_KEY);
  return user ? JSON.parse(user) : {};
};

export const navigateToSignin = () => {
  const win: Window = window;
  win.location = `${DOMAIN_URL}/authentication/login`;
};

export const uuid = () => {
  const tempUrl = URL.createObjectURL(new Blob());
  const uuid = tempUrl.toString();
  URL.revokeObjectURL(tempUrl);
  return uuid.substr(uuid.lastIndexOf("/") + 1);
  // remove prefix (e.g. blob:null/, blob:www.test.com/, ...)
};

import React from "react";
import { Route, Routes } from "react-router-dom";

import { MasterLayout } from "@/components";
// import SignIn from "@/pages/Authentication/SignIn";
// import SignUp from "@/pages/Authentication/SignUp";
import SignOut from "@/pages/Authentication/SignOut";

import PickingReportsGroupByPickingStatus from "@/pages/Reports/Picking/GroupByPickingStatus";
import PickingReportsGroupByProducts from "@/pages/Reports/Picking/GroupByProducts";
import PickingReportsGroupByDocuments from "@/pages/Reports/Picking/GroupByDocuments";

import PantryList from "@/pages/PantryList";

import TransferScreen from "@/pages/Transfer";
import RedirectToOUIScreen from "@/pages/RedirectToOUI";
import NotFound from "@/pages/NotFound";

import { PATH } from "@/configuration/globalVariable";
import { sidebarNavigation } from "@/configuration/sidebarNavigation";
import { SubNav, Nav } from "@/models";
import { checkIsRouteRender } from "@/utils";

const AppRouter = () => {
  return (
    <Routes>
      <Route path={PATH.AUTH}>
        {/* <Route index element={<SignIn />} /> */}
        {/* <Route path={PATH.SIGN_IN} element={<SignIn />} /> */}
        {/* <Route path={PATH.SIGN_UP} element={<SignUp />} /> */}
        <Route path={PATH.SIGN_OUT} element={<SignOut />} />
      </Route>
      <Route path={PATH.TRANSFER} element={<MasterLayout.TransferingLayout />}>
        <Route index element={<TransferScreen />} />
      </Route>
      <Route path={PATH.HOME} element={<MasterLayout.AppLayout />}>
        {sidebarNavigation.map((routeItem: Nav, index: number) => {
          const isRouteRender =
            checkIsRouteRender(routeItem) && !routeItem.isDiffDomain;

          if (isRouteRender) {
            const ElementScrren = routeItem.element;
            // Render item has sub nested route
            if (routeItem.subItems) {
              return (
                <Route
                  key={index + routeItem.name}
                  path={routeItem.path}
                  element={
                    ElementScrren && <ElementScrren pageName={routeItem.name} />
                  }
                >
                  {routeItem.subItems.map(
                    (subRouteItem: SubNav, idx: number) => {
                      const isSubRouteRender =
                        checkIsRouteRender(subRouteItem) &&
                        !subRouteItem.isDiffDomain;
                      if (isSubRouteRender) {
                        const SubElementScrren = subRouteItem.element;
                        return (
                          <React.Fragment key={idx + subRouteItem.name}>
                            {subRouteItem.index && (
                              <Route
                                index
                                element={
                                  SubElementScrren && (
                                    <SubElementScrren
                                      pageName={subRouteItem.name}
                                    />
                                  )
                                }
                              />
                            )}
                            <Route
                              path={subRouteItem.path}
                              element={
                                SubElementScrren && (
                                  <SubElementScrren
                                    pageName={subRouteItem.name}
                                  />
                                )
                              }
                            />
                          </React.Fragment>
                        );
                      }
                      return null;
                    }
                  )}
                </Route>
              );
            }

            // Render router without sub items
            return (
              <React.Fragment key={index + routeItem.name}>
                {routeItem.index && (
                  <Route index element={ElementScrren && <ElementScrren />} />
                )}
                <Route
                  path={routeItem.path}
                  element={ElementScrren && <ElementScrren />}
                />
              </React.Fragment>
            );
          }

          return null;
          // return isRender && <Route index element={<Dashboard />} />;
        })}
        <Route path={PATH.REDIRECT} element={<RedirectToOUIScreen />} />

        {/* <Route index element={<Dashboard />} />
        <Route path={PATH.HOME} element={<Dashboard />} />
        <Route path={PATH.STARTED} element={<GetStartedScreen />} />
        <Route path={PATH.ENTER_ORDER} element={<EnterOrder />} />

        <Route path={PATH.DELIVERY} element={<Delivery />} />
        <Route
          path={`${PATH.DELIVERY}/${PATH.EDIT_ROUTES}`}
          element={<EditRoutes />}
        />
        <Route
          path={`${PATH.DELIVERY}/${PATH.ROUTE_DETAILS}`}
          element={<DeliveryRouteDetail />}
        />
        <Route
          path={`${PATH.DELIVERY}/${PATH.ALLOCATE}`}
          element={<Allocate />}
        />

        <Route path={PATH.SALES_ORDERS} element={<SalesOrders />}>
          <Route index element={<SalesOrders pageName="Orders page" />} />
          <Route
            path={PATH.ORDERS}
            element={<SalesOrders pageName="Orders page" />}
          />
          <Route
            path={PATH.INVOICES}
            element={<SalesOrders pageName="Invoice page" />}
          />
          <Route
            path={PATH.QUOTES}
            element={<SalesOrders pageName="Quotes page" />}
          />
        </Route>
        <Route path={PATH.APP_STORYBOOK} element={<CommonAppComponent />}>
          <Route index element={<ButtonScreen />} />
          <Route path={PATH.COMMON_BUTTON} element={<ButtonScreen />} />
          <Route path={PATH.COMMON_ICON} element={<IconScreen />} />
          <Route path={PATH.COMMON_FORM} element={<FromScreen />} />
          <Route path={PATH.COMMON_TOOLTIP} element={<TooltipScreen />} />
          <Route path={PATH.COMMON_LABEL} element={<LabelScreen />} />
          <Route path={PATH.NOTISTACK} element={<NotistackScreen />} />
          <Route path={PATH.ALERTS} element={<AlertScreen />} />
        </Route> */}
      </Route>
      <Route path={PATH.REPORT._} element={<MasterLayout.EmptyLayout />}>
        <Route path={PATH.REPORT.PICKING._}>
          <Route path={PATH.REPORT.PICKING.GROUPBY._}>
            <Route
              path={PATH.REPORT.PICKING.GROUPBY.PICKINGSTATUS}
              element={<PickingReportsGroupByPickingStatus />}
            />
            <Route
              path={PATH.REPORT.PICKING.GROUPBY.PRODUCTS}
              element={<PickingReportsGroupByProducts />}
            />
            <Route
              path={PATH.REPORT.PICKING.GROUPBY.DOCUMENTS}
              element={<PickingReportsGroupByDocuments />}
            />
          </Route>
        </Route>
      </Route>
      <Route path={PATH.IFRAME._} element={<MasterLayout.EmptyLayout />}>
        <Route
          path={PATH.IFRAME.PANTRY_LIST._}
          element={<PantryList isIframe />}
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRouter;

import axios from "axios";
import { useMutation } from "react-query";

const API_PATH = "picking/";

export const useGetPickingProductsReportGroupByPickingStatus = () =>
  useMutation((queryString: string) =>
    axios
      .get(
        `${API_PATH}GetPickingProductsReportGroupByPickingStatus${queryString}`
      )
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

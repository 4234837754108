import { useEffect } from "react";
import { Common, Icon, ModalContent } from "@/components";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { SnackbarUtils } from "@/utils";
import { defaultNumberProducts } from "../configVariable";
import {
  isPantryListLoadingSelector,
  pantryListSelector,
} from "../providers/selector";
import { fetchAutoPantryAsync, setNumberProducts } from "../providers/slice";

export interface ChangeAutoModeModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onOverlayClick?: () => void;
  onCancel: () => void;
  onSubmit?: () => void;
}
const Index = ({
  isOpen,
  onClose,
  onOverlayClick,
  onCancel,
  onSubmit,
}: ChangeAutoModeModalProps) => {
  const dispatch = useAppDispatch();
  const pantry = useAppSelector(pantryListSelector);
  const isPantryLoading = useAppSelector(isPantryListLoadingSelector);

  useEffect(() => {
    if (isOpen) {
      dispatch(setNumberProducts(defaultNumberProducts));
    }
  }, [isOpen]);

  const onSubmitClick = async () => {
    dispatch(
      fetchAutoPantryAsync({
        id: pantry.id,
        customerAccountId: pantry.customerAccountId,
        supplierAccountId: pantry.supplierAccountId,
        numberProducts: pantry.numberProduct,
      })
    ).then(() => {
      SnackbarUtils.success("Change to <b>Auto mode</b> successfully");
      if (onSubmit) onSubmit();
    });
  };

  return (
    <ModalContent
      open={isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      panelClassWidth="max-w-[32rem] w-full max-h-[90vh]"
    >
      <div className="p-4 flex flex-col items-center max-h-[90vh]">
        <Icon.Alert
          iconType="fill"
          className="mx-auto mt-12 fill-red"
          size={36}
          rounded
        />
        <p className="text-hd4 font-bold my-2">Confirm change to Auto mode</p>
        <p className="my-2">
          Changing to Auto mode will replace all current products in this pantry
          list with the last ordered products for this customer. The auto pantry
          list will be updated continuously as new orders are placed.
        </p>
        {/* <Common.Input
          label="Number of products"
          defaultValue={defaultNumberProducts}
          // value={pantry.numberProduct}
          onChange={(e) => dispatch(setNumberProducts(e.target.value))}
        /> */}
        <div className="flex flex-row items-center my-2">
          <span className="px-2 min-w-[170px]">Number of products:</span>
          <Common.CounterInput
            inputClass="w-16"
            value={pantry.numberProduct}
            setValue={(val) => dispatch(setNumberProducts(val))}
          />
        </div>

        <div className="h-[3.75rem] flex items-center px-5">
          <Common.Button
            color={"gray"}
            btSize={"lg"}
            className="mx-2"
            onClick={onCancel}
          >
            Cancel
          </Common.Button>
          <Common.Button
            color={"blue"}
            btSize={"lg"}
            onClick={onSubmitClick}
            isLoading={isPantryLoading}
            disabled={isPantryLoading}
          >
            Change Now
          </Common.Button>
        </div>
      </div>
    </ModalContent>
  );
};
export default Index;

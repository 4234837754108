import { useCallback } from "react";
import { useAppSelector } from "@/sharedStore/hooks";
import { role } from "@/models";

export const useCheckRole = () => {
  const roles = useAppSelector((state) =>
    state.authReducer.user?.roles?.map((i: role) => i.id)
  );

  return useCallback(
    (id: string | string[]) => {
      if (roles) {
        if (typeof id === "string") {
          return roles.includes(id);
        } else {
          return Boolean(roles.find((rid) => id.includes(rid)));
        }
      }
      return false;
    },
    [roles]
  );
};

export const useCheckSetting = () => {
  const user = useAppSelector((state) => state.authReducer.user);

  return useCallback(
    (settingDisplay: string) => user[settingDisplay as keyof typeof user],
    [user]
  );
};

import { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import { Common, CustomScrollbar, Icon, ModalContent } from "@/components";
import { DatePicker } from "@/components/common/Form/Datepicker";
import { TIME_FORMAT } from "@/configuration/globalVariable";
import { documentViolate } from "../models";
import { useCheckDeliveryDate } from "../services/schedule";

interface Props {
  data: documentViolate[];
  scheduleId: string;
  onClose: () => void;
  onMove: ({
    deliveryDate,
    despatchDate,
    IsErrorOrWarning,
  }: {
    deliveryDate: Date;
    despatchDate: Date;
    IsErrorOrWarning: boolean;
  }) => void;
}

export const ModalChangeDateViolate = ({
  data,
  onClose,
  onMove,
  scheduleId,
}: Props) => {
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [despatchDate, setDespatchDate] = useState<Date | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [despatchErrorMessage, setdespatchErrorMessage] = useState<string>();
  const checkDeliveryDate = useCheckDeliveryDate();
  const { isLoading: isValidating } = checkDeliveryDate;

  useEffect(() => {
    if (data.length) {
      setDeliveryDate(
        moment(data[0].orderDeliveryDate, TIME_FORMAT.GENERAL_DISPLAY).toDate()
      );
    } else {
      setDeliveryDate(null);
      setDespatchDate(null);
      setErrorMessage("");
      setdespatchErrorMessage("");
    }
  }, [data]);

  const checkOnDeliveryDateChange = (date: Date) => {
    setDeliveryDate(date);
  };

  useEffect(() => {
    deliveryDate &&
      checkDeliveryDate.mutate(
        {
          scheduleId,
          inputDate: moment(deliveryDate).format(TIME_FORMAT.REQUEST),
        },
        {
          onSuccess: (res) => {
            despatchErrorMessage && setdespatchErrorMessage("");
            if (res.errorMessage) {
              setErrorMessage(res.errorMessage);
              checkDespatchDate(deliveryDate);
            } else {
              errorMessage && setErrorMessage("");
              checkDespatchDate(moment(res.despatchDate).toDate());
            }
          },
        }
      );
  }, [deliveryDate]);

  const checkDespatchDate = (date: Date) => {
    setDespatchDate(date);
    if (!moment(date).isSameOrBefore(deliveryDate, "day")) {
      !despatchErrorMessage &&
        setdespatchErrorMessage(
          "Despatch date is less than or equal to Delivery date."
        );
    } else {
      despatchErrorMessage && setdespatchErrorMessage("");
    }
  };

  const handleMove = () => {
    deliveryDate &&
      despatchDate &&
      onMove({
        deliveryDate,
        despatchDate,
        IsErrorOrWarning: Boolean(errorMessage),
      });
  };

  return (
    <ModalContent
      open={Boolean(data.length)}
      onClose={onClose}
      panelClassWidth="max-w-[31.25rem]"
    >
      <div className="px-[4.688rem] pt-14 pb-10">
        <div className="w-[21.875rem]">
          <div>
            <div className="h-20 w-20 rounded-full bg-blue-30 flex justify-center items-center mx-auto">
              <div className="w-[3.25rem] h-[3.25rem] rounded-full bg-blue flex justify-center items-center">
                <Icon.MoveTo size={24} className="fill-white" />
              </div>
            </div>
          </div>
          <div className="font-semibold text-hd3 text-center mt-5">
            Confirm move order
          </div>
          <div className="text-center mt-2">
            There are {data.length} order about to change the delivery date
          </div>
          <div
            className={clsx(
              "bg-neutral-10 mt-5",
              data.length > 3 && "h-[10rem]"
            )}
          >
            <CustomScrollbar
              renderView={(props) => (
                <div
                  {...props}
                  className={clsx(
                    data.length <= 3 && "!static overflow-hidden"
                  )}
                />
              )}
            >
              <ul className="p-3 list-disc">
                {data.map((doc, index) => (
                  <li key={index} className={clsx("ml-6", index && "mb-1")}>
                    {doc.saleDocumentNumber} - {doc.customerAccountName}
                  </li>
                ))}
              </ul>
            </CustomScrollbar>
          </div>
          <div className="text-center mt-4">
            Please select the delivery and despatch dates you want to change
          </div>
          <DatePicker
            wrapClassName="mt-4"
            minDate={new Date()}
            selected={deliveryDate}
            onChange={checkOnDeliveryDateChange}
            label="Delivery date"
            isWarning={Boolean(errorMessage)}
            errorMessage={errorMessage}
            isLoading={isValidating}
            disabled={isValidating}
          />
          <DatePicker
            wrapClassName="mt-4"
            minDate={new Date()}
            selected={despatchDate}
            onChange={checkDespatchDate}
            label="Despatch date"
            isError={Boolean(despatchErrorMessage)}
            errorMessage={despatchErrorMessage}
            isLoading={isValidating}
            disabled={isValidating}
          />
          <div className="text-center mt-8">
            <Common.Button outline className="mr-3" onClick={onClose}>
              Cancel
            </Common.Button>
            <Common.Button
              onClick={handleMove}
              disabled={
                !(deliveryDate && despatchDate && !despatchErrorMessage)
              }
            >
              Move
            </Common.Button>
          </div>
        </div>
      </div>
    </ModalContent>
  );
};

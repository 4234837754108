import { SvgIcon } from "./SvgIcon";
// Fill
function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M2 5L8 11L14 5"
        fill="none"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default IconComponent;

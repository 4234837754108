import { Outlet } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { useAppSelector } from "@/sharedStore/hooks";
import { useCheckRole, useCheckSetting } from "@/providers/auth/hooks";
import { AccessDeniedPage } from "@/components";
import { ROLE } from "@/configuration/globalVariable";
import { userSelector } from "@/providers/auth/selector";

const Index = () => {
  const user = useAppSelector(userSelector);

  const checkRole = useCheckRole();
  const checkSetting = useCheckSetting();
  const checkPermission = checkRole(ROLE.ADMIN);
  const checkSettingPermission = checkSetting("isSupportDeliverySchedule");

  return checkPermission && checkSettingPermission ? (
    <Outlet />
  ) : (
    <>{!isEmpty(user) && <AccessDeniedPage />}</>
  );
};

export default Index;

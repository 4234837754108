import { useEffect, useMemo, useState } from "react";
import { Common, CustomScrollbar, Icon, ModalContent } from "@/components";
import { useAppSelector } from "@/sharedStore/hooks";
import { HistoryLog } from "@/models/historyLog";
import { pantryListSelector } from "../providers/selector";
import { useGetHistoryLogs } from "../services/pantryList";
import { Timeline } from "@/components/common/Timeline/TimelineModel";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onOverlayClick?: () => void;
}
const Index = ({ isOpen, onClose, onOverlayClick }: Props) => {
  const pantry = useAppSelector(pantryListSelector);
  const getHistoryLogsMuta = useGetHistoryLogs();
  const [historyLogs, setHistoryLogs] = useState<HistoryLog[]>([]);

  const getHistoryLogs = async () => {
    const result = await getHistoryLogsMuta.mutateAsync({
      id: pantry.id,
      customerAccountId: pantry.customerAccountId,
      supplierAccountId: pantry.supplierAccountId,
    });
    setHistoryLogs(result);
  };

  useEffect(() => {
    if (isOpen) {
      getHistoryLogs();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const timelines = useMemo(() => {
    const timelines: Timeline[] = historyLogs.map((l) => ({
      title: l.log,
      timestamp: `At ${l.timestampFormatted} by ${l.username}`,
    }));
    return timelines;
  }, [historyLogs]);

  return (
    <ModalContent
      open={isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      // panelClassWidth="max-w-[32rem] max-h-[90vh]"
    >
      <div className="flex flex-col">
        <div className="text-[1.25rem] font-semibold p-5">Change History</div>

        <div className="h-[calc(100vh-7.75rem)] overflow-y-auto">
          {getHistoryLogsMuta.isSuccess && timelines?.length > 0 && (
            <CustomScrollbar>
              <Common.Timeline
                nodeSize="md"
                titleClass="text-neutral-80 font-normal"
                timelines={timelines}
              />
            </CustomScrollbar>
          )}

          {/* Show on loading */}
          {getHistoryLogsMuta.isLoading && (
            <div className="h-full flex items-center justify-center">
              <Icon.Loading size={60} />
            </div>
          )}

          {/* Show on no data on result */}
          {getHistoryLogsMuta.isSuccess && timelines?.length === 0 && (
            <Common.NoData title="No data." />
          )}
        </div>
      </div>
    </ModalContent>
  );
};
export default Index;

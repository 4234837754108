import { forwardRef } from "react";
import DatePickerComponent from "react-datepicker";
import clsx from "clsx";
import { Icon } from "@/components";
import Input from "./Input";

interface Props {
  ipSize?: "lg" | "md";
  className?: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  inputClassname?: string;
  disabled?: boolean;
  selected: Date | null;
  popperPlacement?: "bottom-start" | "bottom-end";
  onChange: (date: Date) => void;
  customInput?: boolean;
  disabledTimePicker?: boolean;
}

interface inputProps {
  ipSize?: "lg" | "md";
  className?: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  disabled?: boolean;
}

export type Ref = HTMLInputElement;

export const TimePicker = forwardRef(
  (
    {
      ipSize = "lg",
      className,
      label,
      isError,
      errorMessage,
      wrapClassName,
      inputClassname,
      disabled,
      selected,
      popperPlacement = "bottom-start",
      onChange,
      customInput = true,
      disabledTimePicker,
    }: Props,
    timePickerRef: any
  ) => {
    // const [startDate, setStartDate] = useState<Date | null>(new Date());

    const CustomInput = forwardRef<Ref, inputProps>(({ ...rest }, ref) => (
      <Input
        {...rest}
        ipSize={ipSize}
        iconRight={Icon.Clock}
        label={label}
        isError={isError}
        errorMessage={errorMessage}
        wrapClassName={wrapClassName}
        disabled={disabled}
        ref={ref}
        className={inputClassname}
      />
    ));

    return (
      <DatePickerComponent
        ref={timePickerRef}
        popperPlacement={popperPlacement}
        className={clsx(className, "text-neutral-60", isError && "border-red")}
        selected={selected}
        onChange={onChange}
        customInput={customInput ? <CustomInput /> : undefined}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        disabled={disabledTimePicker}
      />
    );
  }
);

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M15.1381 4.19529L11.3334 0.390625L10.3907 1.33329L13.0574 3.99996H1.33341C0.965415 3.99996 0.666748 4.29796 0.666748 4.66662C0.666748 5.03529 0.965415 5.33329 1.33341 5.33329H13.0574L10.3907 7.99996L11.3334 8.94262L15.1381 5.13796C15.3987 4.87729 15.3987 4.45596 15.1381 4.19529Z" />
      <path d="M0.862004 10.8619L4.66667 7.05725L5.60934 7.99992L2.94267 10.6666H14.6667C15.0347 10.6666 15.3333 10.9646 15.3333 11.3333C15.3333 11.7019 15.0347 11.9999 14.6667 11.9999H2.94267L5.60934 14.6666L4.66667 15.6093L0.862004 11.8046C0.601337 11.5439 0.601337 11.1226 0.862004 10.8619Z" />
    </SvgIcon>
  );
}

export default IconComponent;

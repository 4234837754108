import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M15.8786 2.34667C15.8289 2.28043 15.7646 2.22667 15.6906 2.18964C15.6166 2.15261 15.535 2.13333 15.4523 2.13333H2.99455L2.07274 0.293333C2.02846 0.205379 1.96071 0.131415 1.87701 0.079639C1.79331 0.0278633 1.69691 0.000298509 1.59851 0H0.532838C0.391521 0 0.255991 0.0561903 0.156065 0.15621C0.0561381 0.256229 0 0.391884 0 0.533333C0 0.674782 0.0561381 0.810437 0.156065 0.910457C0.255991 1.01048 0.391521 1.06667 0.532838 1.06667H1.06568L1.47063 1.472L2.16332 2.85867L4.2627 10.2773C4.29494 10.3927 4.36503 10.4938 4.46169 10.5645C4.55835 10.6351 4.67595 10.6711 4.79554 10.6667H13.321C13.4405 10.6711 13.5581 10.6351 13.6548 10.5645C13.7515 10.4938 13.8216 10.3927 13.8538 10.2773L15.9851 2.81067C16.0045 2.73035 16.0049 2.64662 15.9865 2.5661C15.968 2.48558 15.931 2.41046 15.8786 2.34667V2.34667Z" />
      <path d="M13.3212 11.2H4.79578C4.65446 11.2 4.51893 11.2561 4.419 11.3562C4.31908 11.4562 4.26294 11.5918 4.26294 11.7333C4.26294 11.8747 4.31908 12.0104 4.419 12.1104C4.51893 12.2104 4.65446 12.2666 4.79578 12.2666H13.3212C13.4625 12.2666 13.598 12.2104 13.698 12.1104C13.7979 12.0104 13.854 11.8747 13.854 11.7333C13.854 11.5918 13.7979 11.4562 13.698 11.3562C13.598 11.2561 13.4625 11.2 13.3212 11.2Z" />
      <path d="M5.86145 16C6.74429 16 7.45997 15.2837 7.45997 14.4C7.45997 13.5164 6.74429 12.8 5.86145 12.8C4.97862 12.8 4.26294 13.5164 4.26294 14.4C4.26294 15.2837 4.97862 16 5.86145 16Z" />
      <path d="M12.2553 16C13.1381 16 13.8538 15.2837 13.8538 14.4C13.8538 13.5164 13.1381 12.8 12.2553 12.8C11.3724 12.8 10.6567 13.5164 10.6567 14.4C10.6567 15.2837 11.3724 16 12.2553 16Z" />
    </SvgIcon>
  );
}

export default IconComponent;

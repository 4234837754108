import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <circle cx="7" cy="7" r="6" strokeWidth="2" fill="transparent" />
      <line
        x1="11.6739"
        y1="2.34043"
        x2="2.34057"
        y2="11.6738"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}

export default IconComponent;

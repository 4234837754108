import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M10.875 7.05C10.65 6.825 10.35 6.9 10.05 6.975L9.975 6.75C9.15 5.1 8.775 2.775 10.275 1.275C10.5 1.05 10.575 0.75 10.425 0.45C10.35 0.15 10.05 0 9.75 0C9 0 7.575 0.3 6.075 1.05C5.4 0.6 4.275 0 3.3 0C1.65 0 0 1.5 0 1.5C0 1.5 1.65 3.3 3.3 3.3C2.55 4.275 2.025 5.475 2.025 7.05C1.65 6.9 1.35 6.9 1.05 7.125C0.45 7.575 0 8.4 0 9.3C0 10.8 1.2 12 2.7 12C4.2 12 5.4 10.8 5.4 9.3C5.4 8.4 4.95 7.575 4.275 7.125C4.125 6.9 3.825 6.9 3.45 6.975C3.525 3.9 6.225 2.4 8.1 1.8C7.5 3.3 7.575 5.1 8.325 6.9C8.025 6.825 7.8 6.825 7.575 7.05C6.9 7.575 6.525 8.325 6.525 9.225C6.525 10.725 7.725 11.925 9.225 11.925C10.725 11.925 11.925 10.725 11.925 9.225C12 8.4 11.55 7.575 10.875 7.05Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { Icon } from "@/components";
import { useEffect, useState } from "react";
import { DashboardTopItem } from "./DashboardTopItem";

import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector, userViewSelector } from "@/providers/auth/selector";

import { useGetPendingOrdersSummary } from "../services/dashboard";
import { TIME_FORMAT, USER_VIEW } from "@/configuration/globalVariable";
import moment from "moment";

export const DashboardTopPendingOrders = () => {
  const [dataRender, setDatarender] = useState({
    title: "PENDING orders",
    amount: 0,
    count: 0,
    countSuffix: "Orders",
    isLoading: true,
  });
  const user = useAppSelector(userSelector);
  const pendingCreditsSummary = useGetPendingOrdersSummary();
  const { isLoading, isSuccess, data } = pendingCreditsSummary;
  const userView = useAppSelector(userViewSelector);

  const path =
    userView === USER_VIEW.SUPPLIER
      ? `/Sales/documents/Orders?customerid=&number=&status=00000000-0000-0006-0001-000000000000&fromdate=${moment(
          new Date()
        ).format(TIME_FORMAT.REQUEST)}&todate=${moment()
          .add(14, "days")
          .format(
            TIME_FORMAT.REQUEST
          )}&paymethod=&routename=&routeid=&rangefor=&doctype=`
      : `/purchases/documents/Orders?supplierid=&number=&status=00000000-0000-0006-0001-000000000000%2C00000000-0000-0006-0013-000000000000&fromdate=${moment(
          new Date()
        ).format(TIME_FORMAT.REQUEST)}&todate=${moment()
          .add(14, "days")
          .format(
            TIME_FORMAT.REQUEST
          )}&paymethod=&routename=&routeid=&rangefor=&doctype=`;

  useEffect(() => {
    pendingCreditsSummary.mutate({ accountId: user.accountId });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setDatarender({
        ...dataRender,
        isLoading: false,
        amount: data ? data[0]?.totalAmount : 0,
        count: data ? data[0]?.totalCount : 0,
      });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <DashboardTopItem
      title={dataRender.title}
      note={"(Next 14 days)"}
      isLoading={isLoading}
      icon={
        <div>
          <Icon.Order
            size="24"
            className="fill-status-warning hidden lg:block"
          />
          <Icon.Order size="16" className="fill-status-warning lg:hidden" />
        </div>
      }
      count={dataRender.count}
      amount={dataRender.amount}
      countSuffix={dataRender.countSuffix}
      pathViewMore={path}
    />
  );
};

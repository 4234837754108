import React from "react";
import { Icon } from "@/components";
import clsx from "clsx";

interface Props {
  pageTitle: React.ReactNode;
  onTitleBack?: () => void;
  children: React.ReactNode;
  rightContent?: React.ReactNode;
  className?: string;
  disabledBackButton?: boolean;
}

const Index = ({
  pageTitle,
  onTitleBack,
  children,
  rightContent,
  className,
  disabledBackButton,
}: Props) => {
  return (
    <div
      className={clsx(
        "bg-white px-6 pb-5 pt-1.5 rounded min-h-full",
        className
      )}
    >
      <div className="mb-5 flex justify-between shadow-underline min-h-[3.5rem] items-center">
        <div className="flex text-hd5 font-medium items-center">
          {onTitleBack && (
            <button onClick={onTitleBack} disabled={disabledBackButton}>
              <Icon.Back
                className={clsx(
                  "stroke-neutral-80 mr-2",
                  disabledBackButton && "opacity-60"
                )}
              />
            </button>
          )}
          {pageTitle}
        </div>
        <div>{rightContent}</div>
      </div>
      {children}
    </div>
  );
};

export default Index;

import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M9.92109 1.00037C9.78783 1.00037 9.65444 1.03381 9.53399 1.10137L0.404265 6.21687C0.153875 6.3566 0 6.61967 0 6.90552C0 7.19136 0.154677 7.45443 0.404265 7.59417L9.60032 12.7445C9.72036 12.8117 9.85296 12.8455 9.9857 12.8455C10.12 12.8455 10.2526 12.8116 10.3735 12.7445L19.5032 7.62869C19.7536 7.48896 19.9076 7.22602 19.9076 6.94004C19.9076 6.65422 19.7528 6.39113 19.5032 6.25139L10.3071 1.10103C10.1875 1.03387 10.0543 1.0005 9.92109 1.00037ZM0.800171 9.66879C0.520167 9.66495 0.247433 9.81157 0.10187 10.0737C-0.112165 10.4536 0.023738 10.9345 0.40435 11.1477L9.60041 16.2981C9.72045 16.3652 9.85304 16.3991 9.98579 16.3991C10.1201 16.3991 10.2527 16.3652 10.3712 16.2989L19.5009 11.183C19.8823 10.9706 20.0174 10.4888 19.8034 10.1082C19.5909 9.72762 19.1108 9.59171 18.7286 9.80575L9.98499 14.7043L1.17543 9.77022C1.05645 9.70306 0.927481 9.67051 0.800171 9.66879ZM0.800171 13.2224C0.520167 13.2187 0.247433 13.3656 0.10187 13.6272C-0.112165 14.007 0.023738 14.488 0.40435 14.7011L9.60041 19.8514C9.72045 19.9186 9.85304 19.9526 9.98579 19.9526C10.1201 19.9526 10.2527 19.9186 10.3712 19.8522L19.5009 14.7364C19.8823 14.5239 20.0174 14.0423 19.8034 13.6616C19.5909 13.281 19.1108 13.145 18.7286 13.3591L9.98499 18.2576L1.17543 13.3235C1.05645 13.2564 0.927481 13.2241 0.800171 13.2224Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

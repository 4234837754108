import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M24 7.64062V6.375C24 4.92525 22.8248 3.75 21.375 3.75H2.625C1.17525 3.75 0 4.92525 0 6.375V7.64062C0 7.77005 0.104953 7.875 0.234375 7.875H23.7656C23.895 7.875 24 7.77005 24 7.64062Z" />
      <path d="M0 9.60938V17.625C0 19.0747 1.17525 20.25 2.625 20.25H21.375C22.8248 20.25 24 19.0747 24 17.625V9.60938C24 9.47995 23.895 9.375 23.7656 9.375H0.234375C0.104953 9.375 0 9.47995 0 9.60938ZM6 15.75C6 16.1642 5.66419 16.5 5.25 16.5H4.5C4.08581 16.5 3.75 16.1642 3.75 15.75V15C3.75 14.5858 4.08581 14.25 4.5 14.25H5.25C5.66419 14.25 6 14.5858 6 15V15.75Z" />
    </SvgIcon>
  );
}

export default IconComponent;

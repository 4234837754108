import { useState } from "react";
import { Switch as SwitchComponent } from "@headlessui/react";

export const Switch = () => {
  const [enabled, setEnabled] = useState(false);

  return (
    <SwitchComponent
      checked={enabled}
      onChange={setEnabled}
      className={`${enabled ? "bg-blue" : "bg-neutral-20"}
        relative inline-flex h-[1.625rem] w-[3.313rem] shrink-0 cursor-pointer rounded-full border-[0.188rem] border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={`${enabled ? "translate-x-[1.7rem]" : "translate-x-0"}
          pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
      />
    </SwitchComponent>
  );
};

import clsx from "clsx";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Common, Icon } from "@/components";
import { manifest } from "../models";

// const DropdownMenu = [
//   {
//     name: "South to West - Route AB",
//     tooltipContent: "Cannot move orders to started routes",
//     color: "#0099CC",
//   },
//   {
//     name: "Route West to South",
//     tooltipContent: "Cannot move orders to started routes",
//     color: "#EE3381",
//   },
//   {
//     name: "South to West - Route AB",
//     tooltipContent: "Cannot move orders to started routes",
//     color: "#41C074",
//   },
// ];

interface Props {
  manifests?: manifest[];
}

export const EyeMapDropdown = ({ manifests }: Props) => {
  const handleShowHideRoute = (item: any) => {
    console.log("item: ", item);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <div>
          <Menu.Button>
            <div className="w-7 h-7 inline-flex items-center justify-center bg-blue hover:blue-dark rounded">
              {!open ? (
                <Icon.EyeOpen size="14" className="fill-white" />
              ) : (
                <Icon.Close size="14" className="stroke-white" />
              )}
            </div>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 bottom-9 mt-2 w-64 rounded-md shadow-lg bg-white">
              <div className="py-1">
                {manifests &&
                  manifests.map((item, index) => (
                    <div
                      key={item.route.name}
                      className={clsx(
                        "flex items-center p-3",
                        Boolean(index) && "shadow-overline"
                      )}
                    >
                      <Common.DotColor
                        style={{ backgroundColor: item.route.colour }}
                      />
                      <div className="font-semibold text-sm flex-1 mx-1.5">
                        {item.route.name}
                      </div>
                      <button onClick={() => handleShowHideRoute(item)}>
                        <Icon.EyeOn size="14" className="stroke-blue" />
                      </button>
                    </div>
                  ))}
              </div>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  );
};

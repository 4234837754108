import { Combobox } from "@headlessui/react";
import { useState } from "react";
import { Icon } from "@/components";
import clsx from "clsx";
import { driverOption } from "../models";

interface Props {
  label?: string;
  drivers: driverOption[];
  value: driverOption;
  onChange: (driver: driverOption) => void;
}

export const DriverCombobox = ({ label, drivers, value, onChange }: Props) => {
  const [query, setQuery] = useState("");

  const filteredDriver =
    query === ""
      ? drivers
      : drivers.filter((person) => {
          return person.text.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox as="div" value={value} onChange={onChange}>
      {label && (
        <label className="mb-1 block text-base font-semibold text-neutral-90">
          {label}
        </label>
      )}
      <div className="relative mt-2">
        <Combobox.Input
          className="block w-full border text-base rounded border-neutral-20 focus:ring-blue focus:border-blue focus-visible:outline-none"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(driver: driverOption) => driver?.text}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <Icon.ArrowDown />
        </Combobox.Button>

        {filteredDriver.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredDriver.map((driver: driverOption) => (
              <Combobox.Option
                key={driver.id}
                value={driver}
                className={({ active }) =>
                  clsx(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-blue text-white" : "text-neutral-90"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <span
                        className={clsx(
                          "ml-3 truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {driver.text}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={clsx(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-blue"
                        )}
                      >
                        <Icon.Checked className="fill-green" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

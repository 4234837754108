import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C11.9983 4.40923 11.3656 2.88411 10.2407 1.75926C9.1159 0.634414 7.59077 0.00172054 6 0V0ZM6.125 2.5C6.27334 2.5 6.41834 2.54399 6.54168 2.6264C6.66502 2.70881 6.76115 2.82594 6.81791 2.96299C6.87468 3.10003 6.88953 3.25083 6.86059 3.39632C6.83165 3.5418 6.76022 3.67544 6.65533 3.78033C6.55044 3.88522 6.41681 3.95665 6.27132 3.98559C6.12584 4.01453 5.97503 3.99968 5.83799 3.94291C5.70095 3.88614 5.58381 3.79001 5.5014 3.66668C5.41899 3.54334 5.375 3.39834 5.375 3.25C5.375 3.05109 5.45402 2.86032 5.59467 2.71967C5.73532 2.57902 5.92609 2.5 6.125 2.5ZM7.25 9.25H5.25C5.11739 9.25 4.99022 9.19732 4.89645 9.10355C4.80268 9.00979 4.75 8.88261 4.75 8.75C4.75 8.61739 4.80268 8.49021 4.89645 8.39645C4.99022 8.30268 5.11739 8.25 5.25 8.25H5.625C5.65815 8.25 5.68995 8.23683 5.71339 8.21339C5.73683 8.18995 5.75 8.15815 5.75 8.125V5.875C5.75 5.84185 5.73683 5.81005 5.71339 5.78661C5.68995 5.76317 5.65815 5.75 5.625 5.75H5.25C5.11739 5.75 4.99022 5.69732 4.89645 5.60355C4.80268 5.50979 4.75 5.38261 4.75 5.25C4.75 5.11739 4.80268 4.99021 4.89645 4.89645C4.99022 4.80268 5.11739 4.75 5.25 4.75H5.75C6.01522 4.75 6.26957 4.85536 6.45711 5.04289C6.64465 5.23043 6.75 5.48478 6.75 5.75V8.125C6.75 8.15815 6.76317 8.18995 6.78661 8.21339C6.81006 8.23683 6.84185 8.25 6.875 8.25H7.25C7.38261 8.25 7.50979 8.30268 7.60356 8.39645C7.69732 8.49021 7.75 8.61739 7.75 8.75C7.75 8.88261 7.69732 9.00979 7.60356 9.10355C7.50979 9.19732 7.38261 9.25 7.25 9.25Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { useEffect, useState } from "react";

import { CustomScrollbar, Icon, Table, Td, Th } from "@/components";
import { normalScheduleItemType } from "../models";
import { defineWeekDayPrevious, formatAMPM } from "../utils";
import { LISTWEEKDAYDISPLAY } from "../configVariable";

type listWeekDayDisplayType = Partial<normalScheduleItemType> & {
  id: number;
  title: string;
};

type Props = {
  listWeekDays: listWeekDayDisplayType[];
};

export const TabNormalScheduleDetail = ({ listWeekDays }: Props) => {
  const [listWeekDayDisplay, setListWeekDayDisplay] =
    useState<listWeekDayDisplayType[]>(LISTWEEKDAYDISPLAY);

  useEffect(() => {
    const newList = listWeekDayDisplay.map((item) => {
      const daySchedule = listWeekDays.find((i) => i.dayOfWeek === item.id);
      if (daySchedule) {
        return {
          ...item,
          ...daySchedule,
        };
      }
      return {
        ...item,
        isActivated: false,
      };
    });
    setListWeekDayDisplay(newList);
  }, [listWeekDays]);

  return (
    <div className="h-full">
      <CustomScrollbar>
        <Table className="relative w-full">
          <thead className="bg-neutral-20">
            <tr>
              <Th className="bg-neutral-20 text-neutral-50 w-11">
                {listWeekDayDisplay.reduce((count, item) => {
                  return item.isActivated ? count + 1 : count;
                }, 0) === 7 ? (
                  <Icon.DisableChecked />
                ) : (
                  <Icon.DisableUnChecked />
                )}
              </Th>
              <Th className="bg-neutral-20 text-neutral-50">DELIVERY DATE</Th>
              <Th className="bg-neutral-20 text-neutral-50">DESPATCH DATE</Th>
              <Th className="bg-neutral-20 text-neutral-50">ORDER DATE</Th>
              <Th className="bg-neutral-20 text-neutral-50 text-right">
                CUT-OFF TIME
              </Th>
              <Th className="bg-neutral-20 text-neutral-50"></Th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {listWeekDayDisplay.map((item) => {
              const { title, despatchDay, orderDay, cutOffTime, isActivated } =
                item;

              return (
                <tr
                  className="text-sm font-medium text-neutral-90 group hover:bg-neutral-5"
                  key={item.id}
                >
                  <Td className="py-5 border-b border-l border-neutral-20">
                    {/* <Common.Checkbox
                      ipSize="md"
                      disabled
                      checked={isActivated}
                      className="focus:ring-offset-0"
                    /> */}
                    {isActivated ? (
                      <Icon.DisableChecked />
                    ) : (
                      <Icon.DisableUnChecked />
                    )}
                  </Td>
                  <Td className="py-5 border-b border-neutral-20">{title}</Td>
                  <Td className="py-5 border-b border-neutral-20">
                    {isActivated ? (
                      <div>
                        {defineWeekDayPrevious(title, despatchDay)}{" "}
                        <span className="text-neutral-40">
                          ({despatchDay} day(s) in advance)
                        </span>
                      </div>
                    ) : (
                      <span className="text-neutral-40">--</span>
                    )}
                  </Td>
                  <Td className="py-5 border-b border-neutral-20">
                    {isActivated ? (
                      <div>
                        {defineWeekDayPrevious(title, orderDay)}{" "}
                        <span className="text-neutral-40">
                          ({orderDay} day(s) in advance)
                        </span>
                      </div>
                    ) : (
                      <span className="text-neutral-40">--</span>
                    )}
                  </Td>
                  <Td className="text-right py-5 border-b border-neutral-20">
                    {isActivated ? (
                      <div>
                        {(cutOffTime && formatAMPM(cutOffTime)) || (
                          <span className="text-neutral-40">--</span>
                        )}
                      </div>
                    ) : (
                      <span className="text-neutral-40">--</span>
                    )}
                  </Td>
                  <Td className="text-right py-5 border-b border-r border-neutral-20"></Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </CustomScrollbar>
    </div>
  );
};

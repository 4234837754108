import { Icon } from "@/components/Icons";

const CIRCLE = { r: 35, c: 40 };
const RESOULT = 2;
const TOTAL = 4;
const circumference = CIRCLE.r * 2 * Math.PI;
const Complete = circumference - (RESOULT / TOTAL) * circumference;

export const EnhanceStep = () => {
  return (
    <div className="flex items-center px-5 py-4 md:hidden cursor-pointer">
      <div className="relative">
        <svg
          width="38"
          height="38"
          className="w-12 h-12 -rotate-90"
          viewBox="0 0 80 80"
        >
          <circle
            className="stroke-neutral-30"
            strokeWidth="5"
            fill="transparent"
            r={CIRCLE.r}
            cx={CIRCLE.c}
            cy={CIRCLE.c}
          />
          <circle
            className="stroke-green"
            strokeWidth="5"
            strokeDasharray={circumference}
            strokeDashoffset={Complete}
            strokeLinecap="round"
            fill="transparent"
            r={CIRCLE.r}
            cx={CIRCLE.c}
            cy={CIRCLE.c}
          />
        </svg>
        <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center text-sm text-neutral font-medium">
          2 of 4
        </div>
      </div>
      <div className="text-blue ml-4 font-medium">
        Complete steps to enhance with Tomsoft
      </div>
      <div>
        <Icon.AngleRight className="stroke-blue" />
      </div>
    </div>
  );
};

export const compareArrays = (
  arrs: [number[], number[]] | [string[], string[]]
) => {
  console.log("compareArrays");
  if (arrs[0].length !== arrs[1].length) return false;

  const frequencyCounter1: any = {};
  const frequencyCounter2: any = {};

  // Create frequencyCounter1
  for (const num of arrs[0]) {
    frequencyCounter1[num] = frequencyCounter1[num] + 1 || 1;
  }

  // Create frequencyCounter2
  for (const num of arrs[1]) {
    frequencyCounter2[num] = frequencyCounter2[num] + 1 || 1;
  }

  // Compare frequency counters
  for (const key in frequencyCounter1) {
    if (!(key in frequencyCounter2)) return false;

    if (frequencyCounter1[key] !== frequencyCounter2[key]) return false;
  }
  return true;
};

export const countDecimals = (number: number) => {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;
  return number.toString().split(".")[1].length || 0;
};

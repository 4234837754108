import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Icon } from "@/components";
import clsx from "clsx";

interface Props {
  open: boolean;
  onClose?: () => void;
  onOverlayClick?: () => void;
  children: React.ReactNode;
  panelClassWidth?: string;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
}

export default function ModalContent({
  open = false,
  onClose,
  onOverlayClick,
  children,
  panelClassWidth,
  initialFocus,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => (onOverlayClick ? onOverlayClick() : null)}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:mx-4",
                  panelClassWidth || "sm:max-w-[50rem] sm:w-full"
                )}
              >
                <div className="relative">
                  {onClose && (
                    <button
                      onClick={onClose}
                      className="absolute top-4 right-2 z-10 p-2"
                    >
                      <Icon.Close size="16" className="stroke-neutral-60" />
                    </button>
                  )}
                  <div className="relative z-0">{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

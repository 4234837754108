import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_4328_70491)">
        <path d="M0.666667 10.6673C0.843478 10.6673 1.01305 10.5971 1.13807 10.4721C1.2631 10.347 1.33333 10.1775 1.33333 10.0007V6.00065C1.33333 5.82384 1.2631 5.65427 1.13807 5.52925C1.01305 5.40422 0.843478 5.33398 0.666667 5.33398C0.489856 5.33398 0.320286 5.40422 0.195262 5.52925C0.0702379 5.65427 0 5.82384 0 6.00065L0 10.0007C0 10.1775 0.0702379 10.347 0.195262 10.4721C0.320286 10.5971 0.489856 10.6673 0.666667 10.6673Z" />
        <path d="M3.33268 10.6671H7.99935V13.3337C7.99928 13.4593 8.03466 13.5823 8.10144 13.6886C8.16821 13.795 8.26365 13.8803 8.37678 13.9348C8.48991 13.9892 8.61612 14.0107 8.74089 13.9965C8.86565 13.9824 8.9839 13.9334 9.08202 13.8551L15.7487 8.52172C15.827 8.45927 15.8903 8.37998 15.9338 8.28972C15.9773 8.19946 15.9998 8.10057 15.9998 8.00038C15.9998 7.9002 15.9773 7.8013 15.9338 7.71105C15.8903 7.62079 15.827 7.54149 15.7487 7.47905L9.08202 2.14572C8.9839 2.06737 8.86565 2.01832 8.74089 2.00422C8.61612 1.99012 8.48991 2.01154 8.37678 2.06601C8.26365 2.12048 8.16821 2.2058 8.10144 2.31213C8.03466 2.41846 7.99928 2.54149 7.99935 2.66705V5.33372H3.33268C3.15587 5.33372 2.9863 5.40396 2.86128 5.52898C2.73625 5.654 2.66602 5.82357 2.66602 6.00038V10.0004C2.66602 10.1772 2.73625 10.3468 2.86128 10.4718C2.9863 10.5968 3.15587 10.6671 3.33268 10.6671Z" />
      </g>
      <defs>
        <clipPath id="clip0_4328_70491">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M11.5 4C10.3069 4.00132 9.16311 4.47585 8.31948 5.31948C7.47585 6.16311 7.00132 7.30693 7 8.5C7 10.119 8.353 12.357 11.134 15.341C11.1808 15.3913 11.2374 15.4314 11.3004 15.4588C11.3634 15.4862 11.4313 15.5003 11.5 15.5003C11.5687 15.5003 11.6366 15.4862 11.6996 15.4588C11.7626 15.4314 11.8192 15.3913 11.866 15.341C14.647 12.357 16 10.119 16 8.5C15.9987 7.30693 15.5241 6.16311 14.6805 5.31948C13.8369 4.47585 12.6931 4.00132 11.5 4ZM11.5 10C11.2033 10 10.9133 9.91203 10.6666 9.7472C10.42 9.58238 10.2277 9.34811 10.1142 9.07403C10.0006 8.79994 9.97094 8.49834 10.0288 8.20736C10.0867 7.91639 10.2296 7.64912 10.4393 7.43934C10.6491 7.22956 10.9164 7.0867 11.2074 7.02882C11.4983 6.97094 11.7999 7.00065 12.074 7.11418C12.3481 7.22771 12.5824 7.41997 12.7472 7.66664C12.912 7.91332 13 8.20333 13 8.5C13 8.89782 12.842 9.27936 12.5607 9.56066C12.2794 9.84196 11.8978 10 11.5 10Z" />
        <path d="M3.5 0C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5C0 4.744 1.023 6.488 3.128 8.834C3.17489 8.88621 3.23225 8.92797 3.29634 8.95655C3.36043 8.98514 3.42982 8.99991 3.5 8.99991C3.57018 8.99991 3.63957 8.98514 3.70366 8.95655C3.76775 8.92797 3.82511 8.88621 3.872 8.834C5.977 6.488 7 4.744 7 3.5C7 2.57174 6.63125 1.6815 5.97487 1.02513C5.3185 0.368749 4.42826 0 3.5 0ZM3.5 4.5C3.30222 4.5 3.10888 4.44135 2.94443 4.33147C2.77998 4.22159 2.65181 4.06541 2.57612 3.88268C2.50043 3.69996 2.48063 3.49889 2.51921 3.30491C2.5578 3.11093 2.65304 2.93275 2.79289 2.79289C2.93275 2.65304 3.11093 2.5578 3.30491 2.51921C3.49889 2.48063 3.69996 2.50043 3.88268 2.57612C4.06541 2.65181 4.22159 2.77998 4.33147 2.94443C4.44135 3.10888 4.5 3.30222 4.5 3.5C4.5 3.76522 4.39464 4.01957 4.20711 4.20711C4.01957 4.39464 3.76522 4.5 3.5 4.5Z" />
        <path d="M7 16C5.67441 15.9984 4.40356 15.4711 3.46622 14.5338C2.52888 13.5964 2.00159 12.3256 2 11C2 10.7348 2.10536 10.4804 2.29289 10.2929C2.48043 10.1054 2.73478 10 3 10C3.26522 10 3.51957 10.1054 3.70711 10.2929C3.89464 10.4804 4 10.7348 4 11C4 11.7956 4.31607 12.5587 4.87868 13.1213C5.44129 13.6839 6.20435 14 7 14C7.26522 14 7.51957 14.1054 7.70711 14.2929C7.89464 14.4804 8 14.7348 8 15C8 15.2652 7.89464 15.5196 7.70711 15.7071C7.51957 15.8946 7.26522 16 7 16Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

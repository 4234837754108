import React, { useState, useMemo, useEffect } from "react";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";

import { Common, Icon, ModalContent, Pagination } from "@/components";
import ScheduleList from "./ScheduleList";
import {
  DeliveryScheduleDisplayType,
  scheduleDetail,
} from "../models/DeliverySchedule";
import { sortDefaultScheduleToTop } from "../utils";
import { useAppDispatch } from "@/sharedStore/hooks";
import {
  createScheduleAsync,
  fetchListDeliveryScheduleAsync,
  setDefaultScheduleAsync,
} from "../providers/schedulePlanner/slice";
import ModalEditNameSchedule from "./ModalEditNameSchedule";
import { PATH } from "@/configuration/globalVariable";
import { SnackbarUtils } from "@/utils";
import { LIMIT_SCHEDULE_PER_PAGE } from "../configVariable";

// const LIMIT_SCHEDULE_PER_PAGE = [10, 20, 50, 100];

const TabDeliverySchedule = () => {
  const dispatch = useAppDispatch();

  const [paramsGetListSchedule, setParamsGetListSchedule] = useState({
    searchValue: "",
    limit: 10,
    page: 1,
    sortType: "",
    sortColumn: "",
  });
  const navigate = useNavigate();

  const [listAllSchedule, setListAllSchedule] = useState<
    DeliveryScheduleDisplayType[]
  >([]);
  const [listScheduleMatching, setListScheduleMatching] = useState<
    DeliveryScheduleDisplayType[]
  >([]);
  const [modalCreateSchedule, setModalCreateSchedule] =
    useState<boolean>(false);

  const { limit, page, searchValue } = paramsGetListSchedule;

  useEffect(() => {
    handleFetchListSchedule();
  }, []);

  useEffect(() => {
    if (listAllSchedule.length > 0) {
      const listMatching = listAllSchedule.filter(
        (schedule) => schedule?.isValidFilter
      );
      setListScheduleMatching(listMatching);
    }
  }, [listAllSchedule]);

  const listScheduleDisplay = useMemo(() => {
    const startIndex = (page - 1) * limit;
    const endIndex =
      startIndex + limit > listScheduleMatching.length
        ? undefined
        : startIndex + limit;

    return listScheduleMatching
      .filter((schedule) => schedule.isValidFilter)
      .slice(startIndex, endIndex);
  }, [page, limit, listScheduleMatching]);

  const totalPage = useMemo(() => {
    return Math.ceil(listScheduleMatching.length / limit);
  }, [listScheduleMatching.length, limit]);

  const delaySearchKeywords = debounce((newKeywords: string) => {
    setListAllSchedule((previousList) => {
      const newList = [...previousList];
      newList.forEach((schedule) => {
        schedule.isValidFilter = schedule.name
          .toLowerCase()
          .includes(newKeywords.toLowerCase());
      });
      return newList;
    });
  }, 700);

  const handleFetchListSchedule = async () => {
    const response = await dispatch(fetchListDeliveryScheduleAsync()).unwrap();

    let list = response.scheduleList;
    if (Array.isArray(list)) {
      list = list.map((item: DeliveryScheduleDisplayType) => ({
        ...item,
        isValidFilter: true,
      }));
      setListAllSchedule(sortDefaultScheduleToTop(list));
    }
  };

  const handleSearchKeyWord = (value: string) => {
    const trimValue = value.trim();
    setParamsGetListSchedule({
      ...paramsGetListSchedule,
      searchValue: trimValue,
      page: 1,
    });
    delaySearchKeywords(trimValue);
  };

  const handleSetDefaultSchedule = async (
    sche: DeliveryScheduleDisplayType
  ) => {
    const isError = await dispatch(setDefaultScheduleAsync(sche.id)).unwrap();
    if (isError) {
      // show error
    } else {
      let newList = [...listAllSchedule];
      newList.forEach((item) => {
        item.isDefault = item.id === sche.id;
      });
      newList = sortDefaultScheduleToTop(newList);
      setListAllSchedule(newList);
      SnackbarUtils.success(
        `<div><Icon.CheckedFill />Default schedule has been changed to&nbsp;<b>${sche.name}</b>.<div>`
      );
    }
  };

  const handleSortListSchedule = (
    type: "ASC" | "DESC" | undefined,
    column: string
  ) => {
    const newMatchingList = [...listScheduleMatching];
    newMatchingList.sort((a, b) => {
      const valueA = a[column].toString().toLowerCase();
      const valueB = b[column].toString().toLowerCase();
      if (type === "ASC") {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
      } else {
        if (valueA < valueB) return 1;
        if (valueA > valueB) return -1;
      }
      return 0;
    });
    setListScheduleMatching(sortDefaultScheduleToTop(newMatchingList));
  };

  const toggleModalCreateSchedule = () => {
    setModalCreateSchedule(!modalCreateSchedule);
  };

  const handleSubmitNewSchedule = async (newValue: string) => {
    const response: scheduleDetail = await dispatch(
      createScheduleAsync(newValue)
    ).unwrap();
    if (response.id) {
      toggleModalCreateSchedule();
      navigate(
        `/${PATH.SCHEDULE_PLANNER._}/${PATH.SCHEDULE_PLANNER.SCHEDULE_DETAIL}`,
        {
          state: { scheduleId: response.id, editMode: true },
        }
      );
      SnackbarUtils.success(
        `<div><Icon.CheckedFill /><b>${newValue}</b> has been created successfully.<div>`
      );
    }
  };

  return (
    <div className="h-[calc(100vh-12.75rem)] flex flex-col justify-start ">
      <div className="flex justify-between items-center w-100">
        <Common.Input
          wrapClassName="2xl:lg:w-[26.375rem] mr-3"
          className="bg-neutral-5"
          iconRight={Icon.Search}
          iconType="stroke"
          placeholder="Type to filter schedule ..."
          value={searchValue}
          onChange={(e: any) => handleSearchKeyWord(e.target.value)}
          onClear={() => handleSearchKeyWord("")}
        />
        <Common.Button
          onClick={toggleModalCreateSchedule}
          disabled={false}
          iconLeft={() => <Icon.PlusBorder className="fill-white mr-3" />}
          iconType="stroke"
        >
          Create new schedule
        </Common.Button>
      </div>

      <ScheduleList
        listScheduleDisplay={listScheduleDisplay}
        onSetScheduleDefault={handleSetDefaultSchedule}
        onSortListSchedule={handleSortListSchedule}
        onFetchScheduleList={handleFetchListSchedule}
      />

      <div className="flex justify-between items-center w-100 p-4 border border-neutral-20 border-t-0">
        <div className="flex justify-between items-center">
          <Common.DropDowns
            buttonRender={
              <div className="inline-flex items-center justify-center font-medium group text-blue hover:border-blue-dark rounded border px-4 py-2 border-neutral-30">
                Show {limit}
                <Icon.AngleDown size="10" className="stroke-blue ml-3" />
              </div>
            }
            listRender={
              <div className="py-0">
                {LIMIT_SCHEDULE_PER_PAGE.map((item) => {
                  return (
                    <Common.DropdownItem
                      key={item}
                      onClick={() =>
                        setParamsGetListSchedule({
                          ...paramsGetListSchedule,
                          limit: item,
                          page: 1,
                        })
                      }
                    >
                      Show {item}
                    </Common.DropdownItem>
                  );
                })}
              </div>
            }
            listClassName="mb-12 bottom-0"
          />
          <div className="color-neutral-60 font-normal ml-3">
            of{" "}
            <span className="color-neutral font-semibold">
              {listScheduleMatching.length}
            </span>{" "}
            schedules
          </div>
        </div>
        <Pagination
          pageNumber={page}
          pageSize={limit}
          total={listScheduleMatching.length}
          totalPages={totalPage}
          pagerCenterPoint={1}
          startPage={1}
          onPageClick={(page: number) =>
            setParamsGetListSchedule({ ...paramsGetListSchedule, page })
          }
        />
      </div>

      <ModalContent
        open={modalCreateSchedule}
        onClose={toggleModalCreateSchedule}
        onOverlayClick={toggleModalCreateSchedule}
        panelClassWidth="max-w-[32rem] w-full"
      >
        <ModalEditNameSchedule
          type="create"
          scheduleName={""}
          onClose={toggleModalCreateSchedule}
          onSubmitName={handleSubmitNewSchedule}
        />
      </ModalContent>
    </div>
  );
};

export { TabDeliverySchedule };

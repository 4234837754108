import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 7.43747C0.875 7.82702 1.19737 8.14281 1.59504 8.14281H9.93725L6.8462 11.1708C6.56501 11.4462 6.56501 11.8928 6.8462 12.1683C7.1274 12.4437 7.5833 12.4437 7.86449 12.1683L12.1847 7.93622C12.4659 7.66077 12.4659 7.21417 12.1847 6.93872L7.86449 2.70665C7.5833 2.4312 7.1274 2.4312 6.8462 2.70665C6.56501 2.98211 6.56501 3.42871 6.8462 3.70416L9.93725 6.73213L1.59504 6.73213C1.19737 6.73213 0.875 7.04792 0.875 7.43747Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path d="M13.7907 10.7829L8.59375 0.958412C8.44015 0.669033 8.21066 0.426937 7.9299 0.258101C7.64914 0.0892649 7.3277 6.10352e-05 7.00009 6.10352e-05C6.67247 6.10352e-05 6.35104 0.0892649 6.07027 0.258101C5.78951 0.426937 5.56002 0.669033 5.40642 0.958412L0.209503 10.7829C0.0649694 11.057 -0.00617638 11.3639 0.00298189 11.6737C0.0121402 11.9834 0.101291 12.2856 0.261767 12.5507C0.422243 12.8158 0.648584 13.0349 0.918786 13.1866C1.18899 13.3384 1.49386 13.4176 1.80375 13.4167H12.1964C12.5063 13.4176 12.8112 13.3384 13.0814 13.1866C13.3516 13.0349 13.5779 12.8158 13.7384 12.5507C13.8989 12.2856 13.988 11.9834 13.9972 11.6737C14.0063 11.3639 13.9352 11.057 13.7907 10.7829V10.7829ZM7.00009 11.6667C6.82703 11.6667 6.65786 11.6153 6.51396 11.5192C6.37007 11.4231 6.25792 11.2864 6.19169 11.1265C6.12547 10.9666 6.10814 10.7907 6.1419 10.621C6.17566 10.4512 6.259 10.2953 6.38137 10.1729C6.50374 10.0506 6.65965 9.96724 6.82938 9.93347C6.99912 9.89971 7.17505 9.91704 7.33493 9.98327C7.49482 10.0495 7.63148 10.1616 7.72762 10.3055C7.82377 10.4494 7.87509 10.6186 7.87509 10.7917C7.87509 11.0237 7.7829 11.2463 7.61881 11.4104C7.45471 11.5745 7.23215 11.6667 7.00009 11.6667ZM7.58342 8.75H6.41675L6.12509 4.08333H7.87509L7.58342 8.75Z" />
    </SvgIcon>
  );
}

export default IconComponent;

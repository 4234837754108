import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0Z" />
      <path d="M5.19995 5.19995L10.8 10.8" stroke="white" strokeWidth="2" />
      <path d="M5.19995 10.8L10.7999 5.20005" stroke="white" strokeWidth="2" />
    </SvgIcon>
  );
}

export default IconComponent;

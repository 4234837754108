import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { LayoutPartials, Common, Icon } from "@/components";
import {
  EditTabBasic,
  EditTabAdvanced,
  EditTabPricingList,
  EditTabPayment,
} from "../components";

const tabs = [
  {
    id: "basic",
    name: "Basic",
    current: true,
  },
  {
    id: "Advanced",
    name: "Advanced",
    current: false,
  },
  {
    id: "pricing_list",
    name: "Pricing List",
    current: false,
  },
  {
    id: "payment",
    name: "Payment",
    current: false,
  },
];

const MyCustomerEdit = () => {
  const [activeTab, setActiveTab] = useState<any>(tabs[0].id);
  const navigate = useNavigate();
  // Get id customer editting params on url
  const { id } = useParams();
  console.log("id: ", id);

  return (
    <LayoutPartials.BodyContent
      pageTitle={
        <div className="flex items-center">
          That Armore Cheese{" "}
          <Icon.CheckedFill size="12" className="ml-1.5 fill-blue" />
        </div>
      }
      onTitleBack={() => navigate(-1)}
      rightContent={
        <Common.TabUnderline
          data={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          wrapClassName="mt-1 border-b-0"
        />
      }
    >
      <EditTabBasic
        className={clsx(
          "min-h-[calc(100vh-12.7rem)]",
          activeTab !== tabs[0].id && "hidden"
        )}
      />
      <EditTabAdvanced
        className={clsx(
          "min-h-[calc(100vh-12.7rem)]",
          activeTab !== tabs[1].id && "hidden"
        )}
      />
      <EditTabPricingList
        className={clsx(
          "min-h-[calc(100vh-12.7rem)]",
          activeTab !== tabs[2].id && "hidden"
        )}
      />
      <EditTabPayment
        className={clsx(
          "min-h-[calc(100vh-12.7rem)]",
          activeTab !== tabs[3].id && "hidden"
        )}
      />
    </LayoutPartials.BodyContent>
  );
};

export default MyCustomerEdit;

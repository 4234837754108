import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Nav } from "@/models";
import { MenuItem } from "./MenuItem";
import { RedirectToOUI } from "@/components/RedirectToOUI";

interface Props {
  data: Nav;
  isCollapse: boolean;
}

export function NavItem({ data, isCollapse }: Props) {
  if (data.path) {
    return data.isDiffDomain ? (
      <RedirectToOUI
        to={data.path}
        className="block text-neutral-60 cursor-pointer group hover:shadow-navActive hover:text-blue hover:bg-blue-5"
      >
        <MenuItem data={data} isCollapse={isCollapse} />
      </RedirectToOUI>
    ) : (
      <NavLink
        to={data.path}
        className={({ isActive }) =>
          clsx(
            "block group hover:shadow-navActive hover:text-blue hover:bg-blue-5",
            isActive
              ? "shadow-navActive text-blue bg-blue-5"
              : "text-neutral-60"
          )
        }
      >
        {({ isActive }) => (
          <MenuItem data={data} isCollapse={isCollapse} isActive={isActive} />
        )}
      </NavLink>
    );
  } else {
    return null;
  }
}

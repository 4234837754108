import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import { Common, Icon, Table, Th } from "@/components";
import DoubleDatePicker from "./DoubleDatePicker";
import {
  deliveryDisplayType,
  holidayDisplayType,
} from "../models/DeliverySchedule";
import ItemRowHolidayEdit from "./ItemRowHolidayEdit";
import { CUT_OFF_TIME_REG } from "../configVariable";

const TH_CLASSNAME = "bg-neutral-20 text-neutral-50";
let timer: ReturnType<typeof setTimeout>;

type Props = {
  holiday: holidayDisplayType;
  status: string | null;
  isFocus?: boolean;
  onChangeData: (data: holidayDisplayType) => void;
  onDeleteHoliday: (id: string) => void;
  onAddDeliveryDay: (id: string) => void;
  onDeleteDelivery: (id: string, dayId: string) => void;
  onEditName: (id: string, value: string) => void;
  onUpdateDetailHoliday: (
    holidayId: string,
    holiday: holidayDisplayType
  ) => void;
};

const ItemHolidayEdit = ({
  holiday,
  status,
  isFocus,
  onChangeData,
  onDeleteHoliday,
  onAddDeliveryDay,
  onDeleteDelivery,
  onEditName,
  onUpdateDetailHoliday,
}: Props) => {
  const [isShow, setShow] = useState<boolean>(true);

  const [name, setName] = useState<string>(holiday.name || "");

  const [dateRange, setDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: holiday.data?.dateFrom
      ? moment(holiday.data.dateFrom).toDate()
      : null,
    endDate: holiday.data?.dateTo ? moment(holiday.data.dateTo).toDate() : null,
  });

  useEffect(() => {
    setShow(status !== "Collapse");
  }, [status]);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      onEditName(holiday.id || "", name);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [name]);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      updateDateRange();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [dateRange]);

  const handleChangeData = () => {
    onChangeData(holiday);
  };

  const toggleCollapse = () => {
    setShow(!isShow);
  };

  const handleEditName = (value: string) => {
    setName(value);
  };

  if (!(holiday && holiday.data && Array.isArray(holiday.data?.items))) {
    return <div />;
  }

  const listDeliveryDay = holiday.data.items;
  const { id: holidayId = "" } = holiday;

  const editCutoffTime = (deliveryDateId: string, value: string) => {
    const newList = [...listDeliveryDay];
    const cutoffIndex = newList.findIndex((item) => item.id === deliveryDateId);

    newList[cutoffIndex] = {
      ...newList[cutoffIndex],
      cutOffTime: value,
      cutoffTimeError: value !== "" && !CUT_OFF_TIME_REG.test(value),
    };
    holiday.data = { ...holiday.data, items: newList };

    onUpdateDetailHoliday(holidayId, holiday);
  };

  const selectDate = (
    deliveryDateId: string,
    column: "despatchDate" | "orderDate" | "date",
    date: Date | null
  ) => {
    const newList = [...listDeliveryDay];
    const deliveryIndex = newList.findIndex((i) => i.id === deliveryDateId);

    const dateIsoString = moment(date).toISOString(true);

    newList[deliveryIndex] = {
      ...newList[deliveryIndex],
      [column]: dateIsoString,
    };

    holiday.data = { ...holiday.data, items: newList };
    onUpdateDetailHoliday(holidayId, holiday);
  };

  const selectDateRange = (startDate: Date | null, endDate: Date | null) => {
    setDateRange({ startDate, endDate });
  };

  const updateDateRange = () => {
    const startDateMoment = moment(dateRange.startDate);
    const endDateMoment = moment(dateRange.endDate);

    const newData = holiday.data
      ? { ...holiday.data }
      : {
          items: [],
        };

    newData.dateFrom = startDateMoment ? startDateMoment.toISOString(true) : "";
    newData.dateTo = endDateMoment ? endDateMoment.toISOString(true) : "";

    holiday.data = { ...newData };
    onUpdateDetailHoliday(holidayId, holiday);
  };

  const icon = !isShow ? (
    <Icon.AngleDown iconType="stroke" className="stroke-neutral-50" />
  ) : (
    <Icon.AngleUp iconType="stroke" className="stroke-neutral-50" />
  );

  if (!holiday.isValid) return <div />;

  return (
    <div className="py-4">
      <div className="flex justify-between items-center mb-3">
        <div className="text-neutral-90 font-bold flex items-center relative">
          <Common.Input
            id={holidayId}
            value={name}
            onChange={(e) => handleEditName(e.target.value)}
            autoFocus={isFocus}
            isError={!holiday.name}
          />
          {!holiday.name && (
            <div
              className="absolute right-3 top-2.5 cursor-pointer"
              data-tip
              data-for={`error-holiday-name-${holidayId}`}
            >
              <Icon.WarningCircle className="fill-red" />
            </div>
          )}
        </div>

        <div className="flex justify-end items-center">
          <div className="inline-flex items-center justify-center">
            <Icon.Calendar className="fill-neutral-50" />
            <span
              className="ml-2 font-semibold text-neutral-60"
              onClick={handleChangeData}
            >
              Date range
            </span>
            <span className="text-red">&nbsp;*</span>
          </div>
          <div className="ml-3 text-neutral">
            <DoubleDatePicker
              onSelectDateRange={selectDateRange}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              error={holiday.dateRangeError}
              errorMsg={holiday.dateRangeErrorMessage}
            />
          </div>
          <span onClick={() => onDeleteHoliday(holidayId)}>
            <Icon.Trash
              size="16"
              className="fill-neutral-50 ml-4 cursor-pointer"
            />
          </span>
          <div
            className="select-none relative inline-flex items-center justify-center bg-white font-medium text-neutral-60 hover:text-blue group w-9 h-9 rounded cursor-pointer ml-4"
            onClick={toggleCollapse}
          >
            {icon}
          </div>
        </div>
      </div>
      {isShow && (
        <div className="">
          <Table className="relative w-full">
            <thead>
              <tr>
                <Th className={`${TH_CLASSNAME}`}>DELIVERY DATE</Th>
                <Th className={`${TH_CLASSNAME}`}>DESPATCH DATE</Th>
                <Th className={`${TH_CLASSNAME}`}>ORDER DATE</Th>
                <Th className={`${TH_CLASSNAME}`}>CUT-OFF TIME</Th>
                <Th className={`${TH_CLASSNAME}`}></Th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {listDeliveryDay.map((item: deliveryDisplayType) => {
                return (
                  <ItemRowHolidayEdit
                    key={item.id}
                    item={item}
                    onSelectDate={selectDate}
                    onDeleteDelivery={(id) => onDeleteDelivery(holidayId, id)}
                    onChangeCutoffTime={(value) =>
                      editCutoffTime(item.id || "", value)
                    }
                  />
                );
              })}
            </tbody>
          </Table>

          <div>
            <div className="border border-t-0 border-neutral-20 py-2 px-3 text-blue leading-6 font-medium ">
              <span
                className="flex items-center cursor-pointer w-fit"
                onClick={() => onAddDeliveryDay(holidayId)}
              >
                <Icon.PlusBorder className="fill-blue mr-2" />
                Add delivery day
              </span>
            </div>
          </div>
        </div>
      )}

      <ReactTooltip
        id={`error-holiday-name-${holidayId}`}
        place="bottom"
        type="error"
        className="px-4 py-2"
      >
        Holiday name is required
      </ReactTooltip>
    </div>
  );
};

export default ItemHolidayEdit;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M2 0.5H14C14.8284 0.5 15.5 1.17157 15.5 2V14C15.5 14.8284 14.8284 15.5 14 15.5H2C1.17157 15.5 0.5 14.8284 0.5 14V2C0.5 1.17157 1.17157 0.5 2 0.5Z"
        fill="#F1F5F9"
        stroke="#E2E8F0"
      />
      <path
        d="M4 7.24926L7.15195 10.5L12 5.5"
        stroke="#BECBDA"
        strokeWidth="2"
        strokeLinecap="square"
        fill="#F1F5F9"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M6 0C3.075 0 0.75 2.325 0.75 5.25C0.75 6.675 1.275 8.025 2.325 9C2.4 9.075 5.4 11.775 5.475 11.85C5.775 12.075 6.225 12.075 6.45 11.85C6.525 11.775 9.6 9.075 9.6 9C10.65 8.025 11.175 6.675 11.175 5.25C11.25 2.325 8.925 0 6 0ZM6 6.75C5.175 6.75 4.5 6.075 4.5 5.25C4.5 4.425 5.175 3.75 6 3.75C6.825 3.75 7.5 4.425 7.5 5.25C7.5 6.075 6.825 6.75 6 6.75Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M14 1H2C1.60218 1 1.22064 1.15804 0.93934 1.43934C0.658035 1.72064 0.5 2.10218 0.5 2.5V10.5C0.5 10.8978 0.658035 11.2794 0.93934 11.5607C1.22064 11.842 1.60218 12 2 12H5.5L8 15L10.5 12H14C14.3978 12 14.7794 11.842 15.0607 11.5607C15.342 11.2794 15.5 10.8978 15.5 10.5V2.5C15.5 2.10218 15.342 1.72064 15.0607 1.43934C14.7794 1.15804 14.3978 1 14 1V1Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path d="M4 5H12" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 8H8.5" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
}

export default IconComponent;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M15.7333 0.799959C15.5999 0.666626 15.3333 0.666626 15.1333 0.666626L11.3333 1.79996V15.2L15.5333 14C15.7999 13.9333 15.9999 13.6666 15.9999 13.3333V1.33329C15.9999 1.13329 15.9333 0.933293 15.7333 0.799959Z" />
      <path d="M10 1.79993L6 0.799927V14.1999L10 15.1999V1.79993Z" />
      <path d="M4.66667 0.799927L0.466667 1.99993C0.2 2.13326 0 2.39993 0 2.66659V14.6666C0 14.8666 0.0666667 15.0666 0.266667 15.1999C0.4 15.2666 0.533333 15.3333 0.666667 15.3333C0.733333 15.3333 0.8 15.3333 0.866667 15.3333L4.66667 14.1999V0.799927Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { ComponentType } from "react";
// import { useLocation, Navigate } from "react-router-dom";
import { getToken } from "@/utils/authUtils";
// import { PATH } from "@/configuration/globalVariable";
// import { setUser } from "@/providers/auth/authSlice";
// import { userSelector } from "@/providers/auth/selector";
// import { fetchUserProfileAsync } from "@/providers/auth/authSlice";
import { navigateToSignin } from "@/utils";
import {
  fetchUserProfileAsync,
  // fetchSwitchAccountAsync,
} from "@/providers/auth/authSlice";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { userSelector } from "@/providers/auth/selector";

function withAuthGuard(Component: ComponentType) {
  return (props: any) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(userSelector);
    // const location = useLocation();
    const token = getToken();
    // const dispatch = useAppDispatch();
    if (!token) {
      navigateToSignin();
      return (
        <></>
        // <Navigate
        //   to={`/${PATH.AUTH}/${PATH.SIGN_IN}`}
        //   state={{ from: location }}
        //   replace
        // />
      );
    } else {
      if (!user?.token) {
        dispatch(fetchUserProfileAsync());
      }
    }
    // const userInfo = useAppSelector(userSelector);
    // if (!userInfo.token) {
    //   dispatch(fetchUserProfileAsync());
    //   // const dispatch = useAppDispatch();
    //   // dispatch(setUser(user));
    // }

    return <Component {...props} />;
  };
}

export default withAuthGuard;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <rect width="16" height="16" rx="4" fill="#64748B" />
      <path
        d="M7.125 11H4.88068V3.72727H7.22443C7.92992 3.72727 8.53362 3.87287 9.03551 4.16406C9.53741 4.45289 9.92211 4.86837 10.1896 5.41051C10.4571 5.95028 10.5909 6.59659 10.5909 7.34943C10.5909 8.10701 10.456 8.75923 10.1861 9.30611C9.91619 9.85062 9.5232 10.2696 9.0071 10.5632C8.491 10.8544 7.86364 11 7.125 11ZM5.76136 10.2188H7.06818C7.66951 10.2188 8.16785 10.1027 8.56321 9.87074C8.95857 9.63873 9.25331 9.30848 9.44744 8.87997C9.64157 8.45147 9.73864 7.94129 9.73864 7.34943C9.73864 6.76231 9.64276 6.25687 9.45099 5.8331C9.25923 5.40696 8.97277 5.08026 8.59162 4.85298C8.21046 4.62334 7.7358 4.50852 7.16761 4.50852H5.76136V10.2188Z"
        fill="#F8FAFC"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import React, { FC, InputHTMLAttributes } from "react";
import clsx from "clsx";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  ipSize?: "lg" | "md";
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  className?: string;
  iconType?: "stroke" | "fill";
  iconRight?: React.ElementType;
}

export const Radio: FC<Props> = ({
  ipSize = "lg",
  label,
  isError,
  errorMessage,
  className,
  wrapClassName,
  disabled,
  id,
  ...rest
}) => {
  return (
    <div className={clsx("mb-5", wrapClassName)}>
      <div className="relative flex items-center">
        <div
          className={clsx(
            "flex items-center h-5",
            !disabled && "cursor-pointer"
          )}
        >
          <input
            {...rest}
            id={id}
            type="radio"
            className={clsx(
              "focus:ring-transparent text-blue",
              ipSize === "md" ? "h-4 w-4" : "h-5 w-5",
              disabled ? "text-neutral-20" : "cursor-pointer",
              isError ? "border-red" : "border-neutral-30",
              className
            )}
          />
        </div>
        {Boolean(label) && (
          <div className="ml-3 text-base">
            <label htmlFor={id} className="text-neutral-70 cursor-pointer">
              {label}
            </label>
          </div>
        )}
      </div>
      {Boolean(errorMessage) && (
        <p className="mt-[0.375rem] text-sm text-red" id="email-error">
          {errorMessage}
        </p>
      )}
    </div>
  );
};

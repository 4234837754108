import { LayoutPartials } from "@/components";

const Index = () => {
  return (
    <LayoutPartials.BodyContent pageTitle="Tooltip">
      <pre>react-tooltip</pre>{" "}
      <a
        href="https://www.npmjs.com/package/react-tooltip"
        target="_blank"
        rel="noreferrer"
        className="text-blue underline"
      >
        Homepage Document
      </a>
      <br />
      for simple case, just add content for <strong>data-tip</strong> attribute
      into element:
      <pre className="text-sm bg-neutral-10 p-4 rounded">
        data-tip=&quot;tooltip content&quot;
      </pre>
      <br />
      For content reload after, we need to rebuild tooltip to make sure all
      toolyip elements updated. like: modal, loadmore list.....
      <pre className="text-sm bg-neutral-10 p-4 rounded">
        ReactTooltip.rebuild()
      </pre>
    </LayoutPartials.BodyContent>
  );
};

export default Index;

import { CopyToClipboard } from "react-copy-to-clipboard";
import clsx from "clsx";
import { SnackbarUtils } from "@/utils";
import { Icon } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../components";

const Index = () => {
  const renderIcon = () => {
    const arrIcon = [];
    let iconType = "fill";

    for (const [iconComponentName, IconComponent] of Object.entries(Icon)) {
      if (iconComponentName === "undefined") {
        iconType = "stroke";
      }

      if (iconComponentName !== "undefined") {
        arrIcon.push(
          <div
            key={iconComponentName}
            className="w-1/2 sm:w-1/4 lg:w-1/6 flex justify-center items-center flex-col p-2 mb-2"
          >
            {IconComponent && (
              <CopyToClipboard
                text={`<Icon.${iconComponentName} iconType="${iconType}" className="${
                  iconType === "fill" ? "fill-neutral-90" : "stroke-neutral-90"
                }" />`}
                onCopy={() => {
                  SnackbarUtils.success(
                    `Copied <b>Icon ${iconComponentName}</b> code success to clipboard.`
                  );
                }}
              >
                <button className="p-2">
                  <IconComponent
                    className={clsx(
                      iconType === "fill"
                        ? "fill-neutral-90"
                        : "stroke-neutral-90"
                    )}
                    size="20"
                  />
                </button>
              </CopyToClipboard>
            )}
            <span>{iconComponentName}</span>
          </div>
        );
      }
    }
    return arrIcon;
  };

  return (
    <div className="p-4 bg-white rounded shadow">
      <div className="text-hd5 font-medium pb-4 mb-4 shadow-underline">
        Icon components
      </div>

      <ComponentInfo
        title="Import component"
        code={`import { Icon } from "@/components";

<Icon.[IconComponentName] />`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  viewBox: string;
  children?: any;
  className?: string;
  wrapClassName?: string;
  badgeClassName?: string;
  size?: number;
  badge?: string | number;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  size = 16,
}`}
      />
      <strong>
        <em>* Click button to copy code:</em>
      </strong>
      <div>
        <DisplayComponent
          horizontal={true}
          RenderComp={() => (
            <>
              <div className="flex flex-wrap items-center mb-4">
                <CopyToClipboard
                  text={`<Icon.Noti iconType="fill" className="fill-neutral-80" />`}
                  onCopy={() => {
                    SnackbarUtils.success(
                      `Copied <b>Icon Noti</b> code success to clipboard.`
                    );
                  }}
                >
                  <button className="p-2">
                    <Icon.Noti
                      iconType="fill"
                      className="mr-2 fill-neutral-80"
                    />
                  </button>
                </CopyToClipboard>

                <CopyToClipboard
                  text={`<Icon.Noti size="20" badge="99" iconType="fill" className="fill-neutral-80" />`}
                  onCopy={() => {
                    SnackbarUtils.success(
                      `Copied <b>Icon Noti</b> code success to clipboard.`
                    );
                  }}
                >
                  <button className="p-2">
                    <Icon.Noti
                      size="20"
                      badge="99"
                      iconType="fill"
                      className="fill-neutral-80"
                    />
                  </button>
                </CopyToClipboard>
              </div>
            </>
          )}
          code={`<Icon.Noti />
<Icon.Noti size="20" badge="99" />`}
        />
        <div className="flex flex-wrap">{renderIcon()}</div>
      </div>
    </div>
  );
};

export default Index;

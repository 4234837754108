import { LayoutPartials } from "@/components";
import { TabDeliverySchedule } from "../components";

const SchedulePlanner = () => {
  return (
    <LayoutPartials.BodyContent pageTitle="Schedule Planner">
      <TabDeliverySchedule />
    </LayoutPartials.BodyContent>
  );
};

export default SchedulePlanner;

import { forwardRef } from "react";
import DatePickerComponent from "react-datepicker";
import clsx from "clsx";
import { Icon } from "@/components";
import { WrapInput } from "./WrapInput";
interface rangeTime {
  startTime?: Date | null;
  endTime?: Date | null;
}

interface Props {
  ipSize?: "lg" | "md";
  className?: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  disabled?: boolean;
  value: rangeTime;
  onChange: (target: string, date: Date | null) => void;
}

interface inputProps {
  ipSize?: "lg" | "md";
  className?: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  disabled?: boolean;
}

export type Ref = HTMLInputElement;

export const TimeRangePicker = ({
  ipSize = "lg",
  className,
  label,
  isError,
  errorMessage,
  wrapClassName,
  disabled,
  value,
  onChange,
}: Props) => {
  const CustomInput = forwardRef<Ref, inputProps>(({ ...rest }, ref) => (
    <input
      {...rest}
      className={clsx(
        "w-full text-base outline-none rounded",
        ipSize === "md" ? "py-1 pl-2" : "py-[0.375rem] pl-3",
        disabled ? "text-neutral-40" : "text-neutral"
      )}
      ref={ref}
    />
  ));

  return (
    <WrapInput
      label={label}
      errorMessage={errorMessage}
      wrapClassName={wrapClassName}
    >
      <div
        className={clsx(
          "flex items-center border rounded",
          isError && "border-red",
          isError
            ? "border-red focus:outline-none focus:ring-red focus:border-red"
            : "border-neutral-20 focus:ring-blue focus:border-blue",
          disabled ? "bg-neutral-20" : "bg-white",
          className
        )}
      >
        <DatePickerComponent
          placeholderText="Start time"
          className={clsx(className, "text-neutral-60")}
          selected={value.startTime}
          onChange={(date) => onChange("startTime", date)}
          customInput={<CustomInput />}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
        />
        <div className="mx-3">
          <Icon.TimeTo size="16" className="fill-neutral-50" />
        </div>
        <DatePickerComponent
          placeholderText="End time"
          className={clsx(className, "text-neutral-40")}
          selected={value.endTime}
          onChange={(date) => onChange("endTime", date)}
          customInput={<CustomInput />}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
        />
        <div className="mx-3">
          <Icon.Clock size="16" className="fill-neutral-40" />
        </div>
      </div>
    </WrapInput>
  );
};

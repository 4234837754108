import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 80 80">
      <circle cx="40" cy="40" r="40" fill="#FFE8CC" />
      <circle cx="40" cy="40" r="26" fill="#FFBB33" />
      <path
        d="M51.6413 46.4849L42.7323 29.6429C42.469 29.1468 42.0756 28.7318 41.5943 28.4424C41.1129 28.1529 40.5619 28 40.0003 28C39.4387 28 38.8876 28.1529 38.4063 28.4424C37.925 28.7318 37.5316 29.1468 37.2683 29.6429L28.3593 46.4849C28.1115 46.9548 27.9896 47.4809 28.0053 48.0119C28.021 48.5429 28.1738 49.0608 28.4489 49.5153C28.724 49.9698 29.112 50.3454 29.5752 50.6055C30.0384 50.8656 30.561 51.0015 31.0923 50.9999H48.9083C49.4395 51.0015 49.9622 50.8656 50.4254 50.6055C50.8886 50.3454 51.2766 49.9698 51.5517 49.5153C51.8268 49.0608 51.9796 48.5429 51.9953 48.0119C52.011 47.4809 51.8891 46.9548 51.6413 46.4849ZM40.0003 47.9999C39.7036 47.9999 39.4136 47.9119 39.1669 47.7471C38.9203 47.5823 38.728 47.348 38.6145 47.0739C38.5009 46.7998 38.4712 46.4982 38.5291 46.2073C38.587 45.9163 38.7298 45.649 38.9396 45.4392C39.1494 45.2295 39.4167 45.0866 39.7077 45.0287C39.9986 44.9708 40.3002 45.0005 40.5743 45.1141C40.8484 45.2276 41.0827 45.4199 41.2475 45.6665C41.4123 45.9132 41.5003 46.2032 41.5003 46.4999C41.5003 46.8977 41.3423 47.2792 41.0609 47.5605C40.7796 47.8419 40.3981 47.9999 40.0003 47.9999ZM41.0003 42.9999H39.0003L38.5003 34.9999H41.5003L41.0003 42.9999Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { RootState } from "@/sharedStore/store";

export const initPantryListSelector = (state: RootState) =>
  state.pantryListReducer.initPantryList;
export const pantryListSelector = (state: RootState) =>
  state.pantryListReducer.pantryList;
export const pantryLinesSelector = (state: RootState) =>
  state.pantryListReducer.pantryList.pantryLines;
export const isPantryListLoadingSelector = (state: RootState) =>
  state.pantryListReducer.loading;
export const isIframeSelector = (state: RootState) =>
  state.pantryListReducer.isIframe;
export const isScrollToBottomSelector = (state: RootState) =>
  state.pantryListReducer.isScrollToBottom;
export const searchKeywordsSelector = (state: RootState) =>
  state.pantryListReducer.searchKeywords;

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" />
      <rect
        x="4.49268"
        y="6.89453"
        width="4.91767"
        height="1.63922"
        transform="rotate(45 4.49268 6.89453)"
        fill="white"
      />
      <rect
        x="5.69946"
        y="10.3346"
        width="8.21394"
        height="1.63922"
        transform="rotate(-45 5.69946 10.3346)"
        fill="white"
      />
    </SvgIcon>
  );
}

export default IconComponent;

import { Link } from "react-router-dom";
import { Icon } from "@/components";
import { Images } from "@/assets/images";
import { useAppSelector } from "@/sharedStore/hooks";
import { userSelector } from "@/providers/auth/selector";
import { Avatar, Notification } from "./components";

interface Props {
  onHamburgerClick: () => void;
}

const Index = ({ onHamburgerClick }: Props) => {
  const user = useAppSelector(userSelector);

  return (
    <div className="h-[3.75rem] flex items-center px-5 shadow-underline bg-white relative z-40">
      <button onClick={onHamburgerClick} className="mr-4 block md:hidden">
        <Icon.NavigationMenu
          size="24"
          className="stroke-neutral-70 hover:stroke-neutral-90"
        />
      </button>

      <Link to="/" className="mr-auto">
        <img src={Images.logo} className="w-[6.25rem] h-8" alt="TOM" />
      </Link>

      <a href="mailto:support@tomsoft.com.au" className="mb-1">
        <Icon.Support2 size={24} className="fill-neutral-50" />
      </a>
      {user.accountId && <Notification />}
      <Avatar />
    </div>
  );
};

export default Index;

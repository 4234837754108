import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_4096_662686)">
        <path d="M8.725 1.2002L9.425 7.60019L11.525 5.5002C13.425 7.4002 13.425 10.6002 11.525 12.5002C10.625 13.5002 9.325 14.0002 8.025 14.0002C6.725 14.0002 5.425 13.5002 4.525 12.5002C2.625 10.6002 2.625 7.4002 4.525 5.5002C5.125 4.9002 5.925 4.4002 6.825 4.2002L6.225 2.3002C5.025 2.6002 3.925 3.2002 3.025 4.1002C0.325 6.8002 0.325 11.2002 3.025 14.0002C4.325 15.3002 6.125 16.0002 7.925 16.0002C9.825 16.0002 11.525 15.3002 12.825 14.0002C15.525 11.3002 15.525 6.9002 12.825 4.1002L15.025 1.9002L8.725 1.2002Z" />
      </g>
      <defs>
        <clipPath id="clip0_4096_662686">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import { DeliveryStatusLabel, Icon } from "@/components";
import { ModalOrderDetails } from "./ModalOrderDetails";
import { manifestLine, manifest } from "../models/DeliveryManifest";
import { DeliveryLineStatusValue } from "@/configuration/globalVariable";
import { addressGenerate, timeFormat, timeStopFormat } from "@/utils";
import { ETATimeFormat } from "../utils";

interface Props {
  isFirst?: boolean;
  className?: string;
  data: manifestLine;
  routeInfo: manifest;
  orderLineNumber: number;
  manifestETALoading?: boolean;
}

export const DestinationDetailItem = ({
  isFirst,
  className,
  orderLineNumber,
  data,
  routeInfo,
  manifestETALoading,
}: Props) => {
  const [orderDetailsModal, setOrderDetailsModal] = useState<manifestLine>();
  useEffect(() => {
    ReactTooltip.rebuild();
    return () => {
      ReactTooltip.hide();
    };
  });
  const isCompleted = data.deliveryStatus === DeliveryLineStatusValue.COMPLETED;
  const isDelivering =
    data.deliveryStatus === DeliveryLineStatusValue.DELIVERING;
  const ETATime = ETATimeFormat(data, routeInfo?.status);

  return (
    <>
      <div
        className={clsx(
          "px-3 py-2 flex border rounded mx-4 cursor-pointer hover:bg-blue-5 hover:border-blue-80",
          !isFirst && "mt-2",
          isDelivering ? "border-blue bg-blue-5" : "border-neutral-10 bg-white",
          className
        )}
        onClick={() => setOrderDetailsModal(data)}
      >
        <div>
          <div
            className={clsx(
              "w-5 h-5 rounded-full font-semibold flex items-center justify-center",
              isCompleted
                ? "bg-green"
                : isDelivering
                ? "bg-blue text-white"
                : "bg-neutral-10 text-neutral-50"
            )}
          >
            {isCompleted ? (
              // <Icon.Checked size="12" className="fill-white" />
              <span style={{color: 'white'}}>{orderLineNumber}</span>
            ) : (
              orderLineNumber
            )}
          </div>
        </div>
        <div className="flex-1 ml-4">
          <div className="font-semibold">
            <span className="text-blue">
              #{data.document.saleDocumentNumber}
            </span>
            {data.document.customerTradingName
              ? ` - ${data.document.customerTradingName}`
              : ""}
            {(isCompleted || isDelivering) && data.deliveryStatus && (
              <DeliveryStatusLabel
                className="ml-2"
                isManifestLine
                status={data.deliveryStatus}
              />
            )}
          </div>
          <div className="flex mt-2">
            <div className="flex items-center mr-5">
              <div data-tip="Earliest time - Latest time">
                <Icon.DeliveryTime size="12" className="fill-neutral-40" />
              </div>
              <span className="ml-1.5 text-neutral-70">
                {timeFormat(data.earliest, true)} -{" "}
                {timeFormat(
                  data.latest === "00:00:00" ? "23:59:00" : data.latest,
                  true
                )}
              </span>
            </div>
            <div className="flex items-center mr-5">
              <div data-tip="Stop time">
                <Icon.Stock size="12" className="fill-neutral-40" />
              </div>
              <span className="ml-1.5 text-neutral-70">
                {data.stopTime != null &&
                  data.stopTime >= 0 &&
                  timeStopFormat(data.stopTime)}
              </span>
            </div>
            <div className="flex items-center">
              <div data-tip="Estimated time of arrival">
                <Icon.Clock size="12" className="fill-blue-80" />
              </div>
              <span className="ml-1.5 text-blue">
                {manifestETALoading ? (
                  <Icon.Loading className="ml-2" />
                ) : (
                  ETATime
                )}
              </span>
            </div>
          </div>
          <div className="flex mt-2">
            <div className="mt-1" data-tip="Delivery address">
              <Icon.Location size="12" className="fill-neutral-40" />
            </div>
            <div className="ml-1.5 text-neutral-70">
              {addressGenerate(
                data.document.deliveryAddressLine1,
                data.document.deliveryAddressLine2,
                data.document.deliverySuburb,
                data.document.deliveryState,
                data.document.deliveryPostCode
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalOrderDetails
        open={Boolean(orderDetailsModal?.id)}
        data={orderDetailsModal}
        routeInfo={routeInfo}
        orderLineNumber={orderLineNumber}
        onClose={() => setOrderDetailsModal(undefined)}
        ETATime={ETATime}
      />
    </>
  );
};

import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M12 9H4.35C4.125 8.4 3.6 7.875 3 7.65V0.75C3 0.3 2.7 0 2.25 0H0V1.5H1.5V7.65C0.6 7.95 0 8.775 0 9.75C0 11.025 0.975 12 2.25 12C3.225 12 4.05 11.4 4.35 10.5H12V9Z" />
      <path d="M5.25 7.5H10.5C10.95 7.5 11.25 7.2 11.25 6.75V3C11.25 2.55 10.95 2.25 10.5 2.25H5.25C4.8 2.25 4.5 2.55 4.5 3V6.75C4.5 7.2 4.8 7.5 5.25 7.5Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { LayoutPartials } from "@/components";

const SchedulePlannerAssignedList = () => {
  return (
    <LayoutPartials.BodyContent pageTitle="Schedule Planner">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
      temporibus mollitia soluta cumque delectus veritatis. Explicabo iste
      dolore nisi incidunt aspernatur est hic, voluptatibus, ea minus deleniti
      atque delectus dolores?
    </LayoutPartials.BodyContent>
  );
};

export default SchedulePlannerAssignedList;

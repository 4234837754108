import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6841 0V2H2.94256V14H11.6841V16H1.97128C1.43513 16 1 15.552 1 15V1C1 0.448 1.43513 0 1.97128 0H11.6841ZM10.7128 3.586L15 8L10.7128 12.414L9.33939 11L11.2819 9H4.88511V7H11.2819L9.33939 5L10.7128 3.586Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

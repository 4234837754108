import { useEffect } from "react";
import { useLogout } from "@/services/authentication";

const Index = () => {
  const logout = useLogout();
  useEffect(() => {
    logout();
  }, [logout]);

  return null;
};

export default Index;

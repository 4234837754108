import axios from "axios";
import { useMutation } from "react-query";
import { AUTH } from "@/configuration/globalVariable";

const API_PATH = "dashboard/";

export const useGetPendingCreditsSummary = () =>
  useMutation((data: { accountId: string }) =>
    axios
      .post(`${API_PATH}GetPendingCreditsSummary`, {
        ...data,
        userId: "",
        apiKey: AUTH.API_KEY,
        clientInfo: AUTH.CLIENT_INFO,
        fakeUserName: "",
      })
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetPendingOrdersSummary = () =>
  useMutation((data: { accountId: string }) =>
    axios
      .post(`${API_PATH}GetPendingOrdersSummary`, {
        ...data,
        userId: "",
        apiKey: AUTH.API_KEY,
        clientInfo: AUTH.CLIENT_INFO,
        fakeUserName: "",
      })
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetOverduePaymentsSummary = () =>
  useMutation((data: { accountId: string; fromDate: string; toDate: string }) =>
    axios
      .post(`${API_PATH}GetOverduePaymentsSummary`, {
        ...data,
        userId: "",
        apiKey: AUTH.API_KEY,
        clientInfo: AUTH.CLIENT_INFO,
        fakeUserName: "",
      })
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetTotalSpendingSummary = () =>
  useMutation((data: {}) =>
    axios
      .post(`${API_PATH}GetTotalSpendingSummary`, {
        ...data,
      })
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetPendingOrdersList = () =>
  useMutation(
    (data: { timeStamp: number; pageNumber: number; pageSize: number }) =>
      axios
        .post(`${API_PATH}GetPendingOrdersList`, {
          ...data,
          apiKey: AUTH.API_KEY,
          clientInfo: AUTH.CLIENT_INFO,
        })
        .then((res) => res.data.data)
        .catch((reason) => Promise.reject(reason.response))
  );

export const useGetRoutesSummary = () =>
  useMutation(
    (data: { accountId: string; deliveryDate: string; pageSize: number }) =>
      axios
        .post(`${API_PATH}GetRoutesSummary`, {
          ...data,
          apiKey: AUTH.API_KEY,
          clientInfo: AUTH.CLIENT_INFO,
        })
        .then((res) => res.data.data)
        .catch((reason) => Promise.reject(reason.response))
  );

// RelationshipConnects

export const useGetRelationshipConnects = () =>
  useMutation((data: { accountId: string }) =>
    axios
      .post(`${API_PATH}GetRelationshipConnects`, {
        ...data,
        apiKey: AUTH.API_KEY,
        clientInfo: AUTH.CLIENT_INFO,
      })
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

export const useGetRecentlyOrderedSuppliers = () =>
  useMutation((data: { defaultView: string }) =>
    axios
      .post(`${API_PATH}GetRecentlyOrderedAccount`, data)
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

export const useAcceptRelationshipConnect = () =>
  useMutation((data: { relationshipId: string }) =>
    axios
      .post(`${API_PATH}AcceptRelationshipConnect`, {
        ...data,
        apiKey: AUTH.API_KEY,
        clientInfo: AUTH.CLIENT_INFO,
      })
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

export const useRejectRelationshipConnect = () =>
  useMutation((data: { relationshipId: string }) =>
    axios
      .post(`${API_PATH}RejectRelationshipConnect`, {
        ...data,
        apiKey: AUTH.API_KEY,
        clientInfo: AUTH.CLIENT_INFO,
      })
      .then((res) => res.data.data)
      .catch((reason) => Promise.reject(reason.response))
  );

// Customer Dashboad service APIs

export const useGetRecentOrdersList = () =>
  useMutation(
    (data: { timeStamp: number; pageNumber: number; pageSize: number }) =>
      axios
        .post(`${API_PATH}GetRecentOrdersList`, {
          ...data,
          apiKey: AUTH.API_KEY,
          clientInfo: AUTH.CLIENT_INFO,
        })
        .then((res) => res.data.data)
        .catch((reason) => Promise.reject(reason.response))
  );

export const useGetPendingCreditsList = () =>
  useMutation(
    (data: { timeStamp: number; pageNumber: number; pageSize: number }) =>
      axios
        .post(`${API_PATH}GetPendingCreditsList`, {
          ...data,
          apiKey: AUTH.API_KEY,
          clientInfo: AUTH.CLIENT_INFO,
        })
        .then((res) => res.data.data)
        .catch((reason) => Promise.reject(reason.response))
  );

import { useState } from "react";
import { LayoutPartials, Common, Icon } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../../components";

export const Input = () => {
  const [ipValue, setValue] = useState<string | number>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setValue(value);
  };

  return (
    <LayoutPartials.BodyContent className="mb-4" pageTitle="Form Input">
      <ComponentInfo
        title="Input component"
        code={`import { Common } from "@/components";

const [ipValue, setValue] = useState("");

const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
  const { value } = e.target;
  setValue(value);
};

<Common.Input
  name="Input"
  label="Input label"
  isError
  errorMessage="error text"
  ipSize="md"
  id="textIP"
  value={ipValue}
  onChange={handleChange}
/>`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  ipSize?: "lg" | "md";
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  className?: string;
  iconType?: "stroke" | "fill";
  iconRight?: React.ElementType;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  ipSize = "lg",
  iconType = "fill",
}`}
      />

      <DisplayComponent
        componentName="Input with Icon"
        className="mb-3"
        RenderComp={() => (
          <Common.Input
            iconRight={Icon.Alert}
            iconType="fill"
            name="input"
            value={ipValue}
            onChange={handleChange}
          />
        )}
        code={`<Common.Input
  iconRight={Icon.Alert}
  iconType="fill"
  onChange={handleChange}
/>`}
      />
      <DisplayComponent
        componentName="Disabled input"
        className="mb-3"
        RenderComp={() => (
          <Common.Input
            placeholder="Input placeholder"
            name="input"
            value={ipValue}
            onChange={handleChange}
            iconRight={Icon.Alert}
            iconType="fill"
            disabled
          />
        )}
        code={`<Common.Input
  placeholder="Input placeholder"
  name="input"
  onChange={handleChange}
  iconRight={Icon.Alert}
  iconType="fill"
  ipSize="md"
  disabled
/>`}
      />

      <DisplayComponent
        componentName="Label input with error message"
        className="mb-3"
        RenderComp={() => (
          <Common.Input label="Input label" isError errorMessage="error text" />
        )}
        code={`<Common.Input
  label="Input label"
  isError
  errorMessage="error text"
/>`}
      />

      <DisplayComponent
        componentName="Counter Input"
        className="mb-3"
        RenderComp={() => (
          <>
            <Common.CounterInput value={ipValue} setValue={setValue} />
          </>
        )}
        code={`import { useState } from "react";
        import { Common } from "@/components";
const [value, setValue] = useState<string | number>(0);
<Common.CounterInput value={value} setValue={setValue} />`}
      />
    </LayoutPartials.BodyContent>
  );
};

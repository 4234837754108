import { useFormik } from "formik";
import * as yup from "yup";
import { ModalContent, Card, Common } from "@/components";
import { manifestLine } from "../models";

const validationSchema = yup.object().shape({
  position: yup
    .number()
    .min(1, "Position must be greater than or equal to 1.")
    .required("Please enter position number.")
    .typeError("Position is a number!"),
});

interface Props {
  open: boolean;
  data?: manifestLine;
  onClose: () => void;
  onSubmit: (position: number) => void;
}

export const ModalChangePosition = ({
  open,
  data,
  onSubmit,
  onClose,
}: Props) => {
  const formik = useFormik({
    initialValues: {
      position: 1,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values.position - 1);
      formik.resetForm();
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <ModalContent
      open={open}
      onClose={handleClose}
      panelClassWidth="max-w-[31.25rem] w-full"
    >
      <Card
        title={`Change position order #${
          data?.document?.saleDocumentNumber || 0
        }`}
      >
        <div className="p-4">
          <Common.Input
            label={`Enter position in route (current position: ${
              data?.position ? data.position + 1 : 1
            })`}
            name="position"
            value={formik.values.position}
            onChange={formik.handleChange}
            isError={formik.touched.position && Boolean(formik.errors.position)}
            errorMessage={formik.touched.position ? formik.errors.position : ""}
            onKeyDown={(e) => e.key === "Enter" && formik.handleSubmit()}
          />
        </div>
        <div className="shadow-overline px-4 py-3 flex space-x-2 justify-end">
          <Common.Button onClick={handleClose} color="transparent">
            Cancel
          </Common.Button>
          <Common.Button
            disabled={
              (formik.touched.position && Boolean(formik.errors.position)) ||
              (data && formik.values.position == data?.position + 1)
            }
            onClick={() => formik.handleSubmit()}
          >
            {formik.values.position == data?.position} Move
          </Common.Button>
        </div>
      </Card>
    </ModalContent>
  );
};

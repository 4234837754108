import { useState } from "react";
import { LayoutPartials, Common } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../../components";

export const TimeRangePicker = () => {
  const [rangeTime, setRangeTime] = useState({
    startTime: null,
    endTime: null,
  });
  const handleChange = (target: string, date: Date | null) => {
    setRangeTime({ ...rangeTime, ...{ [target]: date } });
  };

  return (
    <LayoutPartials.BodyContent
      className="mb-4"
      pageTitle="TimeRangePicker Input"
    >
      <ComponentInfo
        title="Datepicker input component"
        code={`import { Common } from "@/components";

const [rangeTime, setRangeTime] = useState({
  startTime: null,
  endTime: null,
});

const handleChange = (target: string, date: Date | null) => {
  setRangeTime({ ...rangeTime, ...{ [target]: date } });
};

<Common.TimeRangePicker value={rangeTime} onChange={handleChange} />`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  ipSize?: "lg" | "md";
  className?: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  disabled?: boolean;
  value: {startTime: Date | null, endTime: Date | null};
  onChange: (target: string, date: Date | null) => void;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  ipSize = "lg",
}`}
      />

      <DisplayComponent
        className="mb-3"
        RenderComp={() => (
          <Common.TimeRangePicker value={rangeTime} onChange={handleChange} />
        )}
        code={`const [rangeTime, setRangeTime] = useState({
  startTime: null,
  endTime: null,
});

const handleChange = (target: string, date: Date | null) => {
  setRangeTime({ ...rangeTime, ...{ [target]: date } });
};

<Common.TimeRangePicker value={rangeTime} onChange={handleChange} />`}
      />
    </LayoutPartials.BodyContent>
  );
};

import { useEffect, useMemo, useRef, useState } from "react";
import { batch } from "react-redux";
import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import { v4 as uuidv4 } from "uuid";
import { Common, CustomScrollbar, Icon, ModalContent } from "@/components";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { currencyFormatter } from "@/utils";
import { useSupplierSearchProducts } from "../services/pantryList";
import { Product } from "../models/Product";
import { pantryListSelector } from "../providers/selector";
import { addMultipleProducts, setIsScrollToBottom } from "../providers/slice";
import { SelectProductActionProps } from "../providers/actionProps";
import { useGetPantryLineProductIds } from "../providers/hooks";

export interface AddMulProductsModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onOverlayClick?: () => void;
  onCancel: () => void;
  onSubmit?: () => void;
}

const Index = (props: AddMulProductsModalProps) => {
  const tableHeaderClasses =
    "sticky top-0 p-2 z-[1] border border-line bg-neutral-20 text-left text-neutral-40 font-bold";
  const tableRowClasses = "hover:bg-neutral-5";
  const tableCellClasses = "border-b border-neutral-20 px-2 py-1";

  const dispatch = useAppDispatch();
  const pantry = useAppSelector(pantryListSelector);

  const [searchKeywords, setSearchKeywords] = useState<string>("");
  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState<
    string | undefined
  >(undefined);
  const [products, setProducts] = useState<Product[]>([]);
  const [showProducts, setShowProducts] = useState<Product[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  // const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [controller, setController] = useState(() => new AbortController());

  const pantryLineProductIds = useGetPantryLineProductIds();

  // const selectedProducts = useMemo(() => {
  //   return products.filter((p) => selectedProductIds.includes(p.id));
  // }, [products, selectedProductIds]);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const supplierSearchProducts = useSupplierSearchProducts({
    controller,
    setController,
  });
  const { isLoading, isSuccess } = supplierSearchProducts;

  // Helper functions
  const resetState = () => {
    setSelectedProductCategoryId(undefined);
    setProducts([]);
    setShowProducts([]);
    setSelectedProducts([]);
    setSearchKeywords("");
  };

  const delaySearchKeywords = debounce(() => {
    supplierSearchProducts.mutate({
      keywords: searchKeywords,
      customerAccountId: pantry.customerAccountId,
      productCategoryId: undefined,
    });
    // setSelectedProductIds([]);
    setSelectedProductCategoryId(undefined);
  }, 700);

  const delayCommentChange = debounce((id: string, comment: string) => {
    products[products.findIndex((line) => line.id === id)].comment = comment;
    setProducts(products);
  }, 300);

  // Searching helper
  const isSearching = useMemo(() => {
    const isSearching = searchKeywords?.length > 1;
    if (isSearching) {
      delaySearchKeywords();
    } else {
      setSelectedProductCategoryId(undefined);
      setProducts([]);
      setShowProducts([]);
    }
    return isSearching;
  }, [searchKeywords]);

  // Change products states when fetch new data
  useEffect(() => {
    const products: Product[] = (supplierSearchProducts.data ||
      []) as Product[];

    setProducts(products);
    setShowProducts(products);
  }, [supplierSearchProducts.data]);

  // Reset product list if modal is closed
  // Auto focus search input when modal is opened
  useEffect(() => {
    if (!props.isOpen) {
      resetState();
    }
  }, [props.isOpen]);

  // Seperate from fetch new data, because sometimes products states need to reset
  const productExt = useMemo(() => {
    const productGroups = groupBy(products, "productCategoryName");
    const productCategories = Object.getOwnPropertyNames(productGroups);

    return { productGroups, productCategories };
  }, [products]);

  // Rebuild tooltip every time data has changed
  useEffect(() => {
    if (showProducts?.length > 0) {
      ReactTooltip.rebuild();
    }
  }, [showProducts]);

  // Checkbox all
  useEffect(() => {
    const ids = showProducts?.map((p) => p.id);
    setIsCheckAll(
      !ids?.some((id) => !selectedProducts.map((p) => p?.id).includes(id))
    );
  }, [showProducts, selectedProducts]);

  const onChangeProductCategoryId = (newVal: string | undefined) => {
    setSelectedProductCategoryId(newVal);
    if (!newVal) {
      setShowProducts(products);
    } else {
      setShowProducts(productExt.productGroups[newVal]);
    }
  };

  const onChangeCheckBox = (product: Product) => {
    const changedselectedProducts = [...selectedProducts];
    const index = selectedProducts.findIndex((p) => p.id === product.id);
    if (index === -1) {
      changedselectedProducts.push(product);
    } else {
      changedselectedProducts.splice(index, 1);
    }
    setSelectedProducts(changedselectedProducts);
  };

  const onChangeCheckBoxAll = () => {
    if (isCheckAll) {
      // Uncheck
      setSelectedProducts(
        selectedProducts.filter(
          (i) => !showProducts?.map((i) => i.id).includes(i.id)
        )
      );
    } else {
      // Check
      setSelectedProducts([
        ...selectedProducts,
        ...showProducts.filter(
          (p) => !selectedProducts?.map((p) => p?.id).includes(p.id)
        ),
      ]);
    }
  };

  const onClose = () => {
    if (props.onClose) props.onClose();
  };

  const onOverlayClick = () => {
    if (props.onOverlayClick) props.onOverlayClick();
  };

  const onCancel = () => {
    if (props.onCancel) props.onCancel();
  };

  const onSubmit = () => {
    const payload: SelectProductActionProps[] = selectedProducts.map(
      (product) => ({
        product,
        isNew: true,
        comment: product.comment,
        id: uuidv4(),
      })
    );
    batch(() => {
      dispatch(addMultipleProducts(payload));
      dispatch(setIsScrollToBottom(true));
    });

    if (props.onSubmit) props.onSubmit();
  };

  return (
    <ModalContent
      open={props.isOpen}
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      panelClassWidth="max-w-[72rem] w-full max-h-[90vh]"
      initialFocus={searchInputRef}
    >
      <div className="flex flex-col h-[90vh]">
        <div className="text-[1.25rem] font-semibold p-5 border-b border-neutral-20">
          Add multiple products
        </div>
        <div className="flex flex-1 relative md:static">
          <div
            className={clsx(
              "w-60 h-full md:static absolute top-0 select-none",
              "transition-transform md:transition-width ease-in-out z-50",
              "-translate-x-80 md:translate-x-0"
            )}
          >
            <div className="flex-1 flex flex-col min-h-0 border-r border-line bg-white h-full">
              <div className="flex-1 flex flex-col">
                <nav className="flex-1 space-y-1" aria-label="Sidebar">
                  <CustomScrollbar>
                    <a
                      className={clsx(
                        "block text-neutral-60 cursor-pointer group",
                        "hover:shadow-navActive hover:text-blue hover:bg-blue-5",
                        selectedProductCategoryId === undefined
                          ? "shadow-navActive text-blue bg-blue-5"
                          : ""
                      )}
                      onClick={() => onChangeProductCategoryId(undefined)}
                    >
                      <div className="flex items-center h-10 pl-[1.875rem]">
                        <div className="ml-3 whitespace-nowrap overflow-hidden">
                          All
                        </div>
                      </div>
                    </a>
                    {isSearching &&
                      productExt.productCategories?.map((val, i) => (
                        <a
                          key={i}
                          id={val}
                          className={clsx(
                            "block text-neutral-60 cursor-pointer group",
                            "hover:shadow-navActive hover:text-blue hover:bg-blue-5",
                            selectedProductCategoryId === val
                              ? "shadow-navActive text-blue bg-blue-5"
                              : ""
                          )}
                          onClick={() => onChangeProductCategoryId(val)}
                        >
                          <div className="flex items-center h-10 pl-[1.875rem]">
                            <div className="ml-3 whitespace-nowrap overflow-hidden">
                              {val}
                            </div>
                          </div>
                        </a>
                      ))}
                  </CustomScrollbar>
                </nav>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto h-full w-full p-3 md:p-5">
            <div className="bg-white px-6 pb-5 pt-1.5 rounded h-full">
              <div className="flex items-center">
                {isSearching && (
                  <>
                    <p className="font-bold font-neutral-80">
                      {!selectedProductCategoryId
                        ? "All"
                        : selectedProductCategoryId}
                    </p>
                    <p className="font-medium font-neutral-50">{`: ${
                      showProducts?.length ?? 0
                    }`}</p>
                  </>
                )}

                <span className="ml-auto">
                  <Common.Input
                    ref={searchInputRef}
                    iconRight={Icon.Search}
                    iconType="stroke"
                    placeholder="Search for SKU, product name..."
                    onChange={(e) => {
                      setSearchKeywords(e.target.value || "");
                    }}
                    onClear={() => setSearchKeywords("")}
                    value={searchKeywords || ""}
                  />
                </span>
              </div>

              <div className="py-2 flex flex-col h-full">
                {/* <div className="flex-grow overflow-auto"> */}
                {/* List products */}
                {isSuccess && isSearching && showProducts?.length > 0 && (
                  <CustomScrollbar>
                    <table className="relative w-full border border-line border-separate border-spacing-0">
                      {/* Checkbox Column */}
                      {/* Index Column */}
                      {/* SKU Column */}
                      {/* Product Name Column */}
                      {/* Unit Column */}
                      {/* Comment Column */}
                      {/* Price Column */}
                      <thead>
                        <tr>
                          <th className={tableHeaderClasses}>
                            <Common.Checkbox
                              id="checkbox2"
                              ipSize="md"
                              checked={isCheckAll}
                              onChange={() => onChangeCheckBoxAll()}
                            />
                          </th>
                          <th className={tableHeaderClasses}>#</th>
                          <th className={tableHeaderClasses}>SKU</th>
                          <th className={tableHeaderClasses}>PRODUCT NAME</th>
                          <th className={tableHeaderClasses}>UNIT</th>
                          <th className={tableHeaderClasses}>COMMENT</th>
                          <th className={tableHeaderClasses}>PRICE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {showProducts?.map((product, i) => (
                          <tr
                            key={i}
                            className={clsx(
                              tableRowClasses,
                              pantryLineProductIds?.includes(product.id)
                                ? "bg-blue-10"
                                : "",
                              selectedProducts
                                ?.map((p) => p?.id)
                                .includes(product.id)
                                ? "bg-[#F3F8FF]"
                                : ""
                            )}
                          >
                            {/* Checkbox Column */}
                            <td className={tableCellClasses}>
                              <Common.Checkbox
                                id={`check${i}`}
                                ipSize="md"
                                checked={selectedProducts
                                  ?.map((p) => p?.id)
                                  .includes(product.id)}
                                onChange={() => onChangeCheckBox(product)}
                              />
                            </td>

                            {/* Index Column */}
                            <td className={tableCellClasses}>{i + 1}</td>

                            {/* SKU Column */}
                            <td className={tableCellClasses}>
                              {product.productSKU}
                            </td>

                            {/* Product Name Column */}
                            <td
                              className={`${tableCellClasses} flex items-center`}
                            >
                              <img
                                alt=""
                                loading="lazy"
                                className="mr-2 w-8 h-8 object-cover d-inline-block"
                                src={product.productImageUrl}
                              ></img>
                              <div className="flex flex-col">
                                <div className="flex items-center">
                                  <p className="font-medium">
                                    {product.productName}
                                  </p>
                                  {!product.isFromProductAgreement && (
                                    <span
                                      data-tip={`This product is not in ${pantry.customerTradingName}'s agreed list. <br />Adding it into pantry list will also add to the agreed list for this customer.`}
                                    >
                                      <Icon.WarningCircle
                                        iconType="fill"
                                        className="ml-2 fill-yellow-dark"
                                        size={12}
                                      />
                                    </span>
                                  )}
                                </div>

                                <div className="flex items-center">
                                  <Icon.Tag
                                    size={12}
                                    iconType="fill"
                                    className="fill-neutral-40"
                                  />
                                  <p className="text-neutral-60">
                                    {product.productCategoryName}
                                  </p>
                                </div>
                              </div>
                            </td>

                            {/* Unit Column */}
                            <td className={tableCellClasses}>{product.unit}</td>

                            {/* Comment Column */}
                            <td className={tableCellClasses}>
                              <Common.Input
                                maxLength={200}
                                onChange={(e) =>
                                  delayCommentChange(product.id, e.target.value)
                                }
                              />
                            </td>

                            {/* Price Column */}
                            <td className={tableCellClasses}>
                              {product.isPriceTBC
                                ? "TBC"
                                : `${currencyFormatter.format(product.price)}`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CustomScrollbar>
                )}

                {/* Show on the opening */}
                {(!isSearching || (!isSuccess && !isLoading)) && (
                  <Common.NoData
                    iconRender={() => {
                      return (
                        <Icon.Search
                          iconType="stroke"
                          className="stroke-neutral-30"
                          size={32}
                        />
                      );
                    }}
                    title="Type at least 2 characters to search for products"
                  />
                )}

                {/* Show on loading */}
                {isLoading && isSearching && (
                  <div className="h-full flex items-center justify-center">
                    <Icon.Loading size={60} />
                  </div>
                )}

                {/* Show on no data on result */}
                {isSuccess && isSearching && showProducts?.length === 0 && (
                  <Common.NoData title="No data." />
                )}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[3.75rem] flex items-center px-5 border-t border-neutral-20">
          <div className="ml-auto">
            <div className="flex items-center">
              {selectedProducts?.length > 0 && (
                <>
                  <p className="mx-1 text-blue font-bold">
                    {selectedProducts?.length}
                  </p>
                  <p>products selected</p>
                  <button
                    className="px-3 text-red font-medium hover:text-red-dark"
                    onClick={() => setSelectedProducts([])}
                  >
                    Unselect all
                  </button>

                  <p>|</p>
                </>
              )}
              <Common.Button
                color={"gray"}
                btSize={"lg"}
                className="mx-2"
                onClick={onCancel}
              >
                Cancel
              </Common.Button>
              <Common.Button color={"blue"} btSize={"lg"} onClick={onSubmit}>
                Add products
              </Common.Button>
            </div>
          </div>
        </div>
      </div>
    </ModalContent>
  );
};

export default Index;

import { Icon } from "@/components";
import clsx from "clsx";

interface Props {
  className?: string;
  pageNumber: number;
  pageSize: number;
  total: number;
  totalPages: number;
  pagerCenterPoint: number;
  startPage?: number;
  paginationSize?: number;
  onPageClick: (page: number) => void;
}

const calculatePages = (
  totalPages: number,
  lastPage: number,
  currPage: number,
  paginationSize: number,
  pageStartIndex: number
) => {
  const pages: number[] = [];
  let endPage = totalPages;
  if (endPage <= 0) return [];

  let startPage = Math.max(
    currPage - Math.floor(paginationSize / 2),
    pageStartIndex
  );
  endPage = startPage + paginationSize - 1;

  if (endPage > lastPage) {
    endPage = lastPage;
    startPage = endPage - paginationSize + 1;
  }

  for (let i = startPage; i <= endPage; i += 1) {
    if (i >= pageStartIndex) pages.push(i);
  }

  return pages;
};

export const Pagination = ({
  className,
  pageNumber,
  // pageSize,
  // total,
  totalPages,
  // pagerCenterPoint,
  onPageClick,
  startPage = 1,
  paginationSize = 5,
}: Props) => {
  // console.debug(
  //   "==> ",
  //   pageSize,
  //   total,
  //   totalPages,
  //   pagerCenterPoint,
  //   startPage,
  //   paginationSize,
  //   onPageClick
  // );

  const handlePrevClick = () => {
    onPageClick(pageNumber - 1);
  };

  const handleNextClick = () => {
    onPageClick(pageNumber + 1);
  };

  // const pages = [];
  // for (let i = 1; i <= pageTotal; i++) {
  //   pages.push(i);
  // }
  const pages = calculatePages(
    totalPages,
    totalPages,
    pageNumber,
    paginationSize,
    startPage
  );

  return (
    <nav
      className={clsx("relative z-0 inline-flex space-x-1.5", className)}
      aria-label="Pagination"
    >
      {pageNumber > 1 && (
        <span
          className="select-none relative inline-flex items-center justify-center border border-line bg-white font-medium text-neutral-60 hover:bg-blue-10 hover:text-blue group w-9 h-9 rounded cursor-pointer"
          onClick={handlePrevClick}
        >
          <span className="sr-only">Previous</span>
          <Icon.AngleLeft
            size="12"
            className="stroke-neutral-60 group-hover:stroke-blue"
          />
        </span>
      )}

      {pages.map((item, index) => (
        <span
          key={index + item}
          className={clsx(
            "select-none relative inline-flex items-center justify-center border border-line bg-white font-medium text-neutral-60 group w-9 h-9 rounded",
            pageNumber === item
              ? "pointer-events-none cursor-default text-blue bg-blue-10"
              : "hover:text-blue hover:bg-blue-10 cursor-pointer"
          )}
          onClick={() => onPageClick(item)}
        >
          {item}
        </span>
      ))}

      {pageNumber !== totalPages && (
        <span
          className="select-none relative inline-flex items-center justify-center border border-line bg-white font-medium text-neutral-60 hover:bg-blue-10 hover:text-blue group w-9 h-9 rounded group cursor-pointer"
          onClick={handleNextClick}
        >
          <span className="sr-only">Next</span>
          <Icon.AngleRight
            size="12"
            className="stroke-neutral-60 group-hover:stroke-blue"
          />
        </span>
      )}
    </nav>
  );
};

import { useCallback } from "react";
import axios from "axios";
import { useMutation } from "react-query";
// import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/sharedStore/hooks";
import { resetRootStateAction } from "@/sharedStore/store";
import { navigateToSignin } from "@/utils";
import { DOMAIN_URL } from "@/configuration/globalVariable";

// export const useLogin = () =>
//   useMutation((data: { username: string; password: string }) =>
//     axios
//       .post("latest/authentication/loginnew", {
//         ...data,
//         // accountId: AUTH.ACCOUNT_ID,
//         accountId: "",
//         apiKey: AUTH.API_KEY,
//         clientInfo: AUTH.CLIENT_INFO,
//       })
//       .then((res) => res.data)
//       .catch((reason) => Promise.reject(reason.response))
//   );

export const useLogout = () => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();

  return useCallback(() => {
    localStorage.clear();
    dispatch(resetRootStateAction());
    // navigate(`/${PATH.AUTH}/${PATH.SIGN_IN}`, { replace: true });
    navigateToSignin();
  }, [dispatch]);
};

export const useTransferLogin = () =>
  useMutation((data: { path: string }) =>
    axios
      .post("Authentication/transferlogin", {
        path: DOMAIN_URL + data.path,
      })
      .then((res) => {
        window.location.replace(res.data.data);
        return res.data;
      })
      .catch((reason) => Promise.reject(reason.response))
  );

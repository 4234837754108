import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 15C9.25 15 8.75 14.5 8.75 13.75C8.75 13 9.25 12.5 10 12.5C10.75 12.5 11.25 13 11.25 13.75C11.25 14.5 10.75 15 10 15ZM11.25 11.25H8.75V5H11.25V11.25Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

import { Icon } from "@/components";
import parse from "html-react-parser";
import clsx from "clsx";
import { useSnackbar, VariantType, WithSnackbarProps } from "notistack";
import * as React from "react";

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

export const SnackbarUtils = {
  success(msg: string) {
    this.toast(msg, "success");
  },
  warning(msg: string) {
    this.toast(msg, "warning");
  },
  info(msg: string) {
    this.toast(msg, "info");
  },
  error(msg: string) {
    this.toast(msg, "error");
  },
  clearAllNoti() {
    useSnackbarRef.closeSnackbar();
  },
  toast(msg: string, variant: VariantType = "default") {
    const notiIcon = () => {
      if (variant === "success")
        return <Icon.Checked size="14" className="fill-white" />;
      if (variant === "warning")
        return <Icon.Warning size="14" className="fill-white" />;
      if (variant === "info")
        return <Icon.InfomationOutline size="14" className="fill-white" />;
      if (variant === "error")
        return <Icon.Decline size="14" className="stroke-white" />;
    };
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      content: (key, messageText) => (
        <div
          className={clsx(
            "text-base text-neutral p-3.5 flex items-center relative rounded",
            variant === "success" && "bg-status-bg-success",
            variant === "warning" && "bg-status-bg-warning",
            variant === "info" && "bg-status-bg-info",
            variant === "error" && "bg-status-bg-error",
            variant === "default" && "bg-blue-light"
          )}
          id={key.toString()}
        >
          {Boolean(variant) && variant !== "default" && (
            <div>
              <div
                className={clsx(
                  "w-[1.875rem] h-[1.875rem] rounded-full flex justify-center items-center mr-3",
                  variant === "success" && "bg-status-success",
                  variant === "warning" && "bg-status-warning",
                  variant === "info" && "bg-status-info",
                  variant === "error" && "bg-status-error"
                )}
              >
                {notiIcon()}
              </div>
            </div>
          )}
          <div className="mr-6 min-w-[18.75rem] max-w-[19rem]">
            {messageText && parse(messageText.toString())}
          </div>
          <button
            className="p-2 absolute right-1.5 top-1.5"
            onClick={() => useSnackbarRef.closeSnackbar(key)}
          >
            <Icon.Close size="12" className="stroke-neutral-40" />
          </button>
        </div>
      ),
    });
  },
};

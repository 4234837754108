import { LayoutPartials, Common } from "@/components";
import { useState } from "react";
import { DisplayComponent, ComponentInfo } from "../../../components";

export const Datepicker = () => {
  const [dateValue, setDate] = useState<Date | null>(null);
  const handleChange = (date: Date) => {
    setDate(date);
  };

  return (
    <LayoutPartials.BodyContent className="mb-4" pageTitle="Datepicker Input">
      <ComponentInfo
        title="Datepicker input component"
        code={`import { Common } from "@/components";

const [dateValue, setDate] = useState<Date | null>(null);
const handleChange = (date: Date) => {
  setDate(date);
};

<Common.DatePicker selected={dateValue} onChange={handleChange} />`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  ipSize?: "lg" | "md";
  className?: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  disabled?: boolean;
  selected: Date;
  onChange: (date: Date) => void;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  ipSize = "lg",
}`}
      />

      <DisplayComponent
        componentName="Datepicker input"
        className="mb-3"
        RenderComp={() => (
          <Common.DatePicker
            selected={dateValue}
            onChange={handleChange}
            onSelect={handleChange}
          />
        )}
        code={`const handleChange = (date: Date) => {
  // Todo
};

<Common.DatePicker selected={dateValue} onChange={handleChange} />`}
      />
    </LayoutPartials.BodyContent>
  );
};

import { Common, Icon } from "@/components";
import { Timeline } from "@/components/common/Timeline/TimelineModel";

const Index = () => {
  const timelines: Timeline[] = [
    {
      timestamp: "2022-02-04 02:50:55",
      title: "Account Coordinator",
      description:
        "vestibulum eget vulputate ut ultrices vel augue vestibulum ante ipsum primis in faucibus",
    },
    {
      timestamp: "2022-04-18 04:33:54",
      title: "Quality Control Specialist",
      description:
        "mauris non ligula pellentesque ultrices phasellus id sapien in sapien iaculis congue vivamus metus arcu adipiscing molestie hendrerit at vulputate",
    },
    {
      timestamp: "2022-11-04 06:11:34",
      title: "Junior Executive",
      description:
        "phasellus sit amet erat nulla tempus vivamus in felis eu sapien cursus vestibulum proin",
    },
    {
      timestamp: "2022-08-26 17:21:03",
      title: "Desktop Support Technician",
      description:
        "cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus mus vivamus",
    },
    {
      timestamp: "2022-08-05 19:53:28",
      title: "Quality Engineer",
      description:
        "tortor id nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie",
    },
    {
      timestamp: "2021-12-05 10:50:16",
      title: "Senior Cost Accountant",
      description:
        "ligula pellentesque ultrices phasellus id sapien in sapien iaculis congue vivamus metus arcu adipiscing molestie hendrerit at vulputate vitae",
    },
    {
      timestamp: "2022-01-10 16:58:21",
      title: "Developer II",
      description:
        "odio elementum eu interdum eu tincidunt in leo maecenas pulvinar",
    },
    {
      timestamp: "2022-07-26 20:28:18",
      title: "Accounting Assistant II",
      description:
        "eu massa donec dapibus duis at velit eu est congue elementum in hac habitasse platea dictumst",
    },
    {
      timestamp: "2021-12-04 21:26:26",
      title: "Research Nurse",
      description:
        "venenatis lacinia aenean sit amet justo morbi ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo in",
    },
    {
      timestamp: "2021-12-25 02:03:44",
      title: "Programmer III",
      description:
        "condimentum neque sapien placerat ante nulla justo aliquam quis turpis eget elit sodales scelerisque mauris sit",
    },
    {
      timestamp: "2022-04-22 20:29:41",
      title: "Food Chemist",
      description:
        "at nunc commodo placerat praesent blandit nam nulla integer pede justo lacinia eget tincidunt eget tempus vel pede morbi",
    },
    {
      timestamp: "2022-09-11 19:48:32",
      title: "Actuary",
      description:
        "nec molestie sed justo pellentesque viverra pede ac diam cras pellentesque volutpat dui maecenas tristique est et tempus",
    },
    {
      timestamp: "2022-06-06 22:04:55",
      title: "Help Desk Operator",
      description:
        "at turpis a pede posuere nonummy integer non velit donec diam neque vestibulum eget vulputate ut",
    },
    {
      timestamp: "2022-05-17 15:14:22",
      title: "Senior Quality Engineer",
      description:
        "mauris lacinia sapien quis libero nullam sit amet turpis elementum ligula vehicula consequat morbi a",
    },
    {
      timestamp: "2022-10-16 02:41:23",
      title: "Professor",
      description:
        "ultrices libero non mattis pulvinar nulla pede ullamcorper augue a suscipit nulla elit ac nulla sed vel enim",
    },
    {
      timestamp: "2022-10-12 09:58:33",
      title: "Financial Analyst",
      description:
        "pellentesque ultrices phasellus id sapien in sapien iaculis congue vivamus metus arcu adipiscing molestie hendrerit at vulputate",
    },
    {
      timestamp: "2022-07-28 06:06:22",
      title: "VP Marketing",
      description:
        "id ligula suspendisse ornare consequat lectus in est risus auctor sed tristique in tempus sit",
    },
    {
      timestamp: "2022-09-09 13:44:35",
      title: "Recruiter",
      description:
        "accumsan tellus nisi eu orci mauris lacinia sapien quis libero nullam sit amet turpis elementum ligula vehicula consequat",
    },
    {
      timestamp: "2022-06-02 19:21:13",
      title: "VP Quality Control",
      description:
        "est risus auctor sed tristique in tempus sit amet sem fusce consequat nulla nisl",
    },
    {
      timestamp: "2022-11-24 15:12:50",
      title: "Web Designer IV",
      description:
        "ut odio cras mi pede malesuada in imperdiet et commodo vulputate justo in",
    },
  ];
  return (
    <div className="p-4 bg-white rounded shadow">
      <div className="grid grid-cols-1 lg:grid-cols-3">
        <div className="mb-4">
          <div className="font-bold">Timeline Basic</div>
          <Common.Timeline timelines={timelines} />
        </div>
        <div className="mb-4">
          <div className="font-bold mb-4">Timeline Primary</div>
          <Common.Timeline
            timelines={timelines}
            timestampClass="text-blue-60 hover:text-blue-70 focus:text-blue-80 duration-300 transition ease-in-out text-sm"
            nodeStyle="bg-blue-70"
            nodeSize="md"
            connectorColor="blue-80"
          />
        </div>
        <div className="mb-4">
          <div className="font-bold">Timeline With Icons</div>
          <Common.Timeline
            timelines={timelines}
            titleClass="text-blue hover:text-blue-70 focus:text-blue-80 duration-300 transition ease-in-out"
            timestampClass="text-blue-60 hover:text-blue-70 focus:text-blue-80 duration-300 transition ease-in-out text-sm"
            nodeStyle="bg-blue"
            nodeIcon={Icon.Calendar}
            connectorColor="blue"
          />
        </div>
      </div>
    </div>
  );
};

export default Index;

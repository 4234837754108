import { useEffect, useState } from "react";

import { Common, CustomScrollbar, Icon } from "@/components";
import { holidaySchedulesType } from "../models";
import HolidayScheduleDetail from "./HolidayScheduleDetail";
import clsx from "clsx";
import moment from "moment";

type Props = {
  holidaySchedule: holidaySchedulesType[];
  countAffectedDocuments?: boolean;
};

const LIST_ACTION_COLLAPSE = [
  {
    id: 1,
    name: "Expand",
    icon: <Icon.ExpandAngle className="fill-neutral-50" />,
  },
  {
    id: 2,
    name: "Collapse",
    icon: <Icon.CollapseAngle className="fill-neutral-50" />,
  },
];

export const TabHolidayScheduleDetail = ({
  holidaySchedule = [],
}: // countAffectedDocuments,
Props) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [listDeliveryHolidaySchedule, setListDeliveryHolidaySchedule] =
    useState<holidaySchedulesType[]>([]);
  const [statusCollapse, setStatusCollapse] = useState<string>("expand");

  useEffect(() => {
    handleSortHolidaySchedule(holidaySchedule);
  }, [holidaySchedule]);

  useEffect(() => {
    let listMatching;
    if (searchValue) {
      listMatching = holidaySchedule.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else {
      listMatching = [...holidaySchedule];
    }
    setListDeliveryHolidaySchedule(listMatching);
  }, [searchValue]);

  const handleSortHolidaySchedule = (
    holidaySchedule: holidaySchedulesType[]
  ) => {
    const list = holidaySchedule.sort((a, b) => {
      if (moment(a.data.dateFrom).isSameOrBefore(moment(b.data.dateFrom))) {
        return -1;
      } else {
        return 1;
      }
    });
    setListDeliveryHolidaySchedule(list);
  };

  const handleSearchKeyWord = (value: string) => {
    setSearchValue(value);
  };

  if (holidaySchedule.length === 0) {
    return (
      <div
        className={clsx(
          "mt-4 flex flex-col justify-center items-center h-full"
          // countAffectedDocuments ? "" : "h-[calc(100vh-22.5rem)]"
        )}
      >
        <Common.NoData
          iconRender={Icon.Schedule}
          title="You haven't created any new holiday schedule yet"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between">
        <Common.Input
          wrapClassName="w-80 mr-3"
          className="bg-neutral-5"
          iconRight={Icon.Search}
          iconType="stroke"
          placeholder="Type to filter schedule ..."
          value={searchValue}
          onChange={(e: any) => handleSearchKeyWord(e.target.value)}
          onClear={() => handleSearchKeyWord("")}
        />
        <div className="select-none relative inline-flex items-center justify-center border border-line bg-white font-medium text-neutral-60 hover:bg-blue-10 hover:text-blue group w-9 h-9 rounded cursor-pointer">
          <div>
            <Common.DropDowns
              buttonRender={
                <div className="w-6 h-6 inline-flex items-center justify-center">
                  <Icon.SeeMore className="fill-neutral-50" />
                </div>
              }
              listRender={
                <div className="py-0">
                  {LIST_ACTION_COLLAPSE.map((option) => {
                    return (
                      <Common.DropdownItem
                        key={option.id}
                        onClick={() => setStatusCollapse(option.name)}
                        // className={}
                      >
                        <span className="mr-2">{option.icon}</span>
                        <span className="text-neutral">{option.name} all</span>
                      </Common.DropdownItem>
                    );
                  })}
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className="flex-1">
        <CustomScrollbar renderThumbVertical={() => <div />}>
          {listDeliveryHolidaySchedule.length === 0 ? (
            <div className="flex justify-center items-center">
              <Common.NoData
                iconRender={Icon.Search}
                iconClassName="stroke-neutral-30"
                title="No result founded"
              />
            </div>
          ) : (
            listDeliveryHolidaySchedule.map((item) => {
              return (
                <HolidayScheduleDetail
                  key={item.id}
                  holidayDetail={item}
                  status={statusCollapse}
                />
              );
            })
          )}
        </CustomScrollbar>
      </div>
    </div>
  );
};

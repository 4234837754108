import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="7.25" strokeWidth="1.5" fill="none" />
      <mask id="path-2-inside-1_6128_233965" fill="white">
        <path d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.44769 8C1.44769 11.6187 4.38126 14.5523 8 14.5523C11.6187 14.5523 14.5523 11.6187 14.5523 8C14.5523 4.38126 11.6187 1.44769 8 1.44769C4.38126 1.44769 1.44769 4.38126 1.44769 8Z" />
      </mask>
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.44769 8C1.44769 11.6187 4.38126 14.5523 8 14.5523C11.6187 14.5523 14.5523 11.6187 14.5523 8C14.5523 4.38126 11.6187 1.44769 8 1.44769C4.38126 1.44769 1.44769 4.38126 1.44769 8Z"
        stroke="#0099CC"
        strokeWidth="3"
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_6128_233965)"
      />
      <path
        d="M11.7133 5.9116L10.8383 5.0366C10.8149 5.01316 10.7831 5 10.75 5C10.7168 5 10.685 5.01316 10.6616 5.0366L6.87497 8.82322L5.33835 7.2866C5.31491 7.26316 5.28312 7.25 5.24997 7.25C5.21683 7.25 5.18504 7.26316 5.1616 7.2866L4.2866 8.1616C4.26316 8.18504 4.25 8.21683 4.25 8.24997C4.25 8.28312 4.26316 8.31491 4.2866 8.33835L6.7866 10.8383C6.81004 10.8618 6.84183 10.8749 6.87497 10.8749C6.90812 10.8749 6.93991 10.8618 6.96335 10.8383L11.7133 6.08835C11.7368 6.06491 11.7499 6.03312 11.7499 5.99997C11.7499 5.96683 11.7368 5.93504 11.7133 5.9116V5.9116Z"
        fill="#0099CC"
      />
    </SvgIcon>
  );
}

export default IconComponent;

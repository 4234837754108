import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00491 8.9791H7.95793H7.91037C3.89679 9.0052 0.607041 12.3286 0.400561 16.5179C0.393021 16.6762 0.450438 16.8311 0.560057 16.9465C0.669097 17.0614 0.821056 17.1263 0.979975 17.1263H14.9353H14.9428C15.0989 17.1246 15.2479 17.0596 15.3558 16.9465C15.4648 16.8311 15.5223 16.6762 15.5147 16.5179C15.3082 12.3286 12.0185 9.0052 8.00491 8.9791ZM12.1705 8.95241C14.3286 10.1988 15.9352 12.3726 16.4816 14.9705H23.0199C23.1788 14.9705 23.3308 14.9055 23.4398 14.7907C23.5494 14.6752 23.6068 14.5204 23.5993 14.362C23.4062 10.4447 20.3177 7.3406 16.5587 7.3406C14.9017 7.3406 13.3763 7.94264 12.1705 8.95241ZM7.95793 1.41187C6.03118 1.41187 4.4675 2.9761 4.4675 4.90227C4.4675 6.82902 6.03118 8.3927 7.95793 8.3927C9.88411 8.3927 11.4484 6.82902 11.4484 4.90227C11.4484 2.9761 9.88411 1.41187 7.95793 1.41187ZM16.5587 0.873047C15.049 0.873047 13.8229 2.09915 13.8229 3.60888C13.8229 5.1192 15.049 6.34472 16.5587 6.34472C18.069 6.34472 19.2946 5.1192 19.2946 3.60888C19.2946 2.09915 18.069 0.873047 16.5587 0.873047Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

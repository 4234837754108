import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath={`url(#clip${uid})`}>
        <path d="M3.35447 0.113046L0.0524006 4.81098C-0.00705953 4.89423 -0.0148133 5.00379 0.0323296 5.09459C0.0794725 5.18538 0.173548 5.24207 0.275849 5.24133H1.93102V6.06891C1.93056 8.12377 2.81753 10.0787 4.36412 11.4317L5.04274 12.0248C5.87027 12.7497 6.34484 13.7964 6.34481 14.8965V15.7241C6.34481 15.8764 6.46832 16 6.62067 16H9.37929C9.53165 16 9.65515 15.8764 9.65515 15.7241V14.8965C9.65003 12.8429 8.76406 10.8901 7.22205 9.53374L6.54343 8.94064C5.7159 8.21573 5.24133 7.16905 5.24136 6.06891V5.24133H6.89654C6.99884 5.24207 7.09291 5.18538 7.14005 5.09459C7.1872 5.00379 7.17944 4.89423 7.11998 4.81098L3.80136 0.113046C3.74937 0.0415605 3.66631 -0.000732422 3.57792 -0.000732422C3.48952 -0.000732422 3.40646 0.0415605 3.35447 0.113046V0.113046Z" />
        <path d="M8.82749 4.96821C8.82778 5.04103 8.85703 5.11075 8.90878 5.16198C8.96053 5.21321 9.03053 5.24176 9.10335 5.24132H10.7585V6.0689C10.7586 7.16904 10.284 8.21571 9.45645 8.94062L8.77783 9.53373C8.63714 9.65786 8.50197 9.78752 8.37231 9.91993C8.38887 9.942 8.40818 9.96131 8.42473 9.98338C9.21487 10.9288 9.76474 12.0513 10.0275 13.2551C10.2493 12.7853 10.5658 12.3665 10.9571 12.0248L11.6358 11.4317C13.1824 10.0787 14.0693 8.12376 14.0689 6.0689V5.24132H15.7241C15.8264 5.24206 15.9204 5.18537 15.9676 5.09458C16.0147 5.00378 16.007 4.89422 15.9475 4.81097L12.6289 0.113046C12.5769 0.0415604 12.4938 -0.000732422 12.4054 -0.000732422C12.317 -0.000732422 12.234 0.0415604 12.182 0.113046L8.8799 4.81097C8.84631 4.85656 8.82797 4.91159 8.82749 4.96821V4.96821Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

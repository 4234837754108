import { SvgIcon } from "./SvgIcon";
import { v4 as uuidv4 } from "uuid";

function IconComponent(props: any) {
  const uid = uuidv4();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <g clipPath={`url(#clip${uid})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4 1.54834H13.2333C12.681 1.54834 12.2333 1.99606 12.2333 2.54834V3.49995C12.2333 4.05224 11.7856 4.49995 11.2333 4.49995H4.76665C4.21437 4.49995 3.76665 4.05224 3.76665 3.49995V2.54834C3.76665 1.99606 3.31893 1.54834 2.76665 1.54834H1.59998C1.30532 1.54834 1.06665 1.77931 1.06665 2.06447V15.4838C1.06665 15.769 1.30532 16 1.59998 16H14.4C14.6947 16 14.9333 15.769 14.9333 15.4838V2.06447C14.9333 1.77931 14.6947 1.54834 14.4 1.54834ZM4 7.49995H12V9.49995H4V7.49995ZM12 11.5H4V13.5H12V11.5Z"
        />
        <path d="M9.60005 1.54839C9.60005 0.693161 8.88378 0 8.00005 0C7.11632 0 6.40005 0.693161 6.40005 1.54839H4.80005V3.35484C4.80005 3.49729 4.91952 3.6129 5.06672 3.6129H10.9334C11.0806 3.6129 11.2 3.49729 11.2 3.35484V1.54839H9.60005ZM8.00005 2.06452C7.70538 2.06452 7.46672 1.83355 7.46672 1.54839C7.46672 1.26323 7.70538 1.03226 8.00005 1.03226C8.29472 1.03226 8.53338 1.26323 8.53338 1.54839C8.53338 1.83355 8.29472 2.06452 8.00005 2.06452Z" />
      </g>
      <defs>
        <clipPath id={`clip${uid}`}>
          <rect width="16" height="16" fill="none" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default IconComponent;

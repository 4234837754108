import { Fragment, FC } from "react";
import clsx from "clsx";
import { Switch } from "@headlessui/react";

interface Props {
  checked: boolean;
  className?: string;
  label?: string;
  labelPosition?: "front" | "back";
  name?: string;
  value?: any;
  onChange?: () => void;
}
const SwitchToggle: FC<Props> = ({
  checked,
  className,
  label,
  labelPosition = "front",
  name,
  value,
  onChange,
  ...rest
}: Props) => {
  return (
    <Switch.Group {...rest}>
      <div className={clsx("flex items-center", className)}>
        {!!label && labelPosition === "front" && (
          <Switch.Label className="mr-2">{label}</Switch.Label>
        )}

        <Switch
          checked={checked}
          onChange={onChange}
          as={Fragment}
          name={name}
          value={value}
        >
          {({ checked }) => (
            /* Use the `checked` state to conditionally style the button. */
            <button
              className={`${
                checked ? "bg-blue-70" : "bg-neutral-20"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span
                className={`${
                  checked ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </button>
          )}
        </Switch>

        {!!label && labelPosition === "back" && (
          <Switch.Label className="ml-2">{label}</Switch.Label>
        )}
      </div>
    </Switch.Group>
  );
};

export default SwitchToggle;

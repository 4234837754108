import clsx from "clsx";
import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon
      {...props}
      className={clsx(
        `fill-${props.color || "blue"} animate-spin`,
        props.className
      )}
      viewBox="0 0 40 40"
    >
      <circle
        cx="20"
        cy="20"
        r="18.3333"
        stroke="#F1F5F9"
        strokeWidth="3.33333"
        fill="transparent"
      />
      <mask id="path-2-inside-1_6613_259860" fill="white">
        <path d="M19.1456 1.71995C19.1018 0.782094 19.8276 -0.0212859 20.7658 0.0146619C23.3415 0.113353 25.8794 0.709296 28.238 1.7754C31.114 3.07543 33.6415 5.03768 35.6139 7.50171C37.5862 9.96574 38.9474 12.8616 39.5862 15.9525C40.11 18.4872 40.1357 21.0941 39.668 23.6289C39.4977 24.5522 38.5549 25.0844 37.6494 24.8363C36.7439 24.5882 36.2202 23.6535 36.3744 22.7273C36.7099 20.7133 36.6717 18.6496 36.2565 16.6406C35.7264 14.0751 34.5966 11.6716 32.9595 9.62642C31.3224 7.58127 29.2246 5.95261 26.8375 4.87358C24.9681 4.02857 22.9629 3.53943 20.9243 3.42575C19.9868 3.37347 19.1895 2.65781 19.1456 1.71995Z" />
      </mask>
      <path
        d="M19.1456 1.71995C19.1018 0.782094 19.8276 -0.0212859 20.7658 0.0146619C23.3415 0.113353 25.8794 0.709296 28.238 1.7754C31.114 3.07543 33.6415 5.03768 35.6139 7.50171C37.5862 9.96574 38.9474 12.8616 39.5862 15.9525C40.11 18.4872 40.1357 21.0941 39.668 23.6289C39.4977 24.5522 38.5549 25.0844 37.6494 24.8363C36.7439 24.5882 36.2202 23.6535 36.3744 22.7273C36.7099 20.7133 36.6717 18.6496 36.2565 16.6406C35.7264 14.0751 34.5966 11.6716 32.9595 9.62642C31.3224 7.58127 29.2246 5.95261 26.8375 4.87358C24.9681 4.02857 22.9629 3.53943 20.9243 3.42575C19.9868 3.37347 19.1895 2.65781 19.1456 1.71995Z"
        stroke="rgba(255, 255, 255, .4)"
        strokeWidth="5"
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_6613_259860)"
      />
    </SvgIcon>
  );
}

export default IconComponent;

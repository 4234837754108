import { Common, Icon } from "@/components";
import { DisplayComponent, ComponentInfo } from "../../components";

const Index = () => {
  return (
    <div className="p-4 bg-white rounded shadow">
      <div className="text-hd5 font-medium pb-4 mb-4 shadow-underline">
        Alert components
      </div>

      <ComponentInfo
        title="Import component"
        code={`import { Common } from "@/components";

<Common.Alert>Allert message</Common.Alert>`}
      />
      <ComponentInfo
        title="Props"
        code={`interface Props {
  iconRender?: React.ReactNode;
  alertType?: "error" | "warning" | "info" | "success";
  className?: string;
  children: React.ReactNode;
}`}
      />
      <ComponentInfo
        title="Default Props"
        code={`{
  iconRender,
  children,
  alertType = "error",
  className,
}`}
      />
      <div>
        <DisplayComponent
          componentName="Error alert (Default):"
          RenderComp={() => (
            <Common.Alert>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
              impedit praesentium ratione rerum earum perferendis aperiam
              provident ea dicta numquam expedita accusantium animi velit,
              cupiditate fugit ad unde molestias. A?
            </Common.Alert>
          )}
          code={`<Common.Alert>
  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
  impedit praesentium ratione rerum earum perferendis aperiam
  provident ea dicta numquam expedita accusantium animi velit,
  cupiditate fugit ad unde molestias. A?
</Common.Alert>`}
        />

        <DisplayComponent
          componentName="Warning alert (Default):"
          RenderComp={() => (
            <Common.Alert
              alertType="warning"
              iconRender={
                <Icon.Alert size="30" className="fill-status-warning" />
              }
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
              impedit praesentium ratione rerum earum perferendis aperiam
              provident ea dicta numquam expedita accusantium animi velit,
              cupiditate fugit ad unde molestias. A?
            </Common.Alert>
          )}
          code={`<Common.Alert
  alertType="warning"
  iconRender={
    <Icon.Alert size="30" className="fill-staus-warning" />
  }
>
  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
  impedit praesentium ratione rerum earum perferendis aperiam
  provident ea dicta numquam expedita accusantium animi velit,
  cupiditate fugit ad unde molestias. A?
</Common.Alert>`}
        />

        <DisplayComponent
          componentName="Info alert (Default):"
          RenderComp={() => (
            <Common.Alert
              alertType="info"
              iconRender={
                <Icon.InfoFill size="30" className="fill-status-info" />
              }
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
              impedit praesentium ratione rerum earum perferendis aperiam
              provident ea dicta numquam expedita accusantium animi velit,
              cupiditate fugit ad unde molestias. A?
            </Common.Alert>
          )}
          code={`<Common.Alert
  alertType="info"
  iconRender={
    <Icon.InfoFill size="30" className="fill-status-info" />
  }
>
  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
  impedit praesentium ratione rerum earum perferendis aperiam
  provident ea dicta numquam expedita accusantium animi velit,
  cupiditate fugit ad unde molestias. A?
</Common.Alert>`}
        />

        <DisplayComponent
          componentName="Success alert (Default):"
          RenderComp={() => (
            <Common.Alert
              alertType="success"
              iconRender={
                <Icon.CheckedFill size="30" className="fill-status-success" />
              }
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
              impedit praesentium ratione rerum earum perferendis aperiam
              provident ea dicta numquam expedita accusantium animi velit,
              cupiditate fugit ad unde molestias. A?
            </Common.Alert>
          )}
          code={`<Common.Alert
  alertType="success"
  iconRender={
    <Icon.CheckedFill size="30" className="fill-status-success" />
  }
>
  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
  impedit praesentium ratione rerum earum perferendis aperiam
  provident ea dicta numquam expedita accusantium animi velit,
  cupiditate fugit ad unde molestias. A?
</Common.Alert>`}
        />
      </div>
    </div>
  );
};

export default Index;

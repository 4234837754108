import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import base64 from "base-64";
import { Icon } from "@/components";
import { useTransferLogin } from "@/services/authentication";

const Index = () => {
  const transferLogin = useTransferLogin();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const path = params.get("path");

  useEffect(() => {
    if (path) {
      const pathDecode = base64.decode(path);
      transferLogin.mutate({ path: pathDecode });
    } else {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full h-[calc(100vh-6.25rem)] flex flex-col items-center justify-center">
      {/* Transfering... */}
      <Icon.Loading size="36" />
    </div>
  );
};

export default Index;

import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactTooltip from "react-tooltip";
import { store } from "@/sharedStore/store";
// import setupAxiosInterceptors from "@/configuration/axiosConfig";
import { AxiosInterceptor } from "@/configuration/axiosConfig";
import { SnackbarUtilsConfigurator } from "@/utils";
import "@/assets/styles/App.css";
import "@/assets/styles/react-datepicker.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// setup Axios Interceptors
// setupAxiosInterceptors();
AxiosInterceptor();

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    {/* <React.StrictMode> */}
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <Provider store={store}>
        <HashRouter>
          {/* <AxiosInterceptor> */}
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <SnackbarUtilsConfigurator />
            <App />
          </SnackbarProvider>
          {/* </AxiosInterceptor> */}
        </HashRouter>
      </Provider>
    </QueryClientProvider>
    {/* </React.StrictMode> */}
    <ReactTooltip
      effect="solid"
      multiline={true}
      backgroundColor={"var(--color-neutral-60)"}
      className="!p-2 !opacity-100"
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

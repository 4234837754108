import { useState } from "react";
import BootstrapTable, {
  ColumnDescription,
  PaginationOptions,
  SortOrder,
  TableChangeHandler,
} from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import overlayFactory from "react-bootstrap-table2-overlay-esm";
import { Common, CustomScrollbar, Icon, Pagination } from "@/components";
import ListBoxOption from "@/components/common/ListBox/ListBoxOption";
import { Product, TableProps } from ".";

interface TablePaginationProps {
  headerClasses: string;
  cellClasses: string;
  products: Product[];
  defaultPageSize: number;
}

const Index = (props: TablePaginationProps) => {
  const { headerClasses, cellClasses, products, defaultPageSize } = props;

  const [tableProps, setTableProps] = useState<TableProps>({
    page: 1,
    loading: false,
    data: products.slice(0, defaultPageSize),
    sizePerPage: defaultPageSize,
  });

  const paginationOptions: PaginationOptions = {
    custom: true,
    page: tableProps.page,
    sizePerPage: tableProps.sizePerPage,
    totalSize: products.length,
    sizePerPageList: [10, 20, 50, 100],
  };

  const sortOption = {
    order: "asc" as SortOrder,
    sortCaret: (order: any) => {
      return (
        <>
          {order === "asc" && (
            <Icon.SortByDown size="6" className="fill-neutral-40" />
          )}
          {order === "desc" && (
            <Icon.SortByUp size="6" className="fill-neutral-40" />
          )}
          {!order && <Icon.SortBy size="12" className="fill-neutral-40" />}
        </>
      );
    },
  };

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    let result = products;
    const currentIndex = (page - 1) * sizePerPage;
    type ProductKey = keyof Product;

    // Handle column sort
    if (sortOrder === "asc") {
      result = result.sort((a, b) => {
        if (a[sortField as ProductKey] > b[sortField as ProductKey]) {
          return 1;
        } else if (b[sortField as ProductKey] > a[sortField as ProductKey]) {
          return -1;
        }
        return 0;
      });
    } else {
      result = result.sort((a, b) => {
        if (a[sortField as ProductKey] > b[sortField as ProductKey]) {
          return -1;
        } else if (b[sortField as ProductKey] > a[sortField as ProductKey]) {
          return 1;
        }
        return 0;
      });
    }

    // Call API in here
    setTimeout(() => {
      setTableProps({
        page,
        sizePerPage,
        loading: false,
        data: result.slice(currentIndex, currentIndex + sizePerPage),
      });
    }, 1000);

    setTableProps({ ...tableProps, loading: true });
  };

  const headerFormatter = (
    column: ColumnDescription,
    colIndex: number,
    components: { sortElement: JSX.Element; filterElement: JSX.Element }
  ) => {
    return (
      <div className="inline-flex items-center select-none">
        {column.text}
        <div className="ml-2">{components.sortElement}</div>
      </div>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: "id",
      text: "Product ID",
      headerClasses,
      classes: cellClasses,
      sort: true,
      headerFormatter,
    },
    {
      dataField: "name",
      text: "Product Name",
      headerClasses,
      classes: cellClasses,
      sort: true,
      headerFormatter,
    },
    {
      dataField: "price",
      text: "Product Price",
      headerClasses,
      classes: cellClasses,
      sort: true,
      headerFormatter,
    },
  ];

  const PageListRenderer = ({
    page,
    sizePerPage,
    onPageChange,
  }: PaginationOptions) => {
    return (
      <Pagination
        pageNumber={page || 1}
        pageSize={sizePerPage || defaultPageSize}
        total={products.length}
        totalPages={Math.ceil(
          products.length / (sizePerPage || defaultPageSize)
        )}
        pagerCenterPoint={1}
        startPage={1}
        onPageClick={(page) => {
          if (onPageChange) {
            onPageChange(page, sizePerPage || defaultPageSize);
          }
        }}
      />
    );
  };

  const SizePerPageRenderer = (props: {
    paginationOptions: PaginationOptions;
    alias?: JSX.Element;
  }) => {
    const { paginationOptions, alias: Alias } = props;
    const buttonRender = (
      <>
        <span className="block truncate">
          Show {paginationOptions.sizePerPage}
        </span>
        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <Icon.AngleDown
            className="stroke-neutral-60"
            aria-hidden="true"
            iconType="stroke"
            size={12}
          />
        </span>
      </>
    );
    const listRender = (
      <>
        {paginationOptions.sizePerPageList &&
          paginationOptions.sizePerPageList.map((option) => {
            const numberOption = option as number;
            return (
              <ListBoxOption
                key={numberOption}
                value={numberOption}
                content={(selected) => {
                  return (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {numberOption}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <Icon.Checked
                            iconType="fill"
                            className="h-5 w-5 fill-neutral-90"
                          />
                        </span>
                      ) : null}
                    </>
                  );
                }}
              ></ListBoxOption>
            );
          })}
      </>
    );
    return (
      <div className="flex items-center">
        <Common.ListBox
          className="mr-2"
          buttonRender={buttonRender}
          listRender={listRender}
          selected={paginationOptions.sizePerPage}
          onChange={(option: number) => {
            if (paginationOptions.onSizePerPageChange) {
              paginationOptions.onSizePerPageChange(option, 1);
            }
          }}
          showAbove
        />
        {Alias}
      </div>
    );
  };

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        // console.log({ paginationProps, paginationTableProps });
        paginationProps = {
          ...paginationProps,
          page: tableProps.page,
          sizePerPage: tableProps.sizePerPage,
        };
        return (
          <>
            {tableProps.loading && (
              <div className="absolute top-0 left-0 h-full w-full z-10 bg-black bg-opacity-30 flex justify-center items-center rounded select-none">
                <Icon.Loading size="28" />
              </div>
            )}
            <div className="h-[calc(100%-76px)]">
              <CustomScrollbar>
                <BootstrapTable
                  {...paginationTableProps}
                  remote
                  classes="relative w-full border border-line border-separate border-spacing-0 h-full"
                  rowClasses="hover:bg-neutral-5"
                  keyField="id"
                  data={tableProps.data}
                  columns={columns}
                  sort={sortOption}
                  onTableChange={onTableChange}
                />
              </CustomScrollbar>
            </div>

            <div className="flex lg:items-center flex-col lg:flex-row pt-2">
              <div className="flex items-center">
                <div className="mr-2">
                  <SizePerPageRenderer
                    paginationOptions={{ ...paginationProps }}
                    alias={
                      <>
                        of{" "}
                        <span className="font-medium mx-1">
                          {products.length}
                        </span>{" "}
                        products
                      </>
                    }
                  />
                </div>
              </div>
              <div className="mt-3 mx-auto lg:ml-auto lg:mt-0 lg:mr-0">
                <PageListRenderer {...paginationProps} />
              </div>
            </div>
          </>
        );
      }}
    </PaginationProvider>
  );
};
export default Index;

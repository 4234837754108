import { forwardRef } from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars";

interface Props extends ScrollbarProps {
  children: React.ReactNode;
}

// export const CustomScrollbar = forwardRef({ children, ...rest }: Props, ref) => {
//   return <Scrollbars {...rest}>{children}</Scrollbars>;
// };

export const CustomScrollbar = forwardRef(
  ({ children, ...rest }: Props, ref: React.Ref<Scrollbars>) => {
    return (
      <Scrollbars ref={ref} {...rest}>
        {children}
      </Scrollbars>
    );
  }
);

import { useEffect, useState } from "react";
import moment from "moment";
import { Icon } from "@/components";
import { DashboardTopItem } from "./DashboardTopItem";

// import { useAppSelector } from "@/sharedStore/hooks";
// import { userSelector } from "@/providers/auth/selector";

import { useGetTotalSpendingSummary } from "../services/dashboard";
import { TIME_FORMAT } from "@/configuration/globalVariable";

export const CUSDashboardTopTotalSpending = () => {
  const [dataRender, setDatarender] = useState({
    title: "total spending",
    count: 0,
    amount: 0,
    countSuffix: "Invoices",
    isLoading: true,
  });
  // const user = useAppSelector(userSelector);
  const fromDate = moment().subtract(30, "days").format(TIME_FORMAT.REQUEST);
  const toDate = moment(new Date()).format(TIME_FORMAT.REQUEST);

  const path = `/purchases/documents/invoices?supplierid=&number=&status=&fromdate=${fromDate}&todate=${toDate}&paymethod=&routename=&routeid=&rangefor=1&doctype=3%2c4`;

  const totalSpendingSummary = useGetTotalSpendingSummary();
  const { isLoading, isSuccess, data } = totalSpendingSummary;

  useEffect(() => {
    totalSpendingSummary.mutate({});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setDatarender({
        ...dataRender,
        isLoading: false,
        amount: data ? data[0]?.totalAmount : 0,
        count: data ? data[0]?.totalCount : 0,
      });
    }
    // eslint-disable-next-line
  }, [isSuccess]);

  return (
    <DashboardTopItem
      title={dataRender.title}
      note="(Last 30 days)"
      isLoading={isLoading}
      icon={
        <div>
          <Icon.CreditCard
            size="24"
            className="fill-status-warning hidden lg:block"
          />
          <Icon.CreditCard
            size="16"
            className="fill-status-warning lg:hidden"
          />
        </div>
      }
      count={dataRender.count}
      amount={dataRender.amount}
      countSuffix={dataRender.countSuffix}
      pathViewMore={path}
    />
  );
};

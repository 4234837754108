import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Common } from "@/components";
import { SnackbarUtils } from "@/utils";
import clsx from "clsx";

interface Props {
  componentName?: string;
  RenderComp: React.ElementType<any>;
  code?: string;
  horizontal?: boolean;
  className?: string;
}

export const DisplayComponent = ({
  componentName,
  RenderComp,
  code,
  horizontal,
  className,
}: Props) => {
  return (
    <div
      className={clsx(
        "mb-4 border border-neutral-20 rounded p-3 shadow-md",
        className
      )}
    >
      {componentName && (
        <div className="font-semibold pb-3 mb-3 border-b border-neutral-20">
          {componentName}
        </div>
      )}
      {horizontal ? (
        <div className="flex">
          <div className="w-1/2">
            <RenderComp />
          </div>
          {code && (
            <div className="w-1/2">
              <pre className="text-sm bg-neutral-10 p-4 rounded">{code}</pre>
            </div>
          )}
        </div>
      ) : (
        <>
          <RenderComp />
          {code && (
            <div className="mt-4 relative">
              <CopyToClipboard
                text={code}
                onCopy={() => {
                  SnackbarUtils.success(
                    `Copied <b>${componentName}</b> code success to clipboard.`
                  );
                }}
              >
                <Common.Button
                  className="absolute top-1 right-1"
                  btSize="sm"
                  outline
                >
                  Copy code
                </Common.Button>
              </CopyToClipboard>
              <pre className="text-sm bg-neutral-10 p-4 rounded">{code}</pre>
            </div>
          )}
        </>
      )}
    </div>
  );
};

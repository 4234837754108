import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  memo,
} from "react";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars";

import { Common, CustomScrollbar, Icon } from "@/components";
import ItemHolidayEdit from "./ItemHolidayEdit";
import {
  deliveryDisplayType,
  holidayDisplayType,
  holidaySchedulesType,
} from "../models";
import {
  validateAllHolidayDeliveryDateInRange,
  validateAllHolidayDuplicateDeliveryDate,
  validateAllHolidaySequenceDate,
  validateOverlapDateRange,
} from "../utils";
import { SnackbarUtils } from "@/utils";

const LIST_ACTION_COLLAPSE = [
  {
    id: 1,
    name: "Expand",
    icon: <Icon.ExpandAngle className="fill-neutral-50" />,
  },
  {
    id: 2,
    name: "Collapse",
    icon: <Icon.CollapseAngle className="fill-neutral-50" />,
  },
];

type Props = {
  listHolidays: holidaySchedulesType[];
  onSetEditedHoliday: (status: boolean) => void;
};

const emptyDeliveryDay: deliveryDisplayType = {
  date: "",
  despatchDate: "",
  orderDate: "",
  timeFrom: null,
  timeTo: null,
  cutOffTime: "11:59 PM",
  id: "",
  dateError: false,
  despatchDateError: false,
  orderDateError: false,
  cutoffTimeError: false,
  despatchDateErrorMsg: "",
  orderDateErrorMsg: "",
  dateErrorMsg: "",
};

const emptyHoliday: holidayDisplayType = {
  selectionType: 6,
  id: "",
  planId: "",
  name: "Untitled",
  description: "",
  validationMessage: "",
  isActivated: true,
  isBypassSupplier: true,
  priority: 8,
  timeZone: null,
  dateRangeError: false,
  dateRangeErrorMessage: "",
  dateRangeOverlap: false,
  data: {
    items: [{ id: uuidv4(), ...emptyDeliveryDay }],
    dateFrom: "",
    dateTo: "",
    timeFrom: null,
    timeTo: null,
    cutOffTime: null,
  },
  isValid: true,
};

const TabEditHolidaySchedule = forwardRef(
  ({ listHolidays, onSetEditedHoliday }: Props, ref) => {
    const scrollBarRef = React.useRef<Scrollbars | null>(null);

    const [statusCollapse, setStatusCollapse] = useState<string>("expand");
    const [holidayListDisplay, setHolidayListDisplay] = useState<
      holidayDisplayType[]
    >([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [showConflict, setShowConflict] = useState<boolean>(false);
    const [isEditedholidaySchedule, setEditedholidaySchedule] =
      useState<boolean>(false);
    const [newHolidayId, setNewHolidayId] = useState<string>("");

    useEffect(() => {
      resetData();
    }, [listHolidays]);

    useImperativeHandle(ref, () => {
      return {
        getHolidaySchedule,
        checkDataValid,
      };
    });

    const getHolidaySchedule = () => {
      const dataSave = holidayListDisplay.map((item) => {
        let listItem = item.data?.items ? [...item.data?.items] : [];

        listItem = listItem.filter((i) => !!i.date);

        listItem = listItem.map((i) => {
          delete i.cutoffTimeError;
          delete i.dateError;
          delete i.dateErrorMsg;
          delete i.dateRangeError;
          delete i.despatchDateError;
          delete i.despatchDateErrorMsg;
          delete i.orderDateError;
          delete i.orderDateErrorMsg;
          delete i.id;

          return {
            ...i,
            date: moment(i.date).format("YYYY-MM-DD"),
            despatchDate: moment(i.despatchDate || i.date).format("YYYY-MM-DD"),
            orderDate: moment(i.orderDate || i.despatchDate || i.date).format(
              "YYYY-MM-DD"
            ),
            cutOffTime: moment(i.cutOffTime, ["h:mm A"]).format("HH:mm:ss"),
          };
        });

        const holidaySchedule = {
          name: item.name,
          isActivated: item.isActivated,
          isBypassSupplier: item.isBypassSupplier,
          description: item.description,
          validationMessage: item.validationMessage,
          timeZone: null,
          selectionType: 6,
          priority: 8,
          data: {
            ...item.data,
            dateFrom: moment(item.data?.dateFrom || null).format("YYYY-MM-DD"),
            dateTo: moment(item.data?.dateTo || null).format("YYYY-MM-DD"),
            items: listItem,
          },
        };
        return holidaySchedule;
      });
      return dataSave;
    };

    const checkDataValid = () => {
      const listHolidayError = new Map();

      // check all date range
      const newList = holidayListDisplay.map((item) => {
        if (!(item.data?.dateFrom && item.data.dateTo)) {
          item.dateRangeError = true;
          item.dateRangeErrorMessage = "Date range is required";
          listHolidayError.set(item.id, ` ${item.name}`);
        }

        if (!item.name) {
          listHolidayError.set(item.id, "");
        }

        return { ...item };
      });

      // check any field error
      holidayListDisplay.forEach((item) => {
        return item.data?.items.forEach((i) => {
          if (
            i.dateError ||
            i.despatchDateError ||
            i.orderDateError ||
            i.dateRangeError ||
            i.cutoffTimeError
          ) {
            listHolidayError.set(item.id, ` ${item.name}`);
          }
        });
      });

      if (listHolidayError.size > 0) {
        setHolidayListDisplay(newList);
        SnackbarUtils.error(
          `<div>There is invalid data in schedule<b>${Array.from(
            listHolidayError.values()
          )
            .filter((item) => item !== "")
            .toString()}</b>. Please fix it before saving changes.<div>`
        );
      }

      return listHolidayError.size > 0;
    };

    const resetData = () => {
      const newList: holidayDisplayType[] = listHolidays.map((item) => {
        return {
          ...item,
          dateRangeError: false,
          isValid: true,
          dateRangeErrorMessage: "",
        };
      });
      setHolidayListDisplay(newList);
    };

    const addNewHoliday = () => {
      const newList = [...holidayListDisplay];
      const newIdHoliday = uuidv4();
      newList.unshift({ ...emptyHoliday, id: newIdHoliday });
      setHolidayListDisplay(newList);
      scrollBarRef.current?.scrollToTop();
      handleSetEdited();
      setNewHolidayId(newIdHoliday);
    };

    const deleteHoliday = (id: string) => {
      let newList = [...holidayListDisplay];
      const index = newList.findIndex((item) => item.id === id);
      if (index > -1) {
        newList.splice(index, 1);

        // validate overlap
        newList = validateOverlapDateRange(newList);

        // validate all holiday: delivery date in range
        newList = validateAllHolidayDeliveryDateInRange(newList);

        // validate all holiday: same delivery date
        newList = validateAllHolidayDuplicateDeliveryDate(newList);

        // validate all holiday: wrong sequence
        newList = validateAllHolidaySequenceDate(newList);

        // show all if no overlap
        const isOverLap = newList.some((item) => item.dateRangeOverlap);

        if (!isOverLap) {
          newList = newList.map((item) => {
            return { ...item, isValid: true };
          });
          setShowConflict(false);
        }
        setHolidayListDisplay(newList);
      }
      handleSetEdited();
    };

    const addDeliveryDay = (id: string) => {
      const newList = [...holidayListDisplay];

      const index = newList.findIndex((item) => item.id === id);
      if (index > -1) {
        const selectedHoliday = { ...newList[index] };
        const selectedData = { ...selectedHoliday.data };
        const selectedItems = selectedData.items ? [...selectedData.items] : [];

        newList[index] = {
          ...selectedHoliday,
          data: {
            ...selectedData,
            items: [...selectedItems, { ...emptyDeliveryDay, id: uuidv4() }],
          },
        };

        setHolidayListDisplay(newList);
      }
      handleSetEdited();
    };

    const deleteDeliveryDay = (holidayId: string, deliveyDayId: string) => {
      let newList = [...holidayListDisplay];
      const selectedHolidayIndex = newList.findIndex(
        (item) => item.id === holidayId
      );

      const selectedHoliday = { ...newList[selectedHolidayIndex] };
      const selectedData = { ...selectedHoliday.data };
      const selectedItems = selectedData.items ? [...selectedData.items] : [];

      const deliveryIndex = selectedItems.findIndex(
        (i) => i.id === deliveyDayId
      );
      selectedItems.splice(deliveryIndex, 1);

      newList[selectedHolidayIndex] = {
        ...selectedHoliday,
        data: { ...selectedData, items: selectedItems },
      };

      // validate overlap
      newList = validateOverlapDateRange(newList);

      // validate all holiday: delivery date in range
      newList = validateAllHolidayDeliveryDateInRange(newList);

      // validate all holiday: same delivery date
      newList = validateAllHolidayDuplicateDeliveryDate(newList);

      // validate all holiday: wrong sequence
      newList = validateAllHolidaySequenceDate(newList);

      setHolidayListDisplay(newList);
      handleSetEdited();
    };

    const updateNameHoliday = (holidayId: string, newName: string) => {
      const newList = [...holidayListDisplay];
      const selectedHolidayIndex = newList.findIndex(
        (item) => item.id === holidayId
      );
      if (selectedHolidayIndex > -1) {
        const newHoliday = { ...newList[selectedHolidayIndex] };
        newHoliday.name = newName;
        newList[selectedHolidayIndex] = newHoliday;
        setHolidayListDisplay(newList);
      }
      handleSetEdited();
    };

    const updateDetailholiday = (
      holidayId: string,
      newHoliday: holidayDisplayType
    ) => {
      let newList = [...holidayListDisplay];
      const index = newList.findIndex((item) => item.id === holidayId);
      newList[index] = newHoliday;

      // validate overlap
      newList = validateOverlapDateRange(newList);

      // validate all holiday: delivery date in range
      newList = validateAllHolidayDeliveryDateInRange(newList);

      // validate all holiday: same delivery date
      newList = validateAllHolidayDuplicateDeliveryDate(newList);

      // validate all holiday: wrong sequence
      newList = validateAllHolidaySequenceDate(newList);

      // show all if no overlap
      const isOverlap = newList.some((item) => item.dateRangeOverlap);
      if (!isOverlap) {
        newList = newList.map((item) => {
          return { ...item, isValid: true };
        });
        setShowConflict(false);
      }

      setHolidayListDisplay(newList);
      handleSetEdited();
    };

    const searchHolidaySchedule = (value: string) => {
      const trimValue = value.trim();
      setSearchValue(value);
      delaySearchKeywords(trimValue);
    };

    const delaySearchKeywords = debounce((newKeywords: string) => {
      const newList = [...holidayListDisplay];
      newList.forEach((item) => {
        item.isValid = (item.name || "")
          .toLowerCase()
          .includes(newKeywords.toLowerCase());
      });
      setHolidayListDisplay(newList);
    }, 700);

    const toggleConflictHoliday = () => {
      const newList: holidayDisplayType[] = holidayListDisplay.map((item) => {
        return {
          ...item,
          isValid: showConflict || item.dateRangeOverlap || false,
        };
      });

      setShowConflict(!showConflict);
      setHolidayListDisplay(newList);
    };

    const handleSetEdited = () => {
      if (!isEditedholidaySchedule) setEditedholidaySchedule(true);
      onSetEditedHoliday(true);
    };

    const isHasOverlap = holidayListDisplay.filter(
      (item) => item.dateRangeOverlap
    );

    return (
      <div className="border border-neutral-20 p-4 h-full flex flex-col mb-0">
        <div className="flex justify-between items-center mb-2">
          <div>
            <div className="w-[24.125rem] mr-auto">
              <Common.Input
                className="bg-neutral-10"
                iconRight={Icon.Search}
                iconType="stroke"
                placeholder="Search for schedule..."
                value={searchValue}
                onChange={(e) => {
                  searchHolidaySchedule(e.target.value);
                }}
                onClear={() => {
                  searchHolidaySchedule("");
                }}
              />
            </div>
          </div>
          <div className="inline-flex">
            <div className="mr-2">
              <Common.Button
                onClick={addNewHoliday}
                disabled={false}
                iconLeft={() => <Icon.PlusBorder className="fill-blue mr-3" />}
                iconType="stroke"
                outline
                color="blue"
              >
                Add holiday
              </Common.Button>
            </div>
            <div className="select-none relative inline-flex items-center justify-center border border-line bg-white font-medium text-neutral-60 hover:bg-blue-10 hover:text-blue group w-9 h-9 rounded cursor-pointer">
              <div>
                <Common.DropDowns
                  buttonRender={
                    <div className="w-6 h-6 inline-flex items-center justify-center">
                      <Icon.SeeMore className="fill-neutral-50" />
                    </div>
                  }
                  listRender={
                    <div className="py-0">
                      {LIST_ACTION_COLLAPSE.map((option) => {
                        return (
                          <Common.DropdownItem
                            key={option.id}
                            onClick={() => setStatusCollapse(option.name)}
                            // className={}
                          >
                            <span className="mr-2">{option.icon}</span>
                            <span className="text-neutral">
                              {option.name} all
                            </span>
                          </Common.DropdownItem>
                        );
                      })}
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* error overlap */}
        {isHasOverlap.length > 1 && (
          <div className="flex justify-between items-center border border-red rounded bg-red-light p-4 mb-2">
            <div className="flex justify-start items-center">
              <Icon.WarningCircle className="fill-red mr-3" size="30" />
              <span className="text-neutral font-semibold">
                There are {isHasOverlap.length} holiday schedule date ranges are
                conflicted. Please update holiday ranges to prevent conflicts.
              </span>
            </div>
            <Common.Button
              onClick={toggleConflictHoliday}
              disabled={false}
              iconType="stroke"
              outline
              color="blue"
              className="border border-blue text-blue font-medium"
            >
              {showConflict ? "Show all" : "Show conflicts"}
            </Common.Button>
          </div>
        )}

        {holidayListDisplay.length === 0 ? (
          <div className="flex flex-col justify-center items-center flex-1">
            <Common.NoData
              iconRender={Icon.Schedule}
              title="You haven't created any new holiday schedule yet"
            />
          </div>
        ) : (
          <div className="flex-1" id="holiday-edit">
            <CustomScrollbar
              renderThumbVertical={() => <div />}
              renderThumbHorizontal={() => <div />}
              ref={scrollBarRef}
            >
              {holidayListDisplay.map((item) => {
                return (
                  <ItemHolidayEdit
                    key={item.id}
                    holiday={item}
                    status={statusCollapse}
                    onChangeData={() => {}}
                    onDeleteHoliday={deleteHoliday}
                    onAddDeliveryDay={addDeliveryDay}
                    onDeleteDelivery={deleteDeliveryDay}
                    onEditName={updateNameHoliday}
                    onUpdateDetailHoliday={updateDetailholiday}
                    isFocus={newHolidayId === item.id}
                  />
                );
              })}
            </CustomScrollbar>
          </div>
        )}
      </div>
    );
  }
);

export default memo(TabEditHolidaySchedule);

import { Icon } from "@/components";
import clsx from "clsx";

interface Props {
  label?: string;
  isWarning?: boolean;
  errorMessage?: string;
  wrapClassName?: string;
  id?: string;
  isDisplayOnClear?: boolean;
  iconType?: "stroke" | "fill";
  iconRight?: React.ElementType;
  children: React.ReactNode;
  onClear?: () => void;
  iconClassName?: string;
}

export const WrapInput = ({
  label,
  errorMessage,
  isWarning,
  wrapClassName,
  iconType = "fill",
  iconRight: IconRight = undefined,
  id,
  children,
  isDisplayOnClear,
  onClear,
  iconClassName,
}: Props) => {
  return (
    <div className={clsx(wrapClassName)}>
      {Boolean(label) && (
        <label
          htmlFor={id}
          className="mb-1 block text-base font-semibold text-neutral-90"
        >
          {label}
        </label>
      )}
      <div className="relative">
        {children}
        {isDisplayOnClear && (
          <button
            onClick={onClear}
            className="absolute inset-y-0 right-8 flex items-center group z-50"
          >
            <Icon.Close
              size="12"
              className="stroke-neutral-40 group-hover:stroke-neutral-80"
            />
          </button>
        )}
        {IconRight && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <IconRight
              className={clsx(
                iconType === "fill" ? "fill-neutral-40" : "stroke-neutral-40",
                iconClassName
              )}
              size="16"
            />
          </div>
        )}
      </div>
      {Boolean(errorMessage) && (
        <p
          className={clsx(
            "mt-[0.375rem] text-sm text-red",
            isWarning ? "text-yellow-dark" : "text-red"
          )}
          id="email-error"
        >
          {errorMessage}
        </p>
      )}
    </div>
  );
};

import clsx from "clsx";
import { Icon } from "../Icons";

interface Props {
  iconRender?: React.ElementType;
  iconClassName?: string;
  wrapIconClassName?: string;
  title?: string;
  description?: string;
  bottomElement?: React.ReactNode;
}

export const NoData = ({
  iconRender: IconRender = undefined,
  title,
  description,
  bottomElement,
  iconClassName,
  wrapIconClassName,
}: Props) => {
  return (
    <div className="relative cursor-default select-none px-4 py-8 text-neutral-70 flex flex-col items-center justify-center text-center h-full w-full whitespace-normal">
      <div
        className={clsx(
          "w-[4.5rem] h-[4.5rem] rounded-full bg-neutral-10 flex items-center justify-center",
          wrapIconClassName
        )}
      >
        {IconRender ? (
          <IconRender
            size="32"
            className={clsx(iconClassName || "fill-neutral-30")}
          />
        ) : (
          <Icon.Box
            size="32"
            className={clsx(iconClassName || "fill-neutral-30")}
          />
        )}
      </div>
      <div className="font-semibold text-netral-50 text-[1rem] mt-2.5 w-full">
        {title}
      </div>
      {description && (
        <div className="text-netral-40 mt-3 w-full">{description}</div>
      )}
      {bottomElement}
    </div>
  );
};

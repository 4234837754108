import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9783 2.51519C11.8009 1.09844 10.5351 0 9 0C7.46488 0 6.1991 1.09844 6.0217 2.51518C3.51939 3.59568 1.77882 5.99576 1.77882 8.78307V13.5618L0.108566 16.7137C-0.227108 17.3471 0.256223 18.0952 1.00116 18.0952H6.17112C6.58289 19.2049 7.69397 20 9 20C10.306 20 11.4171 19.2049 11.8289 18.0952H16.9988C17.7438 18.0952 18.2271 17.3471 17.8914 16.7137L16.2212 13.5618V8.78307C16.2212 5.99576 14.4806 3.59568 11.9783 2.51519ZM14.3287 14.2177L15.3741 16.1905H2.62585L3.67127 14.2177C3.74179 14.0846 3.77853 13.9376 3.77853 13.7885V8.78307C3.77853 6.03626 6.11626 3.80952 9 3.80952C11.8837 3.80952 14.2215 6.03626 14.2215 8.78307V13.7885C14.2215 13.9376 14.2582 14.0846 14.3287 14.2177Z"
      />
    </SvgIcon>
  );
}

export default IconComponent;

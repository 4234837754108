import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M13.3333 9.59998H2.66663C2.37208 9.59998 2.1333 9.83876 2.1333 10.1333V15.4666C2.1333 15.7612 2.37208 16 2.66663 16H13.3333C13.6279 16 13.8666 15.7612 13.8666 15.4666V10.1333C13.8666 9.83876 13.6279 9.59998 13.3333 9.59998Z" />
      <path d="M15.4667 4.26672H0.533333C0.391885 4.26672 0.256229 4.32291 0.15621 4.42293C0.0561903 4.52295 0 4.65861 0 4.80006V12.2667C0 12.4082 0.0561903 12.5438 0.15621 12.6438C0.256229 12.7439 0.391885 12.8001 0.533333 12.8001H1.06667V10.1334C1.06667 9.70904 1.23524 9.30208 1.5353 9.00202C1.83535 8.70196 2.24232 8.53339 2.66667 8.53339H13.3333C13.7577 8.53339 14.1646 8.70196 14.4647 9.00202C14.7648 9.30208 14.9333 9.70904 14.9333 10.1334V12.8001H15.4667C15.6081 12.8001 15.7438 12.7439 15.8438 12.6438C15.9438 12.5438 16 12.4082 16 12.2667V4.80006C16 4.65861 15.9438 4.52295 15.8438 4.42293C15.7438 4.32291 15.6081 4.26672 15.4667 4.26672ZM14.4 6.40006H13.3333C13.1919 6.40006 13.0562 6.34387 12.9562 6.24385C12.8562 6.14383 12.8 6.00817 12.8 5.86672C12.8 5.72527 12.8562 5.58962 12.9562 5.4896C13.0562 5.38958 13.1919 5.33339 13.3333 5.33339H14.4C14.5414 5.33339 14.6771 5.38958 14.7771 5.4896C14.8771 5.58962 14.9333 5.72527 14.9333 5.86672C14.9333 6.00817 14.8771 6.14383 14.7771 6.24385C14.6771 6.34387 14.5414 6.40006 14.4 6.40006Z" />
      <path d="M13.8666 0.533333C13.8666 0.391885 13.8104 0.256229 13.7104 0.15621C13.6104 0.0561903 13.4748 0 13.3333 0H2.66663C2.52519 0 2.38953 0.0561903 2.28951 0.15621C2.18949 0.256229 2.1333 0.391885 2.1333 0.533333V3.2H13.8666V0.533333Z" />
    </SvgIcon>
  );
}

export default IconComponent;

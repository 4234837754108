import { ReactNode, useEffect, useState } from "react";

import { Images } from "@/assets/images";
import { Common, Icon } from "@/components";
import { useAppDispatch } from "@/sharedStore/hooks";
import { checkIsDeleteScheduleAsync } from "../providers/schedulePlanner/slice";

type Props = {
  scheduleName: string;
  scheduleId: string;
  customerAssigned: number;
  onCloseModel: () => void;
  onDeleteSchedule: () => void;
};

let timer: ReturnType<typeof setTimeout>;

const ModelConfirmDeleteSchedule = ({
  scheduleName,
  scheduleId,
  customerAssigned,
  onCloseModel,
  onDeleteSchedule,
}: Props) => {
  const dispatch = useAppDispatch();

  const [impossibleDelete, setImpossibleDelete] = useState<string>("");

  useEffect(() => {
    if (customerAssigned === 0) {
      checkIsPossibleDelete();
    } else {
      setImpossibleDelete("TOM");
    }
  }, [customerAssigned]);

  const checkIsPossibleDelete = async () => {
    const response = await dispatch(
      checkIsDeleteScheduleAsync(scheduleId || "")
    ).unwrap();

    setImpossibleDelete(response ? "PASS" : "ERP");
  };

  const title = impossibleDelete ? "Cannot delete this schedule" : "";

  let textDescription: ReactNode;
  switch (impossibleDelete) {
    case "TOM":
      textDescription = (
        <div>
          There is still at least one customer assigned to schedule{" "}
          <span className="font-semibold">{scheduleName}</span>. <br />
          Please move all customers to another schedule before deleting.
        </div>
      );
      break;
    case "ERP":
      textDescription = (
        <div>
          There is still at least one customer assigned to this customer in your
          TOM ERP system. Make sure you move all assigned customers to other
          schedule before deleting this.
        </div>
      );
      break;

    case "PASS":
      textDescription = (
        <div>
          Are you sure to delete this schedule{" "}
          <span className="font-semibold">{scheduleName}</span> ? This action
          cannot be undone.
        </div>
      );
      break;

    default:
      textDescription = <div />;
      break;
  }

  const handleDebounceDelete = () => {
    if (timer) clearTimeout(timer);

    timer = setTimeout(() => {
      onDeleteSchedule();
    }, 500);
  };

  const groupButton =
    impossibleDelete !== "PASS" ? (
      <Common.Button onClick={onCloseModel}>OK</Common.Button>
    ) : (
      <>
        <Common.Button
          outline
          color="gray"
          className="mr-3"
          onClick={onCloseModel}
        >
          Cancel
        </Common.Button>
        <Common.Button color="red" onClick={handleDebounceDelete}>
          Delete
        </Common.Button>
      </>
    );

  if (!impossibleDelete) return <Common.NoData iconRender={Icon.Loading} />;

  return (
    <div className="text-center py-10 px-20 text-neutral">
      <div className="mb-5">
        <img
          src={Images.IconStatus}
          alt="Icon Status"
          className="mx-auto mt-5"
        />
      </div>
      <div className="font-semibold text-hd4 leading-8 mb-2">{title}</div>
      {textDescription}
      <div className="mt-8">{groupButton}</div>
    </div>
  );
};

export default ModelConfirmDeleteSchedule;

import { useState, useCallback, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Common, CustomScrollbar, Icon, LayoutPartials } from "@/components";
import { ModalOptimizeConfigration } from "../components/ModalOptimizeConfigration";
import { ModalOptimizeDetail } from "../components/ModalOptimizeDetail";
import { RouteOptimizeItem } from "../components/RouteOptimizeItem";
import { useGetRouteById } from "../providers/deliveryManifest/hooks";
import { manifest, optimizeInfo, optimizeRequest } from "../models";
import { getRoutificDefaultSettings } from "../providers/deliveryManifest/sliceServices";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { PATH } from "@/configuration/globalVariable";
import {
  routificSettingsLoadedSelector,
  routificSettingsLoadingSelector,
  routificSettingsSelector,
} from "../providers/deliveryManifest/selector";
import { useOptimizeRequest } from "../services/deliveryOptimize";
import { SnackbarUtils } from "@/utils";
import { addOptimizeGroups } from "../providers/deliveryManifest/slice";

const Index = () => {
  const location = useLocation();
  const optimizeRoutes = (location.state as any)?.OptimizeRoutes || [];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const getRouteById = useGetRouteById();
  const optimizeRequest = useOptimizeRequest();
  const {
    data: optimizeRequestData,
    isLoading: optimizeRequestLoading,
    isSuccess: optimizeRequestSuccess,
  } = optimizeRequest;

  const routificSettings = useAppSelector(routificSettingsSelector);
  const routificSettingsLoading = useAppSelector(
    routificSettingsLoadingSelector
  );
  const routificSettingsLoaded = useAppSelector(routificSettingsLoadedSelector);
  const [isOpenOptimizeRouteDetailModal, setOpenOptimizeRouteDetailModal] =
    useState<manifest>();
  const [isOpenOptimizeConfigrationModal, setOpenOptimizeConfigrationModal] =
    useState(false);
  const [items, setItems] = useState<optimizeInfo[]>(optimizeRoutes);

  const handleChangeItem = useCallback(
    (data: optimizeInfo) => {
      setItems(items.map((i) => (i.id === data.id ? data : i)));
    },
    [items]
  );

  useEffect(() => {
    if (!optimizeRoutes.length) {
      navigate(`/${PATH.DELIVERY._}/${PATH.DELIVERY.DELIVERY_MANIFESTS}`, {
        replace: true,
      });
    }
    window.history.replaceState({}, document.title);
    dispatch(getRoutificDefaultSettings());
  }, []);

  const handleOptimize = () => {
    const data = {
      ...routificSettings,
      routes: items,
    } as optimizeRequest;
    optimizeRequest.mutate(data);
  };

  useEffect(() => {
    if (optimizeRequestSuccess) {
      if (optimizeRequestData.error) {
        SnackbarUtils.error(optimizeRequestData.error);
      } else {
        dispatch(addOptimizeGroups({ optimizeRequestData, items }));
        navigate(`/${PATH.DELIVERY._}/${PATH.DELIVERY.DELIVERY_MANIFESTS}`, {
          replace: true,
        });
      }
    }
  }, [optimizeRequestSuccess]);

  const filledItems = useMemo(
    () => items.filter((i) => !(i.workStartTime && i.workEndTime)),
    [items]
  );

  return (
    <>
      <LayoutPartials.BodyContent
        pageTitle={`Optimizing ${items.length} selected route${
          items.length > 1 ? "s" : ""
        }`}
        onTitleBack={() => navigate(-1)}
      >
        <div className="flex flex-col h-[calc(100vh-12.75rem)]">
          <CustomScrollbar>
            {items.map((item: optimizeInfo) => {
              const manifest = getRouteById(item.manifestId) as manifest;
              return (
                <RouteOptimizeItem
                  key={item.id}
                  openDetail={() => setOpenOptimizeRouteDetailModal(manifest)}
                  data={item}
                  manifest={manifest}
                  onItemChange={handleChangeItem}
                />
              );
            })}
          </CustomScrollbar>
          <div className="shadow-overline pt-3 flex space-x-2 items-center">
            <div className="flex-1 flex mr-4">
              {filledItems.length > 0 && (
                <>
                  <div className="mt-1">
                    <Icon.InfoFill size="16" className="fill-red" />
                  </div>
                  <div className="text-red ml-2">
                    You have {filledItems.length} route
                    {filledItems.length > 1 ? "s" : ""} need to fill working
                    time to optimize.
                  </div>
                </>
              )}
            </div>
            <div className="pr-1 !mr-3 border-r border-neutral-20">
              <Common.Button
                color="transparent"
                onClick={() => setOpenOptimizeConfigrationModal(true)}
                disabled={routificSettingsLoading && !routificSettingsLoaded}
              >
                <div className="text-blue font-semibold flex items-center">
                  {routificSettingsLoading && !routificSettingsLoaded ? (
                    <Icon.Loading className="mr-2" />
                  ) : (
                    <Icon.Settings className="fill-blue mr-2" />
                  )}
                  Set up configuration
                </div>
              </Common.Button>
            </div>
            <Common.Button
              onClick={handleOptimize}
              disabled={
                Boolean(filledItems.length) ||
                optimizeRequestLoading ||
                (routificSettingsLoading && !routificSettingsLoaded) ||
                (items.length === 1 && items[0]?.excludeOptimize)
              }
              isLoading={optimizeRequestLoading}
            >
              Optimize
            </Common.Button>
          </div>
        </div>
      </LayoutPartials.BodyContent>
      <ModalOptimizeDetail
        open={Boolean(isOpenOptimizeRouteDetailModal)}
        onClose={() => setOpenOptimizeRouteDetailModal(undefined)}
        data={isOpenOptimizeRouteDetailModal}
      />
      <ModalOptimizeConfigration
        open={isOpenOptimizeConfigrationModal}
        onClose={() => setOpenOptimizeConfigrationModal(false)}
      />
    </>
  );
};

export default Index;

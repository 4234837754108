import { useState, useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import {
  Common,
  CustomScrollbar,
  Icon,
  ModalContent,
  Table,
  Td,
  Th,
  // LoadingOverlay,
} from "@/components";
import {
  DeliveryScheduleDisplayType,
  scheduleDetail,
} from "../models/DeliverySchedule";
import { PATH } from "@/configuration/globalVariable";
import ModalEditNameSchedule from "./ModalEditNameSchedule";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import {
  dublicateScheduleAsync,
  deleteScheduleAsync,
} from "../providers/schedulePlanner/slice";
import { scheduleListLoading } from "../providers/schedulePlanner/selector";
import { dateScheduleFormat, SnackbarUtils } from "@/utils";
import ModelConfirmDeleteSchedule from "./ModelConfirmDeleteSchedule";

type Props = {
  listScheduleDisplay: DeliveryScheduleDisplayType[];
  onSetScheduleDefault: (item: DeliveryScheduleDisplayType) => void;
  onSortListSchedule: (
    type: "ASC" | "DESC" | undefined,
    column: string
  ) => void;
  onFetchScheduleList: () => void;
};

const HEADER_TD_CLASSNAME = "bg-neutral-10 border-t text-neutral-50";
const LIST_ACTION_SCHEDULE = [
  {
    id: 1,
    text: "Edit",
    icon: <Icon.Edit className="fill-neutral-50" />,
    highlight: false,
  },
  {
    id: 2,
    text: "Set as default",
    icon: <Icon.Default className="fill-neutral-50" />,
    highlight: false,
  },
  // {
  //   id: 3,
  //   text: "Assign",
  //   icon: <Icon.Assign className="fill-neutral-50" />,
  //   highlight: false,
  // },
  {
    id: 4,
    text: "Duplicate",
    icon: <Icon.Duplicate className="fill-neutral-50" />,
    highlight: false,
  },
  {
    id: 5,
    text: "Delete",
    icon: <Icon.Trash className="fill-red" />,
    highlight: true,
  },
];

const ScheduleList = ({
  listScheduleDisplay,
  onSetScheduleDefault,
  onSortListSchedule,
  onFetchScheduleList,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(scheduleListLoading);

  const [sort, setSort] = useState<{
    [Key: string]: "ASC" | "DESC" | undefined;
  }>({
    name: undefined,
    customerAssigned: undefined,
    updatedDate: undefined,
  });

  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [modelDuplicateSchedule, setModelDuplicateSchedule] =
    useState<boolean>(false);
  const [scheduleSelected, setScheduleSelected] =
    useState<DeliveryScheduleDisplayType | null>(null);
  const [modelDeleteSchedule, setModelDeleteSchedule] =
    useState<boolean>(false);

  useEffect(() => {
    if (listScheduleDisplay.length > 0 && firstTime) {
      handleSort("name");
      setFirstTime(false);
    }
  }, [listScheduleDisplay]);

  const selectOption = (
    optionId: number,
    schedule: DeliveryScheduleDisplayType
  ) => {
    switch (optionId) {
      case 1: {
        // edit
        setScheduleSelected(schedule);
        navigate(
          `/${PATH.SCHEDULE_PLANNER._}/${PATH.SCHEDULE_PLANNER.SCHEDULE_DETAIL}`,
          {
            state: { scheduleId: schedule.id, editMode: true },
          }
        );
        break;
      }

      case 2: {
        // set default
        onSetScheduleDefault(schedule);
        break;
      }

      case 4: {
        // duplicate
        setScheduleSelected(schedule);
        toggleModelDuplicateSchedule();
        break;
      }

      case 5: {
        // delete
        setScheduleSelected(schedule);
        toggleModelDeleteSchedule();
        break;
      }

      default:
        break;
    }
  };

  const handleSort = (column: string) => {
    const updateSort = { ...sort };

    Object.keys(updateSort).forEach((col) => {
      if (col === column) {
        updateSort[col] = ["DESC", undefined].includes(updateSort[col])
          ? "ASC"
          : "DESC";
        onSortListSchedule(updateSort[col], column);
      } else {
        updateSort[col] = undefined;
      }
    });
    setSort(updateSort);
  };

  const toggleModelDuplicateSchedule = () => {
    setModelDuplicateSchedule(!modelDuplicateSchedule);
  };

  const handleSubmitDuplicateSchedule = async (newValue: string) => {
    const response: scheduleDetail = await dispatch(
      dublicateScheduleAsync({
        name: newValue,
        scheduleId: scheduleSelected?.id || "",
      })
    ).unwrap();
    if (response.id) {
      toggleModelDuplicateSchedule();
      navigate(
        `/${PATH.SCHEDULE_PLANNER._}/${PATH.SCHEDULE_PLANNER.SCHEDULE_DETAIL}`,
        {
          state: { scheduleId: response.id, editMode: true },
        }
      );
      SnackbarUtils.success(
        `<div><Icon.CheckedFill /><b>${newValue}</b> has been created successfully.<div>`
      );
    }
  };

  const toggleModelDeleteSchedule = () => {
    setModelDeleteSchedule(!modelDeleteSchedule);
  };

  const handleDeleteSchedule = async () => {
    const response = await dispatch(
      deleteScheduleAsync(scheduleSelected?.id || "")
    ).unwrap();
    if (response) {
      toggleModelDeleteSchedule();
      onFetchScheduleList();
      SnackbarUtils.success(
        `<div><Icon.CheckedFill /><b>${scheduleSelected?.name}</b> has been deleted successfully.<div>`
      );
    }
  };

  if (loading) return <Common.NoData iconRender={Icon.Loading} />;

  return (
    <div className="mt-4 h-[calc(100vh-20.5rem)] border border-neutral-20">
      <CustomScrollbar>
        <Table className="relative w-full">
          <thead className="text-neutral-50">
            <tr>
              <Th
                className={clsx(`${HEADER_TD_CLASSNAME} `)}
                sort={sort.name}
                onSortClick={() => {
                  handleSort("name");
                }}
              >
                <span>NAME</span>
              </Th>
              <Th
                className={clsx(`${HEADER_TD_CLASSNAME}  text-right`)}
                sort={sort.customerAssigned}
                onSortClick={() => {
                  handleSort("customerAssigned");
                }}
              >
                <span>CUSTOMERS ASSIGNED</span>
              </Th>
              <Th
                className={clsx(`${HEADER_TD_CLASSNAME}  text-right`)}
                sort={sort.updatedDate}
                onSortClick={() => {
                  handleSort("updatedDate");
                }}
              >
                <span>LAST UPDATED</span>
              </Th>
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)} />
              <Th className={clsx(`${HEADER_TD_CLASSNAME}`)} />
            </tr>
          </thead>

          {listScheduleDisplay.length > 0 && (
            <tbody className="bg-white">
              {listScheduleDisplay.map((schedule) => {
                return (
                  <tr
                    className="text-base font-medium text-neutral-90 group hover:bg-neutral-5"
                    key={schedule.id}
                  >
                    <Td className="border-b border-neutral-20">
                      <div className="flex items center">
                        <span className="inline-block max-w-[40rem] truncate">
                          {schedule.name}
                        </span>
                        &nbsp;
                        {schedule.isDefault && (
                          <Common.Label size="md" color="blue">
                            Default
                          </Common.Label>
                        )}
                      </div>
                    </Td>
                    <Td className="text-right border-b border-neutral-20">
                      {schedule.customerAssigned || (
                        <span className="text-neutral-40">--</span>
                      )}
                    </Td>
                    <Td className="text-right border-b border-neutral-20">
                      {dateScheduleFormat(schedule.updatedDate) || (
                        <span className="text-neutral-40">--</span>
                      )}
                    </Td>
                    <Td className="text-right border-b border-neutral-20">
                      <Common.Button
                        onClick={() => {
                          navigate(
                            `/${PATH.SCHEDULE_PLANNER._}/${PATH.SCHEDULE_PLANNER.SCHEDULE_DETAIL}`,
                            {
                              state: { scheduleId: schedule.id },
                            }
                          );
                        }}
                        outline
                        btSize="md"
                        color="blue"
                        className="border-neutral-30"
                      >
                        View Details
                      </Common.Button>
                    </Td>
                    <Td className="text-center border-b border-neutral-20">
                      <Common.DropDowns
                        onOpen={() => {
                          // if (isOpen) {
                          //   const ele = document.getElementById(schedule.id);
                          //   if (ele) {
                          //     ele.scrollIntoView({ behavior: "smooth" });
                          //   }
                          // }
                        }}
                        id={schedule.id}
                        buttonRender={
                          <span className="inline-block">
                            <Icon.SeeMore className="fill-neutral-50" />
                          </span>
                        }
                        listRender={
                          <div className="py-0">
                            {LIST_ACTION_SCHEDULE.map((option) => {
                              const { id: itemId } = option;

                              const isDefaulScheduleBtnDelete =
                                schedule.isDefault &&
                                (option.text === "Delete" ||
                                  option.text === "Set as default");

                              return (
                                <Common.DropdownItem
                                  key={itemId}
                                  onClick={
                                    isDefaulScheduleBtnDelete
                                      ? () => undefined
                                      : () => selectOption(itemId, schedule)
                                  }
                                  className={clsx(
                                    `${
                                      isDefaulScheduleBtnDelete
                                        ? "cursor-default opacity-50"
                                        : ""
                                    }`
                                  )}
                                >
                                  <span className="mr-3">
                                    {isDefaulScheduleBtnDelete ? (
                                      <Icon.Trash className="fill-neutral-30" />
                                    ) : (
                                      option.icon
                                    )}
                                  </span>

                                  {isDefaulScheduleBtnDelete ? (
                                    <span
                                      className={clsx(
                                        `${
                                          option.highlight
                                            ? "text-neutral-40"
                                            : ""
                                        }`
                                      )}
                                      data-tip="Please set another schedule as <br /> default before deleting this one"
                                    >
                                      {option.text}
                                    </span>
                                  ) : (
                                    <span
                                      className={clsx(
                                        `${option.highlight ? "text-red" : ""}`
                                      )}
                                    >
                                      {option.text}
                                    </span>
                                  )}
                                </Common.DropdownItem>
                              );
                            })}
                          </div>
                        }
                      />
                    </Td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </Table>
        {listScheduleDisplay.length === 0 && (
          <Common.NoData
            iconRender={Icon.Search}
            iconClassName="stroke-neutral-30"
            title="No result founded"
          />
        )}
      </CustomScrollbar>

      {/* Modal Edit */}
      <ModalContent
        open={modelDuplicateSchedule}
        onClose={toggleModelDuplicateSchedule}
        onOverlayClick={toggleModelDuplicateSchedule}
        panelClassWidth="max-w-[32rem] w-full"
      >
        <ModalEditNameSchedule
          type="duplicate"
          scheduleName={`${(scheduleSelected?.name || "").slice(
            0,
            237
          )} (Duplicated)`}
          onClose={toggleModelDuplicateSchedule}
          onSubmitName={handleSubmitDuplicateSchedule}
        />
      </ModalContent>

      {/* Modal Delete */}
      <ModalContent
        open={modelDeleteSchedule}
        onClose={toggleModelDeleteSchedule}
        onOverlayClick={toggleModelDeleteSchedule}
        panelClassWidth="max-w-[32rem] w-full"
      >
        <ModelConfirmDeleteSchedule
          scheduleName={scheduleSelected?.name || ""}
          customerAssigned={scheduleSelected?.customerAssigned || 0}
          onCloseModel={toggleModelDeleteSchedule}
          onDeleteSchedule={handleDeleteSchedule}
          scheduleId={scheduleSelected?.id || ""}
        />
      </ModalContent>
    </div>
  );
};

export default ScheduleList;

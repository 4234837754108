import clsx from "clsx";

interface Props {
  viewBox: string;
  children?: any;
  className?: string;
  wrapClassName?: string;
  badgeClassName?: string;
  size?: number;
  badge?: string | number;
}

export const SvgIcon = ({
  className,
  wrapClassName,
  children,
  viewBox,
  size = 16,
  badge,
  badgeClassName,
}: Props) => {
  return badge ? (
    <span className={clsx("relative inline-block", wrapClassName)}>
      <svg
        width={size}
        height={size}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx("", className)}
      >
        {children}
      </svg>

      <span
        className={clsx(
          "absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold leading-none transform translate-x-1/2 -translate-y-1/2 rounded-full bg-red text-white",
          badgeClassName
        )}
      >
        {badge}
      </span>
    </span>
  ) : (
    <svg
      className={clsx("", className)}
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
};

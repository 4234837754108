import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M11.0357 1.5V2.1C11.0357 2.26568 10.8918 2.4 10.7143 2.4H1.07143C0.893909 2.4 0.75 2.26568 0.75 2.1V1.5C0.75 1.33431 0.893909 1.2 1.07143 1.2H3.96429V0.6C3.96429 0.268629 4.2521 0 4.60714 0H7.17857C7.53361 0 7.82143 0.268629 7.82143 0.6V1.2H10.7143C10.8918 1.2 11.0357 1.33431 11.0357 1.5ZM1.95214 10.884C1.99949 11.5137 2.56149 12.0015 3.23786 12H8.56071C9.23708 12.0015 9.79908 11.5137 9.84643 10.884L10.3929 3.6H1.39286L1.95214 10.884Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import debounce from "lodash/debounce";
import { Common } from "@/components";
import { useAppDispatch, useAppSelector } from "@/sharedStore/hooks";
import { pantryListSelector } from "../providers/selector";
import { fetchAutoPantryAsync, setNumberProducts } from "../providers/slice";

const Index = () => {
  const dispatch = useAppDispatch();
  const pantry = useAppSelector(pantryListSelector);

  const delayNumberProductsChange = debounce((numberProducts: number) => {
    dispatch(
      fetchAutoPantryAsync({
        id: pantry.id,
        customerAccountId: pantry.customerAccountId,
        supplierAccountId: pantry.supplierAccountId,
        numberProducts,
      })
    ).then(() => {
      dispatch(setNumberProducts(numberProducts));
      // SnackbarUtils.success("Auto pantry list updated successfully");
    });
  }, 700);
  return (
    <Common.Input
      type={"number"}
      defaultValue={pantry.numberProduct}
      onChange={(e) => {
        const numberProducts = Math.round(
          Number.isNaN(e.target.value) || Number(e.target.value) < 0
            ? 0
            : Number(e.target.value)
        );
        delayNumberProductsChange(numberProducts);
      }}
    />
  );
};

export default Index;

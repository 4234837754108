import { SvgIcon } from "./SvgIcon";
// Fill
function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5941 3H31C31.2764 3 31.5 3.22388 31.5 3.5V7.5C31.5 7.77612 31.2764 8 31 8H7C6.72363 8 6.5 7.77612 6.5 7.5V3.5C6.5 3.22388 6.72363 3 7 3H12.4059H25.5941Z"
      />
      <path d="M30.4902 23.4019C30.4707 23.304 29.9644 21 26 21H20.1815C20.3823 21.4818 20.5 21.9891 20.5 22.5C20.5 22.7761 20.2764 23 20 23H11C10.7236 23 10.5 22.7761 10.5 22.5C10.5 22.2239 10.7236 22 11 22H19.4526C19.1818 20.558 17.7955 19.2166 16.3478 19.0255C16.232 19.0141 16.1163 19 16 19H12.2593C10.9424 17.2224 8.41309 16 6 16H1C0.723633 16 0.5 16.2239 0.5 16.5V23.5C0.5 23.688 0.605469 23.8604 0.773438 23.9456L11.9932 29.655C12.6924 29.9729 13.4233 30.1321 14.1284 30.1321C14.748 30.1318 15.3481 30.009 15.8882 29.7634L30.188 23.9634C30.4106 23.873 30.5376 23.6375 30.4902 23.4019Z" />
      <path d="M12.7383 18H16C16.1299 18 16.2598 18.0115 16.3892 18.0247L16.4443 18.03C17.689 18.1941 18.8638 18.9626 19.6255 20H26C27.0088 20 27.825 20.1421 28.5 20.3597V9.5C28.5 9.22388 28.2764 9 28 9H10C9.72363 9 9.5 9.22388 9.5 9.5V15.7315C10.7629 16.2575 11.9039 17.0366 12.7383 18Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { manifest } from "../../models/DeliveryManifest";
import { sortManifestLine } from "../../utils";
import { routificSetting } from "../../models";

const API_PATH = "Delivery/";

export const fetchAllManifestByDateAsync = createAsyncThunk(
  "fetchAllManifestByDate",
  async (data: { date: string; isFetchETA: boolean }) => {
    return axios
      .post(`${API_PATH}GetManifests`, { date: data.date })
      .then((res) => {
        if ((window as any).getETAByManifestIdController) {
          (window as any).getETAByManifestIdController.abort();
        }

        if (res.data.errorMessage) {
          return res.data;
        }

        const manifests = res.data.data.manifests.map((manifest: manifest) => {
          return sortManifestLine(manifest);
        });

        return {
          ...res.data.data,
          manifests,
        };
      });
  }
);

export const fetchStorageAddressAsync = createAsyncThunk(
  "fetchStorageAddress",
  async () => {
    return axios.get(`${API_PATH}GetStorageAddress`).then((res) => {
      if (res.data.errorMessage) {
        return res.data;
      }

      return res.data.data;
    });
  }
);

export const getRoutificDefaultSettings = createAsyncThunk(
  "RoutificGetDefaultSettings",
  async () => {
    return axios.get(`${API_PATH}RoutificGetDefaultSettings`).then((res) => {
      return res.data.data;
    });
  }
);

export const updateRoutificDefaultSettings = createAsyncThunk(
  "RoutificUpdateDefaultSettings",
  async (data: routificSetting) => {
    return axios
      .post(`${API_PATH}RoutificSetDefaultSettings`, data)
      .then(() => {
        return data;
      });
  }
);

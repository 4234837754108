import { SvgIcon } from "./SvgIcon";

function IconComponent(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 12 12">
      <path d="M10.8929 9.71526C10.4791 7.27031 7.54517 6.46786 7.15649 6.3801C6.34156 7.01952 5.18796 7.01952 4.37302 6.3801C3.98436 6.46791 1.1131 7.25775 0.69934 9.71526C0.486202 10.7434 0.548909 11.6211 1.07551 11.7966C1.23616 11.848 1.44951 11.8402 1.69363 11.7966C1.92581 11.7552 2.18581 11.6816 2.45475 11.596C2.46726 11.596 2.46726 11.596 2.47985 11.5835H2.49236C2.83091 11.4707 3.06916 11.1572 3.06916 10.7936V10.756C3.06916 10.4792 2.93232 10.2318 2.72769 10.0827C2.70056 10.0629 2.67224 10.0449 2.64288 10.0287C2.64288 10.0287 2.67264 10.0485 2.72769 10.0827C3.08628 10.3055 4.5181 11.1428 5.78974 11.1428C7.12202 11.1428 8.66467 10.2186 8.91442 10.0633C8.93732 10.049 8.94935 10.0413 8.94935 10.0413C8.93754 10.0484 8.92589 10.0557 8.91442 10.0633C8.68246 10.2166 8.52308 10.4698 8.52308 10.7685V10.8061C8.52308 11.1823 8.77382 11.4957 9.11237 11.6085C9.50717 11.7226 9.0846 11.7753 9.44885 11.7966C9.60513 11.8058 9.90628 11.8092 10.479 11.8092C10.4915 11.8054 10.5036 11.8013 10.5159 11.7966C11.022 11.6042 11.1011 10.7196 10.8929 9.71526Z" />
      <path d="M2.45475 11.596C2.18581 11.6816 1.92581 11.7552 1.69363 11.7966H9.44885C9.0846 11.7753 9.50717 11.7226 9.11237 11.6085C8.77382 11.4957 8.52308 11.1823 8.52308 10.8061V10.7685C8.52308 10.4698 8.68246 10.2166 8.91442 10.0633C8.66467 10.2186 7.12202 11.1428 5.78974 11.1428C4.5181 11.1428 3.08628 10.3055 2.72769 10.0827C2.93232 10.2318 3.06916 10.4792 3.06916 10.756V10.7936C3.06916 11.1572 2.83091 11.4707 2.49236 11.5835H2.47985C2.46726 11.596 2.46726 11.596 2.45475 11.596Z" />
      <path d="M8.52311 3.4086C8.52311 4.98742 7.30498 6.2673 5.80233 6.2673C4.29969 6.2673 3.08154 4.98742 3.08154 3.4086C3.08154 1.82977 4.29967 0.549896 5.80233 0.549896C7.30496 0.549896 8.52311 1.82977 8.52311 3.4086Z" />
    </SvgIcon>
  );
}

export default IconComponent;

import clsx from "clsx";
import { Timeline as TimelineModel } from "./TimelineModel";

interface Props {
  timelines: TimelineModel[];
  timestampClass?: string;
  titleClass?: string;
  descriptionClass?: string;
  nodeSize?: "sm" | "md" | "lg";
  nodeStyle?: string;
  nodeIcon?: React.ElementType;
  connectorColor?: string;
}
export const Timeline = ({
  timelines,
  timestampClass,
  titleClass,
  descriptionClass,
  nodeSize = "sm",
  nodeStyle,
  nodeIcon: NodeIcon = undefined,
  connectorColor,
}: Props) => {
  const Timestamp = (timeline: TimelineModel) => {
    return (
      timeline.timestamp && (
        <p className={clsx("text-neutral-50 text-sm", timestampClass)}>
          {timeline.timestamp}
        </p>
      )
    );
  };
  const Title = (timeline: TimelineModel) => {
    return (
      timeline.title && (
        <h4 className={titleClass || "text-neutral-80 font-semibold"}>
          {timeline.title}
        </h4>
      )
    );
  };
  const Description = (timeline: TimelineModel) => {
    return (
      timeline.description && (
        <p className={clsx("text-neutral-50", descriptionClass)}>
          {timeline.description}
        </p>
      )
    );
  };

  const handleNodeSize = () => {
    if (NodeIcon) {
      return "w-6 h-6 flex items-center justify-center";
    }
    switch (nodeSize) {
      case "sm":
        return "w-2 h-2";
      case "md":
        return "w-4 h-4";
      case "lg":
        return "w-6 h-6";
      default:
        return "w-2 h-2";
    }
  };

  const handleNodeColor = () => {
    return nodeStyle || "bg-white border border-neutral-30";
  };

  const handleNodePosition = () => {
    if (NodeIcon) {
      return "-ml-3";
    }
    switch (nodeSize) {
      case "sm":
        return "-ml-1";
      case "md":
        return "-ml-2";
      case "lg":
        return "-ml-3";
      default:
        return "-ml-1";
    }
  };

  const handleContentPosition = () => {
    if (NodeIcon) {
      return "ml-6";
    }
    switch (nodeSize) {
      case "sm":
        return "ml-4";
      case "md":
        return "ml-5";
      case "lg":
        return "ml-6";
      default:
        return "ml-4";
    }
  };

  const handleConnecterColor = () => {
    if (connectorColor) {
      return `border-${connectorColor}`;
    }
    return "border-neutral-30";
  };

  return (
    <>
      <ol className={clsx("m-4 border-l", handleConnecterColor())}>
        {timelines.map((timeline, idx) => {
          const elements = [
            Title(timeline),
            Timestamp(timeline),
            Description(timeline),
          ].filter((x) => !!x);
          return (
            elements.length > 0 && (
              <li key={idx}>
                <div className="flex flex-start items-center">
                  <div className={clsx("mr-3", handleNodePosition())}>
                    <div
                      className={clsx(
                        "rounded-full",
                        handleNodeColor(),
                        handleNodeSize()
                      )}
                    >
                      {NodeIcon && (
                        <NodeIcon
                          iconType="fill"
                          className="fill-white w-3 h-3"
                        />
                      )}
                    </div>
                  </div>
                  {elements[0]}
                </div>
                {elements.length > 1 && (
                  <div className={clsx("mt-0.5 mb-4", handleContentPosition())}>
                    <div className="mb-1.5">{elements[1]}</div>

                    {elements.length === 3 && (
                      <div className="mb-3">{elements[2]}</div>
                    )}
                  </div>
                )}
              </li>
            )
          );
        })}
      </ol>
    </>
  );
};
